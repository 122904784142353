<div class="container bg-white py-3 px-5" *ngIf="!isSkeletonLoader" @fadeInOut>
    <div class="d-flex align-items-center justify-content-between text-muted">
        <div class="d-flex align-items-center gap-2 justify-content-start w-100">
            <button (click)="back()" class="custom-btn  d-flex align-items-center">
                <i class="icon-arrow-left fw-semibold"></i>
            </button>
            <div class="d-flex gap-2 align-items-center">
                <i class="fa fa-money-bill"></i>
                <span>Payment</span>
            </div>
        </div>
        <span>{{transaction?.Description}}</span>
    </div>

    <div class="d-flex align-items-center justify-content-between my-4">
        <div class="d-flex gap-2 align-items-center">
            <span class="text-black fw-semibold fs-4">
                {{ transaction?.Amount }}
            </span>
            <span class="fs-4"> USD</span>
            <!-- <span class="custom-badge d-flex align-items-center"
                [ngClass]="{'bg-success': transaction.PaymentStatus === 'Paid', 'bg-danger ': transaction.PaymentStatus === 'Failed'}">
                {{ transaction.PaymentStatus || 'N/A'}}
                <i *ngIf="transaction.PaymentStatus === 'Paid'" class="fa fa-check"></i>
                <i *ngIf="transaction.PaymentStatus === 'Failed'" class="fa fa-x"></i>
            </span> -->

            <span class="custom-badge d-flex align-items-center" [ngClass]="{
                'bg-success': transaction?.PaymentStatus === 'Paid' || transaction?.PaymentStatus === 'RECEIVED' || transaction?.PaymentStatus === 'Captured',
                'bg-danger': transaction?.PaymentStatus === 'FAILED' || transaction?.PaymentStatus === 'Failed',
                'bg-warning': transaction?.PaymentStatus === 'REFUNDED',
                'bg-dark text-white': transaction?.PaymentStatus === 'Declined'
              }">
                {{ transaction?.PaymentStatus || 'FAILED'}}
                <i *ngIf="transaction?.PaymentStatus === 'Paid' || transaction?.PaymentStatus === 'Captured'" class="fa fa-check"></i>
                <i *ngIf="transaction?.PaymentStatus === 'FAILED' || transaction?.PaymentStatus === 'Failed'" class="fa fa-times"></i>
                <i *ngIf="!transaction?.PaymentStatus " class="fa fa-times"></i>
                <i *ngIf="transaction?.PaymentStatus === 'REFUNDED'" class="fa fa-undo"></i>
                <i *ngIf="transaction?.PaymentStatus === 'RECEIVED'" class="fa fa-check"></i>
                <i *ngIf="transaction?.PaymentStatus === 'Declined'" class="fa fa-ban"></i>
            </span>
        </div>
        <div class="d-flex gap-2  align-items-center">
            <app-filter-btn [title]="'Refund'" [isBtn]="true" (click)="onRefundClick(refundModal)"></app-filter-btn>
        </div>
    </div>

    <div class="info-wrapper">
        <div class="d-flex align-items-center gap-4 p-3">
            <div class="border-light">
                <div class="d-flex align-items-center gap-2 flex-column justify-content-start w-100">
                    <span class="fw-semibold text-muted fs-5">Last update</span>
                    <span class="fw-semibold">{{ transactionInfo?.payment?.CreatedDate |date}} </span>
                </div>
            </div>
            <div class="border-light">
                <div class="d-flex align-items-center flex-column gap-2 justify-content-start w-100">
                    <span class="fw-semibold text-muted fs-5">Customer</span>
                    <span class="fw-semibold">{{transactionInfo?.customer?.FirstName + " "
                        +transactionInfo?.customer?.LastName}}</span>
                </div>
            </div>
            <div class="border-light">
                <div class="d-flex align-items-center flex-column gap-2 justify-content-start w-100">
                    <span class="fw-semibold text-muted fs-5">Payment Method</span>
                    <div class="d-flex align-items-center gap-2 justify-content-start ">
                        <img width="30" height="30" src="../../../assets/svg/visa.png"
                            *ngIf="transactionDetail?.payment?.creditCard?.cardType=='Visa'">
                        <img width="30" height="30" src="assets/svg/card.png"
                            *ngIf="transactionDetail?.payment?.creditCard?.cardType=='MasterCard'">
                        <img width="30" height="30" src="assets/svg/symbol.png"
                            *ngIf="transactionDetail?.payment?.creditCard?.cardType=='Klarna'">
                        <img width="30" height="30" src="assets/svg/americanExpress.webp"
                            *ngIf="transactionDetail?.payment?.creditCard?.cardType=='AmericanExpress'">
                        <span>
                            {{ transactionInfo?.transactionDetail?.payment?.creditCard?.cardNumber}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="d-flex justify-content-between my-3 ">
        <span class=" text-black fw-semibold fs-4">Checkout summary</span>
    </div>
    <div class="info-wrapper border-bottom-0 p-3">
        <div class="d-flex gap-4">
            <span class="text-muted">Customer</span>
            <div class="d-flex flex-column gap-1">
                <span class=" text-muted ">{{transactionInfo?.customer?.Email}}</span>
                <span class=" text-muted ">{{transactionInfo?.customer?.FirstName + " "
                    +transactionInfo?.customer?.LastName}}</span>
                <span class=" text-muted ">{{transactionDetail?.billTo?.address}}</span>
                <span class=" text-muted ">{{transactionDetail?.billTo?.city}} ,
                    {{transactionDetail?.billTo?.state}}</span>
            </div>
        </div>
    </div>

    <div class="">
        <div class="item-details-container">
            <table class="table">
                <thead>
                    <tr>
                        <th>ITEMS</th>
                        <th>QTY</th>
                        <th>UNIT PRICE</th>
                        <th>AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transaction of transactionInfo?.invoice?.invoice_items">
                        <td>
                            {{ transaction?.Description }}
                        </td>
                        <td>1</td>
                        <td>${{ transaction?.Amount }}</td>
                        <td>${{ transaction?.Amount }}</td>
                    </tr>
                    <tr *ngIf="transactionInfo?.invoice?.invoice_items.length==0">
                        <td colspan="4" class="text-center">No item found</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="3">Total</td>
                        <td>${{ transaction?.Amount }}</td>
                    </tr>
                </tfoot>
            </table>
        </div>

    </div>


    <div class="d-flex justify-content-between my-3 ">
        <span class=" text-black fw-semibold fs-4">Payment breakdown</span>
    </div>
    <div class=" border-bottom-0 py-3">
        <div class="d-flex flex-column w-100 justify-content-start">
            <div class="d-flex info-wrapper border-top-0 justify-content-between py-2">
                <span class="text-muted">Payment amount</span>
                <span class="text-muted">${{ transaction?.Amount }} USD</span>
            </div>
            <div class="d-flex info-wrapper border-top-0 justify-content-between py-2">
                <span class="text-muted">Tax</span>
                <span class="text-muted">0 USD</span>
            </div>
            <div class="d-flex info-wrapper border-top-0 justify-content-between py-2">
                <span class="text-bold">Total</span>
                <span class="">${{ transaction?.Amount }} USD</span>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-between my-3">
        <span class="text-black fw-semibold fs-4">Payment details</span>
    </div>

    <div class="info-wrapper p-3">
        <div class="d-flex flex-column gap-2 ">
            <div class="d-flex justify-content-between ">
                <span class="text-muted">Statement descriptor</span>
                <span>{{transactionInfo?.payment?.MessageCode}}</span>
            </div>
            <div class="d-flex justify-content-between ">
                <span class="text-muted">Description</span>
                <span *ngIf="transaction?.Description; else editLink">{{ transaction?.Description }}</span>
                <ng-template #editLink>
                    <a href="#" class="text-primary">Edit</a>
                </ng-template>
            </div>
        </div>
    </div>


    <div class="d-flex justify-content-between my-3">
        <span class="text-black fw-semibold fs-4">Payment method</span>
    </div>

    <div class="info-wrapper p-3" *ngIf="transactionInfo?.transactionDetail">
        <div class="d-flex justify-content-between mb-3">

            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">ID</span>
                <span>{{ transactionInfo?.transactionDetail?.transId }} </span>
            </div>


            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Owner</span>
                <span>{{ transactionInfo?.customer?.FirstName }}{{ transactionInfo?.customer?.LastName }}</span>
            </div>



        </div>

        <div class="d-flex justify-content-between mb-3">

            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Number</span>
                <span> {{ transactionInfo?.transactionDetail?.payment?.creditCard?.cardNumber }}</span>
            </div>


            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Owner email</span>
                <span>{{ transactionInfo?.customer?.Email }}</span>
            </div>


        </div>

        <div class="d-flex justify-content-between mb-3">

            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Type</span>
                <span>{{ transactionInfo?.transactionDetail?.payment?.creditCard?.cardType }}</span>
            </div>
            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Zip check</span>
                <span [ngClass]="{'text-success': transaction.ZipCheck === 'Passed'}">{{ transaction.ZipCheck }}</span>
            </div>

            <!-- <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Issuer</span>
                <span>{{ transaction.CardIssuer }}</span>
            </div> -->

        </div>

        <div class="d-flex justify-content-between mb-3">
            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Street check</span>
                <span [ngClass]="{'text-success': transaction.StreetCheck === 'Passed'}">{{ transaction.StreetCheck
                    }}</span>
            </div>

        </div>
        <div class="d-flex justify-content-between mb-3">
            <!-- <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Fingerprint</span>
                <a href="#" class="text-primary">{{ transaction.CardFingerprint }}</a>
            </div> -->
            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Expires</span>
                <span>{{ transactionInfo?.transactionDetail?.payment?.creditCard?.expirationDate }}</span>
            </div>
        </div>
        <div class="d-flex justify-content-between mb-3">

            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Address</span>
                <span>{{ transactionInfo?.transactionDetail?.billTo?.address }}</span>
            </div>

        </div>
    </div>
    <div *ngIf="!transactionInfo?.transactionDetail" class="d-flex justify-content-center">
        <span>No data found</span>
    </div>
    <div class="p-0">
        <div class="d-flex justify-content-between  my-3">
            <span class="text-black fw-semibold fs-4">Invoice</span>
        </div>
        <app-dynamic-invoice-template [CustomerId]="transactionInfo?.customer?.CustomerId ? transactionInfo?.customer?.CustomerId : CustomerId" [isCallFromTransactionDetail]="true" [invoiceId]="invoiceId"></app-dynamic-invoice-template>
    </div>


    <!-- Events and Logs Section -->
    <div class="d-flex justify-content-between my-3 mt-4" *ngIf="activityData.length>0">
        <span class="text-black fw-semibold fs-4">Timeline</span>
    </div>

    

    <div class="timeline" *ngIf="activityData.length>0">
        <div class="timeline-item align-items-center" *ngFor="let event of activityData">
            <div class="timeline-line">
                <div class="timeline-icon">
                    <i class="fa fa-dollar-sign"></i>
                </div>
            </div>
            <div class="timeline-content">
                <div class="timeline-header">
                    <strong class="person-name text-nowrap">{{ event.CreatedByName }}</strong>
                    <span class="action">{{ event.ActivityDetails }}</span>
                    <small class="person-name text-nowrap">{{ event.CreatedDate | date
                        }}</small>
                </div>
    
            </div>
    
        </div>
    </div>
    



</div>

<div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;" class="container bg-white py-3 px-5">
    <div class="card">
        <app-skeleton-loader shape="transaction-card"></app-skeleton-loader>
    </div>
</div>




<ng-template #refundModal let-modal>
    <!-- Modal Header -->
    <div class="modal-header bg-primary text-white">
        <h5 class="modal-title mb-0">Refund Transaction</h5>
    </div>

    <!-- Modal Body -->
    <div class="modal-body">
       

        <!-- Customer Information -->
        <div class="row mb-2">
            <label class="col-6 form-label fw-bold">Customer Name:</label>
            <div class="col-6">{{ transactionInfo?.customer?.FirstName + " " + transactionInfo?.customer?.LastName }}</div>
        </div>
        <div class="row mb-2">
            <label class="col-6 form-label fw-bold">Customer Email:</label>
            <div class="col-6">{{transactionInfo?.customer?.Email}}</div>
        </div>

        <div class="row mb-2">
            <label class="col-6 form-label fw-bold">Payment Method:</label>
            <div class="col-6">XXXX8274</div>
        </div>

        <div class="row mb-2">
            <label class="col-6 form-label fw-bold">Expiration Date:</label>
            <div class="col-6">XXXX</div>
        </div>
        <div class="row mb-2">
            <label class="col-6 form-label fw-bold">Invoice #:</label>
            <div class="col-6">{{transactionInfo?.invoice?.id}}</div>
        </div>
   

        <!-- Refund Amount -->
        <div class="mb-3">
            <label for="refundAmount" class="form-label fw-bold">Refund Amount:</label>
            <div class="d-flex align-items-center">
                <input type="number" id="refundAmount" class="form-control" [(ngModel)]="refundAmount"
                    placeholder="Enter amount" min="0" required />
            </div>
        </div>

        

        <!-- Refund Reason -->
        <div class="mb-3">
            <label for="refundReason" class="form-label fw-bold">Refund Reason</label>
            <ng-select  [(ngModel)]="refundReason">
                <ng-option [value]="item.id" *ngFor="let item of refundReasonList">{{item?.Description}}<</ng-option>
            </ng-select>
        </div>

        <!-- Customer Email -->
        

        <p class="text-muted mb-3">
            Click OK to continue this action and refund this transaction. Click Cancel to abort this action and
            return to the previous page.
        </p>
    </div>

    <!-- Modal Footer -->
    <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="btn btn-primary btn-sm" (click)="confirmRefund()">OK</button>
        <button type="button" class="btn btn-secondary btn-sm"
            (click)="modal.dismiss('Cancel click')">Cancel</button>
    </div>
</ng-template>