import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class PurchaseHistoryService {

  api: string = environment.BASE_API;

  constructor(
    private http: HttpService
  ) { }

  getPlanHistory(data) {
    try {
      return this.http.get('api/Customer/GetPlanHistory',data,false,true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  
  getPosReport(data: any): Observable<any> {
    try {
      const url = 'api/report/getPosReport';
      return this.http.get(url, data, false, true); // Ensure this returns an Observable
    } catch (err) {
      console.error('Error in getPosReport:', err);
      return throwError(() => new Error(err));
    }
  }
  

}
