import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { TagsService } from 'src/app/shared/services/tags.service';
import { CommonService } from '../../../../shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-customer-specific-tags',
  templateUrl: './customer-specific-tags.component.html',
  styleUrls: ['./customer-specific-tags.component.scss']
})
export class CustomerSpecificTagsComponent implements OnInit{

  @Input() customerData:any;
  deviceTags:any;
  orderTags: any;
  availableTags: any;
  availableDeviceTags:any;
  filteredTags: string[] = [];
  filteredDeviceTags: string[] = [];
  newDeviceTag: string = 'Device';
  allAvailableMainTags:any;
  showDeviceDropdown: boolean = false;
  showInput: boolean = false;
  newOrderTag: string = '';
  showOrderInput: boolean = false;
  showDropdown: boolean = false;
newTagName: any;
  processingTag: any;
  selectedOrderTagIds: number[] = [];  // Tracks IDs of selected tags
  customerOrders:any;
  displayedTags: any[] = [];
  showAllTags = false;
  constructor(
    private tagsService:TagsService, 
    private commonService:CommonService,
    private customerProfileService:CustomerProfileService,
    private cdr:ChangeDetectorRef
  ){

  }
 async ngOnInit() {
    this.getMinimalProfile();
   await this.getCustomerSubCategory();
   await this.getOrderSubCategory();
    this.getAwaitingShipmentOrder();
      // this.getAllTags();
      // this.getCustomerSubCategory()
  }
  toggleInput() {
    this.showInput = true;
    this.newDeviceTag = '';
  }

  closeInput() {
    this.showInput = false;
    this.newDeviceTag = '';
    this.showDeviceDropdown = false;
  }
  toggleOrderInput() {
    this.showOrderInput = true;
    this.showDropdown = false;
  }

  closeOrderInput() {
    this.showOrderInput = false;
    this.newOrderTag = '';
    this.showDropdown = false;
  }
  async getCustomerSubCategory() {
    try {
      let res: any;
      try {
        res = await this.tagsService.getCustomerSubTags(); // Use await
      } catch (apiError) {
      }
      this.availableDeviceTags = res?.Status ? res?.data?.data || [] : [];
      // if (!this.availableDeviceTags.some(tag => tag.Name === "Create Device Tag")) {
      //   this.availableDeviceTags = [...this.availableDeviceTags, { id: null, Name: "Create Device Tag" }];
      // }
      this.showDeviceDropdown = this.availableDeviceTags.length > 0;
    } catch (error) {
      console.error('Unexpected Error:', error);
    }
  }
  async getOrderSubCategory() {
    try {
      console.log('Fetching order sub-tags...');
      
      let res: any;
      try {
        res = await this.tagsService.getOrderSubTags(); // Use await
      } catch (apiError) {
        console.error('API Error:', apiError);
      }
      this.availableTags = res?.Status ? res?.data?.data || [] : [];
      // if (!this.availableTags.some(tag => tag.Name === "Create Order Tag")) {
      //   this.availableTags = [...this.availableTags, { id: null, Name: "Create Order Tag" }];
      // }
      this.showDropdown = this.availableTags.length > 0;
      this.updateDisplayedTags();
    } catch (error) {
      console.error('Unexpected Error:', error);
    }
  }
  updateDisplayedTags() {
    this.displayedTags = this.showAllTags ? this.availableTags : this.availableTags.slice(0, 5);
  }

  toggleTagView() {
    this.showAllTags = !this.showAllTags;
    this.updateDisplayedTags();
  }
  getRandomOddNumber(min: number, max: number): number {
    let randomOdd = Math.floor(Math.random() * ((max - min) / 2)) * 2 + min;
    return randomOdd % 2 === 0 ? randomOdd + 1 : randomOdd;
  }
  async createNewDeviceTag() {
    if (!this.newDeviceTag.trim()) return; // Prevent empty input
    const tagTypeId = this.getRandomOddNumber(700, 1700);
    try {
      const response: any = await this.tagsService.createCustomerTag(this.newDeviceTag, tagTypeId);
      if (response?.Status) {
        this.commonService.showToast('success', 'Creating Tag', 'Tag Created Successfully');
        const newTag = { id: response.data.id, Name: this.newDeviceTag };
        this.deviceTags = [...this.deviceTags, newTag];
        this.showDeviceTagInput=false;
      } else {
        this.commonService.showToast('info', 'Creating Tag', response?.message);
      }
    } catch (error) {
      this.commonService.showToast('error', 'Creating Tag', error?.message);
    }
    this.newTagName = '';
  }
  
  async createNewOrderTag() {
    if (!this.selectedOrder) {
      this.commonService.showToast('info', 'Select Order', 'Please select an order first.');
      return;
    }
    if (!this.newOrderTag.trim()) return; // Prevent empty input
  
    const tagTypeId = this.getRandomOddNumber(700, 1700);
  
    try {
      const response: any = await this.tagsService.createOrderTag(this.newOrderTag, tagTypeId);
      if (response?.Status) {
        this.commonService.showToast('success', 'Creating Tag', 'Order Tag Created Successfully');
        const newTag = { id: response.data.id, Name: this.newOrderTag };
        this.orderTags = [...this.orderTags, newTag];
        this.showOrderTagInput = false;
      } else {
        this.commonService.showToast('info', 'Creating Order Tag', response?.message);
      }
    } catch (error) {
      this.commonService.showToast('error', 'Creating Order Tag', error?.message);
    }
    this.newOrderTag = '';
  }
  
  
  
  async getMinimalProfile() {
    try {
      const response: any = await this.customerProfileService.getCustomerMinimalProfileData();
      if (response?.Status) {
        this.customerData = response?.data;
        this.deviceTags = response?.data?.customerTags ? [...response?.data?.customerTags] : [];
        this.orderTags = response?.data?.orderTags ? [...response?.data?.orderTags] : [];
      } else {
        this.deviceTags = [];
        this.orderTags = [];
      }
    } catch (error) {
      this.commonService.showToast('error', 'Fetching Customer Tags', error?.message);
    }
  }
  
  selectedOrder: any = null;
  async getAwaitingShipmentOrder() {
    if (!this.customerData?.personalInfo?.customerId) {
      return; // Stop execution if customerId is missing
    }
    const data = {
      per_page: 50,
      page: 1,
      CustomerId: String(this.customerData?.personalInfo?.customerId || ''),
      orderBy: "desc",
    };
  
    try {
      const response: any = await lastValueFrom(this.commonService.getAwaitingShipmentOrder(data));
  
      if (response?.Status) {
        this.customerOrders = response?.data?.Orders?.data;
        this.customerOrders = this.customerOrders.map(order => ({
          ...order,
          displayLabel: `#${order.OrderId} - ${order.OrderType}`
        }));
        if (this.customerOrders.length === 1) {
          this.selectedOrder = this.customerOrders[0];
        }
      } else {
        console.warn('⚠️ API Response Status is False:', response);
      }
    } catch (error) {
      console.error('❌ Error Fetching Awaiting Shipment Order:', error);
    }
  }
  onOrderSelect() {
    console.log('✅ Selected Order:', this.selectedOrder);
  }
  
  getAllTags(){
    try {
      const response:any = this.tagsService.getTagTypes();
      if(response?.Status){
        this.allAvailableMainTags = response?.data;
      }else{
      }
    } catch (error) {
      this.commonService.showToast('error','Available Tag Types', error?.message)
    }
  }
  // Add Device Tag
  addDeviceTag() {
    if (this.newDeviceTag.trim()) {
      this.deviceTags.push(this.newDeviceTag);
      this.newDeviceTag = '';
      this.showInput = false;
      this.showDeviceDropdown = false;
    }
  }

  addOrderTag() {
    if (this.newOrderTag.trim()) {
      this.orderTags.push(this.newOrderTag);
      this.newOrderTag = '';
      this.showOrderInput = false;
      this.showDropdown = false;
    }
  }

  async removeDeviceTag(tag: any) {
    try {
      const customerId = this.customerData?.personalInfo?.customerId; // Ensure customerId is available
      if (!customerId) {
        console.error('Customer ID is missing!');
        return;
      }
      const response: any = await this.tagsService.removeCustomerTags(customerId, [tag.id]);
      if (response?.Status) {
        this.commonService.showToast('success', 'Removing Customer Tag', 'Tag Removed Successfully');
        this.deviceTags = this.deviceTags.filter(t => t.id !== tag.id);
        this.cdr.detectChanges();
      } else {
        this.commonService.showToast('info', 'Removing Customer Tag', response?.message);
      }
    } catch (error) {
      this.commonService.showToast('error', 'Removing Customer Tag', error?.message);
    }
  }
  
  

  async removeOrderTag(tag: any) {
    try {
      if (!this.selectedOrder) {
        this.commonService.showToast('info', 'Select Order', 'Please select an order first.');
        return;
      }
      const customerId = this.customerData?.personalInfo?.customerId; // Ensure customerId is available
      if (!customerId) {
        console.error('Customer ID is missing!');
        return;
      }
      const response: any = await this.tagsService.removeOrderTags(this.selectedOrder?.OrderId, [tag.id]);
      if (response?.Status) {
        this.commonService.showToast('success', 'Removing Order Tag', 'Tag Removed Successfully');
        this.orderTags = this.orderTags.filter(t => t.id !== tag.id);
        this.cdr.detectChanges();
      } else {
        this.commonService.showToast('info', 'Removing Order Tag', response?.message);
      }
    } catch (error) {
      this.commonService.showToast('error', 'Removing Order Tag', error?.message);
    }
  }
  

  clearAll() {
    this.deviceTags = [];
    this.orderTags = [];
  }
  filterSuggestions() {
    this.filteredTags = this.availableTags.filter(tag => 
      tag.toLowerCase().includes(this.newOrderTag.toLowerCase()) && !this.orderTags.includes(tag)
    );
    this.showDropdown = this.filteredTags.length > 0;
  }
    // Device Tag Filtering
    filterDeviceSuggestions() {
      if (!this.newDeviceTag) {
        this.filteredDeviceTags = [...this.availableDeviceTags]; // Show all if no input
      } else {
        this.filteredDeviceTags = this.availableDeviceTags.filter(tag =>
          tag.toLowerCase().includes(this.newDeviceTag.toLowerCase()) && !this.deviceTags.includes(tag)
        );
      }
      this.showDeviceDropdown = this.filteredDeviceTags.length > 0;
    }
  
    selectedDeviceTagIds: number[] = []; // Ensure it's an empty array

    
    async selectDeviceTag(tag: any) {
      if (!tag || !tag.id || !tag.Name) {
        console.warn('Invalid tag detected:', tag);
        return;
      }
      const isAlreadyAdded = this.deviceTags.some(existingTag => existingTag.id === tag.id);
      if (isAlreadyAdded) {
        this.commonService.showToast('info', 'Tag Already Exists', 'This tag is already assigned.');
        return; // Exit early if duplicate
      }
      this.selectedDeviceTagIds.push(tag.id);
      const isAdded = await this.addCustomerTags(tag);
      if (isAdded) {
        if (!this.deviceTags.some(t => t.id === tag.id)) {
          this.deviceTags = [...this.deviceTags, tag];
        }
      } else {
        console.warn('Tag addition failed, not updating UI.');
      }
    
      this.newDeviceTag = ''; // Reset input field
      this.showDeviceDropdown = false; // Close dropdown
    }
    
    
    async selectOrderTag(tag: any) {
      if (!this.selectedOrder) {
        this.commonService.showToast('info', 'Select Order', 'Please select an order first.');
        return;
      }
      if (!tag || !tag.id || !tag.Name) {
        console.warn('Invalid tag detected:', tag);
        return;
      }
      const isAlreadyAdded = this.orderTags.some(existingTag => existingTag.id === tag.id);
      if (isAlreadyAdded) {
        this.commonService.showToast('info', 'Tag Already Exists', 'This tag is already assigned.');
        return; // Exit early if duplicate
      }
      this.selectedOrderTagIds.push(tag.id);
      const isAdded = await this.addOrderTags(tag, this.selectedOrder?.OrderId);
      if (isAdded) {
        if (!this.orderTags.some(t => t.id === tag.id)) {
          this.orderTags = [...this.orderTags, tag];
        }
      } else {
        console.warn('Tag addition failed, not updating UI.');
      }
    
      this.newOrderTag = ''; // Reset input field
      this.showDropdown = false; // Close dropdown
    }

    async addOrderTags(tag: any, orderId:any): Promise<boolean> {
      try {
        const customerId = this.customerData?.personalInfo?.customerId; // Ensure customerId is available
        if (!customerId) {
          console.error('Customer ID is missing!');
          return false;
        }
    
        const response: any = await this.tagsService.addOrderTags(orderId, this.selectedOrderTagIds);
        if (response?.Status) {
          this.commonService.showToast('success', 'Adding Order Tags', 'Tags Added Successfully');
          if (!this.orderTags.some(t => t.id === tag.id)) {
            this.orderTags = [...this.orderTags, tag];
          }
          this.cdr.detectChanges();
          return true; 
        } else {
          this.commonService.showToast('info', 'Adding Order Tags', response?.message);
          return false; 
        }
      } catch (error) {
        this.commonService.showToast('error', 'Adding Order Tags', error?.message);
        return false; 
      }
    }
    
    
    

    async addCustomerTags(tag: any): Promise<boolean> {
      try {
        const customerId = this.customerData?.personalInfo?.customerId; // Ensure customerId is available
        console.log('Customer ID:', customerId);
        if (!customerId) {
          console.error('Customer ID is missing!');
          return false;
        }
        const response: any = await this.tagsService.addCustomerTags(customerId, this.selectedDeviceTagIds);
        if (response?.Status) {
          this.commonService.showToast('success', 'Adding Customer Tags', 'Tags Added Successfully');
          this.cdr.detectChanges();
          return true; 
        } else {
          this.commonService.showToast('info', 'Adding Customer Tags', response?.message);
          return false;
        }
      } catch (error) {
        this.commonService.showToast('error', 'Adding Customer Tags', error?.message);
        return false; 
      }
    }
    
    
    
    async openDeviceDropdown() {
      await this.getCustomerSubCategory(); 
      this.showDeviceDropdown = this.availableDeviceTags.length > 0;
    }
    
  
    closeDeviceDropdownWithDelay() {
      setTimeout(() => {
        this.showDeviceDropdown = false;
      }, 200);
    }

  openDropdown() {
    this.showDropdown = true;
    this.filterSuggestions();
  }
 
  closeDropdownWithDelay() {
    setTimeout(() => {
      this.showDropdown = false;
    }, 200); 
  }
  removeTagFromSuggestions(tag: string) {
    this.availableTags = this.availableTags.filter(t => t !== tag);
    this.filterSuggestions(); 
  }
  showDeviceTagInput: boolean = false;
  showOrderTagInput: boolean = false;
  hideDeviceTagInput() {
    this.showDeviceTagInput = false;
  }
  
  hideOrderTagInput() {
    this.showOrderTagInput = false;
  }


  // Detect clicks outside the input field
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;

    // Check if the click is outside the input area
    if (!targetElement.closest('.device-tag-input-container')) {
      this.showDeviceTagInput = false;
    }
  }

  // Show the input field
  showTagInput() {
    this.showDeviceTagInput = true;
  }
  
}
