import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
interface Customer {
  id: number;
  name: string;
  accountNumber: string;
  subscriptionId: string;
  mdn: string;
  plan: string;
  balance: number;
  paymentHistory: string[];
}

@Component({
  selector: 'app-refund-multi-step-form',
  templateUrl: './refund-multi-step-form.component.html',
  styleUrls: ['./refund-multi-step-form.component.scss']
})
export class RefundMultiStepFormComponent {
  refundForm: FormGroup;
  step = 1;
  selectedCustomer: Customer | null = null;
  customerList: Customer[] = [
    {
      id: 1,
      name: 'John Doe',
      accountNumber: 'ACC12345',
      subscriptionId: 'SUB67890',
      mdn: '555-1234',
      plan: 'Premium Plan - $50/month',
      balance: 25.75,
      paymentHistory: ['Paid $50 on Jan 1', 'Paid $50 on Dec 1'],
    },
    {
      id: 2,
      name: 'Jane Smith',
      accountNumber: 'ACC67890',
      subscriptionId: 'SUB54321',
      mdn: '555-5678',
      plan: 'Basic Plan - $30/month',
      balance: 0.0,
      paymentHistory: ['Paid $30 on Jan 1', 'Paid $30 on Dec 1'],
    },
  ];
  constructor(private fb: FormBuilder) {
    this.refundForm = this.fb.group({
      customerSelection: ['', Validators.required],
      refundType: ['', Validators.required],
      reason: ['', Validators.required],
      notes: [''],
      calculatedAmount: [{ value: '', disabled: true }, Validators.required],
      manualAdjustment: [''],
      approver: ['', Validators.required],
      attachment: [''],
    });
  }

  nextStep() {
    if (this.step < 5) this.step++;
  }

  prevStep() {
    if (this.step > 1) this.step--;
  }

  submitForm() {
    if (this.refundForm.valid) {
      console.log('Form Submitted', this.refundForm.value);
      alert('Refund Request Submitted Successfully!');
    }
  }
  searchCustomer(value: any) {
    let values = value.target.value
    this.selectedCustomer = this.customerList.find(
      (c) =>
        c.accountNumber.toLowerCase() === values.toLowerCase() ||
        c.name.toLowerCase().includes(values.toLowerCase()) ||
        c.subscriptionId.toLowerCase() === values.toLowerCase() ||
        c.mdn === value
    ) || null;
  }
  }

