import { Component } from '@angular/core';

@Component({
  selector: 'app-notification-email-dashboard',
  templateUrl: './notification-email-dashboard.component.html',
  styleUrls: ['./notification-email-dashboard.component.scss']
})
export class NotificationEmailDashboardComponent {
  activeComponent: string = 'EmailTemplate';

  setActiveComponent(component: string) {
    this.activeComponent = component;
  }
}
