import { ChangeDetectorRef, Component } from '@angular/core';
import { HttpService } from 'src/app/shared/services/httpService.service';
import Echo from 'laravel-echo';
import * as Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-all-visitors',
  templateUrl: './all-visitors.component.html',
  styleUrls: ['./all-visitors.component.scss']
})
export class AllVisitorsComponent {
  customerId:any;
  visitors: any[]=[];
  activeVisitors: any[]=[];
  private echo: any;
  company:any
  hideAndShow:any = 'All'
  valueShow:boolean = true;
  openSearchBox:boolean = false
  searchTerm:any
  constructor(private http: HttpService,private cdr: ChangeDetectorRef) {
    this.getBaseUrl()
    this.customerId = this.http.getCustomerId()
  }

  ngOnInit() {
    console.log("visitors 1",this.activeVisitors) 
    this.initializeEcho();
    this.fetchVisitors();

  }
  hideandShow(e){
    if(this.hideAndShow == e){
     this.valueShow = true
    }else{
      this.valueShow = false
    }

  }
    getBaseUrl(): void {
      this.company = localStorage.getItem('currentCompany');
      if (this.company === 'unity') {
        environment.REVERB_HOST = 'manage-api.aqsatel.com';
        environment.REVERB_PORT = 8086;
    } else if (this.company === 'mingle') {
        environment.REVERB_HOST = 'mingle-stg-api.aqsatel.com';
        environment.REVERB_PORT = 8081;
    }
    }
  initializeEcho() {
     window['Pusher'] = Pusher;
    console.log(environment.REVERB_HOST);

    this.echo = new Echo({
      broadcaster: 'reverb',
      key: environment.REVERB_APP_KEY,
      wsHost: environment.REVERB_HOST,
      wsPort: environment.REVERB_PORT,
      wssPort: environment.REVERB_PORT,
      forceTLS: environment.REVERB_SCHEME === 'https',
      enabledTransports: ['ws', 'wss'], // WebSocket and Secure WebSocket
    });



    console.log("eccho 2",this.echo);
    this.echo.channel(`customer.${this.customerId}`)
      .listen('ViewerJoined', (e: any) => {
        console.log(`${e.name} started viewing.`);
        this.fetchVisitors(true);
      })
      .listen('ViewerLeft', (e: any) => {
        console.log(`${e.name} stopped viewing.`);
        this.fetchVisitors(true);
      });
      
      
      if (!this.echo) {
        console.error("Echo is not initialized!");
        return;
      }
  }

  fetchVisitors(onlyActive?:any) {
    const payload = {
      per_page:5000,
      onlyActive:  onlyActive ? 1 : 0,
      searchTerm:this.searchTerm ? this.searchTerm : ''
    };
    this.http.get('api/note/getVisitors', payload).subscribe((res: any) => {
      if (onlyActive) {
        this.activeVisitors = res.data?.data || [];
      } else {
        this.visitors = res.data?.data || [];
      }
        console.log("visitors",this.visitors)
        this.cdr.detectChanges();

    },(error) => {
      console.error("Error fetching visitors", error);
    });
  }
  openSearch(){
    this.openSearchBox = !this.openSearchBox
  }
  searchChange(e) {
    this.searchTerm = e.target.value;
    this.fetchVisitors()
    
  }
}
