import { Component, OnInit } from "@angular/core";
import { LocalStorage } from "ngx-webstorage";
import { ProfileService } from "src/app/add-member-group/services/profile.service";
import { AuthService } from "src/app/auth/services/auth.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userData: any;
  public profileImg: "assets/images/dashboard/profile.jpg";
  
  @LocalStorage()
  public userDetails;
  profileimg:any
  constructor(
    public authService: AuthService,
    private profileService:ProfileService
  ) {
    this.getUser()
  }

  getUser() {
    this.userData = this.userDetails;
    console.log("user data ",this.userData)
    this.profileimg =localStorage.getItem('profileImg');
  }

  ngOnInit() {
    this.getProfileData()
   }
  getProfileData() {
    this.profileService.getProfileData(this.userDetails?.userID)
    .subscribe((res: any) => {
      if (res) {
        this.profileImg = res.data?.profile_image
      }
 
    });
  }
  logoutFunc() {
    this.authService.logout()
  }
}