import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TicketSystemComponent } from "./ticket-system.component";
import { TicketDashboardComponent } from "./ticket-dashboard/ticket-dashboard.component";
import { TicketTypeComponent } from "./ticket-type/ticket-type.component";
import { TicketReportingDashComponent } from "./ticket-reporting-dash/ticket-reporting-dash.component";

const routes: Routes = [
    {
        path: "", component: TicketSystemComponent,
        children: [
            {
                path: "",
                component: TicketDashboardComponent
            },
            // {
            //     path: "ticket-type",
            //     component: TicketTypeComponent
            // },
        ]
    },
    {
        path:'ticket-tracker',
        component:TicketReportingDashComponent
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TicketSystemRoutingModule { }
