import { CommonService } from 'src/app/shared/services/common.service';
import { RefundManagementService } from './../refund-management.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-refund-dashboard',
  templateUrl: './refund-dashboard.component.html',
  styleUrls: ['./refund-dashboard.component.scss']
})
export class RefundDashboardComponent {
  allplans:any = [ {
    requestId: 1001,
    customerId: 12345,
    refundAmount: 150.00,
    refundType: "Prorated",
    status: "Pending",
    createdDate: "2023-10-01",
    approver: "Manager 1"
  },
  {
    requestId: 1002,
    customerId: 67890,
    refundAmount: 200.00,
    refundType: "Full Refund",
    status: "Approved",
    createdDate: "2023-10-05",
    approver: "Manager 2"
  },
  {
    requestId: 1003,
    customerId: 54321,
    refundAmount: 75.00,
    refundType: "Usage-Based",
    status: "Rejected",
    createdDate: "2023-10-10",
    approver: "Manager 1"
  },
  {
    requestId: 1004,
    customerId: 98765,
    refundAmount: 50.00,
    refundType: "Flat Amount",
    status: "Draft",
    createdDate: "2023-10-15",
    approver: null
  },
  {
    requestId: 1005,
    customerId: 11223,
    refundAmount: 300.00,
    refundType: "Prorated",
    status: "Pending",
    createdDate: "2023-10-20",
    approver: "Manager 3"
  }]
  dropdownVisible = new Set<number>();
  isSkeletonLoader: boolean = true;
  searchTerm:any=''
  status:any = ''
  OpenCalender:boolean = false
  openSearchBox:boolean = false
  bsRangeValue: Date[];
  startDate?: string;
  endDate?: string;
  public itemsPerPage: number = 10;
  public totalItems: number = 0;
  currentPage: number = 1;
  columnDefs = [
    { headerName: 'Request ID', field: 'requestId' },
    { headerName: 'Customer ID', field: 'customerId' },
    { headerName: 'Refund Amount', field: 'refundAmount' },
    { headerName: 'Refund Type', field: 'refundType' },
    { headerName: 'Approver', field: 'approver' },
    { headerName: 'Created Date,', field: 'createdDate' },
    { headerName: 'Status', field: 'status' },
    { headerName: 'Action', field: 'Action' }
  ];
  constructor(private refundManagementService:RefundManagementService,private commonService:CommonService){

  }
  async toggleDropdown(index: number) {
    if (this.dropdownVisible.has(index)) {
      this.dropdownVisible.delete(index); // Hide if already visible
    } else {
      this.dropdownVisible.clear();
      this.dropdownVisible.add(index); // Show if not visible
    }
  }
  searchChange(e) {
    this.searchTerm = e.target.value;
    console.log('searchTerm: ', this.searchTerm);
   
  }
  stop(event: Event) {
    event.stopPropagation();
  }
  ondaysFilterChange(e) {
    let status = e.target.value;
    this.status = status;
    // this.getAdvancePlanList()
  }
  onDateRangeSelected(dateRange: Date[]): void {
    this.startDate = this.formattedDate(dateRange[0]);
    this.endDate = this.formattedDate(dateRange[1]);
    // this.dateRange=dateRange;     


  }
  formattedDate(inputDate: Date): string {
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const year = inputDate.getFullYear();
    return `${month}-${day}-${year}`;
  }
  openCalender(){
    this.OpenCalender = !this.OpenCalender
  }
  openSearch(){
    this.openSearchBox = !this.openSearchBox
  }
  getAdvancePlanList() {
    const obj = {
      perPage: this.itemsPerPage,
      page: this.currentPage,
      searchterm: this.searchTerm,
        status:this.status,
 
  
    } as any;
 
      if (this.startDate) {
          obj.startDate = this.startDate;
      }
      if (this.endDate) {
          obj.endDate = this.endDate;
      }


    this.refundManagementService.getRefundList(obj).then(
      (res: any) => {
        this.allplans = res.data.data;
        this.totalItems = res.data.total;
        setTimeout(() => {
          this.isSkeletonLoader = false;
        }, 1500);
      },
      (error: any) => {
        this.isSkeletonLoader = false;
        this.commonService.showToast('error', 'Error', error);
      }
    );
  }
}
