<div class="cutom-card p-3 mb-3">
    <div class="d-flex flex-column mb-3 gap-1">
        <div class="d-flex justify-content-between align-items-center">
            <h4>PO Info</h4>
            <div class="d-flex gap-3 align-items-center ">
                <span class="custom-badge cursor-pointer" (click)="navigateToPoDetails(po_details)" >#{{po_details?.id}}</span>
                <span class="custom-badge "  matTooltip="PO Status" [ngClass]="getStatusBadgeClass(po_details?.status)"
                >{{po_details?.status}}
                    
                </span>
            </div>
        </div>
        <span class="text-muted"> {{po_details?.type
            }}</span>

    </div>
    <div class="d-flex flex-column mb-3 gap-1">
        <div class="d-flex justify-content-between align-items-center">
            <h6>Invoice Number</h6>
        </div>
        <span class="text-muted"> {{po_details?.number
            }}</span>
    </div>
    <div class="d-flex flex-column mb-3 gap-1">
        <div class="d-flex justify-content-between align-items-center">
            <h6>Action</h6>
        </div>
        <span class="text-muted"> {{ po_details?.action 
            }}</span>
    </div>
    <div class="d-flex flex-column mb-3 gap-1">
        <div class="d-flex justify-content-between align-items-center">
            <h6>Remark</h6>
        </div>
        <span class="text-muted"> {{po_details?.remark
            }}</span>
    </div>
    <div class="d-flex align-items-center justify-content-between">

        <div class="d-flex flex-column mb-3 gap-1">
            <div class="d-flex justify-content-between align-items-center">
                <h6>Created Date</h6>

            </div>
            <span class="text-muted"> {{
                po_details?.created_at | date }}</span>

        </div>
        <div class="d-flex flex-column mb-3 gap-1">
            <div class="d-flex justify-content-between align-items-center">
                <h6>Updated Date </h6>
            </div>
            <span class="text-muted"> {{
                po_details?.updated_at | date }}</span>
        </div>
    </div>

</div>


<!-- {
    "id": 82,
    "number": "6763C471CE9A1-9573",
    "type": "Subscription",
    "action": "Renewal",
    "status": "New",
    "type_id": 9292336,
    "customer_id": 112,
    "remark": "PO Generated",
    "created_at": "2024-12-19T07:00:01.000000Z",
    "updated_at": "2024-12-19T07:00:01.000000Z",
    "created_by": 1,
    "updated_by": 1,
    "action_payload": null,
    "sub_action": null
} -->