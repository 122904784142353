import { Injectable } from '@angular/core';
import { StateService, AppState } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class StateManagementService {
  constructor(private stateService: StateService) {}
  private tabIndicesState = {
    activeTabIndex: 0,
    activeNestedTabIndex: 0,
  };

  saveTabIndices(activeTabIndex: number, activeNestedTabIndex: number): void {
    this.tabIndicesState.activeTabIndex = activeTabIndex;
    this.tabIndicesState.activeNestedTabIndex = activeNestedTabIndex;
  }

  getTabIndices(): { activeTabIndex: number; activeNestedTabIndex: number } {
    return { ...this.tabIndicesState };
  }

  /**
   * Saves the provided state data for a specific route context.
   * Clears the state of other routes to avoid conflicts.
   * @param routeKey - The unique key for the route (e.g., 'customer-email').
   * @param uniqueKey - An optional unique identifier for the component (e.g., 'plan-renewal').
   * @param args - An array of values in the order: filters, items, selectedIndex.
   */
  saveState(routeKey: string, uniqueKey?: string, ...args: [any?, any[]?, number?]): void {
    const [filters = {}, items = [], selectedIndex = null] = args;
    const stateKey = this.getStateKey(routeKey, uniqueKey);

    // Clear states of all other routes if uniqueKey exists
    if (uniqueKey) {
      this.clearOtherStates(routeKey, uniqueKey);
    } else {
      this.clearOtherStates(routeKey);
    }

    // Save the state for the current route
    this.stateService.setState({ [stateKey]: { filters, items, selectedIndex } });
  }

  /**
   * Restores the saved state for a specific route context.
   * Clears states for all other routes except the current one.
   * @param routeKey - The unique key for the route (e.g., 'customer-email').
   * @param uniqueKey - An optional unique identifier for the component (e.g., 'plan-renewal').
   * @param updateCallback - A callback function to update variables based on the restored state.
   * @returns {boolean} - Returns true if the state was successfully restored, false otherwise.
   */
  restoreState(
    routeKey: string,
    uniqueKey: string | undefined,
    updateCallback: (state: Partial<AppState>) => void
  ): boolean {
    // Clear states of all other routes
    if (uniqueKey) {
      this.clearOtherStates(routeKey, uniqueKey);
    } else {
      this.clearOtherStates(routeKey);
    }

    const stateKey = this.getStateKey(routeKey, uniqueKey);
    const savedState = this.stateService.getState()[stateKey];

    if (savedState?.filters && savedState?.items?.length > 0) {
      updateCallback(savedState);
      return true;
    }
    return false;
  }

  /**
   * Clears the saved state for a specific route context.
   * @param routeKey - The unique key for the route (e.g., 'customer-email').
   * @param uniqueKey - An optional unique identifier for the component (e.g., 'plan-renewal').
   */
  clearState(routeKey: string, uniqueKey?: string): void {
    const stateKey = this.getStateKey(routeKey, uniqueKey);
    this.stateService.setState({ [stateKey]: { filters: {}, items: [], selectedIndex: null } });
  }

  /**
   * Clears the states of all routes except the specified one.
   * @param currentRouteKey - The route to preserve (e.g., 'customer-email').
   * @param uniqueKey - An optional unique identifier for the component (e.g., 'plan-renewal').
   */
  private clearOtherStates(currentRouteKey: string, uniqueKey?: string): void {
    const allKeys = Object.keys(this.stateService.getState());
    const stateToPreserve = this.getStateKey(currentRouteKey, uniqueKey);

    const clearedState = allKeys.reduce((acc, key) => {
      if (key !== stateToPreserve) {
        acc[key] = { filters: {}, items: [], selectedIndex: null };
      }
      return acc;
    }, {});

    this.stateService.setState({
      ...this.stateService.getState(),
      ...clearedState,
    });
  }

  /**
   * Generates a unique key for a route context.
   * @param routeKey - The base key for the route.
   * @param uniqueKey - An optional unique identifier for the component.
   * @returns {string} - A unique key for the route context.
   */
  private getStateKey(routeKey: string, uniqueKey?: string): string {
    return uniqueKey ? `state_${routeKey}_${uniqueKey}` : `state_${routeKey}`;
  }
}
