import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PermissionService } from './permission-service.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionResolver implements Resolve<void> {
  constructor(private permissionService: PermissionService) {}

  async resolve(): Promise<void> {
    console.log('🔍 PermissionResolver: STARTED'); // **Force log**

    try {
      await this.permissionService.updatePermission();
      console.log('✅ PermissionResolver: SUCCESS');
    } catch (error) {
      console.error('❌ PermissionResolver: ERROR', error);
    }
  }
}
