import { Component, ElementRef, HostListener, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { Store } from '@ngrx/store';
import { addNotification, startListeningNotifications } from 'src/app/store/notifications/notification.actions';
import { EchoService } from '../../services/echo.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-notification-badge',
  templateUrl: './notification-badge.component.html',
  styleUrls: ['./notification-badge.component.scss']
})
export class NotificationBadgeComponent implements OnInit {
  unreadCount$: Observable<number>;
  isOpen = false;
  channel: any;
  Company:string; // Set your company dynamically if needed
  icon = '/assets/images/logo/Frame 728.png'; // Default icon

  constructor(
    private notificationService: NotificationService,
    private store: Store,
    private injector: Injector,
    private authservice:AuthService,
    private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.store.dispatch(startListeningNotifications());
    this.unreadCount$ = this.notificationService.getUnreadCount();
    this.subscribeToChannel();
    this.Company = this.authservice.getCompanyName()
  }

  toggleNotifications(): void {
    this.isOpen = !this.isOpen;
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target) && this.isOpen) {
      this.isOpen = false;
    }
  }
  private subscribeToChannel(): void {
    console.log('Subscribing to notification channel');
    const echoService = this.injector.get(EchoService);
    this.channel = echoService.subscribe('new_notification');

    this.channel.listen('NotificationEvent', (data: any) => {
      console.log('Event data received:', data);

      // Dispatch to store
      this.store.dispatch(addNotification({ notification: data }));

      // Show desktop notification
      this.showDesktopNotification(data);
    });
  }

  showDesktopNotification(notification: any): void {
    if (this.Company == 'unity') {
      console.log('Application name:', this.Company);
      this.icon = '/assets/images/logo/Frame 728.png';
    } else if (this.Company === 'mingle') {
      console.log('Application name:2', this.Company);
      this.icon =
        'https://www.minglemobile.com/cdn/shop/t/57/assets/favicon.svg?v=178931131784370144781732655519';
    }

    if ('Notification' in window) {
      // Request permission if not already granted
      if (Notification.permission === 'default') {
        Notification.requestPermission();
      }

      if (Notification.permission === 'granted') {
        const desktopNotification = new Notification(notification.Name, {
          body: notification.Message || 'You have a new notification.',
          icon: this.icon,
        });

        desktopNotification.onclick = () => {
          console.log('Notification clicked!');
          this.handleClick(notification);
          window.focus();
        };
      }
    }
  }

  handleClick(notification: any): void {
    console.log('Handling notification click:', notification);
    // Implement navigation or other logic based on the notification
  }

  ngOnDestroy(): void {
    if (this.channel) {
      const echoService = this.injector.get(EchoService);
      echoService.unsubscribe('new_notification');
      console.log('Unsubscribed from notification channel');
    }
  }

  // @HostListener('document:click', ['$event'])
  // handleClickOutside(event: MouseEvent): void {
  //   const clickedInsideDropdown = (event.target as HTMLElement).closest(
  //     ".notification-box"
  //   );

  //   // If click is outside the dropdown, clear the visible dropdown
  //   if (!clickedInsideDropdown) {
  //     this.isOpen = this.isOpen;
  //   }
  // }
}
