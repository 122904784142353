<div class="custom-card"
[ngClass]="{
  'alternative-card-class': isCallFromPurchaseHistory
}">
  <div class="header d-flex justify-content-between border-bottom">

    <h6 class=" mb-lg-0 col-lg-4 col-12 d-flex align-items-center">Advance Plan ({{totalItems||0}}) </h6>

<div class="date-picker" (click)="stop($event)" *ngIf="!isCallFromPurchaseHistory">

    <div class="date-picker hiringCal round-border d-flex mb-2"
        style="justify-content: end;">
        <input type="text" class="form-control ps-2  mx-2 py-1 mt-1" placeholder="Search..." style="height: fit-content;" *ngIf="openSearchBox"
        (keyup.enter)="searchChange($event)" />
          <!-- <label for="isActive" class="form-label"><strong>Status</strong></label> -->
          <select id="isActive" class="form-select py-1 mt-1 mr-2 " style="height: fit-content;"  [(ngModel)]="status" (change)="ondaysFilterChange($event)">
            <option value="">All</option>
            <option value="New">New</option>
            <option value="Disabled">Disabled</option>
            <option value="Applied">Applied</option>
          </select>
        <div class="f-row d-inline-block position-relative reports-datePicker mr-2" *ngIf="OpenCalender">
            <button
                class="f-row f-a-center px-3 filter-btn round hover-light py-1 mt-1 date-button">
                <i class="fa fa-calendar" (click)="drp.toggle()"
                    [attr.aria-expanded]="drp.isOpen"></i>
                <input [(ngModel)]="bsRangeValue"
                    (ngModelChange)="onDateRangeSelected($event)"
                    [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
                    bsDaterangepicker
                    class="pl-2 py-0 text border-0 bg-transparent cursor-pointer"
                    placeholder="Daterangepicker" type="text" readonly
                    #drp="bsDaterangepicker" />
                <i class="fa fa-chevron-down" (click)="drp.toggle()"
                    [attr.aria-expanded]="drp.isOpen"></i>
            </button>
        </div>
        <!-- <i class="fa-solid fa-calendar-days"></i> -->
        <app-filter-btn [icon]="'fa fa-search'" class=" py-1 mr-2" [isBtn]="true" (click)="openSearch()"></app-filter-btn>
        <app-filter-btn [icon]="'fa-solid fa-calendar-days'" class=" py-1" [isBtn]="true" (click)="openCalender()"></app-filter-btn>
        <app-filter-btn [icon]="'fa fa-plus'" class="mx-2 py-1" [isBtn]="true" (click)="openModal()"></app-filter-btn>
        <!-- <button class="btn btn-dark mx-2 py-1" (click)="openModal()" >Add</button> -->
    </div>
</div>
</div>
<div class="table-responsive table-hover table-striped table-card" style="min-height: 500px; overflow-x: auto;">
    <table class="table table-nowrap mb-0" *ngIf="!isSkeletonLoader" @fadeInOut>
      <thead class="table-light">
        <tr class="text-center">
          <th *ngFor="let column of columnDefs" style="white-space: nowrap;">{{ column.headerName }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of allplans; let i = index" class="text-center">
          <td *ngFor="let column of columnDefs">
              <span>
                <ng-container *ngIf="column.field === 'Action'">
                  <div class="position-relative">
                      <i class="fa fa-ellipsis-h py-2 px-4" (click)="toggleDropdown(i)"></i>
                      <div class="dropdown-content1 text-start" *ngIf="dropdownVisible.has(i)">
                          <div class="dropdown-item1" (click)="editMOdal(row)">
                              <span> <i class="fa-regular fa-pen-to-square text-info mr-2"></i> Edit</span>
                          </div>
                       

                      </div>
                  </div>

              </ng-container>

                <ng-container *ngIf="column.field === 'BillingPeriod'">
                  <span>
                    {{ row[column.field] +' days' }}
                  </span>
                </ng-container>
                <ng-container *ngIf="column.field === 'TrialAmount'">
                  <span>
                    {{ '$'+ row[column.field]  }}
                  </span>
                </ng-container>
                <ng-container *ngIf="column.field === 'CreatedAt'" class="text-nowrap">
                  {{ row[column.field] | date }}
                  
              </ng-container>
                <!-- Handle Status field   Applied-->
                <ng-container *ngIf="column.field === 'Status'">
                  <span class="custom-badge" [ngClass]="{
                    'badge-primary': row[column.field] === 'New',
                    'badge-danger': row[column.field] === 'Disabled',
                    'badge-success': row[column.field] === 'Applied'
                  }">
                    {{ row[column.field] || '-' }}
                  </span>

          
                </ng-container>
    
                <!-- Default case for all other fields -->
                <ng-container  *ngIf="![
                'BillingPeriod',
                'TrialAmount',
                'CreatedAt',
                'Status',
                'Action'
              ].includes(column.field)">
                  {{ row[column.field] || '-' }}
                </ng-container>
              </span>
            </td>        
        </tr>
      </tbody>
    </table>

    <!-- Skeleton Loader -->
    <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
      <app-skeleton-loader [tableRow]="8" [tableColumn]="6" shape="table"></app-skeleton-loader>
    </div>

    <!-- No Data Found -->
    <div class="text-center py-3 text-muted" *ngIf="allplans?.length === 0">
      No data found
    </div>
  </div>

  <div class="  d-flex justify-content-end m-2" *ngIf="totalItems">
    <ngb-pagination class="pagination-block" [directionLinks]="true" [boundaryLinks]="true"
        [collectionSize]="totalItems" [(page)]="currentPage"
        (pageChange)="onPaginationChanged($event)" [maxSize]="3" [pageSize]="itemsPerPage"
        [ellipses]="true">
        <ng-template ngbPaginationPrevious>Previous</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
</div>
  </div>

  <ng-template #tagModal let-modal>
    <form [formGroup]="scheduleForm">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="isEdit">Update Schedule</h5>
        <h5 class="modal-title" *ngIf="!isEdit">Create New Schedule</h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        ></button>
      </div>
      <div class="modal-body">
        <!-- CustomerId -->
        <div class="mb-3" *ngIf="!isEdit">
          <label for="customerId" class="form-label"><strong>Customer ID:</strong></label>
          <input
            type="number"
            id="customerId"
            class="form-control"
            formControlName="CustomerId"
            placeholder="Enter Customer ID"
          />
          <div *ngIf="scheduleForm.get('CustomerId')?.invalid && scheduleForm.get('CustomerId')?.touched" class="text-danger">
            Customer ID is required.
          </div>
        </div>
        <div class="mb-3">
          <label for="isActive" class="form-label"><strong>Select Plan ID</strong></label>
          <select id="isActive" class="form-select" formControlName="planId" >
            <option *ngFor="let item of planIds" [ngValue]="item">{{item}}</option>
          </select>
        </div>

        <!-- Billing Period -->
        <div class="mb-3">
          <label for="billingPeriod" class="form-label"><strong>Billing Period (Days):</strong></label>
          <input
            type="number"
            id="billingPeriod"
            class="form-control"
            formControlName="billingPeriod"
            placeholder="Enter Billing Period"
          />
          <div *ngIf="scheduleForm.get('billingPeriod')?.invalid && scheduleForm.get('billingPeriod')?.touched" class="text-danger">
            Billing period is required.
          </div>
        </div>
    
        <!-- Trial Occurrences -->
        <div class="mb-3">
          <label for="trialOccurences" class="form-label"><strong>Trial Occurrences:</strong></label>
          <input
            type="number"
            id="trialOccurences"
            class="form-control"
            formControlName="trialOccurences"
            placeholder="Enter Number of Trial Occurrences"
          />
          <div *ngIf="scheduleForm.get('trialOccurences')?.invalid && scheduleForm.get('trialOccurences')?.touched" class="text-danger">
            Trial occurrences are required.
          </div>
        </div>
    
        <!-- Trial Amount -->
        <div class="mb-3">
          <label for="trialAmount" class="form-label"><strong>Trial Amount:</strong></label>
          <input
            type="number"
            id="trialAmount"
            class="form-control"
            formControlName="trialAmount"
            placeholder="Enter Trial Amount"
          />
          <div *ngIf="scheduleForm.get('trialAmount')?.invalid && scheduleForm.get('trialAmount')?.touched" class="text-danger">
            Trial amount is required.
          </div>
        </div>
    
        <!-- Transaction ID -->
        <div class="mb-3">
          <label for="transactionId" class="form-label"><strong>Transaction ID (Optional):</strong></label>
          <input
            type="text"
            id="transactionId"
            class="form-control"
            formControlName="transactionId"
            placeholder="Enter Transaction ID"
          />
        </div>
        <div class="mb-3">
          <label for="isActive" class="form-label"><strong>Status</strong></label>
          <select id="isActive" class="form-select" formControlName="status" >
            <option value="New">New</option>
            <option value="Disabled">Disabled</option>
            <option value="Applied">Applied</option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Close click')">Close</button>
        <button *ngIf="!isEdit"
          type="button"
          class="btn btn-outline-success"
          [disabled]="scheduleForm.invalid"
          (click)="saveSchedule()"
        >
          Save Changes
        </button>
        <button *ngIf="isEdit"
        type="button"
        class="btn btn-outline-success"
        (click)="saveSchedule()"
      >
        Update
      </button>
      </div>
    </form>
    
  </ng-template>


