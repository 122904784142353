<div class="container-fluid">
  <div class="row">
    <!-- Sidebar -->
    <div class="col-md-3 sidebar">
      <div class="mail-sidebar ">
        <div class="profile-section">
          <img src="https://media.istockphoto.com/id/1393750072/vector/flat-white-icon-man-for-web-design-silhouette-flat-illustration-vector-illustration-stock.jpg?s=612x612&w=0&k=20&c=s9hO4SpyvrDIfELozPpiB_WtzQV9KhoMUP9R9gVohoU=" alt="Profile" class="profile-img">
          <div class="profile-name">{{userData?.name}}</div>
          <div class="profile-email">{{userData?.userGroup[0]?.Code}}</div>
        </div>
        <!-- <button class="btn-new-mail">New Notifications</button> -->
        <ul class="menu-section">
          <li class="menu-item" *ngFor="let item of filterList" (click)="applyFilter2(item)"
          [class.active]="selectedFilter?.title === item.title">
        
            <span><i class="fa-solid menu-icon" [ngClass]="getCategoryIcon(item.title)"></i>{{item.title}}</span>
            <span class="badge-count">{{item.count}}</span>
          </li>
        </ul>
        <h5 class="py-2 border-bottom">Category</h5>

        <ul class="menu">
          <li class="menu-item-1" *ngFor="let item of categoryFilterList">
            <div class="menu-header" (click)="toggleCategory(item.id)">
              <span>
                <i class="fa-solid menu-icon" [ngClass]="getCategoryIcon(item.Name)"></i> {{ item.Name }}
              </span>
              <div>
                <span class="badge-count mx-2">{{ item.types.length }}</span>
                <i class="fa-solid" [ngClass]="{'fa-chevron-down': expandedCategoryId !== item.id, 'fa-chevron-up': expandedCategoryId === item.id}"></i>
              </div>
  
            </div>
        
            <!-- Child items -->
            <ul *ngIf="expandedCategoryId === item.id" class="submenu">
              <li class="submenu-item" *ngFor="let type of item.types" (click)="applyfilter(type.id)"
              [class.active]="type.id === selectedCategoryId" >
                <span class="sub-text"><i class="fa-solid fa-circle-dot menu-icon"></i> {{ type.Name }}</span>
              </li>
            </ul>
          </li>
        </ul>
        
        
        
      </div>
    </div>

    <!-- Main Content -->
    <div class="col-md-9 main-content">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h5 class="m-0"> <i class="fa-regular fa-bell mx-2"></i>{{total}} Notifications</h5>
        <div class="d-flex justify-content-end col-md-6 gap-1">

      
        <input *ngIf="showFilterRow"
        type="text"  style="height: 29px;"
        class="form-control  col-md-5" 
        placeholder="Search..." 
        [(ngModel)]="searchTerm" 
        (keyup.enter)="searchNotifications()">
        <app-filter-btn [icon]="'fa fa-search'" [isBtn]="true" (selectionChanged)="onFilterClick()"></app-filter-btn>
      </div>
      </div>
   
       <div class="overflow-auto scrollbar" style="height: 738px; "  (scroll)="onScroll($event)"  *ngIf="!isSkeletonLoader" @fadeInOut>
      <div class="notification-card d-flex align-items-start cursor-pointer" [ngClass]="{'gray': !notification?.UserIsRead}" *ngFor="let notification of Allnotifications" (click)="handleClick(notification)">
        <div _ngcontent-ng-c423475472="" class="avatar mr-2 d-flex align-items-center justify-content-center text-dark"><i _ngcontent-ng-c423475472="" class="fa-regular fa-user"></i></div>
        <div class="w-100">
          <div class="d-flex justify-content-between">
            <div>
              <span class="dot" *ngIf="!notification?.IsRead"></span>
              <span class="notification-header">{{ notification?.Name }} </span>
            </div>
            <span class="notification-time">{{ notification?.UpdatedDate | date}}</span>
          </div>
          <p class="notification-text m-0">
            {{ notification?.Message }}
          </p>
        </div>
      </div>
      </div>
      <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
        <app-skeleton-loader [tableRow]="12" [tableColumn]="2" shape="timeline-card"></app-skeleton-loader>
      </div>
    </div>
  </div>
</div>

