<div class="container-fluid  " >

  <div class="custom-card  widget-hover" >
    <div class="card-bdy">
      <div class="row">
        <div class="col-md-4 text-start ">
          <h5 class="card-title text-dark">Usage Details for the Period: </h5>
        </div>
        <div class="col-md-8 d-flex justify-content-end">
          <app-date-picker 
          [placeholder]="datePickerPlaceHolder"
          [startDate]="formattedDateRange.startDate"
          [endDate]="formattedDateRange.endDate"
          (onDateRangeChange)="handleDateRangeChange($event)">
        </app-date-picker>
        
        
        </div>
      </div>
      <div class="row" *ngIf="!isSkeletonLoader" @fadeInOut   >
        <div class="row mt-3" >
          <div class="custom-datatable table-responsive">
            <table class="table table-responsive mt-3" style="text-wrap: nowrap;" >
              <thead class="bg-primary">
                <tr>
                  <ng-container *ngFor="let item of column">
                    <th scope="col">
                      {{item}}
                    </th>
                  </ng-container>
                </tr>
              </thead>
      
              <tbody>
                <tr class="shadow-showcase" *ngFor="let item of list;let i = index">
                  <td>{{i+1}}</td>
                  <td>{{item?.MDN || '-'}}</td>
      
                  <td>{{item?.DialedNo || '-'}}</td>
      
                  <td>{{item?.CallDate | date : 'MMM d, y'}} </td>
                  <td>{{item?.RedirectPart || '-'}} </td>
                  <td>{{item?.BillableUnit || '-'}} </td>
                  <td>{{item?.Unit || '-'}} </td>
                  <td>{{item?.CallType || '-'}} </td>
                  <td>{{item?.FreeType || '-'}} </td>
                  <td>{{item?.UsagesType || '-'}} </td>
                  <td>{{item?.DestinationClass || '-'}} </td>
                 
                  <td>{{item?.PlanID || '-'}} </td>
                  <td>{{item?.Roaming || '-'}}</td>
                  <td>{{'-'}}</td>
                  <td>{{item?.BillingCode || '-'}}</td>
      
                </tr>
      
              </tbody>
            </table>
           
          </div>
          <div class="pull-right p-2 m-4 d-flex justify-content-between">
            <div class="summery">
              <button class="btn btn-primary mb-3"  (click)="handleDateRangeDataGeneration(); openSummaryModal(summaryModal)">Open Usage Summary</button>
            </div>
            <div class="pagination">
              <ngb-pagination 
            [collectionSize]="totalItems" 
            class="pagination-block" 
            [directionLinks]="true"
            [boundaryLinks]="true"
            [(page)]="currentPage"
            [pageSize]="pageSize"
            [ellipses]="true"
            [maxSize]="5"
            (pageChange)="onPageChange($event)">
            <ng-template ngbPaginationPrevious>Previous</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template>
          </ngb-pagination>
            </div>
          
  
                  
        </div>
        </div>
      </div>
      <div  *ngIf="isSkeletonLoader" @fadeInOut   style="max-width: 100%;">
        <app-skeleton-loader [tableColumn]="8" [tableRow]="10"  shape="table"></app-skeleton-loader>
      </div>
   
    </div>
  </div>
  
 
  
</div>

<ng-template #summaryModal let-modal>

      
      <!-- Header -->
      <div class="div custom-modal-width">
        <div class="modal-header bg-primary text-white d-flex justify-content-between align-items-center"  *ngIf="!isSummerySkeletonLoader" @fadeInOut>
          <h4 class="modal-title font-weight-bold">Usage Summary</h4>
      
          <!-- Right-aligned Unit Selection Buttons and Close Button -->
          <div class="d-flex align-items-center">
              <!-- Unit Selection Buttons -->
              <div class="btn-group me-2">
                  <button class="btn btn-outline-light text-light" [class.active]="selectedUnit === 'KB'" (click)="setUnit('KB')">KB</button>
                  <button class="btn btn-outline-light text-light" [class.active]="selectedUnit === 'MB'" (click)="setUnit('MB')">MB</button>
                  <button class="btn btn-outline-light text-light" [class.active]="selectedUnit === 'GB'" (click)="setUnit('GB')">GB</button>
              </div>
      
              <!-- Close Button -->
              <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
      </div>
      
      
        <!-- Body -->
        <div class="modal-body p-4"  *ngIf="!isSummerySkeletonLoader" @fadeInOut>
          <div class="container-fluid">
            <div class="row g-3">
              
              <!-- Left Section: Date-wise Summary -->
              <div class="col-md-8 border-end">
                <h5 class="text-center text-primary fw-bold">Daily Usage</h5>
              
                <!-- Scrollable Container for Maximum 3 Days -->
                <div class="daysContainer">
                  <div *ngFor="let date of (summary?.byDate ? objectKeys(summary?.byDate).sort() : [])" 
                       class="mb-3 shadow-sm border border-light rounded-3 overflow-hidden daysCard">
                    <div class="card-body p-4">
                      
                      <!-- Date & Total Usage Row (Header) -->
                      <div class="d-flex justify-content-between align-items-center mb-3 border-bottom pb-2">
                        <div class="fw-bold text-muted">
                          <span class="text-dark fs-6">{{ date | date : 'MMM d, y' }}</span>
                        </div>
                        <div class="fw-bold text-primary fs-6">
                          Total: <span class="text-dark">{{ summary?.byDate[date]?.totalBillableUnit[selectedUnit] | number:'1.2-2' }}</span> {{ selectedUnit }}
                        </div>
                      </div>
              
                      <!-- Call Type and Usage Section (Without Table) -->
                      <div *ngFor="let callType of objectKeys(summary?.byDate[date]?.callTypes)" 
                           class="d-flex justify-content-between align-items-center py-2 callTypeRow">
                        <div class="fw-bold text-primary">{{ callType }}</div>
                        <div class="text-dark fw-semibold">
                          {{ summary?.byDate[date]?.callTypes[callType][selectedUnit] | number:'1.2-2' }} {{ selectedUnit }}
                        </div>
                      </div>
              
                    </div>
                  </div>
                </div>
              
              </div>
              
              
              
      
              <!-- Right Section: Total Summary -->
              <div class="col-md-4">
                <h5 class="text-center text-primary fw-bold">Total Summary</h5>
                
                <div class="card shadow-lg border-0 rounded-3 bg-light p-2">
                  <div class="card-body">
                    
                    <!-- Date Range Section -->
                    <div class="text-center p-2 bg-white rounded shadow-sm">
                      <h6 class="text-muted mb-1">Date Range:</h6>
                      <strong class="text-dark">{{ formattedDateRange.startDate | date:'MMM d, y' }} - {{ formattedDateRange.endDate | date:'MMM d, y' }}</strong>
                    </div>
                    
                    <!-- Call Type Summary Table -->
                    <div class=" mt-3 p-2 bg-white shadow-sm rounded-3" style="overflow: hidden; max-width: 100%;">
                      <table class="table table-hover text-center mb-0 w-100">
                        <thead class="bg-primary text-white rounded-top">
                          <tr>
                            <th scope="col" class="text-center py-2 rounded-start" style="width: 50%;">Call Type</th>
                            <th scope="col" class="text-center py-2 rounded-end" style="width: 50%;">{{ selectedUnit }}</th>
                          </tr>
                        </thead>
                        <tbody class="bg-light">
                          <tr *ngFor="let callType of objectKeys(totalCallTypeSummary)" class="fw-bold">
                            <td class="text-primary py-2 px-3" style="word-break: break-word;">{{ callType }}</td>
                            <td class="text-dark py-2 px-3 rounded-bottom" style="word-break: break-word;">
                              {{ totalCallTypeSummary[callType][selectedUnit] | number:'1.2-2' }} {{ selectedUnit }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    
                    
                    
                    <!-- Total Usage Summary -->
                    <div class="text-center mt-4 p-3 rounded bg-white shadow-sm">
                      <h5 class="text-muted mb-2">Total Usage Over</h5>
                      <h4 class="fw-bold text-primary">{{ objectKeys(summary?.byDate).length }} Days</h4>
                      <h3 class="fw-bold text-dark">{{ summary?.overallTotal[selectedUnit] | number:'1.2-2' }} {{ selectedUnit }}</h3>
                    </div>
                  
                  </div>
                </div>
              </div>
              
      
            </div>
          </div>
        </div>

      
        <!-- Footer -->
        <div class="modal-footer d-flex justify-content-center" *ngIf="!isSummerySkeletonLoader" @fadeInOut>
          <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
        </div>
        <div  *ngIf="isSummerySkeletonLoader" @fadeInOut   style="max-width: 100%;">
          <app-skeleton-loader   shape="usage-summary"></app-skeleton-loader>
        </div>
      </div>

      
      

</ng-template>






