import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-purchase-topups',
  templateUrl: './purchase-topups.component.html',
  styleUrls: ['./purchase-topups.component.scss']
})
export class PurchaseTopupsComponent {

   @Input() currentCustomerId: any;
}
