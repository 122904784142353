
    <div class="custom-card col-md-4" style="    height: 286px;
  ">
      <div class="card-header mb-4 d-flex justify-content-between">
        <h5 class="card-title">Visitor Schedule</h5>
        <span class="custom-badge badge-success">
           {{total}} Active
            <span class="live-icon"></span>
        </span>
   
      </div>
      <div class="card-bo" style="height: 300px;  overflow: scroll;
    scrollbar-width: none;">
        <!-- Today's Schedule -->
        <div class="schedule-section justify-content-between border-bottom  mb-2 d-flex gap-2 " *ngFor="let visitor of activeVisitors">
            <div class="d-flex gap-2">
          <div class="img">
            <img src="https://static.vecteezy.com/system/resources/thumbnails/002/387/693/small/user-profile-icon-free-vector.jpg" alt="" style="max-width: 45px;">
          </div>
          <div class="content  pb-2">
          <h5>{{ visitor.user_name }}</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item-1 text-nowrap">{{ visitor.visited_at | date: 'MMM d, y h:mm a' }}</li>
            <!-- <li class="list-group-item">9:28AM </li> -->
       
          </ul>
          </div>
          </div>
          <div>
            <span class="badge bg-primary rounded-pill mt-2">{{ visitor.customer_id }}</span>
          </div>
         
        </div>
        <div class="col-md-12 text-center">
            <span class="text-danger" *ngIf="activeVisitors.length == 0">Not Found</span>
        </div>
       
      </div>
    </div>
