<div class="customCard py-3 mx-3 my-2">
  <!-- Header Section -->
  <div class="d-flex flex-column flex-lg-row w-100 px-0 justify-content-between position-relative pb-2">
    <h6 class="mb-3 mb-lg-0 col-lg-4 col-12">Purchase Report ({{ totalRecords || 0 }})</h6>
    <div class="d-flex col-lg-8 col-12 align-items-center px-0 justify-content-end gap-2">
      <!-- Search Input -->
      <input *ngIf="openSearchBox" style="height: 28px;"
        type="text"
        class="form-control"
        placeholder="Search..."
        (keyup.enter)="searchChange($event)"
      />

      <!-- Refresh Button -->
      <app-filter-btn [icon]="'fa fa-search'" class=" " [isBtn]="true" (click)="openSearch()"></app-filter-btn>
      <app-filter-btn [icon]="'fa fa-refresh'" [isBtn]="true" (click)="fetchPurchaseReport()" ></app-filter-btn>

      <!-- Export Button -->
      <!-- <button class="btn btn-dark px-2 btn-sm" >Export</button> -->
    </div>
  </div>

  <!-- Table Section -->
  <div class="table-responsive table-hover table-striped table-card" *ngIf="!isSkeletonLoader"  @fadeInOut>
    <table class="table table-nowrap mb-0" *ngIf="filteredData?.length > 0">
      <thead class="table-light mt-0" style="position: sticky; top: -1px; z-index: 5; background: white;">
        <tr class="text-nowrap">
          <th *ngFor="let header of tableHeaders">{{ header.display }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of filteredData; let i = index" class="cursor-pointer text-nowrap" [class.refreshing]="i === refreshingRowIndex" >
          <td *ngFor="let header of tableHeaders" (click)="navigateToPoDetails(item)">
            <ng-container [ngSwitch]="header.key">
              <!-- Default Display -->
              <span *ngSwitchDefault>{{ item[header.key] || '-' }}</span>
            
              <!-- Status Fields with Dynamic Classes -->
              <span
                *ngSwitchCase="'CustomerStatus'"
                [ngClass]="getStatusBadgeClass(item[header.key])"
              >
                {{ item[header.key] }}
              </span>

              <span *ngSwitchCase="'type_id'">
                <a
                  href="javascript:void(0)"
                  (click)="onSubscriptionClick(item['type_id'], item['customer_id'])"
                  class="text-primary text-decoration-underline"
                >
                  {{ item['type_id'] || '-' }}
                </a>
              </span>
              <span *ngSwitchCase="'customer_name'">
                {{ item.customer_name || '-' }}
              </span>
              
              <span
                *ngSwitchCase="'SubscriptionStatus'"
                [ngClass]="getStatusBadgeClass(item[header.key])"
              >
                {{ item[header.key] }}
              </span>
              <span
                *ngSwitchCase="'status'"
                [ngClass]="getStatusBadgeClass(item[header.key])"
              >
                {{ item[header.key] }}
              </span>
            
              <!-- Date Fields -->
              <span *ngSwitchCase="'created_at'">{{ item[header.key] | date }}</span>
              <span *ngSwitchCase="'NextBillingDate'">{{ item[header.key] | date }}</span>
              <span *ngSwitchCase="'NextRenewalDate'">{{ item[header.key] | date }}</span>
           
            </ng-container>
            
          </td>
        </tr>
      </tbody>
    </table>

    <!-- No Data Message -->
    <div class="d-flex justify-content-center p-3" *ngIf="filteredData?.length === 0">
      <span class="text-muted">No data found</span>
    </div>
  </div>
  <div class="  d-flex justify-content-end m-2" *ngIf="totalItems">
    <ngb-pagination class="pagination-block" [directionLinks]="true" [boundaryLinks]="true"
        [collectionSize]="totalItems" [(page)]="currentPage"
        (pageChange)="onPaginationChanged($event)" [maxSize]="3" [pageSize]="itemsPerPage"
        [ellipses]="true">
        <ng-template ngbPaginationPrevious>Previous</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
</div>

  <div *ngIf="isSkeletonLoader" @fadeInOut class=" container-fluid ">
    <div class="div d-flex flex-column justify-content-between">
        <app-skeleton-loader [tableRow]="15" [tableColumn]="8"
            shape="table"></app-skeleton-loader>
    </div>
</div>
</div>
