import { Component, OnInit } from '@angular/core';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usages-information',
  templateUrl: './usages-information.component.html',
  styleUrls: ['./usages-information.component.scss'],
  animations:[fadeInOut]
})
export class UsagesInformationComponent implements OnInit{

  usageInfoData: any = null
  isLoading: any;
isSkeletonLoader: boolean=true;

  constructor(
    private customerProfileService:CustomerProfileService,
    private commonService:CommonService,
    private httpService :HttpService

  ){

  }
  ngOnInit(): void {
   this.getUsagesCardsdata()
  }

  async getUsagesCardsdata(){
    try {
      this.isLoading=true
      let data ={
        CustomerId:this.httpService.getCustomerId()
      }
      const response: any = await this.customerProfileService.getCustomerUsagesCardData(data);
      if(response?.Status){
        this.usageInfoData=response?.data;
        this.isSkeletonLoader=false
      this.isLoading=false
      }else{
      this.isLoading=false
      }
    } catch (error) {
      this.isLoading=false
      
    }
  }
  async sendTomail() {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to send this mail?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, send it!',
      cancelButtonText: 'Cancel'
    });
  
    if (result.isConfirmed) {
      var res5: any = await this.customerProfileService.getSendToMail();
      if (res5.Status) {
        this.commonService.showToast('success', 'Success', res5.message);
      } else {
        this.commonService.showToast('error', 'Error', res5.message);
      }
    }
  }
}
