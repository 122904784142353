  <div class="invoice-container p-5">
    <div class="div p-3">
      <!-- Row 1: Header Section -->
      <div class="row mb-4">
        <div class="col-md-6">
          <div class="logo">
            <img
              [src]="selectedCompanyLogo === 'Mingle' ? 'assets/images/logo/mingle.png' : 'assets/images/logo/unity_logo.png'"
              class="responsive-logo"
              alt="Company Logo"
            />
          </div>
        </div>
        <div class="col-md-6 invoice-info-section d-flex flex-column align-items-end p-0 m-0">
          <div class="info-row d-flex justify-content-between w-100">
            <p class="p-0 m-0"><strong>Invoice Date:</strong></p>
            <p class="p-0 m-0">{{ formatDateTime(commonService.formatDate(invoiceEmittedData?.data?.created_at), 'date')  }}</p>
          </div> 
          <div class="info-row d-flex justify-content-between w-100">
            <p class="p-0 m-0"><strong>Due Date:</strong></p>
            <p class="p-0 m-0">{{ formatDateTime(commonService.formatDate(invoiceEmittedData?.data?.due_date),'date')  }}</p>
          </div> 
          <!-- <div class="info-row d-flex justify-content-between w-100">
            <p class="p-0 m-0"><strong>Account Number:</strong></p>
            <p class="p-0 m-0">{{ invoiceData?.accountNumber }}</p>
          </div> -->
          <div class="info-row d-flex justify-content-between w-100">
            <p class="p-0 m-0"><strong>Invoice Number:</strong></p>
            <p class="p-0 m-0">{{ invoiceEmittedData?.data?.number }}</p>
          </div>
          <div class="info-row d-flex justify-content-between w-100">
            <p class="p-0 m-0"><strong>Total Amount Due:</strong></p>
            <p class="p-0 m-0">${{ invoiceEmittedData?.data?.total_amount }}</p>
          </div>
        </div>
        
        
        
      </div>
  <!-- Bill To and Ship To Section -->
  <div class="row mt-3">
    <!-- Bill To Column -->
    <div class="col-md-6">
      <div class="bill-to">
        <h5>
          <i class="fa fa-user me-2"></i> <strong>Bill To:</strong>
        </h5>
        <p>
          <strong>{{ invoiceEmittedData?.customerData?.personalInfo?.name?.value || ''}}</strong><br />
          <i class="fa fa-map-marker-alt me-2"></i> 
          {{
            (invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.address1 || '') +
            (invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.address2 ? ', ' + invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.address2 : '') +
            (invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.city ? ', ' + invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.city : '') +
            (invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.state ? ', ' + invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.state : '') +
            (invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.zipCode ? ', ' + invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.zipCode : '')
          }}
          
        </p>
      </div>
    </div>

    <!-- Ship To Column -->
    <div class="col-md-6" *ngIf="invoiceEmittedData?.customerData?.personalInfo?.shipingAddress">
      <div class="ship-to">
        <h5>
          <i class="fa fa-shipping-fast me-2"></i> <strong>Ship To:</strong>
        </h5>
        <p>
          <strong>{{ invoiceEmittedData?.customerData?.personalInfo?.name?.value || '' }}</strong><br />
          <i class="fa fa-map-marker-alt me-2"></i> 
          {{
            (invoiceEmittedData?.customerData?.personalInfo?.shipingAddress?.value?.address1 || '') +
            (invoiceEmittedData?.customerData?.personalInfo?.shipingAddress?.value?.address2 ? ', ' + invoiceEmittedData?.customerData?.personalInfo?.shipingAddress?.value?.address2 : '') +
            (invoiceEmittedData?.customerData?.personalInfo?.shipingAddress?.value?.city ? ', ' + invoiceEmittedData?.customerData?.personalInfo?.shipingAddress?.value?.city : '') +
            (invoiceEmittedData?.customerData?.personalInfo?.shipingAddress?.value?.state ? ', ' + invoiceEmittedData?.customerData?.personalInfo?.shipingAddress?.value?.state : '') +
            (invoiceEmittedData?.customerData?.personalInfo?.shipingAddress?.value?.zipCode ? ', ' + invoiceEmittedData?.customerData?.personalInfo?.shipingAddress?.value?.zipCode : '')
          }}
          
        </p>
      </div>
    </div>
  </div>

      <!-- Row 2: Invoice Summary and Details -->
      <div class="row mb-4">
        <!-- Left Column: Invoice Summary -->
        <div class="col-md-6">
          <div class="invoice-summary">
            <h5 class="section-title"><strong>Invoice Summary</strong></h5>
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <td class="text-muted"> {{ invoiceEmittedData?.data?.po?.subscription?.plan?.DisplayName }}</td>
                  <td class="text-end">${{ invoiceEmittedData?.data?.po?.subscription?.plan?.Price }}</td>
                </tr>
                <tr>
                  <td>Tax Amount</td>
                  <td class="text-end">${{ invoiceEmittedData?.data?.tax }}</td>
                </tr>
                <tr>
                  <td><strong>Total Amount</strong></td>
                  <td class="text-end"><strong>${{ invoiceEmittedData?.data?.total_amount }}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      
        <!-- Right Column: Invoice Details and Taxes -->
        <div class="col-md-6">
          <!-- Invoice Details Section -->
          <div class="invoice-details mb-3">
            <h5 class="section-title"><strong>Order Details</strong></h5>
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th>Item Description</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-muted">{{ invoiceEmittedData?.data?.po?.subscription?.plan?.DisplayName }}</td>
                  <td class="text-center">1</td>
                  <td class="text-end">${{ invoiceEmittedData?.data?.total_amount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
      
          <!-- Taxes & Surcharges Section -->
          <!-- <div class="taxes-section mt-5">
            <h5 class="section-title"><strong>Taxes & Surcharges</strong></h5>
            <table class="table table-borderless">
              <tbody>
                <tr *ngFor="let tax of invoiceData?.taxes">
                  <td class="text-muted">{{ tax.name }}</td>
                  <td class="text-end">${{ tax.amount }}</td>
                </tr>
                <tr>
                  <td><strong>Total Current Charges:</strong></td>
                  <td class="text-end"><strong>$48</strong></td>
                </tr>
              </tbody>
            </table>
          </div> -->
        </div>
      </div>
      <div class="row mt-4 align-items-center custom-cards">
        <!-- QR Code Section -->
        <div class="col-md-5 text-center">
          <div class="qr-code-section p-3" >
            <h5 style="font-weight: bold; margin-bottom: 10px;"></h5>
            <img
              [src]="invoiceEmittedData?.data?.qr_code"
              alt="QR Code"
              class="img-fluid rounded"
              style="max-width: 200px; border: 1px solid #ccc; padding: 10px;"
            />
            <p class="mt-3 text-muted">
              Use your mobile device to scan this QR code and complete the payment.
            </p>
          </div>
        </div>
      
        <!-- Vertical Line with "OR" -->
        <div class="col-md-2 text-center d-flex flex-column justify-content-center align-items-center">
          <div style="height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;">
            <div style="border-left: 1px solid #ccc; height: 300px; position: relative;">
              <span
                style="
                  position: absolute; 
                  top: 50%; 
                  left: -15px; 
                  transform: translate(-19%, -50%);
                  background-color: #fff; 
                  padding: 0 10px; 
                  font-weight: bold;
                  color: #999; 
                "
              >
                OR
              </span>
            </div>
          </div>
        </div>
      
        <!-- Payment Link Section -->
        <div class="col-md-5 text-center">
          <div class="dynamic-link-section p-3" >
            <h5 style="font-weight: bold; margin-bottom: 10px;"></h5>
            <a
              [href]="invoiceEmittedData?.data?.dynamic_link"
              target="_blank"
              class="btn btn-primary btn-lg"
              style="padding: 10px 20px; font-size: 16px;"
            >
              Open Payment Link
            </a>
            <p class="mt-3 text-muted">
              Click the above link to open the secure payment page in your browser.
            </p>
          </div>
        </div>
      </div>
      
      
      

      <!-- Row 3: Contact Us Section -->
      <div class="contact-us mt-4">
        <h5><strong>Contact Us</strong></h5>
        <p>Contact us with any questions or disputes about your service or bill:</p>
        <div class="row">
          <!-- Left Column: Phone and Email -->
          <div class="col-md-6">
            <p>
              <i class="fa fa-phone me-2"></i> <strong>Phone:</strong><br />
              Call {{ invoiceData?.contact?.phone }} from your {{ selectedCompanyInfo?.officialName }} device.<br />
              Agents are available Monday - Saturday 9AM - 8PM EST.
            </p>
            <p>
              <i class="fa fa-envelope me-2"></i> <strong>Email:</strong><br />
              You can email support at <a [href]="'mailto:' + invoiceData.contact.email">{{ invoiceData.contact.email }}</a>
            </p>
          </div>
      
          <!-- Right Column: Mail and Online -->
          <div class="col-md-6">
            <div>
              <i class="fa fa-map-marker-alt me-2"></i> <strong>Mail:</strong><br />
              <p class="p-0 m-0">{{ selectedCompanyInfo?.officialName }} <br>  {{ invoiceData.contact.mail }}</p><br/>
            
            </div>
            <p>
              <i class="fa fa-laptop me-2"></i> <strong>Online:</strong><br />
              View your bill and usage details by logging into your account at 
              <a [href]="'http://' + invoiceData.contact.online" target="_blank">{{ invoiceData.contact.online }}</a>
            </p>
          </div>
        </div>
      </div>
      
      
    </div>
    <div class="buttons-section text-end mt-4">
      <app-download-invoice [invoiceType]="invoiceType"></app-download-invoice>
      <!-- <app-send-invoice></app-send-invoice> -->
      <app-pay-invoice></app-pay-invoice>
    </div>
  </div>



