<div class="custom-card">
  <div class="tabs-container">
    <!-- Main tab buttons -->
    <div class="d-flex justify-content-start mb-2">
      <!-- Dynamically generated main tab buttons -->
      <button
        *ngFor="let tab of tabs"
        class="btn mr-2"
        [ngClass]="selectedTab?.label === tab.label ? 'btn-dark' : 'btn-outline-dark'"
        [disabled]="isTabDisabled(tab)"
        (click)="onTabClick(tab)"
      >
        <i [class]="tab.icon" class="example-tab-icon"></i>
        {{ tab.label }}
      </button>
    </div>

    <!-- Conditional Nested Tabs displayed on the next line if applicable -->
    <div class="d-flex justify-content-end">
      <div *ngIf="selectedTab?.subTabs">
        <!-- Dynamically generated nested tab buttons for the selected main tab -->
        <button
          *ngFor="let subTab of selectedTab.subTabs"
          class="btn btn-outline-dark mr-1"
          [ngClass]="{'active': selectedSubTab?.label === subTab.label}"
          (click)="onSubTabClick(subTab)"
        >
          <i [class]="subTab.icon" class="example-tab-icon"></i>
          {{ subTab.label }}
        </button>
      </div>
    </div>

    <!-- Tab Content Area -->
    <div class="mt-3 ">
      <!-- Content for the selected main tab -->
      <div *ngIf="!selectedTab?.subTabs">
        <div  *ngIf="selectedTab.label === 'All'">
          <app-purchase-all  [currentCustomerId]="currentCustomerId"></app-purchase-all>
        </div>
        <div class="custom-card" *ngIf="selectedTab.label === 'Top-Ups'">
        <app-purchase-topups  [currentCustomerId]="currentCustomerId"></app-purchase-topups>
        </div>
        <div class="custom-card" *ngIf="selectedTab.label === 'Equipments'">
        <app-purchase-equipments  [currentCustomerId]="currentCustomerId"></app-purchase-equipments>
        </div>
        
      </div>
      <!-- Content for the selected nested tab, if sub-tabs are present -->
      <div *ngIf="selectedTab?.subTabs" class="tab-content cutsom-card">
        <div class="custom-card" *ngIf="selectedSubTab?.label === 'Plan Renewal'">
          <app-purchase-plan-renewal  [currentCustomerId]="currentCustomerId" style="overflow-x:scroll !important"></app-purchase-plan-renewal>
        </div>
        <div class="custom-card" *ngIf="selectedSubTab?.label === 'Plan Change'">
          <app-purchase-plan-change  [currentCustomerId]="currentCustomerId"></app-purchase-plan-change>
        </div>
      </div>
    </div>
  </div>
</div>
