import { Component, Input, Output, EventEmitter, SimpleChanges, ElementRef, ViewChild, OnInit } from '@angular/core';
import { TicketSystemService } from '../services/ticket-system.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PortRequestsService } from 'src/app/portIn-out/services/porting-requests.service';
import { LocalStorage } from 'ngx-webstorage';
import { CommonService } from 'src/app/shared/services/common.service';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
declare const google: any; // Declare
@Component({
  selector: 'app-ticket-sidebar',
  templateUrl: './add-ticket-sidebar.component.html',
  styleUrls: ['./add-ticket-sidebar.component.scss'],
  providers: [DatePipe ]
})
export class TicketSidebarComponent implements OnInit {
  @Input() isCalledFromCustomerProfile:boolean=false;
  @Input() isCalledTicketsComponent:boolean=false;
  @Input() isSidebarOpen: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() customerId: any
  @Input() ticketId: any = null; // or use a specific interface if available
  @Output() close = new EventEmitter<void>();
  @Output() save = new EventEmitter<any>();
  @Input() statusChangeMessage: string = '';
  allAgents: any = []
  allGroups: any = []
  activeId: any = 1
  allStatusList: any = [];
  editorContent: any;
  toolbarIcon: string = 'eye'
existingAddress:any;
isFormValid: boolean = false;
ticketStatus: string = ''; // Stores the current status
isTicketClosed: boolean = false; // Flag to track if ticket is closed
  commentsConfig: any = {
    editable: true,
    spellcheck: true,
    height: '100px',
    minHeight: '100',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: false,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    upload: (file: File) => { this.uploadFile(file) },
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  descriptionConfig: any = {
    editable: true,
    spellcheck: true,
    height: '100px',
    minHeight: '100',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: false,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    upload: (file: File) => { this.uploadFile(file) },
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  ticketForm: FormGroup;
  @LocalStorage()
  private userDetails;
  AssignType
  isEditingTitle: boolean;
  uploadedFiles: any;
  attachments: any = []
  apiEndPoint: string = environment.BASE_API;
  selectedParentTicketId: any;
  isSubTicket: boolean;
  initialFormValues: any;
  mainTask: any;
  allTicketTypesList: any;
  commentId: any;
  subtaskList: any = [];
  isSubticketMain: boolean;
  priorityList: any = [];
  activityList: any = [];
  isCustomerFound: boolean;
  showcrossIcon: boolean;
  ticketTypeFields: any = {};
  ticketTypes = [
    { id: 1, name: 'Number Port', description: 'Port your number to a new network.' },
    { id: 2, name: 'Refund or Cancel Order', description: 'Request a refund or cancel an existing order.' },
    { id: 3, name: 'Replacement Charger', description: 'Request a replacement charger.' },
    { id: 4, name: 'Reset Caller ID', description: 'Reset your caller ID settings.' },
    { id: 5, name: 'Reset Voicemail', description: 'Reset your voicemail service.' },
    { id: 6, name: 'Sim Replacement New Address', description: 'Replace your SIM with a new address.' },
    { id: 7, name: 'Sim Replacement Same Address', description: 'Replace your SIM without changing your address.' },
    { id: 8, name: 'Others', description: 'General support request.' },
    { id: 9, name: 'ESIM', description: 'ESIM activation and troubleshooting.' }
];

  @ViewChild('inputRef') inputElement!: ElementRef;
  filteredTicketFields: ({ key: string; label: string; control: string; isSelect?: undefined; } | { key: string; label: string; control: string; isSelect: boolean; })[];
  useExistingAddress: boolean = true;
  ticketDescription: any;
  constructor(
    public ticketSystemService: TicketSystemService,
    private fb: FormBuilder,
    public portRequestsService: PortRequestsService,
    public commonService: CommonService,
    private datePipe: DatePipe, private customerProfileService:CustomerProfileService
  ) {
    this.ticketForm = this.fb.group({
      Title: ['', Validators.required],
      AssignmentType: ['AGENT', Validators.required],
      TicketTypeID: [null, [Validators.required]],
      DueDate: ['', Validators.required],
      CustomerId: [''],
      CustomerName: [''],
      ContactNumber: [''],
      AlternateNumber: [''],
      Description: [''],
      comments: [''],
      StatusID: [1, [Validators.required]],
      AssigneeID: [this.getUser().userID, [Validators.required]],
      AgentID: [null],
      CommentText: [''],
      GroupID: [null],
      CreatedBy: [this.getUser().userID],
      UpdatedBy: [this.getUser().userID],
      CategoryID: [1],
      IsInternalNote: [0],
      ParentId: [null],
      PriorityId: [2],
      
      // **Address Fields**
      address1: [''],
      address2: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      MailingAddress: [''], 
    
      // **Newly Added Fields from Template**
      DeviceName: [''],         // For Network Issue
      IMEINumber: [''],         // For Network Issue and ESIM
      PhoneNumber: [''],        // For Number Port
      AccountNumber: [''],      // For Number Port
      PinNumber: [''],          // For Number Port
      ServiceProvider: [''],    // For Number Port
      MDN: [''],               // For ESIM
      MakeAndModel: [''],       // For ESIM

      // TAT
      TAT:['']
    });
  }
  ticketTypeRequiredFields = {
    'Number Port': ['PhoneNumber', 'AccountNumber', 'PinNumber', 'ServiceProvider', 'zipCode'],
    'Refund or Cancel Order': ['ContactNumber'],
    'Network Issue': ['DeviceName', 'IMEINumber', 'ContactNumber'],
    'Sim Replacement New Address': ['address1', 'city', 'state', 'zipCode', , 'ContactNumber'],
    'Reset Caller ID': [ 'ContactNumber'],
    'Reset Voicemail': [ 'ContactNumber'],
    'ESIM': ['MDN', 'MakeAndModel', 'IMEINumber'],
    'Sim Replacement Same Address': [ 'ContactNumber'],
    'Replacement Charger': [ 'ContactNumber'],
    'Others': [ 'ContactNumber']
  };
   ticketTypeTAT: { [key: string]: string } = {
    'Number Port': '24-48 business hours',
    'Refund or Cancel Order': '24-48 business hours',
    'Network Issue': '24 business hours',
    'Sim Replacement New Address': '5-7 business days',
    'Reset Caller ID': '24-48 business hours',
    'Reset Voicemail': '24-48 business hours',
    'ESIM': '24 business hours',
    'Sim Replacement Same Address': '5-7 business days',
    'Replacement Charger': '5-7 business days',
    'Others': '5-7 business days'
  };
  
async ngOnInit() {
  if(this.isCalledFromCustomerProfile || this.isCalledTicketsComponent){
    this.customerId = this.customerProfileService.getCustomerId();
  }
  await this.getTicketTypes();


    this.initialFormValues = this.ticketForm.value;
    this.getAgents();
    this.getGroups();
    this.getTicketsStatus();
    this.getticketPriorityOption();

    if (this.ticketId) {
      await this.getTicketById();
      await this.getActivityById();
      this.isCustomerFound = true;
    }

    if (this.customerId) {
      this.getCustomerData(this.customerId);
    }
    this.ticketForm.get('StatusID')?.valueChanges.subscribe(statusId => {
      this.checkTicketStatus(statusId);
    });
  
    // Listen for Ticket Type selection changes
  // **Move Google API Calls Here**
  this.ticketForm.get('TicketTypeID')?.valueChanges.subscribe((value) => {
    this.updateFormFields(value);

    const selectedTicket = this.allTicketTypesList.find(ticket => ticket.id === value);
    if (selectedTicket?.Name === 'Sim Replacement New Address') {
      this.getGooleFunctionalityStart();
    } else {
      this.destroyGoogleFunctionality();
    }
  });
  if(this.statusChangeMessage){
    this.commonService.showToast('info','Ticket Closing Information',this.statusChangeMessage);
    // const closedStatus = this.allStatusList.find(status => status.Name.toLowerCase() === 'closed');
    // console.log('closed status:', closedStatus);
    //   if (closedStatus) {
    //       this.ticketForm.patchValue({ StatusID: closedStatus.id });
    //   }
  }
}

checkTicketStatus(statusId: number): void {
  const selectedStatus = this.allStatusList.find(status => status.id === statusId);
  
  if (selectedStatus) {
    this.ticketStatus = selectedStatus.Name;
    this.isTicketClosed = this.ticketStatus.toLowerCase() === 'closed';
    
    console.log('Current Ticket Status:', this.ticketStatus);
    console.log('Is Ticket Closed?', this.isTicketClosed);
    
    // Disable the form if ticket is closed
    if (this.isTicketClosed) {
      this.statusChangeMessage=null;
      // Make "Closing Comment" mandatory
      this.ticketForm.get('CommentText')?.setValidators([Validators.required]);
    } else {
      
      // Remove "Closing Comment" from required fields
      this.ticketForm.get('CommentText')?.clearValidators();
    }
    //  // **Update Ticket Type Required Fields based on status**
    //  this.updateRequiredFields();

    //  // Apply validation changes
    //  this.ticketForm.get('CommentText')?.updateValueAndValidity();
  }
}
updateRequiredFields(): void {
  const selectedTicketTypeId = this.ticketForm.get('TicketTypeID')?.value;
  
  if (!selectedTicketTypeId) return;

  const selectedTicketType = this.allTicketTypesList.find(ticket => ticket.id === selectedTicketTypeId);
  
  if (!selectedTicketType) return;

  // Get required fields for the selected ticket type
  const requiredFields = this.ticketTypeRequiredFields[selectedTicketType.Name] || [];

  // **Apply Required Validators to the fields**
  Object.keys(this.ticketForm.controls).forEach((field) => {
    if (requiredFields.includes(field) || this.isTicketClosed && field === 'CommentText') {
      this.ticketForm.get(field)?.setValidators([Validators.required]);
    } else {
      this.ticketForm.get(field)?.clearValidators();
    }
    this.ticketForm.get(field)?.updateValueAndValidity();
  });

  console.log('Updated required fields:', requiredFields);
}

getTAT(ticketType: string): string {
  return this.ticketTypeTAT[ticketType] || '-';
}
  isChecked(): boolean {
    return this.useExistingAddress;
  }
  toggleUseExistingAddress(event: Event): void {
    this.useExistingAddress = (event.target as HTMLInputElement).checked;
    console.log('Updated value of useExistingAddress:', this.useExistingAddress);
  
    if (!this.useExistingAddress) {
      this.getGooleFunctionalityStart(); // Initialize Google functionality
    } else {
      this.destroyGoogleFunctionality(); // Destroy Google functionality
    }
  }
  initAutocomplete(addressInput: HTMLInputElement): void {
    if (typeof google !== "undefined" && google.maps) {
      const autocomplete = new google.maps.places.Autocomplete(addressInput, {
        types: ["geocode"],
        componentRestrictions: { country: "us" },
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place && place.address_components) {
          const addressComponents = place.address_components.map(
            (component: any) => ({
              long_name: component.long_name,
              short_name: component.short_name,
              types: component.types,
            })
          );
          this.bindAddressComponentsToForm(addressComponents);
        }
      });
    } else {
      console.error("Google Maps API not loaded");
    }
  }
  bindAddressComponentsToForm(components: AddressComponent[]): void {
    let address1 = "";
    let address2 = "";
    let country = "";
    let state = "";
    let city = "";
    let zipCode = "";

    components.forEach((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          address1 = component.long_name;
          break;
        case "route":
          address1 += " " + component.long_name;
          break;
        case "sublocality":
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          break;
        case "administrative_area_level_1":
          state = component.short_name;
          break;
        case "postal_code":
          zipCode = component.long_name;
          break;
        default:
          break;
      }
    });

    if (this.ticketForm){
      this.ticketForm.patchValue({
        address1: address1.trim(),
        address2: address2.trim(),
        country: country.trim(),
        state: state.trim(),
        city: city.trim(),
        zipCode: zipCode.trim(),
      });
    }

  }
  getGooleFunctionalityStart(){
    setTimeout(() => {
      const addressInput = document.getElementById(
        "address1"
      ) as HTMLInputElement;
      console.log('addressInpu:', addressInput);
      if (addressInput) {
        this.initAutocomplete(addressInput);
      } else {
        console.error("addressInput is undefined after modal is shown");
      }
    }, 0);
  }
  destroyGoogleFunctionality(): void {
    // Utility function to reset address fields
    this.ticketForm.patchValue({
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      MailingAddress: '' // Reset MailingAddress as well
    });

  }
  updateFormFields(ticketTypeId: number) {
    const selectedTicket = this.allTicketTypesList.find(ticket => ticket.id === ticketTypeId);
    this.ticketDescription = selectedTicket ? selectedTicket.Description || 'No description available' : '';

    this.ticketForm.patchValue({
      Description: this.ticketDescription
    });
  }

  

  closeSidebar() {
    this.statusChangeMessage=null
    this.close.emit();
  }



  async getAgents() {
    try {
      const res: any = await this.ticketSystemService.getAgents()
      if (res.Status) {
        this.allAgents = res.data
      } else {
      }
    } catch (err) {
    }
  }

  async getGroups() {
    try {
      const res: any = await this.ticketSystemService.getGroups()
      if (res.Status) {
        this.allGroups = res.data
      } else {
      }
    } catch (err) {
    }
  }

  async getTicketsStatus() {
    try {
      const res: any = await this.ticketSystemService.getTicketsStatus()
      if (res.Status) {
        this.allStatusList = res.data.map(obj => ({
          ...obj,
          active: false
        }));
      } else {
      }
    } catch (err) {
    }
  }

  async getticketPriorityOption() {
    try {
      const res: any = await this.ticketSystemService.getticketPriorityOption()
      if (res.Status) {
        this.priorityList = res.data
      } else {
      }
    } catch (err) {
    }
  }
  async getTicketTypes() {
    try {
      const res: any = await this.ticketSystemService.getTicketTypes()
      if (res.Status) {
        this.allTicketTypesList = res.data || [];
              // Update ticketTypeTAT dynamically based on API response
              this.ticketTypeTAT = res.data.reduce((acc: { [key: string]: string }, item: any) => {
                if (item.Name && item.TAT) {
                  acc[item.Name] = item.TAT; // Using Name as key and TAT as value
                }
                return acc;
              }, {});
            console.log('Tat: ', this.ticketTypeTAT);
        
      } else {
        this.commonService.showToast('error', "Error", res.message);
        this.allTicketTypesList = [];
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"));
      this.allTicketTypesList = [];
    }
  }


  getInitials(name: string): string {
    if (name) {
      const initials = name.split(' ').map(n => n[0]).join('').toUpperCase();
      return initials.length > 1 ? initials.substring(0, 2) : initials;

    }
    return ""
  }

  // This function generates a random color for the avatar background
  getColor(name: string): string {
    if (name) {
      const colors = ['#FFB3A7', '#A7FFB3', '#A7B3FF', '#FFF3A7', '#FFA7D7', '#A7FFF3']; // Lighter colors
      const hash = this.hashString(name);
      const colorIndex = hash % colors.length;
      return colors[colorIndex];
    }
    return "";
  }

  // This function generates a hash from the name string to assign a consistent color
  hashString(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash);
  }

  uploadFile(file) {

    if (this.isEditing) {
      this.uploadFiles(file);
    } else {
      this.uploadedFiles = file
    }

  }

  toggleToolbar(config) {
    this[config].showToolbar = !this[config].showToolbar;
  }

  saveTicket() {
    console.log(this.ticketForm.value);
  }

  async getCustomerData(customerIdControl?) { 
    console.log(this.ticketForm?.value, customerIdControl);
    this.portRequestsService.geProfileData(customerIdControl ? customerIdControl : this.ticketForm?.value?.CustomerId).subscribe(async (res: any) => {
      if (res?.Status) {
        const profileData = res.data.personalInfo;
        console.log('profileData: ', profileData);
        const existingAddress = [
          profileData?.mailingAddress?.value?.address1,
          profileData?.mailingAddress?.value?.address2,
          profileData?.mailingAddress?.value?.city,
          profileData?.mailingAddress?.value?.state,
          profileData?.mailingAddress?.value?.zipCode
        ].filter(Boolean).join(', '); // Filter out empty values and join with commas

        this.ticketForm.patchValue({
          CustomerName: profileData?.name?.value,
          ContactNumber: profileData?.phone?.value,
          AlternateNumber: profileData.AlternateNumber || '', // Optional field
          CustomerId: customerIdControl ? customerIdControl : this.ticketForm?.value?.CustomerId, // Optional field
        });
        this.isCustomerFound = true;
        this.showcrossIcon = false;
        this.existingAddress= existingAddress // Populate mailing address
        console.log('existing adress: ', this.existingAddress);

      }
    }, err => {
      this.isCustomerFound = false;
      this.showcrossIcon = true;
      this.commonService.showToast('error', "Customer Not Found", "No customer was found associated with the provided ID. Please check and try again.");
    });
  }

  getUser() {
    return this.userDetails;
  }
  private formatDateToYYYYMMDD(date: string | Date): string {
    const selectedDate = new Date(date);
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-based
    const day = String(selectedDate.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  async onSubmit() {

    try {
      if (this.isTicketClosed) {
        const closingComment = this.ticketForm.get('CommentText')?.value?.trim();
        
        if (!closingComment) {
          this.commonService.showToast('error', 'Closing Comment Required', 'You must enter a closing comment before closing the ticket.');
          return; // Stop execution if comment is missing
        }
      }
    
    // Check if all required fields are filled
    const areRequiredFieldsFilled = this.checkRequiredFields();
    if (!areRequiredFieldsFilled) {
      this.commonService.showToast('error', 'Form Validation', 'Fill all the required form fields.');
      return;
    }
      const formValues = { ...this.ticketForm.value };
      if (formValues.DueDate) {
        formValues.DueDate = this.formatDateToYYYYMMDD(formValues.DueDate);
      }
      console.log(this.ticketForm.value);
      console.log("Original Form Values:", formValues);

          // **Filter out empty, null, and undefined values**
    const filteredPayload = Object.keys(formValues).reduce((acc, key) => {
      if (formValues[key] !== null && formValues[key] !== undefined && formValues[key] !== '') {
        acc[key] = formValues[key];
      }
      return acc;
    }, {} as any);

    console.log("Filtered Payload:", filteredPayload);
      // Perform validations
      const title = this.ticketForm.get("Title").value;
      const customerId = this.ticketForm.get("CustomerId").value;

      if (!title || !customerId || !this.isCustomerFound) {
        const missingFields = [];
        if (!title) missingFields.push("title");
        if (!customerId) missingFields.push("customerId");



        if (this.isSubTicket) this.isSubTicket = false;
        if (!this.isCustomerFound) {
          this.commonService.showToast(
            "error",
            "Error",
            `Please enter valid customerid .`
          )
          this.showcrossIcon = true
        } else {
          this.showcrossIcon = false
          this.commonService.showToast(
            "error",
            "Error",
            `Please fill the ${missingFields.join(" and ")}.`
          );
        }

        return;
      }
    // **Group address & newly added fields into ticketDetails**
        // ✅ Get the selected ticket type
        const selectedTicketTypeId = this.ticketForm.get('TicketTypeID')?.value;
        const selectedTicketType = this.allTicketTypesList.find(ticket => ticket.id === selectedTicketTypeId);
        
        // ✅ Determine the TAT value for the selected ticket type
        let tatValue : string | null = null;
        if (selectedTicketType) {
          tatValue = this.ticketTypeTAT[selectedTicketType.Name] || null;
        } 
    const TicketDetails = {
      address1: formValues.address1,
      address2: formValues.address2,
      city: formValues.city,
      state: formValues.state,
      zipCode: formValues.zipCode,
      MailingAddress: formValues.MailingAddress,

      DeviceName: formValues.DeviceName,
      IMEINumber: formValues.IMEINumber,
      PhoneNumber: formValues.PhoneNumber,
      AccountNumber: formValues.AccountNumber,
      PinNumber: formValues.PinNumber,
      ServiceProvider: formValues.ServiceProvider,
      MDN: formValues.MDN,
      MakeAndModel: formValues.MakeAndModel,
      ContactNumber: formValues.ContactNumber,   // General contact number
      AlternateNumber: formValues.AlternateNumber,
      TAT: tatValue,
      CommentText:formValues.CommentText

    };

    // **Remove these fields from the main payload and move to ticketDetails**
    Object.keys(TicketDetails).forEach(key => delete filteredPayload[key]);

    // Attach the ticketDetails object if it contains any data
    filteredPayload.TicketDetails = Object.values(TicketDetails).some(value => value) ? TicketDetails : undefined;

    console.log("Final Payload (with ticketDetails):", filteredPayload);
      // Set user-related fields
      const currentUser = this.getUser().userID;
      if (this.isEditing && !this.isSubTicket) {
        filteredPayload.UpdatedBy = currentUser;
      console.log("Updated Payload:", filteredPayload);
        formValues.UpdatedBy = currentUser;
        console.log(formValues);
        const res: any = await this.ticketSystemService.updateTicket(this.ticketId, filteredPayload);
        res.Status
          ? this.commonService.showToast("success", "Success", "Updated successfully!")
          : this.commonService.showToast("error", "Error", res.message);
        this.getTicketById()
        this.closeSidebar()
      } else {
        filteredPayload.CreatedBy = currentUser;

        // Handle linked ticket
        if (this.selectedParentTicketId) {
          filteredPayload.LinkedTickets = [this.selectedParentTicketId];
        }

        const res: any = await this.ticketSystemService.addTicket(filteredPayload);
        if (res.Status) {
          this.ticketId = res.data.id;
          this.isEditing = true
          this.getTicketById()
          this.selectedParentTicketId = res.data.id;
          this.commonService.showToast("success", "Success", "Added successfully!");
          this.closeSidebar()
          // Reset form if it’s a sub-ticket
          if (this.isSubTicket) {
            this.resetFormToInitialState(false);
            this.attachments = [];
            this.isSubTicket = false
          }

          // Handle file uploads
          if (this.uploadedFiles) {
            this.uploadFiles(this.uploadedFiles);
          }

        } else {
          this.commonService.showToast("error", "Error", res.message);
        }
      }

      this.save.emit();
    } catch (err) {
      console.log(err);
      if (this.isSubTicket) this.isSubTicket = false;
      if (err.error.error && err.error.error.CustomerId && err.error.error.CustomerId.length > 0) {
        this.commonService.showToast("error", "Error", err.error.error.CustomerId[0]);
      } else {
        this.commonService.showToast("error", "Error", err.message || "An error occurred");
      }
    }
  }



  async getTicketById() {
    try {
      this.subtaskList = [];
      const res: any = await this.ticketSystemService.getTicketById(this.ticketId);
  
      if (res.Status) {
        const resData = res.data;
  
        // Convert DueDate if present
        if (resData?.DueDate) {
          resData.DueDate = new Date(resData.DueDate);
        }
  
        // Ensure AssigneeID is in string format
        if (resData?.AssigneeID) {
          resData.AssigneeID = resData.AssigneeID.toString();
        }
  
        console.log("Processing response data for form population:", resData);
  
        // **Loop through each field in ticketForm and update if present in response**
        Object.keys(this.ticketForm.controls).forEach((key) => {
          if (resData.hasOwnProperty(key) && resData[key] !== null && resData[key] !== undefined && resData[key] !== '') {
            this.ticketForm.get(key)?.patchValue(resData[key]);
          }
        });
  
        // **Explicitly Check and Populate Description**
        if (resData?.Description) {
          this.ticketForm.get('Description')?.patchValue(resData.Description);
        }

        if (resData?.TicketDetails) {
          Object.keys(resData.TicketDetails).forEach((key) => {
            if (this.ticketForm.contains(key) && resData.TicketDetails[key] !== null && resData.TicketDetails[key] !== undefined && resData.TicketDetails[key] !== '') {
              this.ticketForm.get(key)?.patchValue(resData.TicketDetails[key]);
            }
          });
        }
  
        // Attachments & Subtasks
        this.attachments = resData.attachments || [];
        this.subtaskList = resData.children || [];
  
        // **Handle Customer Details separately**
        if (resData?.CustomerID) {
          this.ticketForm.get('CustomerId')?.patchValue(resData.CustomerID);
        }
  
        if (resData?.customer?.personal_info) {
          const personalInfo = resData.customer.personal_info;
  
          if (personalInfo.FirstName) {
            this.ticketForm.get('CustomerName')?.patchValue(
              `${personalInfo.FirstName || ''} ` +
              `${personalInfo.MiddleName ? personalInfo.MiddleName + ' ' : ''}` +
              `${personalInfo.LastName || ''}`
            );
          }
  
          if (personalInfo.PrimaryPhone) {
            this.ticketForm.get('ContactNumber')?.patchValue(personalInfo.PrimaryPhone);
          }
  
          if (personalInfo.SecondaryPhone) {
            this.ticketForm.get('AlternateNumber')?.patchValue(personalInfo.SecondaryPhone);
          }
        }
      } else {
        this.commonService.showToast("error", "Error", res?.message);
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err?.error?.message);
    }
  }
  

  async getActivityById() {
    try {
      const res: any = await this.ticketSystemService.getActivityById(this.ticketId)
      if (res.Status) {
        this.activityList = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err.error.message)
    }
  }

  onAssignmentTypeChange() {
    this.ticketForm.get('AgentID').reset()
    this.ticketForm.get('GroupID').reset()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ticketId'] && changes['ticketId'].currentValue) {
      this.isSubTicket = false
      // this.getTicketById(); // Fetch new ticket data when ticketId changes
      // this.getActivityById(); // Fetch new ticket data when ticketId changes
    } else {
      // this.resetFormToInitialState(false)
      this.attachments = []
    }

  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return ''; // Return a placeholder or handle it as needed
    }
    return formatDate(dateString, 'MMM d, y h:mm a', 'en-US');
  }


  startEditingTitle() {
    this.isEditingTitle = true;
    setTimeout(() => {
      this.inputElement.nativeElement.focus();  // Programmatically focus on the textarea
    }, 0);
  }

  stopEditingTitle() {
    this.isEditingTitle = false;
  }


  async uploadFiles(file) {
    // Implement file upload logic here
    const form = new FormData()
    form.append('companyId', "UNITYCRM0021")
    form.append('userID', this.getUser().userID)
    form.append('files[]', file)
    form.append('TicketID', this.ticketId)
    try {
      const res = await this.ticketSystemService.attachFile(form);
      this.ticketId = null
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  onAddSubTicketClick() {
    this.mainTask = this.ticketForm.value
    this.isSubTicket = true
    if (!this.ticketId) {
      this.onSubmit()
    } else {
      this.selectedParentTicketId = this.ticketId
      this.resetFormToInitialState(true)
      this.attachments = []
      this.ticketId = null
    }
    console.log(this.ticketId);
    console.log(this.ticketForm.get('Title')?.value);

  }

  resetFormToInitialState(addParentId) {
    this.ticketForm.reset({
      Title: '',
      AssignmentType: 'AGENT',
      TicketTypeID: null,
      DueDate: '',
      CustomerId: '',
      CustomerName: '',
      ContactNumber: '',
      AlternateNumber: '',
      Description: '',
      comments: '',
      StatusID: 1,
      AssigneeID: this.getUser().userID,
      AgentID: null,
      CommentText: '',
      GroupID: null,
      CreatedBy: this.getUser().userID,
      UpdatedBy: this.getUser().userID,
      CategoryID: 1,
      IsInternalNote: 0,
      PriorityId: null,
      ParentId: this.selectedParentTicketId && addParentId ? parseInt(this.selectedParentTicketId, 10) : null,
  
      // **Address Fields**
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      MailingAddress: '',
  
      // **Newly Added Fields from Template**
      DeviceName: '',         // For Network Issue
      IMEINumber: '',         // For Network Issue and ESIM
      PhoneNumber: '',        // For Number Port
      AccountNumber: '',      // For Number Port
      PinNumber: '',          // For Number Port
      ServiceProvider: '',    // For Number Port
      MDN: '',               // For ESIM
      MakeAndModel: '',       // For ESIM
    });
    console.log('form values: ', this.ticketForm.value);
  }

  async onCommentAdd() {
    try {
      const modifiedFormValue = { ...this.ticketForm.value };
      modifiedFormValue.CreatedBy = this.getUser()?.userID
      modifiedFormValue.CommentText = modifiedFormValue.CommentText.replace(/&nbsp;/g, ' ').replace(/&#160;/g, ' ');
      console.log(modifiedFormValue);
      const res: any = await this.ticketSystemService.addComment(this.ticketId, modifiedFormValue)
      if (res.Status) {
        this.commonService.showToast("success", "Success", "Comment added successfully!")
        this.getTicketById()
        this.ticketForm.patchValue({
          CommentText: ""
        });
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }


  setChildrenToMain(item) {
    this.isSubticketMain = true
    this.ticketId = item?.id
    this.getTicketById()
    this.getActivityById()
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    console.log(changeEvent);
  }

  isRefundOrCancelOrder(): boolean {
    return this.isTicketType('Refund or Cancel Order');
  }
  
  isNetworkIssue(): boolean {
    return this.isTicketType('Network Issue');
  }
  
  isSimReplacementNewAddress(): boolean {
    return this.isTicketType('Sim Replacement New Address');
  }
  
  isResetCallerID(): boolean {
    return this.isTicketType('Reset Caller ID');
  }
  
  isResetVoicemail(): boolean {
    return this.isTicketType('Reset Voicemail');
  }
  
  isNumberPort(): boolean {
    return this.isTicketType('Number Port');
  }
  
  isESIM(): boolean {
    return this.isTicketType('ESIM');
  }
  
  isSimReplacementSameAddress(): boolean {
    return this.isTicketType('Sim Replacement Same Address');
  }
  
  isReplacementCharger():boolean{
    return this.isTicketType('Replacement Charger');
  }
  isOthers(): boolean {
    return this.isTicketType('Others');
  }
  
  private isTicketType(ticketName: string): boolean {
    if (!this.allTicketTypesList || this.allTicketTypesList.length === 0) {
      // console.error("allTicketTypesList is not loaded yet!");
      return false; // Prevents error
    }
  
    const selectedTicketTypeId = this.ticketForm.get('TicketTypeID')?.value;
    if (!selectedTicketTypeId) return false;
  
    const selectedTicketType = this.allTicketTypesList.find(ticket => ticket.id === selectedTicketTypeId);
    return selectedTicketType?.Name === ticketName;
  }
  

  checkRequiredFields(): boolean {
    const selectedTicketTypeId = this.ticketForm.get('TicketTypeID')?.value;
    if (!selectedTicketTypeId) {
      this.formValidation(false); // No ticket type selected
      return false;
    }
  
    // Find the selected ticket type
    const selectedTicket = this.allTicketTypesList.find(ticket => ticket.id === selectedTicketTypeId);
    if (!selectedTicket) {
      this.formValidation(false); // Invalid ticket type
      return false;
    }
  
    // Get the required fields for the selected ticket type
    const requiredFields = this.ticketTypeRequiredFields[selectedTicket.Name] || [];
  
    console.log('Selected Ticket Type:', selectedTicket.Name);
    console.log('Required Fields:', requiredFields);
  
    // Check if all required fields are filled
    const allFieldsFilled = requiredFields.every(field => {
      const fieldValue = this.ticketForm.get(field)?.value;
      console.log(`Field: ${field}, Value:`, fieldValue); // Log each field and its value
      return fieldValue !== null && fieldValue !== undefined && fieldValue.toString().trim() !== '';
    });
  
    console.log('All Required Fields Filled:', allFieldsFilled);
  
    // Update isFormValid based on whether all required fields are filled
    this.formValidation(allFieldsFilled);
    return allFieldsFilled; // Return the validation result
  }
  
  formValidation(isFormValid: boolean): void {
    this.isFormValid = isFormValid;
  }
}
