import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TransactionComponent } from "./transaction.component";
import { TransactionDetailComponent } from "./transaction-detail/transaction-detail.component";
import { RefundDashboardComponent } from "./refund-management/refund-dashboard/refund-dashboard.component";
import { RefundMultiStepFormComponent } from "./refund-management/refund-multi-step-form/refund-multi-step-form.component";


const routes: Routes = [
    {
        path: "",
        component: TransactionComponent,
    },
    {
        path: "detail",
        component: TransactionDetailComponent,
    },
    {
        path:'refund-dashboard',
        component:RefundDashboardComponent,
        data: { breadcrumb: 'Refund Dashboard' } 
    },
    {
        path:'refund-creation',
        component:RefundMultiStepFormComponent,
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TransactionRoutingModule { }
