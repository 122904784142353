import { Injectable } from '@angular/core';
import { HttpService } from './httpService.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(
    private http: HttpService,
    public common: CommonService,
  ) { }


  // gettags with search term requireds

  // for customerTags

  // addTags // assign existing tag
  public addCustomerTags(customerId: any, tags:any) {
    try {
      let data = {
        tags:tags
      };
      return this.http
        .post(`api/Customer/${customerId}/tags`,data)
        .toPromise();
    } catch (error) {
      this.common.loader = false;
      throw Error(error);
    }
  }

  getCustomerSubTags(){
    let data = {
        "tagType":'Customer',
        // "typeId":2, 
    }
    try {
      return this.http.get('api/tickets/getTags',data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  // Removetags
  removeCustomerTags(customerId: any, tags:any) {
    try {
      let data = {
        tags:tags
      };
      return this.http
      .post(`api/Customer/${customerId}/detachTags`, data)
      .toPromise();
    } catch (error) {
      this.common.loader = false;
      throw Error(error);
    }

  }

  //create tag // manually create new tag
 createCustomerTag(tagName:string, tagTypeId:number) {
    try {
      let data = {
        tagName: tagName,
        tagTypeId:tagTypeId
      };
      return this.http
      .post(`api/tickets/createTag`, data)
      .toPromise();
    } catch (error) {
      this.common.loader = false;
      throw Error(error);
    }
  }

  // getTagsType
  getTagTypes(){
    try {
      let data = {
       "searchTerm":""
      };
      return this.http
      .get(`api/tickets/getTagTypes`, data)
      .toPromise();
    } catch (error) {
      this.common.loader = false;
      throw Error(error);
    }
  }


  // ORDERS
    // addTags
    public addOrderTags(orderId: any, tags:any) {
      try {
        let data = {
          tags:tags
        };
        return this.http
          .post(`api/orders/${orderId}/tags`,data)
          .toPromise();
      } catch (error) {
        this.common.loader = false;
        throw Error(error);
      }
    }

    getOrderSubTags(){
      let data = {
          "tagType":'Order',
          // "typeId":2, 
      }
      try {
        return this.http.get('api/tickets/getTags',data).toPromise()
      } catch (err) {
        throw Error(err)
      }
    }
      // Removetags
  removeOrderTags(orderId: any, tags:any) {
    try {
      let data = {
        tags:tags
      };
      return this.http
      .post(`api/orders/${orderId}/detachTags`, data)
      .toPromise();
    } catch (error) {
      this.common.loader = false;
      throw Error(error);
    }

  }

  // createOrderTag
    //create tag // manually create new tag
    createOrderTag(tagName:string, tagTypeId:number) {
  try {
    let data = {
      tagName: tagName,
      tagTypeId:tagTypeId
    };
    return this.http
    .post(`api/tickets/createTag`, data)
    .toPromise();
  } catch (error) {
    this.common.loader = false;
    throw Error(error);
  }
}
}
