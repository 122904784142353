import { ChangeDetectorRef, Component } from '@angular/core';
import { HttpService } from '../../services/httpService.service';
import { environment } from 'src/environments/environment';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

@Component({
  selector: 'app-visitors-notification',
  templateUrl: './visitors-notification.component.html',
  styleUrls: ['./visitors-notification.component.scss']
})
export class VisitorsNotificationComponent {
 customerId:any
  visitors: any[]=[];
  activeVisitors: any[]=[];
  private echo: any;
  company:any;
  searchTerm:any;
  total:any
  constructor(private http: HttpService,private cdr: ChangeDetectorRef) {
    this.getBaseUrl()
    this.customerId = this.http.getCustomerId()
  }

  ngOnInit() {

    this.initializeEcho();
    this.fetchVisitors();
// this.customerId = this.http.getCustomerId()
  }

    getBaseUrl(): void {
      this.company = localStorage.getItem('currentCompany');
      if (this.company === 'unity') {
        environment.REVERB_HOST = 'manage-api.aqsatel.com';
        environment.REVERB_PORT = 8086;
    } else if (this.company === 'mingle') {
        environment.REVERB_HOST = 'mingle-stg-api.aqsatel.com';
        environment.REVERB_PORT = 8081;
    }
    }
  initializeEcho() {

     window['Pusher'] = Pusher;
    console.log(environment.REVERB_HOST);

    this.echo = new Echo({
      broadcaster: 'reverb',
      key: environment.REVERB_APP_KEY,
      wsHost: environment.REVERB_HOST,
      wsPort: environment.REVERB_PORT,
      wssPort: environment.REVERB_PORT,
      forceTLS: environment.REVERB_SCHEME === 'https',
      enabledTransports: ['ws', 'wss'], // WebSocket and Secure WebSocket
    });



    console.log("eccho 2",this.echo);
    this.echo.channel(`customer.${this.customerId}`)
      .listen('ViewerJoined', (e: any) => {
        console.log(`${e.name} started viewing.`);
        this.fetchVisitors(true);
      })
      .listen('ViewerLeft', (e: any) => {
        console.log(`${e.name} stopped viewing.`);
        this.fetchVisitors(true);
      });
      
      
      if (!this.echo) {
        console.error("Echo is not initialized!");
        return;
      }

      console.log("customer id",this.customerId)
  }

  fetchVisitors(onlyActive?:any) {
    const payload = {
      onlyActive:  onlyActive ? 1 : 1,
   
      per_page:5000
    };
    this.http.get('api/note/getVisitors', payload).subscribe((res: any) => {
      // if (onlyActive) {
        this.activeVisitors = res.data?.data || [];
        this.total = res?.data?.total
        console.log("visitors",this.activeVisitors)
      // } else {
      //     this.visitors = res.data?.data || [];

   
      // }

        this.cdr.detectChanges();

    },(error) => {
      console.error("Error fetching visitors", error);
    });
  }

}
