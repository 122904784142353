import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { Component, ViewEncapsulation, HostListener, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { environment } from 'src/environments/environment.dev';
import { fadeInOut } from '../../services/app.animation';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations:[fadeInOut]
})
export class SidebarComponent implements OnInit{
  content = environment.content;
  public iconSidebar;
  public menuItems: any[];
  selectedCompanyLogo:any
  hidesetting:any
  // public menuItems: Menu[];

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;
isSkeletonLoader: boolean=true;

  constructor(private router: Router, public navServices: NavService,
    public layout: LayoutService, private cdr: ChangeDetectorRef) {}
  ngOnInit(){
    this.getLogoSrc();
    this.navServices.getSideMenuBar().subscribe((menuItems:any) => {
      this.menuItems = menuItems.filter((item: any) => item.title !== 'Settings');
      this.hidesetting = menuItems.filter((item:any) => item.title == 'Settings')
      console.log("hidesetting",this.hidesetting)
      this.isSkeletonLoader=false
       this.cdr.detectChanges();
      this.getLogoSrc();
      this.menuItems= this.menuItems.filter(menuItem => !menuItem.isHidden);
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // menuItems.filter(items => {
          //   if (items.path === event.url) {
          //     this.setNavActive(items);
          //   }
          //   if (!items.children) { return false; }
          //   items.children.filter(subItems => {
          //     if (subItems.path === event.url) {
          //       this.setNavActive(subItems);
          //     }
          //     if (!subItems.children) { return false; }
          //     subItems.children.filter(subSubItems => {
          //       if (subSubItems.path === event.url) {
          //         this.setNavActive(subSubItems);
          //       }
          //     });
          //   });
          // });
        }
      });
    });

  }
  

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  getLogoSrc(){
    const currentCompany = localStorage.getItem('currentCompany');
    currentCompany === 'mingle' ?  this.selectedCompanyLogo = 'Mingle' : 'Aqsatel';
  }

  // Active Nave state
  setNavActive(item) {    
    // Deactivate all menu items
  this.menuItems.forEach(menuItem => {
    menuItem.active = false;
    if (menuItem.children) {
      menuItem.children.forEach(submenuItem => {
        submenuItem.active = false;
      });
    }
  });

  // Activate the clicked menu item and its direct parent items
  const activateMenuItem = (menuItem) => {
    if (menuItem) {
      menuItem.active = true;
      if (menuItem.parent) {
        menuItem.parent.active = true;
        activateMenuItem(menuItem.parent);
      }
    }
  };

  activateMenuItem(item);
  }

  // Click Toggle menu
  toggletNavActive(item) {
    const deactivateAll = (menuItems) => {
      menuItems.forEach(menuItem => {
        menuItem.active = false;
        if (menuItem.children) {
          deactivateAll(menuItem.children);
        }
      });
    };
  console.log("item",item)
    // Deactivate all menu items except the one being toggled
    deactivateAll(this.menuItems);
  
    // Toggle the active state of the clicked item
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
}
