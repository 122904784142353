import { Component } from '@angular/core';

@Component({
  selector: 'app-usage-summary-skeleton',
  templateUrl: './usage-summary-skeleton.component.html',
  styleUrls: ['./usage-summary-skeleton.component.scss']
})
export class UsageSummarySkeletonComponent {

}
