import { Component, Input, ViewChild } from '@angular/core';
import { RenewalServiceService } from '../service/renewal-service.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-advance-plans',
  templateUrl: './advance-plans.component.html',
  styleUrls: ['./advance-plans.component.scss'],
    animations: [fadeInOut]
})

export class AdvancePlansComponent {
    @Input() isCallFromPurchaseHistory:boolean;
    @Input() currentCustomerId:any;
  allplans:any=[]
  public itemsPerPage: number = 10;
  public totalItems: number = 0;
  currentPage: number = 1;
  startDate?: string;
  endDate?: string;
  bsRangeValue: Date[];
  modalRef:any;
  scheduleForm:FormGroup;
  planIds:any = []
  dropdownVisible = new Set<number>();
  isEdit:boolean = false
  id:any;
  searchTerm:any = ''
  status:any = ''
  isSkeletonLoader: boolean = true;
  OpenCalender:boolean = false
  openSearchBox:boolean = false
constructor(
    private renewalServiceService: RenewalServiceService,
    private commonService:CommonService,
    private modalService: NgbModal,
    private fb: FormBuilder
){
  const currentDate = new Date();
  this.bsRangeValue = [currentDate, currentDate];

}
@ViewChild('tagModal') tagModal;

columnDefs = [
  { headerName: 'Customer ID', field: 'CustomerID' },
  { headerName: 'Plan ID', field: 'PlanId' },
  { headerName: 'Billing Period', field: 'BillingPeriod' },
  { headerName: 'Trial Occurances', field: 'TrialOccurances' },
  { headerName: 'Trial Amount', field: 'TrialAmount' },
  { headerName: 'Transaction Id', field: 'TransactionId' },
  { headerName: 'Created At', field: 'CreatedAt' },
  { headerName: 'Status', field: 'Status' },
  { headerName: 'Action', field: 'Action' }
];
ngOnInit(): void {
this.getAdvancePlanList()
this.scheduleForm = this.fb.group({
  CustomerId: [null, [Validators.required, Validators.min(1)]],
  planId:[null,[Validators.required, Validators.min(1)]],
  billingPeriod: [null, [Validators.required, Validators.min(1)]],
  trialOccurences: [null, [Validators.required, Validators.min(1)]],
  trialAmount: [null, [Validators.required, Validators.min(1)]],
  transactionId: [null], // Optional
  status:['New']
});
  
}
stop(event: Event) {
  event.stopPropagation();
}
onPaginationChanged(params: any) {
  this.getAdvancePlanList()
}

  getAdvancePlanList() {
    const obj = {
      perPage: this.itemsPerPage,
      page: this.currentPage,
      searchterm: this.searchTerm,
        status:this.status,
 
  
    } as any;
 
      if (this.startDate) {
          obj.startDate = this.startDate;
      }
      if (this.endDate) {
          obj.endDate = this.endDate;
      }
      if(this.isCallFromPurchaseHistory){
        const obj = {
          perPage: this.itemsPerPage,
          page: this.currentPage,
          searchterm: this.currentCustomerId,
            status:this.status,
        }
      }

    this.renewalServiceService.getadvancePlanData(obj).then(
      (res: any) => {
        this.allplans = res.data.data;
        this.totalItems = res.data.total;
        this.planIds = this.allplans.map((plan: any) => plan.PlanId);
        // this.filterportingRequestList = res.data.data;
        setTimeout(() => {
          this.isSkeletonLoader = false;
        }, 1500);
      },
      (error: any) => {
        this.isSkeletonLoader = false;
        this.commonService.showToast('error', 'Error', error);
      }
    );
  }
  async toggleDropdown(index: number) {
    if (this.dropdownVisible.has(index)) {
      this.dropdownVisible.delete(index); // Hide if already visible
    } else {
      this.dropdownVisible.clear();
      this.dropdownVisible.add(index); // Show if not visible
    }
  }

  
  onDateRangeSelected(dateRange: Date[]): void {
    this.startDate = this.formattedDate(dateRange[0]);
    this.endDate = this.formattedDate(dateRange[1]);
    // this.dateRange=dateRange;     
    this.getAdvancePlanList()

  }
  formattedDate(inputDate: Date): string {
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const year = inputDate.getFullYear();
    return `${month}-${day}-${year}`;
  }
  openModal() {

     this.modalRef =  this.modalService.open(this.tagModal, { centered: true });
    }
    editMOdal(id:any){
      this.id = id.id
    this.isEdit = true
      this.modalRef = this.modalService.open(this.tagModal, { centered: true });
      this.scheduleForm.patchValue({
        planId: id.PlanId,
        billingPeriod: id.BillingPeriod,
        trialOccurences: id.TrialOccurances,
        trialAmount: id.TrialAmount,
        transactionId: id.TransactionId,
        status:id.Status
      });
    }
    saveSchedule(): void {
 
        if(this.isEdit){
          this.renewalServiceService.updateadvancePlanData(this.scheduleForm.value,this.id).then((res:any)=>{
            if(res.Status){
              this.commonService.showToast("success", "Success", 'Updated   Successfully');
              this.getAdvancePlanList()
            }else{
              this.commonService.showToast('error', "Error", (res?.error?.error ?? "Some Error Occurred"))
              console.log("res",res)
  
            }
      
          }, (err) => {
            this.commonService.showToast('error', "Error", (err?.error?.error ?? "Some Error Occurred"))
            console.log("error",err)
        
          })
        }else{
         this.renewalServiceService.AddadvancePlanData(this.scheduleForm.value).then((res:any)=>{
          if(res.Status){
            this.commonService.showToast("success", "Success", 'Added New Schedule  Successfully');
            this.getAdvancePlanList()
          }else{
            this.commonService.showToast('error', "Error", (res?.message ?? "Some Error Occurred"))

          }
    
        }, (err) => {
          this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
          console.log("error",err)
      
        })
      }
      
      
    }

    searchChange(e) {
      this.searchTerm = e.target.value;
      console.log('searchTerm: ', this.searchTerm);
      this.getAdvancePlanList()
    }
    ondaysFilterChange(e) {
      let status = e.target.value;
      this.status = status;
      this.getAdvancePlanList()
    }
    openCalender(){
      this.OpenCalender = !this.OpenCalender
    }
    openSearch(){
      this.openSearchBox = !this.openSearchBox
    }
}
