import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketSystemRoutingModule } from './ticket-system-routing.module';
import { TicketSystemComponent } from './ticket-system.component';
import { SharedModule } from '../shared/shared.module';
import { TicketTableComponent } from './ticket-table/ticket-table.component';
import { TicketStatusCardComponent } from './ticket-status-card/ticket-status-card.component';
import { ManageCommentsComponent } from '../shared/components/manage-comments/manage-comments.component';
import { TicketDashboardComponent } from './ticket-dashboard/ticket-dashboard.component';
import { TicketTypeComponent } from './ticket-type/ticket-type.component';
import { AddEditGroupComponent } from './add-edit-group/add-edit-group.component';
import { BoardModule } from 'ngx-board';
import { TicketKanbanBoardComponent } from './ticket-kanban-board/ticket-kanban-board.component';
import { GroupTableComponent } from './group-table/group-table.component';
import { TicketSidebarComponent } from './add-ticket-sidebar/add-ticket-sidebar.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { CustomDatePipe } from '../shared/pipes/kanban-date.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TicketReportingDashComponent } from './ticket-reporting-dash/ticket-reporting-dash.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AddNewTicketSidebarComponent } from './add-new-ticket-sidebar/add-new-ticket-sidebar.component';


@NgModule({
    declarations: [
        TicketSystemComponent,
        TicketTableComponent,
        TicketStatusCardComponent,
        TicketDashboardComponent,
        TicketTypeComponent,
        AddEditGroupComponent,
        GroupTableComponent,
        TicketKanbanBoardComponent,
        TicketSidebarComponent,
        TicketReportingDashComponent,
        AddNewTicketSidebarComponent,
    ],
    imports: [
        CommonModule,
        TicketSystemRoutingModule,
        SharedModule,
        BoardModule,
        AngularEditorModule,
        HttpClientModule,
        NgApexchartsModule,
        BsDatepickerModule.forRoot(),

    ],
    exports:[TicketSidebarComponent]
})
export class TicketSystemModule { }
