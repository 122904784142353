<div class="container mt-4">
  <div class="header d-flex justify-content-end mx-3">
    <div class="d-flex gap-2 ">
    <button class="custom-btn" (click)="hideandShow('All')">All Visitors</button>
    <button class="custom-btn" (click)="hideandShow('Active')">Active Visitors</button>
    </div>
  </div>

  <div class="container mt-4" id="allVisitors" *ngIf="valueShow">
    <div class="custom-card col-md-12" style="height: 700px;" >
      <div class="card-header pb-2 border-bottom d-flex justify-content-between">
        <h5 class="card-title">All Visitor</h5>
        <div class="d-flex gap-1" >
          <input type="text" class="form-control ps-2  mx-2 py-1 mt-1" placeholder="Search..." style="height: fit-content;" *ngIf="openSearchBox"
          (keyup.enter)="searchChange($event)" />
          <app-filter-btn [icon]="'fa fa-search'" class=" py-1 mr-2" [isBtn]="true" (click)="openSearch()"></app-filter-btn>
        </div>
      </div>
      <div class="card-bo mt-2" style="height: 700px; scrollbar-width: none; overflow: scroll;">
        <!-- Today's Schedule -->
        <div class="schedule-section border-bottom justify-content-between mb-2 d-flex gap-2 " *ngFor="let visitor of visitors">
          <div class="d-flex gap-3">

      
          <div class="img">
            <img src="https://static.vecteezy.com/system/resources/thumbnails/002/387/693/small/user-profile-icon-free-vector.jpg" alt="" style="max-width: 45px;">
          </div>
          <div class="content pb-2">
          <h5>{{ visitor.user_name }}</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item-1">{{ visitor.visited_at | date: 'MMM d, y h:mm a' }}   - {{ visitor.left_at | date: 'MMM d, y h:mm a' }} </li>
            <!-- <li class="list-group-item">9:28AM </li> -->
          </ul>
          </div>
          </div>
          <div>
          <span class="badge bg-primary rounded-pill">{{ visitor.customer_id }}</span>
          </div>
        </div>
        <div class="col-md-12 text-center">
          <span class="text-danger" *ngIf="visitors.length == 0">Not Found</span>
      </div>
      </div>
    </div>
  </div>
  
  <div class="container mt-4" id="activeVisitors" *ngIf="!valueShow">
    <div class="custom-card col-md-12" style="height: 500px;">
      <div class="card-header mb-4">
        <h5 class="card-title">Active Visitor </h5>
      </div>
      <div class="card-bo" style="height: 700px; scrollbar-width: none; overflow: scroll;">
        <!-- Today's Schedule -->
        <div class="schedule-section border-bottom mb-2 d-flex gap-2 " *ngFor="let visitor of activeVisitors">
          <div class="d-flex gap-3">

      
          <div class="img">
            <img src="https://static.vecteezy.com/system/resources/thumbnails/002/387/693/small/user-profile-icon-free-vector.jpg" alt="" style="max-width: 45px;">
          </div>
          <div class="content  pb-2">
          <h5>{{ visitor.user_name }}</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item-1">{{ visitor.visited_at | date: 'MMM d, y h:mm a' }}   - {{ visitor.left_at | date: 'MMM d, y h:mm a' }} </li>
            <!-- <li class="list-group-item">9:28AM </li> -->
          </ul>
          </div>
          </div>
          <div>
          <span class="badge bg-primary rounded-pill">{{ visitor.customer_id }}</span>
          </div>
        </div>
        <div class="col-md-12 text-center">
          <span class="text-danger" *ngIf="activeVisitors.length == 0">Not Found</span>
      </div>
      </div>
    </div>
  </div>

</div>

