import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
// import { DragulaModule } from "ng2-dragula";
import { TranslateModule } from "@ngx-translate/core";
// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { TableCellComponent } from "./components/table-cell/table-cell.component";
// import { CustomizerComponent } from "./components/customizer/customizer.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
// import { SearchComponent } from "./components/header/elements/search/search.component";
import { MegaMenuComponent } from "./components/header/elements/mega-menu/mega-menu.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";
// Directives
import { DisableKeyPressDirective } from "./directives/disable-key-press.directive";
import { OnlyAlphabetsDirective } from "./directives/only-alphabets.directive";
import { OnlyNumbersDirective } from "./directives/only-numbers.directive";
import { ShowOptionsDirective } from "./directives/show-options.directive";
// Services
import { ChatService } from "./services/chat.service";
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { TableService } from "./services/table.service";
import { NgbdSortableHeader } from "./directives/NgbdSortableHeader";
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from "./components/header/elements/swiper/swiper.component";
import { TokenInterceptor } from "./services/token.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { SearchComponent } from "./components/header/elements/search/search.component";
import { MatStepperModule } from "@angular/material/stepper";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { NgSelectModule } from "@ng-select/ng-select";

import { ToastModule } from "primeng/toast";
import { MessagesModule } from "primeng/messages";

import { CommonBreadcrumbComponent } from "./components/common-breadcrumb/common-breadcrumb.component";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { MatSnackBarModule } from "@angular/material/snack-bar";

import { ToastComponent } from "./components/toast/toast.component";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgxDropzoneModule } from "ngx-dropzone";
import { AdvanceSearchComponent } from "./components/advance-search/advance-search.component";
import { ErrorValidatorsComponent } from "./components/error-validators/error-validators.component";
import { AddEditTicketComponent } from "./components/add-edit-ticket/add-edit-ticket.component";
import { AdvanceSearchOrderComponent } from "./components/advance-search-order/advance-search-order.component";
import { ManageCommentsComponent } from "./components/manage-comments/manage-comments.component";
import { BulkDialogComponent } from "./components/bulk-dialog/bulk-dialog.component";
import { ApplicationComponent } from "./components/header/elements/application/application.component";
import { ChangeCompanyComponent } from "./components/header/elements/change company/change_company.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { GlobalLoaderComponent } from "./components/global-loader/global-loader.component";
import { CommonFilterComponent } from "./components/common-filter/common-filter.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NativeDateAdapter } from "@angular/material/core";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CustomDatePipe } from "./pipes/kanban-date.pipe";
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { FilterBtnComponent } from './components/filter-btn/filter-btn.component';
import { CardStatsComponent } from './components/card-stats/card-stats.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { SkeletonCreditCardComponent } from './components/skeleton-loader/skeleton-credit-card/skeleton-credit-card.component';
import { SkeletonSubscriptionInfoComponent } from './components/skeleton-loader/skeleton-subscription-info/skeleton-subscription-info.component';
import { SkeletonAddressInfoComponent } from './components/skeleton-loader/skeleton-address-info/skeleton-address-info.component';
import { SmallCardSkeletonComponent } from './components/skeleton-loader/card-skeleton/small-card-skeleton/small-card-skeleton.component';
import { SidebarNavigationSkeletonComponent } from './components/skeleton-loader/card-skeleton/sidebar-navigation-skeleton/sidebar-navigation-skeleton.component';
import { MediumCardSkeletonComponent } from './components/skeleton-loader/card-skeleton/medium-card-skeleton/medium-card-skeleton.component';
import { TimlineCardSkeletonComponent } from './components/skeleton-loader/card-skeleton/timline-card-skeleton/timline-card-skeleton.component';
import { DynamicColumnFormSkeletonComponent } from './components/skeleton-loader/card-skeleton/form-skeleton/dynamic-column-form-skeleton/dynamic-column-form-skeleton.component';
import { AddressFormSkeletonComponent } from './components/skeleton-loader/card-skeleton/form-skeleton/address-form-skeleton/address-form-skeleton.component';
import { PaymentFormSkeletonComponent } from './components/skeleton-loader/card-skeleton/form-skeleton/payment-form-skeleton/payment-form-skeleton.component';
import { PlanCardSkeletonComponent } from './components/skeleton-loader/card-skeleton/plan-skeleton/plan-card-skeleton/plan-card-skeleton.component';
import { PlanDetailSkeletonComponent } from './components/skeleton-loader/card-skeleton/plan-skeleton/plan-detail-skeleton/plan-detail-skeleton.component';
import { ButtonSkeletonComponent } from './components/skeleton-loader/card-skeleton/button-skeleton/button-skeleton.component';
import { NormalCardSkeletonComponent } from './components/skeleton-loader/card-skeleton/normal-card-skeleton/normal-card-skeleton.component';
import { TransactionSkeletonComponent } from './components/skeleton-loader/transaction-skeleton/transaction-skeleton.component';
import { TicketSystemSkeletonComponent } from './components/skeleton-loader/ticket-system-skeleton/ticket-system-skeleton.component';
import { TableSkeletonComponent } from './components/skeleton-loader/table-skeleton/table-skeleton.component';
import { AnimatedSkeletonRowsComponent } from './components/skeleton-loader/card-skeleton/animated-skeleton-rows/animated-skeleton-rows.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { NotificationBadgeComponent } from './components/notification-badge/notification-badge.component';
import { GlobalNotificationComponent } from "./components/notification/notification.component";
import { HollowCircleComponent } from './components/skeleton-loader/hollow-circle/hollow-circle.component';
import { RequestStateService } from "./services/request-state.service";
import { PostRequestBlockerInterceptorService } from "./services/post-request-blocker-interceptor.service";
import { GlobalSearchComponent } from "./components/global-search/global-search.component";
import { DataLoadingSkeletonComponent } from "./components/skeleton-loader/data-loading-skeleton/data-loading-skeleton.component";
import { CreditCardComponent } from "./components/credit-card/credit-card.component";
import { CreditCardNumberPipe } from "./pipes/credit-card-number.pipe";
import { ExecutiveDashboardSkeletonLoaderComponent } from './components/skeleton-loader/executive-dashboard-skeleton-loader/executive-dashboard-skeleton-loader.component';
import { TranssectionDetailSkeletonComponent } from './components/skeleton-loader/transsection-detail-skeleton/transsection-detail-skeleton.component';
import { RenewalReportDetailComponent } from './components/skeleton-loader/renewal-report-detail/renewal-report-detail.component';
import { CustomDatePickerComponent } from "./components/custom-date-picker/custom-date-picker.component";
import { DynamicInvoiceTemplateComponent } from './components/dynamic-invoice-template/dynamic-invoice-template.component';
import { InvoiceTemplateComponent } from './components/invoice-template/invoice-template.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { CarouselSkeletonComponent } from './components/skeleton-loader/carousel-skeleton/carousel-skeleton.component';
import { TimeAgoPipe } from "./pipes/timeAgo.pipe";
import { AllNotificationComponent } from './components/all-notification/all-notification.component';
import { VisitorsComponent } from './components/visitors/visitors.component';
import { VisitorsNotificationComponent } from './components/visitors-notification/visitors-notification.component';
import { UsageSummarySkeletonComponent } from './components/skeleton-loader/usage-summary-skeleton/usage-summary-skeleton.component';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CommonBreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    GlobalLoaderComponent,
    TapToTopComponent,
    MegaMenuComponent,
    LanguagesComponent,
    ChangeCompanyComponent,
    NotificationComponent,
    MyAccountComponent,
    NgbdSortableHeader,
    SvgIconComponent,
    SwiperComponent,
    TableCellComponent,
    SearchComponent,
    ToastComponent,
    AdvanceSearchComponent,
    AdvanceSearchOrderComponent,
    ErrorValidatorsComponent,
    AddEditTicketComponent,
    ManageCommentsComponent,
    BulkDialogComponent,
    ApplicationComponent,
    CommonFilterComponent,
    CustomDatePipe,
    CustomSelectComponent,
    FilterBtnComponent,
    CardStatsComponent,
    SkeletonLoaderComponent,
    SkeletonCreditCardComponent,
    SkeletonSubscriptionInfoComponent,
    SkeletonAddressInfoComponent,
    SmallCardSkeletonComponent,
    SidebarNavigationSkeletonComponent,
    MediumCardSkeletonComponent,
    TimlineCardSkeletonComponent,
    DynamicColumnFormSkeletonComponent,
    AddressFormSkeletonComponent,
    PaymentFormSkeletonComponent,
    PlanCardSkeletonComponent,
    PlanDetailSkeletonComponent,
    ButtonSkeletonComponent,
    NormalCardSkeletonComponent,
    TransactionSkeletonComponent,
    TicketSystemSkeletonComponent,
    TableSkeletonComponent,
    AnimatedSkeletonRowsComponent,
    DatePickerComponent,
    NotificationBadgeComponent,
    GlobalNotificationComponent,
    HollowCircleComponent,
    GlobalSearchComponent,
    DataLoadingSkeletonComponent,
    CreditCardComponent,
    CreditCardNumberPipe,
    ExecutiveDashboardSkeletonLoaderComponent,
    TranssectionDetailSkeletonComponent,
    RenewalReportDetailComponent,
    CustomDatePickerComponent,
    DynamicInvoiceTemplateComponent,
    InvoiceTemplateComponent,
    CarouselSkeletonComponent,
    AllNotificationComponent,
    VisitorsComponent,
    VisitorsNotificationComponent,
    UsageSummarySkeletonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbPaginationModule,
    TranslateModule.forRoot(),
    MessagesModule,
    MatSnackBarModule,
    CarouselModule,
    MatCheckboxModule,
    ToastModule,
    SweetAlert2Module,
    SwiperModule,
    MatStepperModule,
    MatFormFieldModule,
    MatButtonModule,
    MatButtonModule,
    MatInputModule,
    NgSelectModule,
    NgxDropzoneModule,
    NgApexchartsModule,
    MatDatepickerModule,
    BsDatepickerModule,
    MatTooltipModule,
    NgSelectModule
  ],
  providers: [
    NavService,
    ChatService,
    LayoutService,
    TableService,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    NativeDateAdapter,
    
    RequestStateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PostRequestBlockerInterceptorService,
      multi: true,
    },
    NativeDateAdapter,
  ],
  exports: [
    CustomDatePipe,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    TranslateModule,
    NgbPaginationModule,
    LoaderComponent,
    GlobalLoaderComponent,
    BreadcrumbComponent,
    CommonBreadcrumbComponent,
    MatSnackBarModule,
    FeatherIconsComponent,
    TapToTopComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    NgbdSortableHeader,
    TableCellComponent,
    SearchComponent,
    MatStepperModule,
    SweetAlert2Module,
    MatFormFieldModule,
    MatCheckboxModule,
    ToastModule,
    MessagesModule,
    SvgIconComponent,
    SwiperModule,
    MatButtonModule,
    MatInputModule,
    NgSelectModule,
    ToastComponent,
    NgxDropzoneModule,
    AdvanceSearchComponent,
    AdvanceSearchOrderComponent,
    ErrorValidatorsComponent,
    AddEditTicketComponent,
    ManageCommentsComponent,
    CommonFilterComponent,
    CustomSelectComponent,
    FilterBtnComponent,
    CardStatsComponent,
    SkeletonLoaderComponent,
    DatePickerComponent,
    GlobalNotificationComponent,
    CustomDatePickerComponent,
    DynamicInvoiceTemplateComponent,
  ],
})
export class SharedModule {}