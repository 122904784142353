/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { overrideConsoleLogs } from './app/shared/services/console-override.service';
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from "@sentry/angular";
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

if (environment.production) {
  enableProdMode();
}

overrideConsoleLogs();

Sentry.init({
  dsn: "https://60aebf87d58ae947f6ebc78ed0aceb33@o4508377603506176.ingest.us.sentry.io/4508694354853888",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  
  // Reduce Tracing Rate to Prevent Overload
  tracesSampleRate: 0.2, // Log only 20% of transactions
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Limit Session Replay Logging
  replaysSessionSampleRate: 0.05, // Capture 5% of sessions
  replaysOnErrorSampleRate: 0.5, // Capture 50% of error sessions

  // ✅ Corrected Transport Implementation - Use Function Reference
  transport: (options) => Sentry.makeFetchTransport(options), // ✅ This avoids the type error

  // Ignore Non-Critical Errors to Reduce Noise
  beforeSend(event) {
    if (event.exception && event.exception.values) {
      const errorType = event.exception.values[0].type;
      if (["HttpErrorResponse", "NetworkError", "TimeoutError"].includes(errorType)) {
        return null; // Ignore known non-critical errors
      }
    }
    return event;
  },

  // Ensure Sentry Only Works in Production
  enabled: environment.production,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
