import { Injectable } from '@angular/core';
import { HttpService } from '../shared/services/httpService.service';
import { BehaviorSubject } from 'rxjs';
import { PermissionService } from '../shared/services/permission-service.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  executivePermission:any
   public sideMenubarSub = new BehaviorSubject<any[]>([]);
   executivePermission2:any

  constructor(private http: HttpService,    private permissionService:PermissionService,) { }

  getTransactionList(data) {
    try {
      let url = 'api/transactions/list'
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",

      }
      console.log(data);
      if (data?.searchTerm) {
        body.searchTerm = data?.searchTerm
      }
      if (data?.per_page) {
        body.per_page = data?.per_page
      }
      if (data?.page) {
        body.page = data?.page
      }
      if (data?.status) {
        body['status[]'] = data?.status
      }
      if (data?.amount) {
        body['amount'] = data?.amount
      }
      if (data?.range) {
        body['range'] = data?.range
      }
      if (data?.customStartDate) {
        body['customStartDate'] = data?.customStartDate
      }
      if (data?.customEndDate) {
        body['customEndDate'] = data?.customEndDate
      }
      if (data?.IsExport) {
        body['IsExport'] = data?.IsExport
      }

      return this.http.get(url, body)
    } catch (err) {
      throw Error(err)
    }
  }
  getTransactionStat() {
    try {
      let url = 'api/transactions/getInvoicePaymentsStatusCount'
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021"
      }

      return this.http.get(url, body)
    } catch (err) {
      throw Error(err)
    }
  }

  getTransactionDetails(data) {
    try {
      let url = `api/subscription/getTransactionDetails/${data.TracID}`
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
      }
      if (data?.PaymentId) {
        body.PaymentId = data?.PaymentId
      }
      return this.http.get(url, body)
    } catch (err) {
      throw Error(err)
    }
  }

  getDetails(data) {
    try {
      let url = 'api/transactions/getDetail'
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
      }
      if (data?.PaymentId) {
        body.paymentId = data?.PaymentId
      }
      if (data?.source) {
        body.source = data?.source
      }
      return this.http.get(url, body)
    } catch (err) {
      throw Error(err)
    }
  }

  getTableHeader() {
    try {
      let url = 'api/orders/showTableHeader'
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        "type": "transaction"
      }
      return this.http.get(url, body)
    } catch (err) {
      throw Error(err)
    }
  }

  getRefundReason() {
    try {
      let url = 'api/subscription/getReasonsList'
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
      }
      return this.http.get(url, body)
    } catch (err) {
      throw Error(err)
    }
  }

  getPaymentTimeline(data) {
    try {
      let url = 'api/transactions/customerActivityTimeline'
      return this.http.get(url, data,false)
    } catch (err) {
      throw Error(err)
    }
  }

  refundTransaction(data) {
    try {
      let url = 'api/transactions/refundTransaction'
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        "paymentId": data.paymentId,
        "CustomerId": data.CustomerId,
        "refundReasonId": data.refundReasonId,
        "transactionId": data.transactionId,
        "refundAmount": data.refundAmount,
        "lastFourDigits": data.lastFourDigits,
      }
      return this.http.post(url, body)
    } catch (err) {
      throw Error(err)
    }
  }

  async getDeactivatedResouceDetails(){
    try {
      let data={
        resource_Category:"CUSTOMER_DASHBOARD"
      }
      return this.http.get('api/system/resource-permission-detail',data, false, false).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }
  async sideMenuInitilize() {
    try {
      const res = await  this.permissionService.getResourceCategoryById('Transections') as any;
      const data = res;
        const resourceTypes = data?.resource_types;
        if (Array.isArray(resourceTypes) && resourceTypes.length > 0) {
          this.executivePermission = resourceTypes[0]?.resources || [];
          const mappedMenuItems = this.mapedMenuItems();
          this.sideMenubarSub.next(mappedMenuItems);
     
      } else {
        console.log("Data array is empty or not defined");
      }
    } catch (error) {
      console.error("Error occurred while fetching menu types:", error);
    }
  }
  

mapedMenuItems() {
  return this.executivePermission.map(el => {
    let tempData = {}
    let lastIndex = el.resource_permission.length - 1;
    let isHidden = el.resource_permission[0]?.CanView || 0;
    if (el.children.length == 0) {
      tempData = {
        title: el.DisplayName,

        //isHidden: this.getPermission(el),
        // isHidden: convertToBoolean(this.getPermission(el)),

        isHidden,
        // isHidden: convertToBoolean(el.HideMenu),

        icon: el.NavIconClass,
        type: "link",
        badgeType: "light-success",
        path: el.UrlRoute
      }


    } else {
      tempData = {
        title: el.DisplayName,

        //isHidden: this.getPermission(el),

        isHidden,

        icon: el.NavIconClass,
        type: "sub",
        badgeType: "light-success",
        path: el.UrlRoute,
        badgeValue: el.children?.length,
        children: el.children.map(el2 => {
          return {
            path: el2.UrlRoute, title: el2.DisplayName, type: "link"
          }
        })
      }
    }

    return tempData
  })
}



DeactivedItems() {
return this.executivePermission2.map(el => {
  let tempData = {}
  let isHidden = el.resource_permission[0]?.CanView || 0;
  if (el.children.length == 0) {
    tempData = {
      title: el.DisplayName,
      isHidden,
      icon: el.NavIconClass,
      type: "link",
      badgeType: "light-success",
      path: el.UrlRoute
    }


  } else {
    tempData = {
      title: el.DisplayName,
      isHidden,
      icon: el.NavIconClass,
      type: "sub",
      badgeType: "light-success",
      path: el.UrlRoute,
      badgeValue: el.children?.length,
      children: el.children.map(el2 => {
        return {
          path: el2.UrlRoute, title: el2.DisplayName, type: "link"
        }
      })
    }
  }

  return tempData
})
}
getSideMenuBar() {
  return this.sideMenubarSub.asObservable();
}
}
