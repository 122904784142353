export const CONTENT_CONFIG = {
    // TagLine
  tagline:
    "An effective tool and operating system to manage your customers lifecycle.",

  // CompanyName
  companyName:{
    unity:'Unity Wireless',
    mingle:'Mingle'
  },
  // Login page Logos
  logos: {
    unityLogo: "assets/images/logo/logo.png",
    mingleLogo: "assets/images/logo/mingle.png",
  },

  companyLogoIcons:{

    //landing page header logo (Home page)
    unityIcon :'https://unitywireless.com/cdn/shop/files/unitywireless_04e7d271-53f8-4f14-835f-87ed2d976778_32x32.webp?v=1688574366',
    mingleIcon: 'https://www.minglemobile.com/cdn/shop/t/57/assets/favicon.svg?v=178931131784370144781732655519',

    // landing sidebar logo (Home page)

    unityLogo: 'assets/images/logo/unity_logo.png',
    mingleLogo: 'assets/images/logo/mingle.png' ,
  },


    // Notice
    notice: ` You are going to enter a system owned by Unity Wireless Inc. The information on this computer system is for official use only. This system can only be accessed by authorized users. 
              Federal or state criminal laws may be broken by unauthorized access to, use of, or modification of this computer system, the data it contains, or the data while it is being transferred to or from this system. Administrative penalties, fines, or imprisonment may be imposed on anyone who gains unauthorized access to a computer system, exceeds their authorized access authority, obtains, alters, damages, destroys, or discloses information, or obstructs authorized use of the system's data.`,
};
