<div class="container-fluid custom-cad">
    <div class="row">
      <nav class="col-md-12 d-none d-md-block bg-light sidebar">
        <div class="sidebar-sticky">
          <ul class="nav flex-row mx-2">
            <li class="nav-item">
                
              <a class="nav-link" [class.active]="activeComponent === 'EmailTemplate'" (click)="setActiveComponent('EmailTemplate')">
                <i class="fa-solid fa-envelope-open-text"></i> Email Template
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [class.active]="activeComponent === 'emailTemplateType'" (click)="setActiveComponent('emailTemplateType')">
                <i class="fa-solid fa-paper-plane"></i> Email Template Types
              </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="activeComponent === 'emailTemplatePlace'" (click)="setActiveComponent('emailTemplatePlace')">
                  <i class="fa-solid fa-gears"></i> Email Template Placeholders
                </a>
              </li>
          
          </ul>
        </div>
      </nav>
      <main role="main" class="col-md-9 ml-sm-auto col-lg-12 px-4">
           <app-notification-email-tem *ngIf="activeComponent === 'EmailTemplate'"> </app-notification-email-tem>
           <app-email-template-type *ngIf="activeComponent === 'emailTemplateType'"></app-email-template-type>
           <app-email-templat-place *ngIf="activeComponent === 'emailTemplatePlace'"></app-email-templat-place>
        </main>
    </div>
  </div>
  