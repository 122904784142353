import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RenewalListComponent } from './renewal-list/renewal-list.component';
import { RenewalReportsRoutingModule } from './renewal-reports-routing.module';
import { LogInfoComponent } from './log-info/log-info.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { InvoiceInfoComponent } from './invoice-info/invoice-info.component';
import { RenewalDetailComponent } from './renewal-detail/renewal-detail.component';
import { CustomerInformationComponent } from './customer-information/customer-information.component';
import { TransactionModule } from '../transactions/transaction.module';
import { PlanChangeComponent } from './plan-change/plan-change.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AdvancePlansComponent } from './advance-plans/advance-plans.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MisAlignedSubscriptionsComponent } from './mis-aligned-subscriptions/mis-aligned-subscriptions.component';
import { PlanRenewalComponent } from './plan-renewal/plan-renewal.component';
import { PoInfoCardComponent } from './po-info-card/po-info-card.component';
import { PoDetailsComponent } from './po-details/po-details.component';
@NgModule({
    declarations: [
        RenewalListComponent,
        LogInfoComponent,
        PaymentInfoComponent,
        InvoiceInfoComponent,
        RenewalDetailComponent,
        CustomerInformationComponent,
        PlanChangeComponent,
        AdvancePlansComponent,
        MisAlignedSubscriptionsComponent,
        PlanRenewalComponent,
        PoInfoCardComponent,
        PoDetailsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        BsDatepickerModule,
        AgGridModule,
        RenewalReportsRoutingModule,
        TransactionModule,
        MatTooltipModule,
        MatTabsModule
    ],
    exports:[CustomerInformationComponent, RenewalListComponent, PlanRenewalComponent,AdvancePlansComponent
    ]
})

export class RenewalReportsModule { }

