import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-purchase-plan-renewal',
  templateUrl: './purchase-plan-renewal.component.html',
  styleUrls: ['./purchase-plan-renewal.component.scss']
})
export class PurchasePlanRenewalComponent {

   @Input() currentCustomerId: any;

   constructor(){
    console.log('customerId: ', this.currentCustomerId);
   }
}
