import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
  isPrepaidCustomer: Subscription;
  isNonPrepaid: boolean;

  constructor(
    private customerProfileService:CustomerProfileService
  ){

  }
  ngOnInit(): void {
    this.isPrepaidCustomer = this.customerProfileService.isPrePaidEvent$.subscribe((isNonPrepaid) => {
      this.isNonPrepaid = isNonPrepaid;
    });
  }
  ngOnDestroy(): void {
    if (this.isPrepaidCustomer) {
      this.isPrepaidCustomer.unsubscribe();
    }
  }

}
