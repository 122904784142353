<ng-container>
  <div class="container-fluid m-0 customer-profile-view w-100 p-0"  *ngIf="!isSkeletonLoader" @fadeInOut
    style="height: 100%;  padding:0;background-color: transparent;">


    <div class="tab-content p-0 w-100 m-0" id="notesTabsContent">
      <div class="tab-pane fade show active" id="customer-notes" role="tabpanel" aria-labelledby="customer-notes-tab">
        <div class="container-fluid w-100 d-flex flex-column justify-content-center align-items-center px-0">
          <div class="row customer-notes-card w-100 p-1 mb-1 mt-2" style="border-radius:10px">
            <h5 class="my-2">Customer Notes</h5>
            <div class="col-12 p-0">
              <div class="card  mb-0">
                <form [formGroup]="formData" class=" mb-1">
                  <div class="row mb-1 mx-2">
                    <div class="col-12 col-md-1 d-flex align-items-center">

                      <div class="d-flex justify-content-center align-items-center bg-primary text-white rounded p-3"
                        style=" border-radius: 10px;  ">
                        <p class="m-0 text-center" style="font-size: 2rem; line-height: 1;">{{firstLettersCustomerName}}
                        </p>
                      </div>

                    </div>
                    <div class="col-12 col-md-10 my-2 d-flex flex-column justify-content-center align-items-center">
                      <!-- Parent Container -->

                      <div class="mb-0 w-100">
                        <textarea id="customerNotes" formControlName="Text" placeholder="Leave a note..."
                          class="form-control" rows="3" style="
                                            border: none; 
                                            padding: 20px 0; 
                                            resize: none; 
                                            overflow: auto; 
                                            height: 100%; 
                                            line-height: 1; 
                                          "></textarea>
                      </div>
                    </div>
                    <div class="col-md-1 text-end mt-2">
                      <span (click)="refreshNotes()"><app-feather-icons [icon]="'refresh-cw'"
                        class="ms-2 text-primary cursor-pointer"></app-feather-icons></span>
                    </div>
                  </div>
                  <div
                    class="card-footer action d-flex flex-column flex-sm-row justify-content-between align-items-center m-0 p-3">
                    <div class="d-flex flex-column flex-sm-row align-items-center w-100">

                      <!-- Note Type Dropdown -->
                      <div class="note-wrapper m-1 w-100">
                        <div class="noteType mb-2" style="flex-basis: 30%; min-width: 200px;">
                          <ng-select [items]="allNotesType" bindLabel="Name" bindValue="id" formControlName="NoteTypeID"
                            placeholder="Select note type" (change)="onSelectNote($event)" [searchable]="true"
                            class="form-control border-0 p-0">
                          </ng-select>
                        </div>
                      </div>

                      <!-- Icon Section -->
                      <div class="d-flex justify-content-start justify-content-sm-center align-items-center mb-2 mx-2">
                        <!-- Add New Note Type -->
                        <div class="me-3 cursor-pointer" (click)="openModal(addNoteType)" data-bs-toggle="tooltip"
                          title="Add New Note Type">
                          <span class="text-secondary hover-text-primary">
                            <i class="fa-solid fa-note-sticky" style="font-size: 1.8rem;"></i>
                          </span>
                        </div>

                        <!-- Lowest (Priority) -->
                        <!-- <div class="me-3 cursor-pointer" data-bs-toggle="tooltip" title="Lowest">
                          <span class="text-secondary hover-text-primary">
                            <i class="fa-solid fa-arrow-down" style="font-size: 1.4rem;"></i>
                          </span>
                        </div> -->

                        <!-- Create New Ticket -->
                        <div class="me-3 cursor-pointer" (click)="openModal(addEditTicketModal, 'lg')"
                          data-bs-toggle="tooltip" title="Create New Ticket">
                          <span class="text-secondary hover-text-primary">
                            <i class="fa-solid fa-ticket" style="font-size: 1.8rem;"></i>
                          </span>
                        </div>
                      </div>

                      <!-- Post Button -->
                      <div class="me-3 mt-sm-0">
                        <button type="button" (click)="onSubmit()"
                          class="btn btn-outline-success btn-lg d-flex align-items-center px-4 w-100 w-sm-auto"
                          [disabled]="!formData.valid || !formData.get('NoteTypeID')?.value">
                          <span class="fw-bold">Post</span>
                        </button>
                      </div>

                    </div>
                  </div>

                  <!-- <div class="div col-sm-auto">
                            <button type="button" (click)="onSubmit()" class="btn btn-outline-primary btn-sm d-flex align-items-center px-4"
                            [disabled]="!formData.valid || !formData.get('NoteTypeID')?.value">
                                <span class="fw-bold">Post</span>
                            </button>
                        </div>   -->
                  <!-- </div> -->
                </form>
              </div>

            </div>
          </div>
          <div class="row notification  p-5 pt-1 w-100" *ngIf="!isNoNotes ;">
            <div class="col-12 customerNotes  p-0 ">
              <div class="body m-0 p-2 content-scroll" (scroll)="onScroll($event)"
                style="height: 70vh;overflow-y: auto; overflow-x: hidden;">
                <ng-container *ngIf="allNotes?.length">
                  <ul class="mt-0">
                    <ng-container>
                      <li class="d-flex mb-4" *ngFor="let note of allNotes">
                        <div class="activity-dot-info" *ngIf="!note.group">
                          <img *ngIf="note.type === 'activity' && !note?.emailId?.value" src="../../../../assets/svg/restore.png" width="20"
                            height="20">
                          <i *ngIf="note.type === 'note'" class="fa fs-5 fa-regular fa-note-sticky"></i>
                          <i *ngIf="note.type === 'ticketLog' " class="fa fs-5 fa-regular fa-ticket"></i>
                          <i *ngIf="note?.emailId?.value" class="fa-regular fs-5 fa-envelope"></i>
                        </div>
                        <div class="d-flex flex-column w-100 mx-3">
                          <span *ngIf="note.group" class="text-center badge-container">
                            <span class="custom-badge">{{ note.group }}</span>
                          </span>
                          <div class="timeline-content w-100 ms-3" *ngIf="!note.group">
                            <div class="d-flex flex-row justify-content-between">
                              <div class="d-flex gap-2 align-items-end">
                                <div>
                                  <h6 *ngIf="note?.name?.id !== 10">{{ note.type === 'note' ? note.Text : note.activityDetail?.value }}</h6>
                                  <h6 *ngIf="note.type === 'activity' && note?.name?.id === 10" (click)="navigateToSubsDetails(note.activityDetail?.RelatedTableId)">
                                    <span class="text-underline cursor-pointer clickable-ticket" >
                                      {{ note.activityDetail?.value }}
                                    </span>
                                    
                                  </h6>
                                  <h6 *ngIf="note.type === 'ticketLog'">
                                    <span *ngIf="note.type === 'ticketLog'">
                                      <span 
                                        class="text-underline cursor-pointer clickable-ticket" 
                                        (click)="onTicketClick(note.activityDetail)">
                                        {{ extractTicketId(note.activityDetail) }}
                                      </span>
                                      {{ extractRemainingText(note.activityDetail) }}
                                    </span>
                                  </h6>
                                  

                                </div>
                              </div>
                              <small class="text-muted fw-bold">{{ note.CreatedDate | date: 'h:mm a' }}</small>
                            </div>
                            <div class="mb-0 d-flex flex-row justify-content-between">
                              <p class="mt-3">
                                <small
                                  [ngClass]="{'bg-primary': note.type === 'activity' || 'ticketLog', 'bg-secondary': note.type === 'note'}"
                                  class="badge text-light h-fit-content">
                                  {{ note.CreatedDate | date: 'MMM d, y' }}
                                </small>
                                <span class="badge bg-light text-dark" *ngIf="note.type === 'activity'">
                                  {{ (note.createdByUser?.value || 'Old system') }}
                                </span>
                                <span class="badge bg-light text-dark" *ngIf="note.type === 'note'">
                                  {{ (note.agent?.user?.name ||
                                  'Old system') }}
                                </span>
                                <span *ngIf="note.type === 'ticketLog'" class="badge bg-light text-dark">
                                  {{note?.createdByUser}}
                                </span>
                                <span *ngIf="note.type === 'note' && note.notetype?.Name">
                                  <b>Type:</b> {{ note.notetype.Name }}
                                </span>
                                <button class="btn btn-dark btn-xs mx-3" (click)="resendEmail(note)"  *ngIf="note?.emailId?.value">Resend email </button>
                                <button class="btn btn-dark btn-xs" (click)="previewEmail(note,emailPreview)"  *ngIf="note?.emailId?.value">Preview email </button>
                            
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                </ng-container>
                <ng-container *ngIf="!hasMoreData">
                  <div class="d-flex gap-3 mx-5">
                  <p class="text-danger">No notes available.  </p>
                  <span (click)="refreshNotes()" class="cursor-pointer"> <i class="fa-solid fa-arrows-rotate"></i></span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <ng-template #noNotesContainer>
            <p>Nothing to show</p>
          </ng-template>
        </div>


        <div class="container">

        </div>
      </div>

      <div class="tab-pane fade" id="add-notes" role="tabpanel" aria-labelledby="add-notes-tab">




      </div>
    </div>


  </div>
  <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
    <app-skeleton-loader shape="timeline-card"></app-skeleton-loader>
  </div>

  <ng-template #addNoteType let-modal>
    <div class="modal-header modal-copy-header">
      <h5 class="headerTitle mb-0">Add Note Type</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <app-add-note-type (addNoteTypeEvent)="addNoteTypeEvent()"></app-add-note-type>
    </div>
  </ng-template>

  <ng-template #addEditTicketModal let-modal>
    <div class="modal-header modal-copy-header">
      <h5 class="headerTitle mb-0">Add Ticket</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <app-add-ticket (addEditTicketEvent)="addEditTicketEvent()"></app-add-ticket>
    </div>
  </ng-template>
  <ng-template #emailPreview let-modal>
    <div class="modal-header modal-copy-header">
      <h5 class="headerTitle mb-0">Preview Email </h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body email-content">
      <div [innerHtml]="emailContent"></div>
    </div>
  </ng-template>

</ng-container>



<app-ticket-sidebar *ngIf="isSidebarOpen" [isEditing]="isEditing" [ticketId]="ticketId" (close)="closeSidebar()" [isCalledFromCustomerProfile]="isCalledFromCustomerProfile" (save)="saveTicket($event)">
</app-ticket-sidebar>