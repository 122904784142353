import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerDashboardRoutingModule } from './customer-dashboard-routing.module';
import { CustomerDashboardComponent } from './customer-dashboard.component';
import { CustomizerComponent } from './customizer/customizer.component';
import { QuickLinksModule } from './quick-links/quick-links.module';
import { CustomerAccountComponent } from './customer-footer/customer-account/customer-account.component';
import { CustomerNotesComponent } from './customer-footer/customer-notes/customer-notes.component';
import { CustomerFooterComponent } from './customer-footer/customer-footer.component';
import { ChangeAddressAccountComponent } from './customer-footer/customer-account/change-address-account/change-address-account.component';
import { EditDeviceInfoAccountComponent } from './customer-footer/customer-account/edit-device-info-account/edit-device-info-account.component';
import { FeaturedServiceAccountComponent } from './customer-footer/customer-account/featured-service-account/featured-service-account.component';
import { CustomerInfoAccountComponent } from './customer-footer/customer-account/customer-info-account/customer-info-account.component';
import { OtherInfoAccountComponent } from './customer-footer/customer-account/other-info-account/other-info-account.component';
import { TagAccountComponent } from './customer-footer/customer-account/tag-account/tag-account.component';
import { AddNoteTypeComponent } from './customer-footer/customer-notes/add-note-type/add-note-type.component';
import { AddTicketComponent } from './customer-footer/customer-notes/add-ticket/add-ticket.component';
import { ChangeBillingAddressComponent } from './customer-footer/customer-account/change-billing-address/change-billing-address.component';
import { DragDropModule } from '@angular/cdk/drag-drop';


import { TicketSystemModule } from '../ticket-system/ticket-system.module';
import { AdhocInvoiceModule } from '../adhoc-invoice/adhoc-invoice.module';
import { AdhocInvoiceComponent } from '../adhoc-invoice/adhoc-invoice.component';
import { AdhocFormComponent } from '../adhoc-invoice/adhoc-form/adhoc-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
  declarations: [
    CustomerDashboardComponent,
    CustomizerComponent,
    CustomerAccountComponent,
    CustomerNotesComponent,
    CustomerFooterComponent,
    ChangeAddressAccountComponent,
    EditDeviceInfoAccountComponent,
    FeaturedServiceAccountComponent,
    CustomerInfoAccountComponent,
    OtherInfoAccountComponent,
    TagAccountComponent,
    AddNoteTypeComponent,
    AddTicketComponent,
    ChangeBillingAddressComponent,

   
  

  ],
  imports: [
    CommonModule,
    CustomerDashboardRoutingModule,
    QuickLinksModule,
    TicketSystemModule,
    DragDropModule,
    AdhocInvoiceModule,
    NgSelectModule
  ],
  exports:[CustomerNotesComponent,ChangeAddressAccountComponent,AdhocFormComponent],
  // schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class CustomerDashboardModule { }
