<div class="custom-modal-width">

  
    <!-- Skeleton Body -->
    <div class="modal-body p-4">
      <div class="container-fluid">
        <div class="row g-3">
          
          <!-- Left Section: Daily Usage Skeleton -->
          <div class="col-md-8 border-end">
            <h5 class="text-center text-primary fw-bold">Daily Usage</h5>
            <div class="daysContainer">
              <div *ngFor="let item of [1,2,3]" class="skeleton-card mb-3"></div>
            </div>
          </div>
  
          <!-- Right Section: Total Summary Skeleton -->
          <div class="col-md-4">
            <h5 class="text-center text-primary fw-bold">Total Summary</h5>
            <div class="skeleton-summary-card"></div>
            <div class="skeleton-summary-table"></div>
            <div class="skeleton-summary-total"></div>
          </div>
  
        </div>
      </div>
    </div>
  

  </div>
  