import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { fadeInOut } from "src/app/shared/services/app.animation";
import { UserAgentService } from "../../services/user-agent.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { HttpService } from "src/app/shared/services/httpService.service";
import { CommonService } from "src/app/shared/services/common.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationsEmailTempService } from "../../services/notification-template-service";


@Component({
  selector: "app-email-template-type",
  templateUrl: "./email-template-type.component.html",
  styleUrls: ["./email-template-type.component.scss"],
  animations:[fadeInOut]
})
export class EmailTemplateTypeComponent {
  public pageDropdownMenu: any = [10, 20, 30];
  public itemsPerPage: number = 10;
  public totalItems: number;
  currentPage: number = 1;
  totalPages: number = 1;
  userList: any;
  public first_page_url: any = "";
  public last_page_url: any = "";
  initials: any;
  public next_page_url: any = "";
  totalusers: number;
  tagForm: FormGroup;
  modalRef: any;
  tagId: any;
  showmodal: boolean = false;
  tagTypeList: any;
  isSkeletonLoader: boolean = true;
  constructor(
    private fb: FormBuilder,
    private userAgentService: UserAgentService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private http: HttpService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private notifationTempService: NotificationsEmailTempService
  ) {}
  @ViewChild("tagModal") tagModal;

  ngOnInit(): void {
    this.tagForm = this.fb.group({
      typeName: ["", Validators.required],
      description: [""],
    });
    this.getUserList();
    this.getTagType();
  }

  getTagType(search?) {
    let itemsPerPage = this.itemsPerPage;
    let obj = {
      per_page: itemsPerPage,
      page: this.currentPage,
    };
    this.userAgentService.getTagTypeList(search, obj).subscribe((res: any) => {
      this.tagTypeList = res?.data?.data;
    });
  }
  getUserList(search?) {
    let itemsPerPage = this.itemsPerPage;
    let obj = {
      // per_page: itemsPerPage,
      // page: this.currentPage,
      searchTerm:search
    };
    this.notifationTempService.getTemplatetypes1(obj).subscribe((res: any) => {
      this.userList = res?.data;
      this.totalusers = res?.data?.total;
      this.totalItems = res.data.total;
      this.currentPage = res.data.current_page;
      this.first_page_url = res.data.first_page_url;
      this.last_page_url = res.data.last_page_url;
      this.next_page_url = res.data.next_page_url;
    });
    setTimeout(() => {
      this.isSkeletonLoader = false;
    }, 1500);
    // this.loadUser=false
  }
  onChangeNumberOfPage() {
    this.getUserList();
  }
  pageChange(event) {
    this.currentPage = event;
    this.getUserList();
  }
 
  back() {
    this.router.navigateByUrl(`/user-settings`);
  }
  filterdData(event: any) {
    let data = event.target.value;
    this.getUserList(data);
    // console.log('getUser data: ',this.getUserList(data))
  }

  deleteTag(id) {
    this.notifationTempService.DeleteTemplateType(id).subscribe(
      (res: any) => {
        if (res.Status) {
          this.commonService.showToast("success", "Success", res.data);
          this.getUserList();
        } else {
          this.commonService.showToast(
            "error",
            "Error",
            "Something went wrong"
          );
        }
      },
      (error) => {
        this.commonService.showToast(
          "error",
          "Error",
          "Failed to add resource"
        );
        console.error("API error:", error);
      }
    );
  }
  openModal() {
    if (this.showmodal) {
      this.tagForm.reset()
    }
    this.showmodal = false;
    this.modalRef = this.modalService.open(this.tagModal, { centered: true });
  }
  editRouter(user:any){
    this.tagForm.patchValue({
      typeName: user.type_name,
      description:user.description,

    });
    this.tagId = user.id
    this.showmodal = true

   this.modalRef= this.modalService.open(this.tagModal, { centered: true });
  }
  saveTagData() {
    if (this.tagForm.valid) {
      const data = this.tagForm.value;
      if (!this.showmodal) {
        this.notifationTempService.createTemplateTypes(data).subscribe(
          (res: any) => {
            if (res.Status) {
              this.commonService.showToast("success", "Success", res?.message);
              this.modalRef.close();
              this.getUserList();
            } else {
              this.commonService.showToast(
                "error",
                "Error",
                "Something went wrong"
              );
            }
          },
          (error) => {
            this.commonService.showToast(
              "error",
              "Error",
              "Failed to add resource"
            );
            console.error("API error:", error);
          }
        );
      } else {
        this.notifationTempService.UpdateTemplateTypes(data, this.tagId).subscribe(
          (res: any) => {
            if (res.Status) {
              this.commonService.showToast("success", "Success", res.message);
              this.getUserList();
            } else {
              this.commonService.showToast(
                "error",
                "Error",
                "Something went wrong"
              );
            }
          },
          (error) => {
            this.commonService.showToast(
              "error",
              "Error",
              "Failed to add resource"
            );
            console.error("API error:", error);
          }
        );
      }
    } else {
      console.log("Form is invalid");
    }
  }
}
