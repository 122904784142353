import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsEmailTempService {
  userId: string;
  companyId:string;

  @LocalStorage()
  private userDetails;

  constructor(
    private http: HttpService,
  ) { 

  }

  getTemplateList(name?,obj?){
    let body={
        searchTerm:name ? name : "",
        ...obj
    }
    let url="api/template/listTemplates"
    return this.http.get(url,body,false)
  }


  getTemplatetypes(){
    let url="api/template/getTypes"
    return this.http.get(url,false)
  }
  getTemplatetypes1(obj){
    let url="api/template/getTypes"
    return this.http.get(url,obj,false)
  }
  getShowEditData(id){
    let url="api/template/showTemplate/"+id
    return this.http.get(url,false)
  }


  getTagtypes(){
    let url="api/tickets/getTagTypes"
    return this.http.get(url,false)
  }
  storeTempalateData(val){
    let url="api/template/storeTemplate"
    return this.http.post(url,val)
  }
  updateTempalateData(id,val){
    let url="api/template/updateTemplate/"+id
    return this.http.put(url,val)
  }
  DeleteTemplate(id){
    let url="api/template/destroyTemplate/"+id
    return this.http.delete(url)
  }
  createTemplateTypes(val){
    let url="api/template/saveType"
    return this.http.post(url,val)
  }
  UpdateTemplateTypes(val,id){
    let url="api/template/updateType/"+id
    return this.http.put(url,val)
  }
  DeleteTemplateType(id){
    let url="api/template/destroyType/"+id
    return this.http.delete(url)
  }
  getTemplatetPlaceholders(obj){
    let url="api/template/listPlaceholders"
    return this.http.get(url,obj,false)
  }
  createTemplatePlaceholder(val){
    let url="api/template/storePlaceholder"
    return this.http.post(url,val)
  }
  UpdatePlaceholder(val,id){
    let url="api/template/updatePlaceholder/"+id
    return this.http.put(url,val)
  }
  
  DeletePlaceholder(id){
    let url="api/template/destroyPlaceholder/"+id
    return this.http.delete(url)
  }
}