import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-purchase-plan-change',
  templateUrl: './purchase-plan-change.component.html',
  styleUrls: ['./purchase-plan-change.component.scss']
})
export class PurchasePlanChangeComponent {

   @Input() currentCustomerId: any;
}
