<div class="container-fluid" style="overflow-x: scroll !important;">
    <div class="d-flex flex-wrap w-100 mt-3" *ngIf="!isCallFromPurchaseHistory">
        <app-card-stats class="col-lg-2 col-sm-4 col-12 mb-3" *ngFor="let item of summaryData" style="cursor:pointer"
            [data]="item" (click)="onCardClick(item)"  [activeCardId]="activeCardId"  >
        </app-card-stats>
    </div>    
    
    <div class="card py-3 mx-3" >
        <div
            class="d-flex flex-column flex-lg-row w-100  justify-content-between position-relative pb-2">
            <div class="col-lg-4 col-12">
            <!-- <h6 class=" mb-lg-0 text-nowrap">Renewal Report ({{totalOrders||0}}) </h6> -->
            <div class="d-flex gap-2 align-items-center" *ngIf="!isCallFromPurchaseHistory">
                <app-custom-select [title]="'Add filter'" (selectionChanged)="handleFilterSelectionChange($event)"
                  [optionList]="filters" [label]="'display'" [value]="'key'" [icon]="'plus'"></app-custom-select>
                <app-custom-select [title]="item.display" *ngFor="let item of selectedFilters; let i = index"
                  [optionList]="item.optionList" [label]="item.name" [value]="item.code"
                  (selectionChanged)="sendFilter($event,item)"></app-custom-select>
                <div *ngIf="selectedFilters?.length > 0">
      
                  <button class="custom-btn active " (click)="clearAll()"> Clear All </button>
                </div>
              </div>
      
              </div>
            <div class="d-flex col-lg-6 col-12 align-items-center justify-content-end gap-2">
                <div class="py-1" *ngIf="showFilterRow">
                    <input type="text"  class="form-control ps-3" placeholder="Search..." 
                        (keyup.enter)="searchChange($event)" />
                    </div>
           
             
                <div class=" d-flex align-items-end" *ngIf="showdaterange && !isCallFromPurchaseHistory">
                    <div class="date-picker" (click)="stop($event)">
                        <div class="date-picker hiringCal round-border d-flex"
                            style="justify-content: center;">
                            <div class="f-row d-inline-block position-relative reports-datePicker">
                                <button
                                    class="f-row f-a-center px-3 filter-btn round hover-light py-0 date-button">
                                    <i class="fa fa-calendar" (click)="drp.toggle()"
                                        [attr.aria-expanded]="drp.isOpen"></i>
                                    <input [(ngModel)]="bsRangeValue"
                                        (ngModelChange)="onDateRangeSelected($event)"
                                        [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
                                        bsDaterangepicker
                                        class="pl-2 py-0 text border-0 bg-transparent cursor-pointer"
                                        placeholder="Daterangepicker" type="text" readonly
                                        #drp="bsDaterangepicker" />
                                    <i class="fa fa-chevron-down" (click)="drp.toggle()"
                                        [attr.aria-expanded]="drp.isOpen"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
    
                <!-- Days Filter Dropdown -->
                <select class="form-select py-1" [(ngModel)]="days" (change)="ondaysFilterChange($event)" *ngIf="!isCallFromPurchaseHistory"
                    style="max-width: 250px;">
                    <option *ngFor="let day of daysOptions" [value]="day.value">
                        {{ day.label }}
                    </option>
                </select>
                <app-filter-btn [icon]="'fa fa-search'" [isBtn]="true" (selectionChanged)="onFilterClick()"></app-filter-btn>
                <app-filter-btn [icon]="'fa fa-refresh'" [isBtn]="true" *ngIf="!isCallFromPurchaseHistory"
                    (click)="refreshData()"></app-filter-btn>
                <button class="btn btn-dark py-1" (click)="exportData()">Export</button>
          
                  <!-- <app-filter-btn [icon]="'fa fa-filter'" [isBtn]="true" (selectionChanged)="onFilterClick()"></app-filter-btn> -->
            </div>
        </div>
    
    
        <div class="table-responsive table-hover table-striped table-card"
           >
            <table class="table table-nowrap mb-0" *ngIf="!isSkeletonLoader" @fadeInOut>
    
                    <thead class="table-light mt-0" style="position: sticky; top: -1px; z-index: 5; background: white; ">
                    <tr class="text-nowrap">
                        <ng-container *ngFor="let header of header">
                            <th *ngIf="!(isCallFromPurchaseHistory && header.key === 'Actions')">
                              {{ header.display }}
                            </th>
                          </ng-container>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of rowDatas; let i = index" class="cursor-pointer text-nowrap"
                    [class.highlight]="i === highlightedRowIndex"  [class.refreshing]="i === refreshingRowIndex">
                        <ng-container *ngFor="let header of header">
                            <td  *ngIf="!(isCallFromPurchaseHistory && header.key === 'Actions')" (click)="goToDetails(item, i,header)">
                                <ng-container [ngSwitch]="header.key">
                                    <span *ngSwitchDefault>
                                    {{ item[header.key] }}
                                    </span>
                
                                            <ng-container *ngSwitchCase="'po_status'">
                                                <div class="status-container justify-content-start">
                                                    <div class="status-circle"
                                                        [style.backgroundColor]="getStatusColor('poStatus', item?.po_status)"
                                                        [matTooltip]="getStatusDescription('poStatus', item?.po_status)"
                                                        [matTooltipPosition]="'above'">
                                                    </div>
                                                    <div class="status-circle"
                                                        [style.backgroundColor]="getStatusColor('paymentStatus', item?.payment_status)"
                                                        [matTooltip]="getStatusDescription('paymentStatus', item?.payment_status)"
                                                        [matTooltipPosition]="'above'">
                                                    </div>
                                                    <div class="status-circle"
                                                        [style.backgroundColor]="getStatusColor('renewalStatus', item?.renewal_capture)"
                                                        [matTooltip]="getStatusDescription('renewalStatus', item?.renewal_capture)"
                                                        [matTooltipPosition]="'above'">
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'po_action'">
                                              <div class="d-flex flex-column align-items-start">
                                                <!-- Main PO Action -->
                                                <span [ngClass]="getStatusBadgeClass(item[header.key])" class="">
                                                  {{ item[header.key] || 'No Action' }}
                                                </span>
                                                
                                                <!-- SubStatus Branch -->
                                                <div *ngIf="item?.PoSubAction" class="d-flex align-items-center text-muted small" >
                                                  {{ item?.PoSubAction }}
                                                </div>
                                              </div>
                                            </ng-container>
                                            <div *ngSwitchCase="'Customer'" class="position-relative">
                                                <div class="d-flex align-items-center gap-2 customer-cell"
                                                    (click)="toggleDropdown(i, item,'customerDropdown')">
                                                    <span>{{ item?.FirstName + " " + item?.LastName }}</span>
                                                    <i class="fa fa-chevron-down chevron-icon" *ngIf="!isCallFromPurchaseHistory"></i>
                                                </div>
                                                <div class="dropdown-content"
                                                    [ngClass]="{'d-block': customerDropdown.has(i), 'd-none': !customerDropdown.has(i)}">
                                                    <div class="customer-card d-flex flex-column Polaris-Box gap-2">
                                                        <div
                                                            class="d-flex justify-content-between align-item-center">
                                                            <span class="text-dark fw-semibold customerinfo-text">{{
                                                                customerDetails?.personalInfo?.firstName?.value + "
                                                                " +
                                                                customerDetails?.personalInfo?.lastName?.value
                                                                }}</span>
                                                            <span class="text-success custom-badge">{{
                                                                customerDetails?.personalInfo?.accountStatus?.value
                                                                }}</span>
                                                        </div>
                                                        <span class="plan-price customerinfo-text">{{
                                                            customerDetails?.personalInfo?.serviceAddress?.value?.city
                                                            + ", " +
                                                            customerDetails?.personalInfo?.serviceAddress?.value?.state
                                                            }}</span>
                
                                                        <div class="customer-address">
                                                            <span class="customerinfo-text">{{
                                                                customerDetails?.personalInfo?.serviceAddress?.value?.address1
                                                                }}</span>
                                                            <span class="customerinfo-text">{{
                                                                customerDetails?.personalInfo?.serviceAddress?.value?.city
                                                                + ", " +
                                                                customerDetails?.personalInfo?.serviceAddress?.value?.state
                                                                + ", " +
                                                                customerDetails?.personalInfo?.serviceAddress?.value?.zipCode
                                                                }}</span>
                                                        </div>
                
                                                        <div class="customer-plan d-flex flex-column">
                                                            <span class="plan-name customerinfo-text">{{
                                                                customerDetails?.plan?.planName?.value }}</span>
                                                            <span class="plan-price customerinfo-text">${{
                                                                customerDetails?.plan?.planPrice?.value }} per
                                                                month</span>
                                                        </div>
                
                                                        <div class="customer-contact">
                                                            <span class="customer-email customerinfo-text">{{
                                                                customerDetails?.personalInfo?.email?.value
                                                                }}</span>
                                                            <span class="plan-price customerinfo-text">{{
                                                                customerDetails?.personalInfo?.phone?.value
                                                                }}</span>
                                                        </div>
                
                                                        <button class="view-company-btn"
                                                            (click)="sendToCustomerDashboard(item.CustomerId,i)">View
                                                            Details</button>
                                                    </div>
                                                </div>
                                            </div>
                
                                            <span *ngSwitchCase="'NextRenewalDate'">
                                                {{ item[header.key]| date }}
                                            </span>
                
                                            <span *ngSwitchCase="'NextBillingDate'">
                                                {{ item[header.key]| date }}
                                            </span>
                
                
                                            <div *ngSwitchCase="'CustomerStatus'">
                                                <span [ngClass]="getStatusBadgeClass(item[header.key])"
                                                    class="custom-badge">
                                                    {{ item[header.key] }}
                                                </span>
                                            </div>
                
                                            <div *ngSwitchCase="'SubscriptionStatus'">
                                                <span [ngClass]="getStatusBadgeClass(item[header.key])"
                                                    class="custom-badge">
                                                    {{ item[header.key] }}
                                                </span>
                                            </div>
                
                                            <div *ngSwitchCase="'remarks'">
                
                                                <span>
                                                    {{ item?.po_data?.remark|| "-" }}
                                                </span>
                
                                            </div>
                                            <div *ngSwitchCase="'dueDate'">
                                                <span>
                                                    {{ item?.po_invoice?.due_date|date }}
                                                </span>
                                            </div>
                                            <div *ngSwitchCase="'SubscriptionId'" class="position-relative">
                                                <div class="d-flex align-items-center gap-2 customer-cell"
                                                    (click)="toggleDropdown(i, item, 'subscriptionDropdown')">
                                                    <span>{{ item[header.key] || "-" }}</span>
                                                    <i class="fa fa-chevron-down chevron-icon"></i>
                                                </div>
                
                                                <div class="dropdown-content"
                                                    [ngClass]="{'d-block': subscriptionDropdown.has(i), 'd-none': !subscriptionDropdown.has(i)}">
                                                    <div class="customer-card d-flex flex-column Polaris-Box gap-2">
                                                        <div
                                                            class="  d-flex align-items-center gap-2 justify-content-between">
                                                            <span class="text-nowrap">{{
                                                                subscriptionDetail?.authorizeSubscriptionDetail?.name
                                                                || "-" }}</span>
                                                            <span
                                                                [ngClass]="getStatusBadgeClass(subscriptionDetail?.authorizeSubscriptionDetail?.status)"
                                                                class="custom-badge">
                                                                {{
                                                                subscriptionDetail?.authorizeSubscriptionDetail?.status
                                                                || "-" }}
                                                            </span>
                                                        </div>
                                                        <div class="">
                                                            <div class="subscription-detail">
                                                                <span><strong>Amount:</strong>
                                                                    <span class="custom-badge">
                                                                        ${{
                                                                        subscriptionDetail?.authorizeSubscriptionDetail?.amount
                                                                        || "-" }}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div class="subscription-detail">
                                                                <span><strong>Interval:</strong> {{
                                                                    subscriptionDetail?.authorizeSubscriptionDetail?.paymentSchedule?.interval?.length
                                                                    || "-" }} {{
                                                                    subscriptionDetail?.authorizeSubscriptionDetail?.paymentSchedule?.interval?.unit
                                                                    || "-" }}</span>
                                                            </div>
                                                            <div class="subscription-detail">
                                                                <span><strong>Start Date:</strong> {{
                                                                    subscriptionDetail?.authorizeSubscriptionDetail?.paymentSchedule?.startDate
                                                                    | date:'MMM d, y' || "-" }}</span>
                                                            </div>
                                                        </div>
                                                        <div class=" mt-3">
                                                            <button class="btn view-company-btn"
                                                                (click)="sendToSubscriptionDetail(item)">View
                                                                Details</button>
                                                        </div>
                                                    </div>
                
                                                </div>
                                            </div>
                
                
                    <div *ngSwitchCase="'Actions'" class="position-relative ">
                      <div class="d-flex align-items-center gap-2  "
                        (click)="toggleDropdown(i,item,'actionDropdown')">
                        <i class="fa fa-ellipsis-h"></i>
                      </div>
                      <div class="dropdown-content-2 bg-white "
                        [ngClass]="{'d-block': actionDropdown.has(i), 'd-none': !actionDropdown.has(i)}">
                        <div class="dropdown-item">
                          <span (click)="openConfirmationModal('PO',item,i)">
                            <i class="fa fa-list-ol mr-2"></i>Generate Po
                          </span>
                        </div>
                        <div class="dropdown-item">
                          <span (click)="openConfirmationModal('Suspend',item,i)">
                            <i class="fas fa-file-invoice mr-2"></i>Suspend Account
                          </span>
                        </div>
                        <div class="dropdown-item">
                          <span (click)="openConfirmationModal('Renewal' ,item,i)">
                            <i class="fa-solid fa-cart-shopping mr-2"></i>Renew
                            Account
                          </span>
                        </div>
                        <div class="dropdown-item">
                          <span (click)="refreshRow(item, i)">
                            <i class="fa-solid fa-arrows-rotate mr-2"></i>Refresh
                          </span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </td>
                        </ng-container>
    </tr>
    </tbody>
    
            </table>
            <div *ngIf="isSkeletonLoader" @fadeInOut class=" container-fluid ">
                <div class="div d-flex flex-column justify-content-between">
                    <app-skeleton-loader [tableRow]="15" [tableColumn]="8"
                        shape="table"></app-skeleton-loader>
                </div>
            </div>
            <div class="d-flex justify-content-center p-3" *ngIf="rowDatas?.length==0">
                <span class="text-muted">
                    No data found
                </span>
            </div>
        </div>
        <div class="  d-flex justify-content-end m-2" *ngIf="totalOrders">
            <ngb-pagination class="pagination-block" [directionLinks]="true" [boundaryLinks]="true"
                [collectionSize]="totalOrders" [(page)]="currentPage"
                (pageChange)="onPaginationChanged($event)" [maxSize]="3" [pageSize]="itemsPerPage"
                [ellipses]="true">
                <ng-template ngbPaginationPrevious>Previous</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4">
            <span class="mx-2">
                PO status
            </span>
            <div class="d-flex align-items-center gap-2 flex-wrap">
                <div *ngFor="let entry of poStatus | keyvalue" class="d-flex align-items-center gap-1">
                    <div class="status-circle" [style.backgroundColor]="entry.value"></div>
                    <span>{{ entry.key }}</span>
                </div>
            </div>
        </div>
    
        <div class="col-lg-4">
            <span class="mx-2">
                Payment status
            </span>
            <div class="d-flex align-items-center gap-2 flex-wrap">
                <div *ngFor="let entry of paymentStatus | keyvalue"
                    class="d-flex align-items-center gap-1">
                    <div class="status-circle" [style.backgroundColor]="entry.value"></div>
                    <span>{{ entry.key }}</span>
                </div>
            </div>
        </div>
    
        <div class="col-lg-4">
            <span class="mx-2">
                Renewal status
            </span>
            <div class="d-flex align-items-center gap-2 flex-wrap">
                <div *ngFor="let entry of renewalStatus | keyvalue"
                    class="d-flex align-items-center gap-1">
                    <div class="status-circle" [style.backgroundColor]="entry.value"></div>
                    <span>{{ entry.key }}</span>
                </div>
            </div>
        </div>
    </div>
    
</div>

<ng-template #renewConfirmationModal let-modal>
    <div class="modal-dialog modal-dialog-centered m-0 ">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/svg/alarm.gif" style="width:130px;height:130px">
                <div class="mt-4 pt-4">
                    <h4>Important Notice</h4>
                    <p class="text-muted"> This action will override the existing bucket and renew the service
                        without verifying the payment status.</p>
                    <!-- Toogle to second dialog -->
                    <div class="d-flex gap-3 align-items-center mb-3">
                        <select class="form-select" [(ngModel)]="renewalTime"
                            (change)="onActionChange('renew',$event)">
                            <option value="onDate">On renewal date</option>
                            <option value="now">Renew now</option>
                            <option value="specificDate">Renew on specific date</option>
                        </select>
                        <span *ngIf="showNextRenewalDate">{{nextRenewalDate}}</span>
                        <input type="date" [(ngModel)]="dueDate" class="form-control" *ngIf="showDatePicker">
                        <button class="btn btn-warning" data-bs-target="#secondmodal" data-bs-toggle="modal"
                            data-bs-dismiss="modal" (click)="performAction('renew')">
                            Continue
                        </button>
                    </div>
                    <p class="text-muted">Note: Any add-ons on this account will be removed, if applicable! </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #suspensionConfirmationModal let-modal>
    <div class="modal-dialog modal-dialog-centered m-0 ">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/svg/alarm.gif" style="width:130px;height:130px">
                <div class="mt-4 pt-4">
                    <h4>Important Notice</h4>
                    <p class="text-muted"> This action will suspend services. The end-user will still be able to
                        receive messages and calls while suspended..</p>
                    <!-- Toogle to second dialog -->
                    <div class="d-flex gap-3 align-items-center mb-3">
                        <select class="form-select" [(ngModel)]="renewalTime"
                            (change)="onActionChange('suspend',$event)">
                            <option value="onDate">Suspend on renewal date</option>
                            <option value="now">Suspend now</option>
                            <option value="specificDate">Suspend on specific date</option>
                        </select>
                        <span *ngIf="showNextRenewalDate">{{nextRenewalDate}}</span>
                        <input type="date" [(ngModel)]="dueDate" class="form-control" *ngIf="showDatePicker">
                        <button class="btn btn-warning" data-bs-target="#secondmodal" data-bs-toggle="modal"
                            data-bs-dismiss="modal" (click)="performAction('suspend')">
                            Continue
                        </button>
                    </div>
                    <p class="text-muted">This action will suspend services. The end-user will still be able to
                        receive messages and calls while suspended. </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #poConfirmationModal let-modal>
    <div class="modal-dialog modal-dialog-centered m-0">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/svg/alarm.gif" style="width:130px; height:130px" alt="Confirmation Icon">
                <div class="mt-4 pt-4">
                    <h4>Confirm PO Generation</h4>
                    <p class="text-muted">
                        You are about to generate a Purchase Order. Please ensure all details are correct before
                        proceeding.
                    </p>
                    <!-- Action Selector -->
                    <div class="d-flex gap-3 align-items-center mb-3">
                        <select id="poAction" class="form-select" (change)="onActionChange('po', $event)">
                            <option value="now">Generate PO now</option>
                            <option value="specificDate">Generate on specific date</option>
                        </select>
                        <input type="date" [(ngModel)]="poDate" class="form-control" *ngIf="showDatePicker"
                            placeholder="Select Date">
                    </div>
                    <!-- Confirmation Button -->
                    <div>
                        <button class="btn btn-primary" data-bs-target="#secondmodal" data-bs-toggle="modal"
                            data-bs-dismiss="modal" (click)="performAction('PO')">
                            Confirm
                        </button>
                    </div>
                    <p class="text-muted mt-3">
                        Note: Once a PO is generated, changes to the order details may not be allowed.
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>