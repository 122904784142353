import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss'],
  animations:[fadeInOut]
})
export class UsageComponent implements OnInit{
  recordsList: any = [
    "10", "20", "30", "40", "50", "60", "70", "80"
  ];
isSummerySkeletonLoader: boolean=true;
  objectKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];  // Returns an empty array if obj is null/undefined
  }
  
  datePickerPlaceHolder:string="Detailed Usage Information Between Range :";
  startDate: string;
  endDate: string;
  perPage:any;
  selectedDate: string | null = "All"; 
  column: any = [
    "#",
    "MDN",
    "Dialed Digit",
    "CALL DATE",
    "Redirecting Part",
    "Billable unit",
    "UNIT",
    "Call Type",
    "Free Type",
    "Usage Type", 
    "Destination Class",
    "PLAN CODE",
    "ROAMING",
    "THREEGP RAT TYPE",
    "BILLING CODE"
  ]
  formattedDateRange = {
    startDate: '',
    endDate: ''
  };
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = 10; // Default page size
  filterForm: any;
  list: any = [];
  maxDate: string;
    summary = {
      byDate: {},      // Summary by date
      overallTotal: { MB: 0, KB: 0, GB: 0 }  // Total billable units across all data
    };
  selectedUnit: 'MB' | 'KB' | 'GB' = 'MB'; // Default unit to MB


isSkeletonLoader: any;
  totalCallTypeSummary: {};
  constructor(private http: HttpService, public customerProfileService: CustomerProfileService,
    public commonService: CommonService,
    public fb: FormBuilder,private modalService: NgbModal) {
    this.filterForm = this.fb.group({
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      perPage: [10, Validators.required],
      page: [1] 
    });
    // this.setDates();
  }
  ngOnInit() {
    this.filterForm = this.fb.group({
      startDate: [this.startDate],
      endDate: [this.endDate],
      perPage: [this.perPage || 10]

    });
    this.setDates();
    
  }
  async sendTomail(){
    var res5: any = await this.customerProfileService.getSendToMail()
    if (res5.Status) {
      this.commonService.showToast('success', "Success", res5.message)
    }else{
      this.commonService.showToast('error', "Error", res5.message)
    }
  }
  openSummaryModal(content: TemplateRef<any>) {
    this.modalService.open(content, { size: 'md', centered: true });
  }
  async setDates() {
    this.isSkeletonLoader=true
    const today = new Date();
  
    // Set endDate as today - 1 day
    const end = new Date();
    end.setDate(today.getDate() - 1);
    this.endDate = end.toISOString().split('T')[0];
  
    // Set startDate as today - 16 days
    const start = new Date();
    start.setDate(today.getDate() - 16);
    this.startDate = start.toISOString().split('T')[0];
  
    // Format dates before passing to API
    const formattedStartDate = this.formatDate(this.startDate);
    const formattedEndDate = this.formatDate(this.endDate);
  
    // Update formattedDateRange to reflect default values
    this.formattedDateRange.startDate = formattedStartDate;
    this.formattedDateRange.endDate = formattedEndDate;
  
    try {
      // ✅ Call API with formatted dates
      let res: any = await this.getData({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        perPage: 10
      });
  
      if (res?.data?.data) {
        this.list = res.data.data;
        this.totalItems = res?.data?.total;
        console.log("✅ Data Loaded Successfully:", this.list);
  
        // ✅ Update form values after successful response
        this.filterForm.patchValue({
          startDate: formattedStartDate,
          endDate: formattedEndDate
        });
        this.isSkeletonLoader=false;
      }
    } catch (err) {
      console.error("❌ Error fetching data:", err);
      this.commonService.showToast("error", "Error", err.error.message || "Failed to fetch data.");
      this.isSkeletonLoader=false;
    }
  }
  
  
    // Function to change the selected unit
    setUnit(unit: 'MB' | 'KB' | 'GB') {
      this.selectedUnit = unit;
    }
  setMaxDate() {
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(today.getDate() + 20); // Set max date to 5 days from today
    this.maxDate = maxDate.toISOString().split('T')[0]; // Convert to 'YYYY-MM-DD'
  }

  formatDate(inputDate) {
    var parts = inputDate.split('-');

    var formattedDate = parts[1] + '-' + parts[2] + '-' + parts[0];

    return formattedDate;
  }

  async search() {

    if (this.filterForm.valid) {
      
      let start = this.formatDate(this.filterForm.value.startDate)
      let end = this.formatDate(this.filterForm.value.endDate)
      this.filterForm.value.startDate = start
      this.filterForm.value.endDate = end
      try {
        let res: any = await this.getData(this.filterForm.value)
        this.list = res.data.data
        this.totalItems = res?.data?.total
        console.log(this.list)

        
      }
      catch (err) {
        this.filterForm.patchValue({
          startDate: null,
          endDate: null,
          perPage: 10
        });
        this.commonService.showToast("error", "Error", err.error.message)
        
      }
    }

  }
  getData(data: any) {
    try {
      let url = "api/Customer/GetUsagesCDR"
      return this.http.get(url, data, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

formatedDate(inputDate: Date | string): string {
  const date = new Date(inputDate);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

  async handleDateRangeChange(dateRange: Date[], page: number = 1,per_page?:number) {
    this.isSkeletonLoader=true;
    console.log('daterange: ',dateRange);
    if (dateRange && dateRange.length === 2) {
      let start = this.formatedDate(dateRange[0]);
      let end = this.formatedDate(dateRange[1]);

      this.formattedDateRange.startDate = start;
      this.formattedDateRange.endDate = end;  
      try {
        let data={
          startDate: start,
          endDate: end,
          page: page
        }
        let res: any = await this.getData(data);
        this.list = res?.data?.data;
        this.totalItems = res?.data?.total;
        this.currentPage = page; 
        this.summary = { byDate: {}, overallTotal:  { MB: 0, KB: 0, GB: 0 } };
        this.isSkeletonLoader=false; 
        console.log(this.list);
      } catch (err) {
        this.filterForm.patchValue({
          startDate: null,
          endDate: null,
          perPage: 10
        });
        this.isSkeletonLoader=false; 
        this.commonService.showToast("error", "Error", err.error.message);
      }
    }
  }
  parseBillableUnit(unit: string, callType: string): number {
    if (callType === "Data" && unit.includes("MB")) {
      return parseFloat(unit.replace("MB", "")) || 0;
    } else {
      return parseFloat(unit) || 0;
    }
  }
  async handleDateRangeDataGeneration() {
    try {
      this.isSummerySkeletonLoader=true;
        // Always use the current selected date range from formattedDateRange
        const start = this.formattedDateRange.startDate;
        const end = this.formattedDateRange.endDate;

        if (!start || !end) {
            console.error("❌ No date range selected.");
            this.commonService.showToast("error", "Error", "Please select a date range.");
            return;
        }

        console.log("📌 Fetching Data for Date Range:", start, "to", end);

        // Prepare request payload
        let data = {
            startDate: start,
            endDate: end,
           perPage: 9999 // Always fetch full data for the summary
        };

        // Fetch Data
        let res: any = await this.getData(data);
        const calculatableData = res?.data?.data;

        console.log("📌 Data Fetched Successfully:", this.list);

        // Update summary before summarizing data
        this.summary = {
            byDate: {},
            overallTotal: { MB: 0, KB: 0, GB: 0 }
        };

        this.summarizeData(calculatableData);

        console.log("✅ Updated Summary after handleDateRangeDataGeneration:", this.summary);

    } catch (err) {
        console.error("❌ Error fetching data:", err);
        this.commonService.showToast("error", "Error", err.error.message || "Failed to fetch data.");
    } finally{
      this.isSummerySkeletonLoader=false;
    }
}

summarizeData(data: any[]) {
  // Reset summary before processing new data
  this.summary = {
    byDate: {},
    overallTotal: { MB: 0, KB: 0, GB: 0 }
  };

  this.totalCallTypeSummary = {}; // Ensure this is initialized

  data.forEach((entry) => {
    const { CallDate, CallType, BillableUnit } = entry;
    const dateKey = CallDate.split(" ")[0]; // Extract Date in YYYY-MM-DD format

    // Extract numerical value from BillableUnit
    const unitValueMB = parseFloat(BillableUnit.replace(/MB$/, '')); // Remove 'MB' and parse float
    if (isNaN(unitValueMB)) return; // Skip invalid values

    // Convert to KB and GB
    const unitValueKB = unitValueMB * 1024;
    const unitValueGB = unitValueMB / 1024;

    // Initialize Date-wise Summary
    if (!this.summary.byDate[dateKey]) {
      this.summary.byDate[dateKey] = {
        totalBillableUnit: { MB: 0, KB: 0, GB: 0 },
        callTypes: {}
      };
    }

    // Initialize Call Type for the Date
    if (!this.summary.byDate[dateKey].callTypes[CallType]) {
      this.summary.byDate[dateKey].callTypes[CallType] = { MB: 0, KB: 0, GB: 0 };
    }

    // Initialize Total Call Type Summary
    if (!this.totalCallTypeSummary[CallType]) {
      this.totalCallTypeSummary[CallType] = { MB: 0, KB: 0, GB: 0 };
    }

    // Aggregate Data for Specific Date and Call Type
    this.summary.byDate[dateKey].callTypes[CallType].MB += unitValueMB;
    this.summary.byDate[dateKey].callTypes[CallType].KB += unitValueKB;
    this.summary.byDate[dateKey].callTypes[CallType].GB += unitValueGB;

    this.summary.byDate[dateKey].totalBillableUnit.MB += unitValueMB;
    this.summary.byDate[dateKey].totalBillableUnit.KB += unitValueKB;
    this.summary.byDate[dateKey].totalBillableUnit.GB += unitValueGB;

    // Aggregate Overall Totals
    this.summary.overallTotal.MB += unitValueMB;
    this.summary.overallTotal.KB += unitValueKB;
    this.summary.overallTotal.GB += unitValueGB;

    // Aggregate Total Summary Across All Dates
    this.totalCallTypeSummary[CallType].MB += unitValueMB;
    this.totalCallTypeSummary[CallType].KB += unitValueKB;
    this.totalCallTypeSummary[CallType].GB += unitValueGB;
  });

  console.log("✅ Final Summary Data:", this.summary);
}


  onPageChange(page: number) {
    this.currentPage = page;
    const { startDate, endDate } = this.formattedDateRange;
    if (startDate && endDate) {
      this.handleDateRangeChange([new Date(startDate), new Date(endDate)], page);
    } else {
      console.warn("Date range is missing, unable to fetch paginated data.");
    }
  }

  
  
}
