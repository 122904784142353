import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { PurchaseHistoryService } from '../services/purchase-history.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { catchError, finalize, of, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateManagementService } from 'src/app/shared/services/state-management.service';
import { RenewalServiceService } from 'src/app/renewal-reports/service/renewal-service.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-purchase-all',
  templateUrl: './purchase-all.component.html',
  styleUrls: ['./purchase-all.component.scss'],
  animations:[fadeInOut]
})
export class PurchaseAllComponent implements OnInit {
  public itemsPerPage: number = 10;
  public totalItems: number = 0;
  currentPage: number = 1;
  startDate?: string;
  @Input() currentCustomerId: any;
  purchaseData: any[] = [];
  filteredData: any[] = [];
  searchTerm: string;
  totalRecords: any;
  openSearchBox:boolean = false
  renewalTime: any = "onDate";
  dummyActionDropdown: Set<number> = new Set();
  showNextRenewalDate: boolean = true
  action: any;
    @ViewChild("renewConfirmationModal", { static: true })
    renewConfirmationModal: any;
    @ViewChild("suspensionConfirmationModal", { static: true })
    suspensionConfirmationModal: any;
    @ViewChild('poConfirmationModal', { static: true }) poConfirmationModal: any;
  tableHeaders = [
    { key: 'customer_id', display: 'Customer ID' },
    { key: 'type_id', display: 'Subscription ID' },
    { key: 'customer_name', display: 'Customer Name' },
    { key: 'FirstName', display: 'Customer' },
    { key: 'CustomerStatus', display: 'Account Status' },
    { key: 'SubscriptionStatus', display: 'Subscription Status' },
    { key: 'status', display: 'Status' },
    { key: 'action', display: 'Po Action' },
    { key: 'type', display: 'Po Type' },
    { key: 'created_at', display: 'Created Date' },
    { key: 'NextBillingDate', display: 'Next Billing Date' },
    { key: 'NextRenewalDate', display: 'Next Renewal Date' },
    { key: 'remark', display: 'Remarks' },
  ];

  selectedCustomer: any;
  nextRenewalDate: any;
  highlightedRowIndex: any;
  poDate: any;
  status: any;
  dueDate: any;
  selectedValue: string;
showDatePicker: any;
  refreshingRowIndex: number;
isSkeletonLoader: boolean=true;
  constructor(
    private purchaseService: PurchaseHistoryService, 
    private commonService: CommonService,
    private modalService: NgbModal, 
    private stateManagementService: StateManagementService,
    private renewalServiceService:RenewalServiceService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.fetchPurchaseReport();
  }
  searchChange(e) {
    this.searchTerm = e.target.value;
    this.fetchPurchaseReport()
  }
  onPaginationChanged(params: any) {
    this.fetchPurchaseReport()
  }
 fetchPurchaseReport(): void {
  this.isSkeletonLoader=true;
    const data = 
    { 
      perPage: this.itemsPerPage,
      page: this.currentPage,
     } as any ;

    if(this.searchTerm){
      data.searchTerm = this.searchTerm
    }
    this.purchaseService.getPosReport(data).subscribe(
      (response: any) => {
        if (response?.Status) {
          this.purchaseData = response?.data?.data || [];
          this.filteredData = [...this.purchaseData];
          this.filteredData = this.filteredData.map(item => ({
            ...item,
            customer_name: `${item.FirstName || ''} ${item.LastName || ''}`.trim(), // Combine first and last names
          }));
          this.totalItems = response?.data?.total;
          this.totalRecords = response?.data?.total;
          this.isSkeletonLoader=false;
        } else {
          this.commonService.showToast('error', 'Customer Purchase Info', response?.message || 'Error occurred');
          this.isSkeletonLoader=false;
        }
      },
      (error) => {
        this.commonService.showToast('error', 'Customer Purchase Info', error?.message || 'Error occurred');
        this.isSkeletonLoader=false;
      }
    );
  }

  onSubscriptionClick(subscriptionId: string, customerId: string): void {
    this.router.navigate(['/subscriptions/subscription-details'], {
      queryParams: {
        id: subscriptionId,
        CustomerId: customerId,
      },
    });
  }
  
  getStatusBadgeClass(status: string): any {
    switch (status) {
      case "Active":
        return "badge badge-success";
      case "New":
        return "badge badge-primary";
      case "Deactivated":
        return "badge badge-danger";
      case "Suspended":
        return "badge badge-warning";
      case "Pending":
        return "badge badge-secondary";
      case "Cancelled":
        return "badge badge-danger";
      default:
        return "";
    }
  }
  navigateToPoDetails(poId: any | null | undefined): void {
    if (poId) {
      this.router.navigate(['/renewal-reports/po-details'], {
        state: { poId: poId }
      });
    }
  }
  
  openSearch(){
    this.openSearchBox = !this.openSearchBox
  }
}
