import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-add-new-ticket-sidebar',
  templateUrl: './add-new-ticket-sidebar.component.html',
  styleUrls: ['./add-new-ticket-sidebar.component.scss']
})
export class AddNewTicketSidebarComponent {
  isOpen = false;
  @Input() isEditing: boolean = false;
  @Input() ticketId: any = null; // or use a specific interface if available

  openSidebar() {
    this.isOpen = true;
  }

  closeSidebar() {
    this.isOpen = false;
  }
}
