<div class="container-fluid">
  <div class="custom-card">
  <div class="header d-flex justify-content-between border-bottom">

    <h6 class=" mb-lg-0 col-lg-4 col-12 d-flex align-items-center">Refund List</h6>

<div class="date-picker" (click)="stop($event)">

    <div class="date-picker hiringCal round-border d-flex mb-2"
        style="justify-content: end;">
        <input type="text" class="form-control ps-2  mx-2 py-1 mt-1" placeholder="Search..." style="height: fit-content;" *ngIf="openSearchBox"
        (keyup.enter)="searchChange($event)" />
          <!-- <label for="isActive" class="form-label"><strong>Status</strong></label> -->
          <select id="isActive" class="form-select py-1 mt-1 mr-2 " style="height: fit-content;"  [(ngModel)]="status" (change)="ondaysFilterChange($event)">
            <option value="">All</option>
            <option value="New">New</option>
            <option value="Disabled">Disabled</option>
            <option value="Applied">Applied</option>
          </select>
        <div class="f-row d-inline-block position-relative reports-datePicker mr-2" *ngIf="OpenCalender">
            <button
                class="f-row f-a-center px-3 filter-btn round hover-light py-1 mt-1 date-button">
                <i class="fa fa-calendar" (click)="drp.toggle()"
                    [attr.aria-expanded]="drp.isOpen"></i>
                <input [(ngModel)]="bsRangeValue"
                    (ngModelChange)="onDateRangeSelected($event)"
                    [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
                    bsDaterangepicker
                    class="pl-2 py-0 text border-0 bg-transparent cursor-pointer"
                    placeholder="Daterangepicker" type="text" readonly
                    #drp="bsDaterangepicker" />
                <i class="fa fa-chevron-down" (click)="drp.toggle()"
                    [attr.aria-expanded]="drp.isOpen"></i>
            </button>
        </div>
        <!-- <i class="fa-solid fa-calendar-days"></i> -->
        <app-filter-btn [icon]="'fa fa-search'" class=" py-1 mr-2" [isBtn]="true" (click)="openSearch()"></app-filter-btn>
        <app-filter-btn [icon]="'fa-solid fa-calendar-days'" class=" py-1" [isBtn]="true" (click)="openCalender()"></app-filter-btn>
        <app-filter-btn [icon]="'fa fa-plus'" class="mx-2 py-1" [isBtn]="true" ></app-filter-btn>
        <!-- <button class="btn btn-dark mx-2 py-1" (click)="openModal()" >Add</button> -->
    </div>
</div>
</div>
<div class="table-responsive table-hover table-striped table-card" style="min-height: 500px; overflow-x: auto;">
    <table class="table table-nowrap mb-0" >
      <thead class="table-light">
        <tr class="text-center">
          <th *ngFor="let column of columnDefs" style="white-space: nowrap;">{{ column.headerName }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of allplans; let i = index" class="text-center">
          <td *ngFor="let column of columnDefs">
              <span>
                <ng-container *ngIf="column.field === 'Action'">
                  <div class="position-relative">
                      <i class="fa fa-ellipsis-h py-2 px-4" (click)="toggleDropdown(i)"></i>
                      <div class="dropdown-content1 text-start" *ngIf="dropdownVisible.has(i)">
                          <div class="dropdown-item1" >
                              <span> <i class="fa-regular fa-pen-to-square text-info mr-2"></i> Edit</span>
                          </div>
                       

                      </div>
                  </div>

              </ng-container>

                <ng-container *ngIf="column.field === 'BillingPeriod'">
                  <span>
                    {{ row[column.field] +' days' }}
                  </span>
                </ng-container>
                <ng-container *ngIf="column.field === 'TrialAmount'">
                  <span>
                    {{ '$'+ row[column.field]  }}
                  </span>
                </ng-container>
                <ng-container *ngIf="column.field === 'CreatedAt'" class="text-nowrap">
                  {{ row[column.field] | date }}
                  
              </ng-container>
                <!-- Handle Status field   Applied-->
                <ng-container *ngIf="column.field === 'Status'">
                  <span class="custom-badge" [ngClass]="{
                    'badge-primary': row[column.field] === 'New',
                    'badge-danger': row[column.field] === 'Disabled',
                    'badge-success': row[column.field] === 'Applied'
                  }">
                    {{ row[column.field] || '-' }}
                  </span>

          
                </ng-container>
    
                <!-- Default case for all other fields -->
                <ng-container  *ngIf="![
                'BillingPeriod',
                'TrialAmount',
                'CreatedAt',
                'Status',
                'Action'
              ].includes(column.field)">
                  {{ row[column.field] || '-' }}
                </ng-container>
              </span>
            </td>        
        </tr>
      </tbody>
    </table>

    <!-- Skeleton Loader -->
    <!-- <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
      <app-skeleton-loader [tableRow]="8" [tableColumn]="6" shape="table"></app-skeleton-loader>
    </div> -->

    <!-- No Data Found -->
    <div class="text-center py-3 text-muted" *ngIf="allplans?.length === 0">
      No data found
    </div>
  </div>
  </div>
  </div>