import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router, Scroll } from "@angular/router";
import { LocalStorage, LocalStorageService } from "ngx-webstorage";
import { BehaviorSubject, Observable, Subscription, timer } from "rxjs";
import { CommonService } from "src/app/shared/services/common.service";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";
import { HttpService } from "src/app/shared/services/httpService.service";
import * as feather from "feather-icons";
import { SubscriptionService } from "src/app/subscriptions/service/subscription.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { PlanChangeService } from "../../quick-links/plan-change/services/plan-change.service";
import { CreditCardUpdateComponent } from "src/app/subscriptions/credit-card-update/credit-card-update.component";
import { fromEvent } from "rxjs";
import { map, filter, debounceTime, switchMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { PlanChangePaymentComponent } from "../../quick-links/plan-change/plan-change-payment/plan-change-payment.component";
import { ApplicationInformationComponent } from "./application-information/application-information.component";
import { SubscriptionInformationComponent } from "./subscription-information/subscription-information.component";
import { UsageComponent } from "../../quick-links/usage/usage.component";
import { LineInformationComponent } from "./line-information/line-information.component";
import { CustomerEventsComponent } from "./customer-events/customer-events.component";
import { PersonalInformationComponent } from "./personal-information/personal-information.component";
import { UpcomingPlansComponent } from "./upcoming-plans/upcoming-plans.component";
import { EligibilityInformationComponent } from "./eligibility-information/eligibility-information.component";
import { CustomerDetailedInformationService } from "./shared/customer-detailed-information.service";
import { UsagesInformationComponent } from "./usages-information/usages-information.component";
import { CardTrackingService } from "./shared/card-tracking.service";
import { SearchService } from "src/app/shared/services/search.service";
import { NotesService } from "../../customer-footer/customer-notes/services/notes.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { PermissionService } from "src/app/shared/services/permission-service.service";
import { PanelStateService } from "./PanelStateService";
import {
  fadeInOut,
  fadeInOutSlow,
} from "src/app/shared/services/app.animation";
import { CustomerSpecificTagsComponent } from "./customer-specific-tags/customer-specific-tags.component";
@Component({
  selector: "app-customer-profile",
  templateUrl: "./customer-profile.component.html",
  styleUrls: ["./customer-profile.component.scss"],
  animations: [fadeInOut, fadeInOutSlow],
})
export class CustomerProfileComponent
  implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit, OnChanges
{
  public autoRenew: boolean = false;
  lastSubscription: any;

  public accountInformations = [];

  public activity = [];
  subscriptionIsFake:boolean;
  
  data: any = [];
  signUpType:any;
  isNonPrepaid: boolean = false;
  private isPrepaidSubscription: Subscription;
  moreInfoTab: string = "";
  moreInfo: boolean = false;
  moreInfoData: any = [];
  applicationInfoFormData: FormGroup;
  personalInfoFormData: FormGroup;
  lineInfoFormData: FormGroup;
  eligibilityFormData: FormGroup;
  subscriptionFormData: FormGroup;
  editFieldName: string;
  loader: boolean = false;
  customerEventsData: any;
  usageInfoData: any = null;
  lineInfoData: any = null;
  cardLoader: boolean = false;
  subscriptionData: any[];
  subscriptionDetail: any;
  customerSubscriptionData: any = [];
  customerSubscriptionLength: any;
  isSubscription: boolean = false;
  private subscription: Subscription;
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 5;
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  public pageDropdownMenu: any = [5, 10, 15];
  public showCustomerEventAsList: boolean = false;
  showCustomerEventAsList1: boolean = false;
  showCancelToggle: boolean = false;
  simType: any;
  isSubscribe: boolean;
  isShowScheduleData: boolean;
  showHistoryPage: boolean;
  showHistoryDetail: any;
  reInitKey = 0;
  paymentGeneratedLink: string = "";
  isPaymentGenerated: boolean;
  defaultPaymentLink: string = "https://unitywireless.com";
  plans: any;
  currentPlanName: string;
  schedulePlanName: string;
  schedulePlanList: any;
  modalRef: NgbModalRef | undefined;
  hasMoreData = true;
  isAutoRenewModelOpen: boolean;
  CustomerId;
  isOperationPerformed: boolean;
  autoRenewTransactionStatus: boolean;
  private timeoutSubscription: Subscription | undefined;
  isBreadCrumbRefreshedClickedSubscription: Subscription;
  private;
  @ViewChild("childComponent", { static: false })
  planChangePaymentComponent!: PlanChangePaymentComponent;
  @Output() closeAutoRenewModal = new EventEmitter<void>();
  panelsAreReady = false;
  private customerIdSubject = new BehaviorSubject<string | null>(null);
  customerId$ = this.customerIdSubject.asObservable();
  private subscriptionInterval: any;
  private prepaidInterval: any;
  isNoNotes: boolean = false;
  isHiddenData: any;
  SETTINGSItems: any;
  cards = [
    {
      title: "Personal Information",
      componentName: PersonalInformationComponent,
      hasCard: false,
      hasExpand: false,
    },
    {
      title: "Line Information",
      componentName: LineInformationComponent,
      hasCard: false,
      hasExpand: false,
    },
    {
      title: "Subscription Information",
      componentName: SubscriptionInformationComponent,
      hasCard: false,
      hasExpand: false,
    },
    {
      title: "Application Information",
      componentName: ApplicationInformationComponent,
      hasCard: false,
      hasExpand: false,
    },
    {
      title: "Eligibility Information",
      componentName: EligibilityInformationComponent,
      hasCard: false,
      hasExpand: false,
    },
    {
      title: "Usage",
      componentName: UsagesInformationComponent,
      hasCard: false,
      hasExpand: false,
    },
    {
      title: "Customer Events",
      componentName: CustomerEventsComponent,
      hasCard: false,
      hasExpand: false,
    },
    {
      title: "Upcoming Plans",
      componentName: UpcomingPlansComponent,
      hasCard: false,
      hasExpand: false,
    },
    {
      title: "Customer Tags",
      componentName: CustomerSpecificTagsComponent,
      hasCard: false,
      hasExpand: false,
    },
  ];
  isCustomerProfileViewEnabled: boolean;
  showNotesComponent: boolean = true;
  searchSubscription: Subscription;
  noNotesEventSubscription: Subscription;
  listenPersonalTab: boolean;
  isPersonalInformationOpenSubscription: Subscription;
  private firstTimePersonalInfoCheck = true;
  isAlert: boolean;
  isAlertSubscription: Subscription;
  isUsage: boolean;
  isUsageSubscription: Subscription;
  isSkeletonLoader: boolean = true;
  isLineInfoRefreshed: boolean;
  isusageRefreshed: boolean;
  isSkeletonLoaderSubscription: Subscription;
  autoRenewStatusSubscription: Subscription;
  isCustomerChangedSubscription: Subscription;
  simTypeSubscription:Subscription;
  isSignUpTypePortIn: boolean =false;
  customerInfo:any
  customerPlandata:any
  planId:any
  echo: any;
  visitorChangeDetection: Subscription;
  
  constructor(
    public customerProfileService: CustomerProfileService,
    private planChangeService: PlanChangeService,
    public commonService: CommonService,
    private fb: FormBuilder,
    private panelStateService: PanelStateService,
    private subscriptionService: SubscriptionService,
    private permissionService: PermissionService,
    private httpService: HttpService,
    private renderer: Renderer2,
    private el: ElementRef,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private http: HttpClient,
    private customerdetailedInformationService: CustomerDetailedInformationService,
    private cardTrackingService: CardTrackingService,
    private searchService: SearchService,
    private notesService: NotesService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private router: Router, 
    private localStorage: LocalStorageService,
    
  ) {}

  ngAfterViewInit(): void {
    this.cardTrackingService.trackingCardsAndExpandButton(this.cards, this.el);
    feather.replace();
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {

 
    this.cd.detectChanges();  
  }

  noNotes() {
 
    this.noNotesEventSubscription = this.notesService.noNotesEvent$.subscribe(
      (isNoNotes) => {
  
        this.isNoNotes = isNoNotes;
      }
    );
  }

  async listenPersonalInfoTab() {
    this.isPersonalInformationOpenSubscription =
      this.customerProfileService.isPersonalInformationOpen$.subscribe(
        (value) => {
          this.listenPersonalTab = value;
          const personalInfoCard = this.customerdetailedInformationService
            .getregisteredCard()
            .find(
              (card) => card.title === "Personal Information" && card.hasExpand
            );
          if (personalInfoCard || this.listenPersonalTab) {
            this.toggleMoreInf();
            this.moreInfoSelectedTab("Personal Information");
          } else {
            if (!this.firstTimePersonalInfoCheck) {
              this.commonService.showToast(
                "error",
                "Personal Information",
                "Personal Information is not accessible."
              );
            } else {
              this.firstTimePersonalInfoCheck = false; // Set the flag to false after the first execution
            }
          }
        }
      );
  }

  refreshDashboardCards() {
    this.isBreadCrumbRefreshedClickedSubscription =
      this.customerProfileService.isBreadcrumbRefreshed$.subscribe(
        (isRefreshable: boolean) => {
          this.isSkeletonLoader = isRefreshable;
        }
      );
  }
  autoRenewStatus() {
    this.autoRenewStatusSubscription =
      this.customerProfileService.autoRenewStatus$.subscribe(
        (status: boolean) => {
          this.autoRenew = status;
        }
      );
  }


  leaveCustomerProfile() {
    this.httpService.post(`api/note/${this.httpService.getCustomerId()}/leave`).subscribe();
  }

  visitorChangedetection(){
    this.visitorChangeDetection= this.customerProfileService.vistorchangeDetection$.subscribe(status => {
      if(status){
      this.joinCustomerProfile()
      }
    });
  }
  joinCustomerProfile() {
    this.httpService.post(`api/note/${this.httpService.getCustomerId()}/join`,true).subscribe();
  }
  async ngOnInit() {
    this.visitorChangedetection();
    // this.joinCustomerProfile()
    const shouldProceed = await this.DashboardRedirect(this.httpService.getCustomerId());
    if (!shouldProceed) {
      return;
    }
    await this.isSignUpTYpePortIn();
    await this.getCustomerTags();
    this.cards.forEach((card) =>
      this.customerdetailedInformationService.doCardregistration(
        card?.title,
        card?.componentName,
        card?.hasCard,
        card?.hasExpand
      )
    );
    this.refreshDashboardCards();
    this.isSkeletonLoaderSubscription =
      this.customerProfileService.isSkeletonLoader$.subscribe(
        (isLoader: boolean) => {
          this.isSkeletonLoader = isLoader;
        }
      );
    this.autoRenewStatus();
    this.getCardsData("Usage", true);
    this.listenPersonalInfoTab();
    this.isCustomerChangedSubscription =
      this.customerProfileService.isCustomerChanged.subscribe(
        (isCustomerChange) => {
          if (isCustomerChange) {
            // console.log('isCustomerChanged: ',isCustomerChange);
            this.customerSubscriptions();
          }
        }
      );
      this.simTypeSubscription= this.customerProfileService?.simTypeEvent$.subscribe((simType)=>{
        this.simType = simType;
        console.log('simTYpe: ', this.simType);
        this.getCustomerTags();
      })
    this.isAlertSubscription = this.customerProfileService.isAlert.subscribe(
      (isAlert) => (this.isAlert = isAlert)
      
    );
    this.isUsageSubscription= await this.customerProfileService.isUsage$.subscribe(value=>{
      this.isUsage = false;  
      this.getAdvancePlan()
this.cdr.detectChanges()
    
      setTimeout(() => {
          this.isUsage = true; 
      }, 0);
    })

    this.customerSubscriptions();
    // this.customerProfileService.getCustomerDashboardResouceDetails()
    // this.customerProfileService.sideMenuInitilize();
    // this.customerProfileService.getSideMenuBar().subscribe((SETTINGSItems: any) => {

    //   this.isHiddenData = SETTINGSItems
    //       // console.log("Item at index 5:", this.isHiddenData);
    //   this.cd.detectChanges();
    //     this.SETTINGSItems = SETTINGSItems.filter(item => !item.isHidden);
    //       // console.log("Item at index 6:", this.SETTINGSItems);
    //       // this.cd.detectChanges();// Trigger change detection after the assignment
    // });
    this.noNotes();
    // this.loadMoreData()
    await this.startMonitoringSubscription();
    await this.isPrepaidSub();
    this.customerdetailedInformationService.autoRenew$.subscribe(
      (autoRenewStatus: boolean) => {
        if (!autoRenewStatus) {
          this.autoRenew = false;
         
        }
      }
      
    );

    // this.getSubscriptionDetail()
    this.subscriptionData = [];
    if (this.commonService.loader == true) {
      this.subscriptionData = [];
      this.isSubscribe = true;
    }
    this.searchSubscription = this.searchService.searchTerm$.subscribe(
      (searchTerm) => {
        if (searchTerm) {
          this.showNotesComponent = false;
          this.hasMoreData = true;
          this.currentPage = 1;
          this.loadMoreData();
          setTimeout(() => {
            this.showNotesComponent = true;
          });
        }
      }
    );
    // this.subscription =
    //   this.customerProfileService.isSubscriptionEmitter.subscribe(
    //     (isSubscribed: boolean) => {
    //       this.isSubscription = isSubscribed;
    //       this.subscriptionData = [];
    //     }
    //   );
    this.subscription = this.customerProfileService.isSubscriptionEmitter.subscribe(
      ({ isSubscription, isSubscriptionFake }: { isSubscription: boolean; isSubscriptionFake: boolean }) => {
        this.isSubscription = isSubscription;
        this.subscriptionIsFake = isSubscriptionFake;
        this.subscriptionData = []; 
        console.log('isSubscriptionFake: ',isSubscriptionFake);
        this.cdr.detectChanges();
      }
    );
    
    if (!!localStorage.getItem("SearchCustomerId")) {
      await this.customerProfileService.getMinimalProfileData(
        localStorage.getItem("SearchCustomerId")
      );
    } else {
      await this.customerProfileService.getMinimalProfileData(null, true);
    }

    const schedulededPlan =
      this.customerProfileService.minimalProfileData?.schedulededPlan;
    if (Array.isArray(schedulededPlan) && schedulededPlan.length == 0) {
      this.isShowScheduleData = false;
    } else if (
      typeof schedulededPlan === "object" &&
      schedulededPlan !== null
    ) {
      this.isShowScheduleData = true;
      this.getPlansDetails();
    }

    this.customerProfileService.isSchedulededPlanEmitter.subscribe(
      (isSchedule: boolean) => {
        this.isShowScheduleData = isSchedule;
        if (this.isShowScheduleData) {
          this.getPlansDetails();
        }
      }
    );

    await this.customerProfileService.CustomerDataUpdated.subscribe((res) => {
      this.moreInfo = null;
      this.moreInfoData = null;
      this.usageInfoData = null;
      this.subscriptionData = [];
      this.refreshAllCards();
    });

    this.initializePersonalInfoForm();
    this.initializeLineInfoForm();
    this.initializeEligibilityFormData();
    this.initializeSubscriptionFormData();
    await this.startMonitoringIsPrepaid();

    this.initializeApplicationFormData();
    // await this.getData()
    // await this.customerSubscriptions()
    // this.getPlanScheduler()
    if (this.customerProfileService.minimalProfileData?.subscription[0]) {
      this.autoRenew =
        this.customerProfileService.minimalProfileData?.subscription[0]
          ?.Status === "Active"
          ? true
          : false;
    }
  }
deviceTags:any;
orderTags:any;
getCustomerTags() {
  try {
    if (!this.customerProfileService.minimalProfileData) {
      return;
    }
    this.deviceTags = this.customerProfileService.minimalProfileData?.customerTags || [];
    this.orderTags = this.customerProfileService.minimalProfileData?.orderTags || [];
  } catch (error) {
  }
}

  isSignUpTYpePortIn() {
    this.customerProfileService.isSignUpTypePortInEmitter.subscribe(
      ({
        isSignupType,
        isSignupTypePortIn,
        signupTypeValue,
      }: { isSignupType: boolean; isSignupTypePortIn: boolean; signupTypeValue?: string }) => {
        // If isSignupType is true, use isSignupTypePortIn; otherwise, set to false
        this.isSignUpTypePortIn = isSignupType ? isSignupTypePortIn : false;
  
        // Set the signupTypeValue to a local property if available
        this.signUpType = signupTypeValue || null;
  
        // Log the results for debugging
        console.log('isSignUpTypePortIn: ', this.isSignUpTypePortIn);
        console.log('SignUp Type Value: ', this.signUpType);
      }
    );
  }
  
  
  async DashboardRedirect(customerId): Promise<boolean> {
    try {
      const customerData = { search: this.httpService.getCustomerId(), page: "1" };
      const customerResponse: any = await this.searchService.searchCustomer(customerData);
  
      if (!customerResponse?.Status) {
        const message = `CustomerId Not Exist in ${this.authService.getCompanyName()}`;
        // Store the toast message in localStorage
        
        const toastMessage = {
          type: 'Info',
          title: 'Customer Information',
          message
        };
        this.localStorage.store('toastMessage', JSON.stringify(toastMessage));
  
        // Navigate to the dashboard
        this.router.navigate(['/dashboard']);
        return false;
      }
  
      return true; 
    } catch (error) {
      console.error('Error in DashboardRedirect method:', error);
      this.router.navigate(['/dashboard']);
      return false; // Indicate an error occurred and stop further execution
    }
  }
  
  
  startMonitoringSubscription() {
    this.subscriptionInterval = setInterval(() => {
      if (this.isSubscription) {
        this.cardTrackingService.trackingCardsAndExpandButton(
          this.cards,
          this.el
        );

        clearInterval(this.subscriptionInterval);
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(this.subscriptionInterval);
    }, 10000);
  }
  startMonitoringIsPrepaid() {
    this.prepaidInterval = setInterval(() => {
      if (this.isNonPrepaid) {
        this.cardTrackingService.trackingCardsAndExpandButton(
          this.cards,
          this.el
        );
        clearInterval(this.prepaidInterval);
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(this.prepaidInterval);
    }, 10000);
  }
  onPaymentHistoryClose(show: boolean) {
    this.showHistoryPage = show;
  }
  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  cancelToggle() {
    this.showCancelToggle = true;
  }
  closeToggle() {
    this.showCancelToggle = false;
  }

  openModal(): void {
    const modalBackdrop = this.el.nativeElement.querySelector(".modal");
    if (modalBackdrop) {
      this.renderer.setStyle(modalBackdrop, "--bs-backdrop-zindex", "0");
    }
  }
  copyToClipboard() {
    const linkElement = document.getElementById("linkInput");
    const linkText = linkElement?.textContent?.trim();

    if (linkText) {
      navigator.clipboard
        .writeText(linkText)
        .then(() => {
          this.commonService.showToast("info", "Clipboard Copy", "Link Copied");
        })
        .catch((err) => {
          this.commonService.showToast(
            "error",
            "Clipboard Copy",
            `Link Can't be Copied`
          );
        });
    } else {
      this.commonService.showToast("error", "Clipboard Copy", "No Link to Copy");
    }
  }

  openInNewWindow() {
    const url = this.paymentGeneratedLink;
    window.open(url, "_blank");
  }
  openInSameWindow() {
    const url = this.paymentGeneratedLink;
    window.location.href = url;
  }

  async generatePaymentLink(content: TemplateRef<any>) {
    try {
      this.customerProfileService
        .generatePaymentLink(this.httpService.getCustomerId())
        .subscribe(
          (response: any) => {
            this.paymentGeneratedLink = response;
            this.isPaymentGenerated = true;
            this.modalRef = this.modalService.open(content, {
              backdrop: "static",
              centered: true,
            });
            this.commonService.showToast(
              "info",
              "Payment Link",
              "Payment link generated"
            );
          },
          (error) => {
            this.isPaymentGenerated = false;
            this.commonService.showToast(
              "error",
              "Payment Link",
              `Unable to generate payment link`
            );
            console.log(error);
          }
        );
    } catch (error) {
      console.log(error);
    }
  }
  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  async getSubscriptionDetail() {
    try {
      await this.subscriptionService
        .getDetail(this.customerSubscriptionData?.id)
        .subscribe(
          (res: any) => {
            this.subscriptionDetail = res?.data;
            this.cd.detectChanges();
          },
          (error: any) => {}
        );
    } catch (error) {
      console.error("Exception caught:", error?.message);
    }
  }
  async openHistoryPage(data: any) {
    this.showHistoryDetail = data;
    await this.getSubscriptionDetail();
    setTimeout(() => {
      this.showHistoryPage = true;
    }, 40);
  }
  async customerSubscriptions() {
    try {
      const res: any =
        await this.customerProfileService.getCustomerSubscription(
          this.getCustomerId(),
          true
        );

      if (res.Status) {
        this.customerSubscriptionData = res?.data || [];
        sessionStorage.setItem(
          "SubscriptionId",
          this.customerSubscriptionData.id
        );
        this.subscriptionData = this.customerSubscriptionData || [];
        this.autoRenew =
          this.customerSubscriptionData.Status === "Active" ? true : false;
        this.subscriptionFormData?.patchValue(this.moreInfoData);
      } else {
        // this.commonService.showToast("error", "Customer Subscription", res.message);
      }
    } catch (error) {
      throw new Error(error.message || "Error fetching customer subscriptions");
    } finally {
    }
  }
  updatePaymentMethod(justUpdateAddress?: boolean) {
    const modalRef = this.modalService.open(CreditCardUpdateComponent, {
      backdropClass: "dark-modal",
      centered: true,
    });
    modalRef.componentInstance.subscriptionDetail = this.subscriptionDetail;
    modalRef.componentInstance.justUpdateAddress = justUpdateAddress;
  }

  async getData() {
    try {
      // const res:any = await this.customerProfileService.getSubscriptionCardData();
      // this.subscriptionData=res?.data?.subscription
      this.subscriptionData = this.customerSubscriptionData || [];
      // this.customerSubscriptions()
    } catch (err) {
      console.error("Error getting subscription card data: ", err);
    }
  }

  initializePersonalInfoForm() {
    this.personalInfoFormData = this.fb.group({
      firstName: this.createFormGroup(),
      lastName: this.createFormGroup(),
      phone: this.createFormGroup(),
      dateOfBirth: this.createFormGroup(),
      email: this.createFormGroup(),
      ssn: this.createFormGroup(),
    });
    this.subscriptionData = [];
    this.getData();
  }

  createFormGroup() {
    return this.fb.group({
      editable: [],
      lable: [],
      value: [],
      visible: [],
    });
  }

  initializeLineInfoForm() {
    this.lineInfoFormData = this.fb.group({
      MDN: [],
      simNumber: [],
    });
  }

  initializeEligibilityFormData() {
    this.eligibilityFormData = this.fb.group({
      acpQualifyDate: this.createFormGroup(),
      nvApplicationId: this.createFormGroup(),
      deviceQualification: this.createFormGroup(),
      acpDeviceOrderType: this.createFormGroup(),
      subscriberId: this.createFormGroup(),
    });
  }
  initializeSubscriptionFormData() {
    this.subscriptionFormData = this.fb.group({
      id: this.createFormGroup(),
      firstName: this.createFormGroup(),
      lastName: this.createFormGroup(),
      emailAddress: this.createFormGroup(),
      profileId: this.createFormGroup(),
      paymentProfileId: this.createFormGroup(),
      subscriptionId: this.createFormGroup(),
      subscriptionType: this.createFormGroup(),
      status: this.createFormGroup(),
      createdDate: this.createFormGroup(),
      updatedDate: this.createFormGroup(),
    });
  }

  initializeApplicationFormData() {
    this.applicationInfoFormData = this.fb.group({
      userName: this.createFormGroup(),
      acpStartDate: this.createFormGroup(),
      approvalDate: this.createFormGroup(),
      disconnectionDate: this.createFormGroup(),
      approvedBy: this.createFormGroup(),
      enrollId: this.createFormGroup(),
      orderType: this.createFormGroup(),
      programs: this.createFormGroup(),
      shipmentStatus: this.createFormGroup(),
      shiprackingNo: this.createFormGroup(),
      source: this.createFormGroup(),
    });
  }

  toggleMoreInf() {
    this.panelStateService.setPanelMinimized(false);
    // this.moreInfo = !this.moreInfo
  }

  isCustomerProfileViewAllowed(event: any) {
    this.isCustomerProfileViewEnabled = event;
    this.customerProfileService.getMinimalProfileData(null, true);
  }

  moreInfoSelectedTab(tabName: string) {
    const adjustedTabName =
      tabName === "Customer Information" ? "Application Information" : tabName;
    const isValidTab = this.cards.some(
      (card) => card.title === adjustedTabName
    );
    if (isValidTab) {
      this.moreInfoTab = adjustedTabName;
      if (this.moreInfoTab === "Application Information") {
        this.customerdetailedInformationService.setActiveTab(
          "Application Information"
        );
      } else {
        this.customerdetailedInformationService.setActiveTab(this.moreInfoTab);
      }
      if (tabName === "Line Information") {
        this.customerIdSubject.next(this.httpService.getCustomerId());
      }

      this.isCustomerProfileViewEnabled = true;
    } else {
      this.isCustomerProfileViewEnabled = false;
    }
  }

  refreshAllCards() {
    this.getCardsData("Line Information", true);
    // this.getCardsData('Usage', true)
    // this.getCardsData('Subscription Information', true)
    // this.showScheduleData()

    // this.getCardsData('Subscription Information', true)
  }
  async refreshUsageCard() {
    this.isUsageSubscription= await this.customerProfileService.isUsage$.subscribe(value=>{
      this.isUsage = false;  // Temporarily set to false
      setTimeout(() => {
          this.isUsage = true;  // Set back to true to re-render <app-usage-info>
      }, 0);
    })
  }

  async refreshSimType() {
    try {
      const res3: any = await this.customerProfileService.getLineInfoSimType();
      if(res3?.Status && res3?.data?.simType?.error){
        this.simType = res3?.data?.simType?.error;
      }else if (res3?.Status && res3?.data?.simType) {
        this.simType = res3?.data?.simType;
        // this.customerProfileService.minimalProfileData?.lineInfo?.simType?.value=this.simType
      }
    } catch (error) {
      this.commonService.showToast(
        "error",
        "Refresh SimType",
        error?.error?.message ?? "Some Error Occurred"
      );
    }
  }

  onScroll(event: any): void {
    const element = event.target;
    const scrollPosition = element.scrollHeight - element.scrollTop;
    const bottomPosition = element.clientHeight;

    if (
      scrollPosition <= bottomPosition + 100 &&
      !this.loader &&
      this.hasMoreData
    ) {
      this.loadMoreData();
    }
  }
  async sendMail() {
    const resminmal: any =
      this.customerProfileService?.minimalProfileData?.lineInfo?.MDN?.value;
    try {
      const data = {
        mdn: resminmal,
      };
      const res: any = await this.customerProfileService.sendToEmail(data);

      if (res.Status) {
        this.commonService.showToast("success", "Send Mail", res.data);
      } else {
        this.commonService.showToast("error", "Send Mail", res.message);
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Send Mail",
        err?.error?.message ?? "Some Error Occurred"
      );
    } finally {
    }
  }

  isPrepaidSub() {
    this.isPrepaidSubscription =
      this.customerProfileService.isPrePaidEvent$.subscribe((isNonPrepaid) => {
        this.isNonPrepaid = isNonPrepaid;
        if (!this.isNonPrepaid) {
          this.customerdetailedInformationService.removeCardsFromRegisteredCards(
            EligibilityInformationComponent
          );
        } else {
          this.customerdetailedInformationService.registerCard(
            "Eligibility Information",
            EligibilityInformationComponent,
            true,
            true
          );
          this.cards.forEach((card) =>
            this.customerdetailedInformationService.doCardregistration(
              card?.title,
              card?.componentName,
              card?.hasCard,
              card?.hasExpand
            )
          );
        }
      });
  }
  customerEventData: any;
  async loadMoreData() {
    if (this.hasMoreData && !this.loader) {
      this.loader = true;

      try {
        let data = {
          per_page: this.itemsPerPage,
          page: this.currentPage,
        };

        const res3: any =
          await this.customerProfileService.getActivityInfo(data);

        if (res3.Status) {
          // Extract new data from response
          const newData = res3?.data?.data || [];

          // Check if the data is empty or contains only falsy values
          const isValidData =
            newData.length > 0 && !newData.every((item) => !item);

          if (isValidData) {
            // Append new data to the existing data
            this.customerEventData = [
              ...(this.customerEventData || []),
              ...newData,
            ];

            // Update total items and current page
            this.totalItems = res3.data.total;
            this.currentPage = res3.data.current_page + 1; // Increment the page for the next call

            // Check if more data is available
            this.hasMoreData = !!res3.data.next_page_url;
          } else {
            // If the data is not valid, stop further data loading
            this.hasMoreData = false;
          }
        } else {
          this.commonService.showToast("error", "Activity Info", res3.message);
          this.hasMoreData = false; // Stop further data loading
        }
      } catch (error) {
        this.commonService.showToast(
          "error",
          "Activity Info",
          "Failed to load more data."
        );
      } finally {
        this.loader = false; // Reset loading state
      }
    }
    if (!this.hasMoreData) {
      this.commonService.showToast("info", "Activity Info", "No More Data To Show");
    }
  }

  async getCardsData(moreInfoTab, isCardLoader?: boolean) {
    try {
      //
      this.loader = true;
      switch (moreInfoTab) {
        case "Personal Information":
          const res1: any =
            await this.customerProfileService.getCustomerPersonalCardData();
          this.moreInfoData = res1?.data;
          this.personalInfoFormData.patchValue(this.moreInfoData?.personalInfo);
          break;
        case "Line Information":
          this.isLineInfoRefreshed = true;
          setTimeout(() => {
            this.cardLoader = isCardLoader;
          }, 100);
          var res2: any =
            await this.customerProfileService.getLineInfoMoreCardData();
          this.cardLoader = false;
          if (res2?.Status) {
            this.moreInfoData = res2?.data;
            if (this.moreInfoData) {
              this.lineInfoFormData
                .get("simNumber")
                .setValue(this.moreInfoData?.sim);

              this.customerProfileService.minimalProfileData.lineInfo.subscriberStatus.value =
                res2?.data?.SubscriberStatus;
              if (res2?.data?.MDN) {
                this.customerProfileService.minimalProfileData.lineInfo.MDN.value =
                  res2?.data?.MDN;
              }

              this.customerProfileService.minimalProfileData.lineInfo.simCarrierStatus.value =
                res2?.data?.IccidStatus;
              this.customerProfileService.minimalProfileData.lineInfo.puk1.value =
                res2?.data?.Puk1;
              this.customerProfileService.minimalProfileData.lineInfo.puk2.value =
                res2?.data?.Puk2;
            }
            this.isLineInfoRefreshed = false;
          } else {
            this.commonService.showToast("error", "Line Information", res2?.message);
            this.isLineInfoRefreshed = false;
          }

          break;
        case "Customer Events":
          let data = {
            per_page: this.itemsPerPage,
            page: this.currentPage,
          };

          try {
            const res3: any =
              await this.customerProfileService.getActivityInfo(data);

            if (res3.Status) {
              // Ensure that moreInfoData is an array before spreading
              this.moreInfoData = [
                ...(Array.isArray(this.moreInfoData) ? this.moreInfoData : []),
                ...(Array.isArray(res3?.data?.data) ? res3.data.data : []),
              ];

              // Update total items and current page
              this.totalItems = res3.data.total;
              this.currentPage = res3.data.current_page + 1; // Increment the page for the next call

              // Check if more data is available
              this.hasMoreData = !!res3.data.next_page_url;
            } else {
              this.commonService.showToast("error", "Customer Event", res3.message);
            }
          } catch (error) {
            this.commonService.showToast(
              "error",
              "Customer Event",
              "Failed to load more data."
            );
          }
          break;

        case "Customer Information":
          const res4: any =
            await this.customerProfileService.getCustomerInfoCardData();
          if (res4.Status) {
            this.moreInfoData = res4?.data?.customerlInfo;
            this.applicationInfoFormData.patchValue(this.moreInfoData);
          } else {
            this.commonService.showToast("error", "Customer Information", res4.message);
          }

          break;
        case "Usage":
          setTimeout(() => {
            this.cardLoader = isCardLoader;
          }, 100);
          let param = {
            CustomerId:this.httpService.getCustomerId()
          }
          
          var res5: any =
            await this.customerProfileService.getCustomerUsagesCardData(param);
          this.cardLoader = false;
          if (res5.Status) {
            this.moreInfoData = res5?.data;
            this.usageInfoData = res5?.data;
          } else {
            this.commonService.showToast("error", "Usage Information", res5.message);
          }
          break;
        case "Churn Risk":
          break;
        case "Financial Information":
          const res7: any =
            await this.customerProfileService.getFinancialCardData();
          if (res7.Status) {
            this.moreInfoData = res7.data;
          } else {
            this.commonService.showToast("error", "Financial Information", res7.message);
          }
          break;
        case "Eligibility Information":
          const res8: any =
            await this.customerProfileService.getEligibilityCardData();
          if (res8.Status) {
            this.moreInfoData = res8.data;
            this.eligibilityFormData.patchValue(
              this.moreInfoData?.eligibilityInfo
            );
          } else {
            this.commonService.showToast("error", "Eligibility Information", res8.message);
          }
          break;
        case "Subscription Information":
          this.customerSubscriptions();
          break;
        default:
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Cards Data ",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
    //
    this.loader = false;
    this.cardLoader = false;
  }

  async updateCustomerProfile(field) {
    try {
      let data = {};
      switch (this.moreInfoTab) {
        case "Personal Information":
          data[field] = this.personalInfoFormData.get(field).get("value").value;
          break;
        case "Line Information":
          data[field] = this.lineInfoFormData.get(field).value;
          break;
        case "Eligibility Information":
          data[field] = this.eligibilityFormData.get(field).get("value").value;
          break;
        case "Customer Information":
          data[field] = this.applicationInfoFormData
            .get(field)
            .get("value").value;
          break;
        case "Subscription Information":
          data[field] = this.subscriptionFormData.get(field).get("value").value;
          break;
        default:
          throw new Error("Invalid moreInfoTab value");
      }

      const res: any =
        await this.customerProfileService.updateCustomerProfile(data);
      if (res.Status) {
        this.editFieldName = "";
        try {
          await this.customerProfileService.getMinimalProfileData(null, true);
        } catch (err) {
          console.log("Failed to get minimal profile data", err);
        }
        this.getCardsData(this.moreInfoTab);
        this.commonService.showToast(
          "success",
          "Customer Information",
          "Successfully Updated"
        );
      } else {
        this.commonService.showToast("error", "Customer Information", res?.message);
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Customer Profile",
        err?.error?.message ?? "Some Error Occurred"
      );
    } finally {
    }
  }

  onEditIconClick(editFieldName) {
    this.editFieldName = editFieldName;
  }

  async getCustomerEventsData() {
    try {
      let data = {
        per_page: this.itemsPerPage,
        page: this.currentPage,
      };
      const res: any = await this.customerProfileService.getActivityInfo(data);
      if (res?.Status) {
        this.customerEventsData = res?.data.data;
        this.totalItems = res.data.total;
        this.currentPage = res.data.current_page;
        this.first_page_url = res.data.first_page_url;
        this.last_page_url = res.data.last_page_url;
        this.next_page_url = res.data.next_page_url;
      } else {
        this.commonService.showToast("error", "Customer Event", res.message);
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Customer Event",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
  }

  removeInputField() {
    this.editFieldName = "";
  }

  getValue(item, key: any) {
    return item[key]?.value;
  }
  getVisibility(item, key: any) {
    return item[key]?.visible;
  }

  // updates

  async updateSim() {
    try {
      const data = {
        newIccid: this.lineInfoFormData.get("simNumber").value,
      };
      const res: any = await this.customerProfileService.updateSim(data);
      if (res.Status) {
        this.commonService.showToast(
          "success",
          "Sim Update Information",
          "Successfully Updated"
        );
        this.editFieldName = null;
      } else {
        this.lineInfoFormData.get("simNumber").reset();
        this.commonService.showToast("error", "Sim Update Information", res.message);
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Sim Update Information",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
  }

  openServiceAddressModal() {}

  openMailingAddressModal() {}

  openFeatures() {}

  onChangeNumberOfPage() {
    this.getCardsData(this.moreInfoTab);
  }

  pageChange(event) {
    this.currentPage = event;
    this.getCardsData(this.moreInfoTab);
  }

  toggleCustomerEvent() {
    this.showCustomerEventAsList = !this.showCustomerEventAsList;
  }
  toggleCustomerEventModel() {
    this.showCustomerEventAsList1 = !this.showCustomerEventAsList1;
  }
  async autoRenewSubsc(event: any, subscriberId) {
    try {
      let autoRenew = event.target.checked;
      this.subscriptionService
        .autoRenewSubscription(subscriberId, autoRenew)
        .subscribe(
          (res: any) => {
            if (res?.Status) {
              this.commonService.showToast("success", "Auto Renew", res?.data);
            } else {
              this.commonService.showToast("error", "Auto Renew", res?.data);
            }
          },
          (error: any) => {
            this.commonService.showToast("error", "Auto Renew", error?.error.error);
          }
        );
    } catch (error) {
      console.error("Exception caught:", error);
    }
  }

  renewSubscription(cardCode) {
    try {
      this.subscriptionService
        .renewSubscription(this.getCustomerId(), cardCode)
        .subscribe(
          (res: any) => {
            if (res?.Status) {
              this.autoRenew = true;
              this.isOperationPerformed = true;
              this.commonService.showToast("success", "Renew Subscription", res?.data);
              this.customerProfileService.getMinimalProfileData(null, true);
              this.customerSubscriptions();
              this.autoRenewTransactionStatus = true;
              this.notifyChildPaymentSuccess(res);
            } else {
              this.autoRenew = false;
              this.autoRenewTransactionStatus = false;
              this.commonService.showToast("error", "Renew Subscription", res?.data);
              this.notifyChildPaymentSuccess(res);
            }
          },
          (error: any) => {
            this.autoRenewTransactionStatus = false;
            this.autoRenew = false;
            this.commonService.showToast("error", "Renew Subscription", error?.error.error);
          }
        );
    } catch (error) {
      this.autoRenew = false;
      console.error("Exception caught:", error);
    }
  }

  updateAutoRenewCardDetails(cardDetails: any) {
    this.commonService.startLoader();
    try {
      const subscriptionId = sessionStorage.getItem("SubscriptionId");
      this.subscriptionService
        .updateCardInfo(subscriptionId, cardDetails)
        .subscribe(
          (res: any) => {
            if (res?.Status) {
              this.renewSubscription(cardDetails?.cardCode);
            } else {
              this.commonService.showToast("error", "Update Card", res?.message);
            }
            this.commonService.stopLoader();
          },
          (error: any) => {
            this.commonService.showToast("error", "Update Card", error?.error.error);
            this.commonService.stopLoader();
          }
        );
    } catch (error) {}
  }

  notifyChildPaymentSuccess(response: any) {
    if (this.planChangePaymentComponent) {
      this.planChangePaymentComponent.autorenewStatusTracker(response);
    }
  }
  withConfirmation() {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel this subscription?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
      customClass: {
        popup: "swal2-custom-width",
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        this.cancelsubscription();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.autoRenew = true;
      }
    });
  }

  getCustomerId() {
    return sessionStorage.getItem("CustomerId");
  }

  async cancelsubscription() {
    try {
      this.subscriptionService
        .cancelSubscription(
          this.customerSubscriptionData,
          "Cancel Subscription via the Auto-Renewal Toggle in the Customer Profile."
        )
        .subscribe(
          (res: any) => {
            if (res?.Status) {
              this.autoRenew = false;
              this.commonService.showToast("success", "Cancel Subscription", res?.data);
              this.customerSubscriptions();
              this.customerProfileService.getMinimalProfileData(null, true);
            }
          },
          (error: any) => {
            this.autoRenew = true;
            this.commonService.showToast(
              "error",
              "Cancel Subscription",
              "An error occurred while cancelling the subscription."
            );
          }
        );
    } catch (err) {
      this.autoRenew = true;
      this.commonService.showToast(
        "error",
        "Cancel Subscription",
        "An unexpected error occurred."
      );
    }
  }

  async autoRenewSub(event: any) {
    if (event.target.checked) {
      this.isAutoRenewModelOpen = true;
    } else {
      this.withConfirmation();
    }
  }

  getsubmitCVVautoRenew(cvv: any) {
    this.renewSubscription(cvv);
  }
  getsubmitCreditCardAutoRenew(cardDetails: any) {
    this.updateAutoRenewCardDetails(cardDetails);
  }
  onIsAutoRenewModelOpenChange(newValue: boolean) {
    this.isAutoRenewModelOpen = newValue;
    this.autoRenew = this.autoRenew;
  }
  handleModalClosedByX(isModalClosedBYX: boolean) {
    if (isModalClosedBYX || !this.autoRenewTransactionStatus) {
      this.autoRenew = false;
    }
  }
  showCreditCardAlert() {
    type CardCodeResult = {
      cardCode: string;
    };

    let cardCodeInput: HTMLInputElement;

    Swal.fire<CardCodeResult>({
      title: "Enter Card Code",
      html: `
        <input type="text" id="cardCode" class="swal2-input" placeholder="Card Code" style="width:250px" maxlength="4" >
      `,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      focusConfirm: false,
      width: "400px",
      didOpen: () => {
        const popup = Swal.getPopup()!;
        cardCodeInput = popup.querySelector("#cardCode") as HTMLInputElement;
        cardCodeInput.onkeyup = (event) =>
          event.key === "Enter" && Swal.clickConfirm();
      },
      preConfirm: () => {
        const cardCode = cardCodeInput.value;
        if (!cardCode) {
          Swal.showValidationMessage("Please enter a card code");
          return null;
        } else {
          return { cardCode };
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const cardCode = result.value?.cardCode;
        this.renewSubscription(cardCode);
      } else {
        this.autoRenew = !this.autoRenew;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscriptionData = [];
      this.isSubscription = false;
      this.subscription.unsubscribe();
      this.isPrepaidSubscription.unsubscribe();
      this.noNotesEventSubscription.unsubscribe();
      this.isPersonalInformationOpenSubscription.unsubscribe();
      this.isSkeletonLoaderSubscription.unsubscribe();
      this.isBreadCrumbRefreshedClickedSubscription.unsubscribe();
      this.autoRenewStatusSubscription.unsubscribe();
      this.isCustomerChangedSubscription.unsubscribe();
      this.isAlertSubscription.unsubscribe();
      this.isUsageSubscription.unsubscribe();
      this.simTypeSubscription.unsubscribe();
    }

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    this.leaveCustomerProfile();
    if (this.echo) {
      this.echo.leave(`customer.${this.httpService.getCustomerId()}`);
    }
    if(this.visitorChangeDetection){
      this.visitorChangeDetection.unsubscribe()
    }
  }

  currentplan:any;
  scheduledPlan:any;
  currentAndSheduledplan:any
 async getPlansDetails() {
   this.loader = true;
   try {
     const companyName = this.authService.getCompanyName();
     if (companyName === "unity") {
     }
     if(this.customerProfileService.minimalProfileData?.schedulededPlan?.CurrentPlanID !=this.customerProfileService.minimalProfileData?.schedulededPlan?.SchedulePlan){
       if(this.customerProfileService.minimalProfileData?.schedulededPlan?.CurrentPlanID){
         const data = {
           planId: this.customerProfileService.minimalProfileData?.schedulededPlan?.CurrentPlanID
         }
         const response: any = await this.planChangeService.getPlansDetails(data);
         if(response?.Status){
           this.currentplan = response?.data;
         }else{
          this.commonService.showToast("error", "Plan Details", response?.error?.planId[0]);
         }
       }
       if(this.customerProfileService.minimalProfileData?.schedulededPlan?.SchedulePlan){
         const data = {
           planId: this.customerProfileService.minimalProfileData?.schedulededPlan?.SchedulePlan
         }
         const response: any = await this.planChangeService.getPlansDetails(data);
         if(response?.Status){
           this.scheduledPlan = response?.data;
         }else{
          this.commonService.showToast("error", "Plan Details", response?.error?.planId[0]);
         }
       }
     }
     if(this.customerProfileService.minimalProfileData?.schedulededPlan?.CurrentPlanID ==this.customerProfileService.minimalProfileData?.schedulededPlan?.SchedulePlan){
       if(this.customerProfileService.minimalProfileData?.schedulededPlan?.SchedulePlan){
         const data = {
           planId: this.customerProfileService.minimalProfileData?.schedulededPlan?.SchedulePlan
         }
         const response: any = await this.planChangeService.getPlansDetails(data);
         if(response?.Status){
           this.currentAndSheduledplan = response?.data;
         }else{
          this.commonService.showToast("error", "Plan Details", response?.error?.planId[0]);
         }
       }
     }
     
   } catch (error) {
     this.commonService.showToast("error", "Error", error?.message);
   } finally {
     this.loader = false;
   }
 }

 // getPlanNameById(id: number): string {
 //   const plan = this.plans.find((val) => val.id === id);
 //   return plan ? plan.Name : "No Plan Found";
 // }

 async getPlanScheduler() {
   try {
     const res = (await this.customerProfileService.getPlanScheduler()) as any;
     if (res?.Status) {
       this.schedulePlanList = res?.data;
     } else {
       this.commonService.showToast("error", "Error", res?.message);
     }
   } catch (error) {
     this.commonService.showToast("error", "Error", error?.message);
   }
 }

 getAdvancePlan() {
  const obj = {
    CustomerId: this.httpService.getCustomerId()
  } as any;
this.cdr.detectChanges()
  console.log('🚀 Fetching customer info with payload:', obj);

  this.customerProfileService.getCustomerInfo(obj).then(
    (res: any) => {
      console.log('✅ Customer Info Response:', res);

      if (res?.Status) {
        this.customerInfo = res.data.data;
        this.planId = res.data?.data[0]?.PlanId;

        console.log('🔍 Extracted Plan ID:', this.planId);
        this.cdr.detectChanges()
        if(!this.planId){
          this.customerPlandata=null;
          return;
        }
        // Fetch plan details
        this.getPlanDetails(this.planId);
      } else {
        console.warn('⚠️ No customer data found in response');
      }
    },
    (error: any) => {
      console.error('❌ Error Fetching Customer Info:', error);
      // this.commonService.showToast('error', 'Error', error);
    }
  );
}

getPlanDetails(planID) {
  const obj = {
    CustomerId: this.httpService.getCustomerId(),
    planId: planID
  } as any;

  console.log('🚀 Fetching Plan Details with payload:', obj);

  this.customerProfileService.getCurrentPlanInfo(obj).then(
    (res: any) => {
      console.log('✅ Plan Details Response:', res);
      this.cdr.detectChanges()

      if (res?.data) {
        this.customerPlandata = res.data;
        console.log('📌 Plan Data:', this.customerPlandata);
      } else {
        console.warn('⚠️ No Plan Data Found for Plan ID:', planID);
      }
    },
    (error: any) => {
      console.error('❌ Error Fetching Plan Details:', error);
      this.commonService.showToast('error', 'Error', error);
    }
  );
}

}
