<div class="container-fluid p-3">
  <!-- Main Tab Buttons on one line -->
  <div class="d-flex justify-content-start mb-2">
    <button class="btn mr-2" [class]="activeTabIndex === 0 ? 'btn-dark' : 'btn-outline-dark'"
    (selectedIndexChange)="onTabChange(0)"
     (click)="onTabChange(0)">
      <i class="fas fa-calendar-alt"></i> Renewals
    </button>
    <button class="btn mr-2" [class]="activeTabIndex === 1 ? 'btn-dark' : 'btn-outline-dark'" 
    (selectedIndexChange)="onTabChange(1)"
    (click)="onTabChange(1)">
      <i class="fas fa-arrow-down"></i> Scheduled Downgrade
    </button>
    <button class="btn mr-2" [class]="activeTabIndex === 2 ? 'btn-dark' : 'btn-outline-dark'" 
    (selectedIndexChange)="onTabChange(2)"
    (click)="onTabChange(2)">
      <i class="fas fa-sync-alt"></i> Plan Change
    </button>
  </div>

  <!-- Conditional Nested Tabs for Button 1 (Renewals) on the next line -->
  <div class="d-flex justify-content-end">
    <div *ngIf="activeTabIndex === 0">
      <button class="btn btn-outline-dark mr-1" [class.active]="activeNestedTabIndex === 0" (click)="onNestedTabChange(0)">
        <i class="fas fa-redo"></i> Plan Renewals
      </button>
      <button class="btn btn-outline-dark mr-1" [class.active]="activeNestedTabIndex === 1" (click)="onNestedTabChange(1)">
        <i class="fas fa-edit"></i> Renewal Correction
      </button>
    </div>
  </div>

  <!-- Tab Content Area -->
  <div class="mt-3">
    <!-- Content for Main Tabs -->
    <div *ngIf="activeTabIndex === 0" class="tab-content">
      <!-- Nested Tab Content for Renewals -->
      <div *ngIf="activeNestedTabIndex === 0">
        <app-plan-renewal></app-plan-renewal>
      </div>
      <div *ngIf="activeNestedTabIndex === 1">
        <app-mis-aligned-subscriptions></app-mis-aligned-subscriptions>
      </div>
    </div>

    <!-- Content for Scheduled Downgrades -->
    <div *ngIf="activeTabIndex === 1" class="tab-content">
      <app-plan-change></app-plan-change>
    </div>

    <!-- Content for Plan Change -->
    <div *ngIf="activeTabIndex === 2" class="tab-content">
      <app-advance-plans></app-advance-plans>
    </div>
  </div>
</div>
