import {   Component, OnInit} from '@angular/core';
import { PurchaseHistoryService } from './services/purchase-history.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { HttpService } from 'src/app/shared/services/httpService.service';


@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss'],
  animations:[fadeInOut]
})
export class PurchaseHistoryComponent implements OnInit  {
  tabs = [
    { label: 'All', icon: 'fas fa-list', component: 'PurchaseAllComponent' },
    {
      label: 'Plan',
      icon: 'fas fa-layer-group',
      subTabs: [
        { label: 'Plan Renewal', icon: 'fas fa-redo', component: 'PurchasePlanRenewalComponent' },
        { label: 'Plan Change', icon: 'fas fa-exchange-alt', component: 'PurchasePlanChangeComponent' },
      ],
    },
    // { label: 'Top-Ups', icon: 'fas fa-plus-circle', component: 'PurchaseTopupsComponent' },
    // { label: 'Equipments', icon: 'fas fa-mobile-alt', component: 'PurchaseEquipmentsComponent' },
  ];

  currentCustomerId: any;
  selectedTab: any = null;
  selectedSubTab: any = null;

  constructor(
    private purchaseService: PurchaseHistoryService,
    private httpService: HttpService,
    private commonService:CommonService
  ) {}

  ngOnInit(): void {
    this.currentCustomerId = this.httpService.getCustomerId();
    this.initializeCustomerId();
    this.selectedTab = this.tabs[0]; // Default to the first tab
  }

  private initializeCustomerId(): void {
    this.currentCustomerId = this.httpService.getCustomerId();
  }

  isTabDisabled(tab: any): boolean {
    // Disable "Top-Ups" and "Equipments" tabs
    return tab.label === 'Top-Ups' || tab.label === 'Equipments';
  }
  showTooltip(tab: any): void {
    if (this.isTabDisabled(tab)) {
      // Tooltip will automatically show due to MatTooltip on disabled tabs
      console.log(`Tab "${tab.label}" is disabled. Message: Coming Soon`);
    }
  }
  onTabClick(tab: any): void {
    this.selectedTab = tab;

    if (tab.subTabs && tab.subTabs.length > 0) {
      // Set the first sub-tab as selected by default
      this.selectedSubTab = tab.subTabs[0];
    } else {
      // Clear selected sub-tab if the tab doesn't have sub-tabs
      this.selectedSubTab = null;
    }
  }

  onSubTabClick(subTab: any): void {
    this.selectedSubTab = subTab;
  }
}
