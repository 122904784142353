<div class="card py-3 mx-3 mt-3">
    <div
    class="d-flex flex-column flex-lg-row w-100 px-3 justify-content-between position-relative pb-2"
  >
  <div class="d-flex flex-column align-items-start text-start">
    <h6 class="mb-2" style="white-space: nowrap;">
      Mis Aligned Subscription ({{ misAlingnedTotal || 0 }})
    </h6>
    <div class="filters-info">
      <small *ngIf="searchTerm || riskLevel" class="text-muted">
        Displaying data for
        <span *ngIf="searchTerm" class="badge bg-info mx-1">Search: {{ searchTerm }}</span>
        <span *ngIf="riskLevel" class="badge bg-warning mx-1">Risk Level: {{ previousRiskLevel }}</span>
      </small>
    </div>
  </div>
  
    <div class="d-flex col-lg-8 col-12 align-items-center gap-3">
      <!-- Search Input -->
      <input
        type="text"
        class="form-control ps-3"
        placeholder="Search..."
        (keyup.enter)="searchChange($event)"
      />


      <!-- Refresh Button -->
      <app-filter-btn
        [icon]="'fa fa-refresh'"
        [isBtn]="true"
        (click)="refreshData()"
      ></app-filter-btn>
      <div class="dropdown">
        <app-filter-btn
          [icon]="'fa fa-filter'"
          [isBtn]="true"
          (selectionChanged)="toggleRiskLevelDropdown()"
          style="border-radius: 20px;"
        ></app-filter-btn>
      
        <ul
          class="dropdown-menu"
          [class.show]="isRiskLevelDropdownOpen"
          aria-labelledby="filterDropdown"
        >
          <li>
            <a
              class="dropdown-item "
              href="javascript:void(0)"
              (click)="applyRiskLevelFilter('Critical')"
            >
              <span class="icon">
                <i class="fa fa-exclamation-circle"></i>
              </span>
              <span class="text">Critical</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item "
              href="javascript:void(0)"
              (click)="applyRiskLevelFilter('High')"
            >
              <span class="icon">
                <i class="fa fa-exclamation-triangle"></i>
              </span>
              <span class="text">High</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item "
              href="javascript:void(0)"
              (click)="applyRiskLevelFilter('Medium')"
            >
              <span class="icon">
                <i class="fa fa-bolt"></i>
              </span>
              <span class="text">Medium</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              (click)="applyRiskLevelFilter('Low')"
            >
              <span class="icon">
                <i class="fa fa-check-circle"></i>
              </span>
              <span class="text">Low</span>
            </a>
          </li>
        </ul>
      </div>
      
      
      
      
      
      <!-- Export Button -->
      <button class="btn btn-dark" (click)="exportData()">Export</button>
    </div>
  </div>
    <div class="table-responsive table-hover table-striped table-card"  *ngIf="!isSkeletonLoader" @fadeInOut>
        <div style="overflow-x: auto; width: 100%;">
            <table class="table table-nowrap mb-0">
              <thead>
                <tr>
                  <th *ngFor="let header of misAlingnedHeader" style="white-space: nowrap;">
                    {{ header.display }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of misAlingnedData; let i=index" style="white-space: nowrap;" [class.highlight]="i === highlightedRowIndex">
                  <td *ngFor="let header of misAlingnedHeader">
                    <ng-container [ngSwitch]="header.key">
                      <!-- For displaying Customer Name (First and Last Name combined) -->
                      <ng-container *ngSwitchCase="'FirstName'">
                        <a
                          href="javascript:void(0)"
                          (click)="onNameClick(item.CustomerId,i)"
                          class="text-decoration-none text-primary"
                          title="View Details"
                        >
                          {{ item['FirstName'] || '-' }} {{ item['LastName'] || '' }}
                        </a>
                      </ng-container>
                      <ng-container *ngSwitchCase="'BillingPeriod'">
                        {{ item[header.key] }} days
                      </ng-container>
                      <!-- <ng-container *ngSwitchCase="'Email'">
                        {{ item[header.key]  }}
                      </ng-container> -->
                      <ng-container *ngSwitchCase="'SubscriptionId'">
                        <a
                          href="javascript:void(0)"
                          (click)="onSubscriptionIdClick(item.SubscriptionId,item.CustomerId,i)"
                          class="text-decoration-none text-primary"
                          title="View Details"
                        >
                          {{ item['SubscriptionId'] }}
                        </a>
                      </ng-container>
                      
                      
              
                      <!-- For handling specific keys like dates -->
                      <ng-container *ngSwitchCase="'NextBillingDate'">
                        {{commonService.formatDate(item[header.key],'date')}}
                        <!-- {{ item[header.key] | date }} -->
                      </ng-container>
                      <ng-container *ngSwitchCase="'IntervalLength'">
                        {{ item[header.key] || '-' }}
                        <!-- {{ item[header.key] | date }} -->
                      </ng-container>
                      <ng-container *ngSwitchCase="'NextRenewalDate'">
                        <!-- {{ item[header.key] | date }} -->
                        {{commonService.formatDate(item[header.key],'date')}}
                      </ng-container>
              
                      <!-- For Subscription Status -->
                      <ng-container *ngSwitchCase="'SubscriptionStatus'">
                        <span [ngClass]="getStatusBadgeClass(item[header.key])">
                          {{ item[header.key] || '-' }}
                        </span>
                      </ng-container>
                      <ng-container *ngSwitchCase="'CustomerStatus'">
                        <span [ngClass]="getStatusBadgeClass(item[header.key])">
                          {{ item[header.key] || '-' }}
                        </span>
                      </ng-container>
              
                      <!-- For Risk Level -->
                      <ng-container *ngSwitchCase="'RiskLevel'">
                        <div
                          class="risk-bar"
                           matTooltip="{{ getRiskLevelTooltip(item['RiskLevel']) }}"
                             matTooltipPosition="above"
                          [ngClass]="{
                            'risk-critical': item['RiskLevel'] === 'Critical',
                            'risk-high': item['RiskLevel'] === 'High',
                            'risk-medium': item['RiskLevel'] === 'Medium',
                            'risk-low': item['RiskLevel'] === 'Low'
                          }"
                        >
                          <span class="risk-label">{{ item['RiskLevel'] || 'Unknown' }}</span>
                        </div>
                      </ng-container>
                      
              
                      <!-- Default case -->
                      <ng-container *ngSwitchDefault>
                        {{ item[header.key] || '-' }}
                      </ng-container>
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="risk-level-container text-muted">
              <div class="risk-item">
                <span class="risk-dott risk-cri"></span> Critical
              </div>
              <div class="risk-item">
                <span class="risk-dott risk-hi"></span> High
              </div>
              <div class="risk-item">
                <span class="risk-dott risk-med"></span> Medium
              </div>
              <div class="risk-item">
                <span class="risk-dott risk-l"></span> Low
              </div>
            </div>
            
          </div>
          
          
          
          
    </div>
      <!-- Pagination -->
  <div class="d-flex justify-content-end m-2" *ngIf="misAlingnedTotal">
    <ngb-pagination
      class="pagination-block"
      [directionLinks]="true"
      [boundaryLinks]="true"
      [collectionSize]="misAlingnedTotal"
      [(page)]="currentPage"
      (pageChange)="onMisAlignedPageChange($event)"
      [maxSize]="3"
      [pageSize]="itemsPerPage"
      [ellipses]="true"
    >
      <ng-template ngbPaginationPrevious>Previous</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>
    <div *ngIf="isSkeletonLoader" @fadeInOut class=" container-fluid ">
        <div class="div d-flex flex-column justify-content-between">
            <app-skeleton-loader [tableRow]="15" [tableColumn]="8"
                shape="table"></app-skeleton-loader>
        </div>
    </div>
  </div>