import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { CustomerProfileComponent } from "./customer-profile/customer-profile.component";
import { BillingAndInvoiceComponent } from "./billing-and-invoice/billing-and-invoice.component";
import { OrderHistoryComponent } from "./order-history/order-history.component";
import { AcpComponent } from "./order-history/acp/acp.component";
import { NewComponent } from "./order-history/new/new.component";
import { ToReceiveComponent } from "./order-history/to-receive/to-receive.component";
import { CompleteComponent } from "./order-history/complete/complete.component";
import { ReturnedComponent } from "./order-history/returned/returned.component";
import { PurchaseHistoryComponent } from "./purchase-history/purchase-history.component";
import { CustomerHistoryComponent } from "./customer-history/customer-history.component";
import { TicketsComponent } from "./tickets/tickets.component";
import { ActivityLogComponent } from "./activity-log/activity-log.component";
import { ManageCardComponent } from "./manage-card/manage-card.component";
import { CustomerPagesComponent } from "./customer-pages.component";
import { FilesComponent } from './files/files.component';
import { CustomerNotesComponent } from "../customer-footer/customer-notes/customer-notes.component";
import { OrderTableComponent } from "./order-history/order-table/order-table.component";
import { NewDashboardComponent } from "./new-dashboard/new-dashboard.component";
import { AllVisitorsComponent } from "./visitors/all-visitors/all-visitors.component";

const routes: Routes = [
  {
    path: "",
    component: CustomerPagesComponent,
    children: [
      { path: "", redirectTo: 'customer-profile', pathMatch: 'full' },
      { path: "home", component: HomeComponent },
      { path: "customer-profile", component: CustomerProfileComponent },
      { path: "customer-profile?showBack=true", redirectTo: "customer-profile", pathMatch: "full" },
      { path: "billing-invoice", component: BillingAndInvoiceComponent },
      {
        path: "order-history", component: OrderHistoryComponent,
        children: [
          { path: "", redirectTo: 'order-table', pathMatch: 'full' },
          { path: "acp", component: AcpComponent },
          { path: "new", component: NewComponent },
          { path: "order-table", component: OrderTableComponent },
          { path: "receive", component: ToReceiveComponent },
          { path: "complete", component: CompleteComponent },
          { path: "returned", component: ReturnedComponent },
        ]
      },
      { path: "purchase", component: PurchaseHistoryComponent },
      { path: "tickets", component: TicketsComponent },
      { path: "customer-history", component: CustomerHistoryComponent },
      { path: "activity-log", component: CustomerNotesComponent },
      { path: "manage-card", component: ManageCardComponent },
      {path :"files",component:FilesComponent},
      {path:'all-visitors',component:AllVisitorsComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [
  ],
})
export class CustomerPagesRoutingModule { }
