import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SearchService } from '../shared/services/search.service';
import { CustomerProfileService } from '../shared/services/customer-profile.service';
import { CommonService } from '../shared/services/common.service';
import { Router } from '@angular/router';
import { HttpService } from '../shared/services/httpService.service';
import { LocalStorage } from 'ngx-webstorage';
import { FormBuilder} from '@angular/forms';
import { OrderReviewService } from '../review/services/order-review.service';
import { SearchHistoryService } from '../shared/services/search-history.service';
import { StateManagementService } from '../shared/services/state-management.service';
import { fadeInOut } from '../shared/services/app.animation';


@Component({
  selector: 'app-search-result-page',
  templateUrl: './search-result-page.component.html',
  styleUrls: ['./search-result-page.component.scss'],
   animations: [fadeInOut]
})
export class SearchResultPageComponent implements OnInit {

  data: any = [];
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public selectedSource: any = "";
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  public prev_page_url: any = "";
  public pageDropdownMenu: any = [10, 20, 30];
  loader = false;
  searchCount:any
  advanceSearchPayload:any
  public orderStatusMap: { [key: string]: { priority: number; badgeClass: string } } = {};
  isAdvanceSearched: boolean = false;
  column = [];

  @LocalStorage()
  private userDetails;
  rowDatas: any[];
  highlightedRowIndex: number;
  searchTerm: any;

  constructor(
    public searchService: SearchService,
    public customerProfileService: CustomerProfileService,
    public commonService: CommonService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    public http: HttpService,
    public fb: FormBuilder,
    public orderReviewService: OrderReviewService,
    private searchHistoryService:SearchHistoryService,
    private stateManagementService: StateManagementService
  ) {
    this.column = [
      { displayName: 'OrderId', value: 'serialNumber' }, // Most important
      { displayName: 'Order Date', value: 'orderDate' },
      { displayName: 'Status', value: 'status' },
      { displayName: 'Carrier', value: 'carrier' },
      { displayName: 'Customer Id', value: 'customerId' },
      { displayName: 'Customer Name', value: 'FullName' },
      { displayName: 'Plan Name', value: 'PlanName' },
      { displayName: 'Email', value: 'Email' },
      { displayName: 'Amount Paid', value: 'amountPaid' },
      { displayName: 'Address', value: 'address' },
      { displayName: 'Subscriber Id', value: 'subscriberId' },
      { displayName: 'Enroll Number', value: 'EnrollNumber' },
      { displayName: 'Enroll Id', value: 'enrollId' },
      { displayName: 'Order Items', value: 'orderItems' },
      { displayName: 'nvNlad Date', value: 'nvNladDate' },
      { displayName: 'First Name', value: 'FirstName' },
      { displayName: 'Last Name', value: 'LastName' },
      { displayName: 'Agent Id', value: 'agentId' },
      { displayName: 'Device Info', value: 'deviceInfo' },
      { displayName: 'Application Id', value: 'applicationId' },
      { displayName: 'Enrollment Type', value: 'EnrollmentType' },
      { displayName: 'Tribal', value: 'Tribal' }, // Less critical
      { displayName: 'Source', value: 'source' },  // Least important
      { displayName: 'Last Comment', value: 'LastComment' },
    ];
    
    this.searchService.advanceSearchedData.subscribe(res => {
      if (res) {
        this.isAdvanceSearched = true;
        this.data = res.data.data
        this.totalItems = res.data.total
        this.currentPage = res.data.current_page
        this.first_page_url = res.data.first_page_url
        this.last_page_url = res.data.last_page_url
        this.next_page_url = res.data.next_page_url
        this.prev_page_url = res.data.prev_page_url
        //here we can access the data
      } else {
        this.isAdvanceSearched = false;
      }
    })

    this.searchService.advanceSearchedPayloadData.subscribe(res=>{
      if(res){
        this.advanceSearchPayload =res;
      }
    })
  }


 async ngOnInit() {
    // Attempt to restore state

    this.searchService.searchData.subscribe((res) => {
      // if (!restored) {
        this.isAdvanceSearched = false; // Default to non-advanced search
        this.data = res.data;
        this.totalItems = res.total;
        this.currentPage = res.current_page;
        this.first_page_url = res.first_page_url;
        this.last_page_url = res.last_page_url;
        this.next_page_url = res.next_page_url;
        this.prev_page_url = res.prev_page_url;
      // }
      console.log('data: ', res);
      this.searchService.searchCount.subscribe(res => {
        this.searchCount= res?.totalCount;
      });
    });
    const restored = this.stateManagementService.restoreState("search-result",undefined, (state) => {
      this.data = state.items || []; // Restore row data
      this.isAdvanceSearched = state.filters?.isAdvanceSearched || false; // Restore advanced search flag
      this.highlightedRowIndex = state.selectedIndex ?? null; // Restore highlighted index
      this.searchTerm = state.filters?.searchterm || ""; // Restore search term
      this.totalItems = state.filters?.totalItems || 0; // Restore total items
      this.currentPage = state.filters?.currentPage || 1; // Restore current page
      this.first_page_url = state.filters?.first_page_url || ""; // Restore first page URL
      this.last_page_url = state.filters?.last_page_url || ""; // Restore last page URL
      this.next_page_url = state.filters?.next_page_url || ""; // Restore next page URL
      this.prev_page_url = state.filters?.prev_page_url || ""; // Restore previous page URL
    });
    if (!restored) {
      // If no state is restored, this is likely a page refresh
      this.currentPage = 1;
      await this.fetchPaginatedData();
    }
    this.setBreadCrumb();
     this.getOrderStatus();
  }
  getBadgeClassByStage(stage: number): string {
    switch (stage) {
      case 1:
        return 'badge-primary';
      case 2:
        return 'badge-warning';
      case 3:
        return 'badge-danger';
      default:
        return 'badge-secondary';
    }
  }

  /**
   * Fetch and process order statuses
   */
/**
 * Fetch and process order statuses
 */
async getOrderStatus(): Promise<void> {
  try {
    const res: any = await this.commonService.getOrderStatus();
    if (res?.Status) {
      // Define manual mappings for badge classes and priorities
      const badgeClassMapping = {
        success: ['Delivered', 'Completed', 'Approved', 'Shipped'],
        warning: ['In Transit', 'Pending', 'Reviewing', 'Processing'],
        danger: ['Cancelled', 'Failed', 'Refunded', 'Rejected Orders'],
        primary: ['Awaiting Payment', 'Provisioning in progress', 'New Orders', 'Payment Received'],
        secondary: ['On Hold', 'Unknown'], // Default group
      };

      // Build order status map based on manual groups
      this.orderStatusMap = res.data.reduce((map, status) => {
        const badgeClass = Object.entries(badgeClassMapping).find(([key, statuses]) =>
          statuses.includes(status.Name)
        )?.[0] || 'secondary';

        map[status.Name] = {
          priority: badgeClass === 'success' ? 1 : badgeClass === 'warning' ? 2 : 3, // Adjust priorities as needed
          badgeClass: `badge-${badgeClass}`,
        };
        return map;
      }, {});

      console.log('Order Status Map:', this.orderStatusMap);
    }
  } catch (error) {
    console.error('Error fetching order statuses:', error);
  }
}


/**
 * Get badge class for a status
 * @param status - Status name
 * @returns badge class string
 */
getStatusBadgeClass(status: string): string {
  return this.orderStatusMap[status]?.badgeClass || 'badge-secondary';
}
  
  

  async setUser(data: any, index: any) {
    try {
      const customerId = this.isAdvanceSearched ? data.customerId : data.CustomerID;
  
      // Set customer ID and add data to customer searched data
      this.customerProfileService.setCustomerId(customerId);
      this.searchService.addToCustomerSearchedData(data);
  
      // Save the state
      this.stateManagementService.saveState(
        "search-result",
        undefined,
        {
          isAdvanceSearched: this.isAdvanceSearched,
          searchterm: this.searchTerm,
          totalItems: this.totalItems,
          currentPage: this.currentPage,
          first_page_url: this.first_page_url,
          last_page_url: this.last_page_url,
          next_page_url: this.next_page_url,
          prev_page_url: this.prev_page_url,
        },
        this.data, // Save row data
        index // Save selected index
      );
  
      // Navigate to customer profile
      this.router.navigate([`/customer-dashboard/customer-profile`], {
        state: { searchData: data },
        queryParams: { showBack: true },
      });
  
      // Save the search record
      this.searchHistoryService.saveSearchRecord(customerId, "customers", data);
    } catch (err) {
      console.error(err);
      this.commonService.showToast("error", "Error", err?.error?.message ?? "Some Error Occurred");
    }
  }
  
  
  

  setBreadCrumb() {
    this.commonService.setbreadCrumb({
      "title": "Search Result",
      "active_item": "Search Result"
    })
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.fetchPaginatedData();
  }
  
  onItemsPerPageChange(): void {
    this.currentPage = 1; // Reset to the first page when items per page changes
    this.fetchPaginatedData();
  }
  
  async fetchPaginatedData(): Promise<void> {
    this.loader = true;
    try {
      if(this.isAdvanceSearched){
        const payload = {
          page: this.currentPage, // Current page
          perPage: this.itemsPerPage, // Items per page
        };
    
        const res: any = await this.orderReviewService.advanceOrderSearch(payload);
    
        if (res.Status) {
          this.data = res.data.data;
          this.totalItems = res.data.total;
          this.currentPage = res.data.current_page;
          this.searchService.setAdvanceSearchedData(res);
          this.searchService.setAdvanceSearchedPayloadData(payload);
        } else {
          this.commonService.showToast('error', 'Error', res.message);
        }
      }else{
        return;
      }
  } catch (err) {
      this.commonService.showToast('error', 'Error', err?.error?.message ?? 'Some Error Occurred');
    } finally {
      this.loader = false;
    }
  }
  



   async handleOrderClick(result: any, index:any) {
    const order = { orderId: result.id, customerId: result.customerId };
     // Save the state
     this.stateManagementService.saveState(
      "search-result",
      undefined,
      {
        isAdvanceSearched: this.isAdvanceSearched,
        searchterm: this.searchTerm,
        totalItems: this.totalItems,
        currentPage: this.currentPage,
        first_page_url: this.first_page_url,
        last_page_url: this.last_page_url,
        next_page_url: this.next_page_url,
        prev_page_url: this.prev_page_url,
      },
      this.data, // Save row data
      index // Save selected index
    );
    this.goToDetails(order);

  }
  goToDetails(order: any) {
    this.router
      .navigateByUrl("/reload", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(
          [`/awaiting-shipment/order-details/${order.orderId}`],
          {
            queryParams: { customerid: order.customerId },
            state: { order },
          }
        );
      });
  }
  getUser() {
    return this.userDetails;
  }
  async onNext() {
    this.loader = true;
    try {
      const searchParams = new URLSearchParams();
      if (this.advanceSearchPayload) {
        Object.keys(this.advanceSearchPayload).forEach((key) => {
          searchParams.append(key, this.advanceSearchPayload[key]);
        });
      }
      const fullUrl = `${this.next_page_url}&companyId=UNITYCRM0021&userID=${this.getUser()?.userID}&${searchParams.toString()}`;
      const res: any = await this.http.get(fullUrl, false, true, false, true, true).toPromise();
      if (res?.Status) {
        this.data = res.data.data;
        this.totalItems = res.data.total;
        this.currentPage = res.data.current_page;
        this.first_page_url = res.data.first_page_url;
        this.last_page_url = res.data.last_page_url;
        this.next_page_url = res.data.next_page_url;
        this.prev_page_url = res.data.prev_page_url;
  
        // Reset row highlighting
        // this.highlightedRowIndex = null;
      } else {
        this.data = [];
        this.commonService.showToast('error', 'Error', res?.message);
      }
    } catch (err) {
      this.commonService.showToast('error', 'Error', err?.error?.message ?? 'Some Error Occurred');
    }
    this.loader = false;
  }
  
  async onPrevious() {
    this.loader = true;
    try {
      const res: any = await this.http.get(this.prev_page_url + '&companyId=UNITYCRM0021&userID=' + this.getUser()?.userID, false, true, false, true, true).toPromise();
      if (res?.Status) {
        this.data = res.data.data;
        this.totalItems = res.data.total;
        this.currentPage = res.data.current_page;
        this.first_page_url = res.data.first_page_url;
        this.last_page_url = res.data.last_page_url;
        this.next_page_url = res.data.next_page_url;
        this.prev_page_url = res.data.prev_page_url;
  
        // Reset row highlighting
        // this.highlightedRowIndex = null;
      } else {
        this.data = [];
        this.commonService.showToast("error", "Error", res?.message);
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err?.error?.message ?? "Some Error Occurred");
    }
    this.loader = false;
  }
  

}
