import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpService } from 'src/app/shared/services/httpService.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { RenewalServiceService } from '../service/renewal-service.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-po-details',
  templateUrl: './po-details.component.html',
  styleUrls: ['./po-details.component.scss'],
  animations:[fadeInOut]
})
export class PoDetailsComponent {
@ViewChild('renewConfirmationModal', { static: true }) renewConfirmationModal: any;
  @ViewChild('suspensionConfirmationModal', { static: true }) suspensionConfirmationModal: any;
  id: any;
  renewal: any
  paymentData: any
  logData: any
  invoiceData: any
  timelineEvents = [
    { status: 'Payment succeeded', icon: 'fa fa-check-circle text-success', timestamp: 'Oct 25, 2023, 9:50 PM' },
    { status: 'Payment started', icon: 'fa fa-money text-muted', timestamp: 'Oct 25, 2023, 9:50 PM' }
  ];
  customerDetails: any;
  showPoDetails:boolean
  showDatePicker: boolean;
  status: string;
  action: any;
  selectedCustomer: any;
  dueDate: any;
  selectedActivityType: any;
  isSkeletonLoader: boolean = true;
  timelineData: any = []
  activityTypes = ['All', 'PO', 'Payment'];
  filteredTimelineData: any = [];
  customerdata:any
  
  poId:any
  po_details:any
  constructor(
    private route: ActivatedRoute,
    private renewalServiceService: RenewalServiceService,
    private http: HttpService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private customerProfileService: CustomerProfileService,
    private router: Router
  ) {

  }

  async ngOnInit() {
    this.renewal = history.state.renewalItem ? history.state.renewalItem : 2004018 ;
    this.poId = history.state.poId
    // let res1: any = await this.renewalServiceService.getPosPayment(data);
    this.paymentData = this.renewal?.po_invoice; // Store data for payment info
    // let res2: any = await this.renewalServiceService.getPosInvoice(data);
    // this.invoiceData = res2?.data[0] || {}; // Store data for invoice info
    this.getCustomerDetais()
    await this.getTimelineData()

    console.log('PO Details', this.renewal);
    this.getCustomerData()
  }

  back() {
    window.history.back();

  }

  async getCustomerDetais() {
    const data = {
      poId: this.poId?.id
    }

    const res: any = await this.http.get('api/report/getPoDetail', data, false).toPromise();
    if (res.Status) {
      this.customerDetails = res.data
      this.isSkeletonLoader = false;
    }
    this.isSkeletonLoader = false;
  }
  async getCustomerData() {
    const data = {
      CustomerId: this.poId?.customer_id
    }

    const res: any = await this.http.get('api/Customer/getCustomerPersonalInfo', data, false).toPromise();
    if (res.Status) {
      this.customerdata = res.data
      this.isSkeletonLoader = false;
    }
    this.isSkeletonLoader = false;
  }


  onActionChange(action, e) {
    if (action == this.action) {
      if (e.target.value == "specificDate") {
        this.showDatePicker = true
        this.status = "Pending"
      } else if (e.target.value == "now") {
        this.status = "Progress"
      } else if (e.target.value == "onDate") {
        this.status = "Pending"
      }
    } else {
      if (e.target.value == "specificDate") {
        this.showDatePicker = true
        this.status = "Pending"
      } else if (e.target.value == "now") {
        this.status = "Progress"
      } else if (e.target.value == "onDate") {
        this.status = "Hold"
      }
    }
  }

  performAction(action) {
    if (action == "suspend") {
      this.forceSuspendAccount()
    } else {
      this.forceRenewAccount()
    }
  }

  forceSuspendAccount() {
    let data = {
      customer_id: this.selectedCustomer?.CustomerId,
      subscription_id: this.selectedCustomer?.SubscriptionId,
      action: "Suspension",
      status: this.status,
      po_id: this.selectedCustomer?.po_data?.id,
    }

    if (this.dueDate) {
      data['due_date'] = this.dueDate
    }

    this.renewalServiceService.generatePo(data).then((res: any) => {
      if (res.Status) {
        this.commonService.showToast("success", res?.message, "")
      }
    }).catch((res: any) => {
      this.commonService.showToast("error", res?.message, "")
    })
  }

  forceRenewAccount() {

    let data = {
      customer_id: this.selectedCustomer.CustomerId,
      subscription_id: this.selectedCustomer.SubscriptionId,
      action: "Renewal",
      status: this.status,
      po_id: this.selectedCustomer?.po_data?.id,
    }

    if (this.dueDate) {
      data['due_date'] = this.dueDate
    }
    this.renewalServiceService.generatePo(data).then((res: any) => {
      if (res.Status) {
        this.commonService.showToast("success", res?.message, "")
        this.modalService.dismissAll()
      }
    }).catch((res: any) => {
      this.commonService.showToast("error", res?.message, "")
    })
  }

  openConfirmationModal(action, item) {
    this.action = action
    this.selectedCustomer = item
    if (action == "Suspend") {
      this.modalService.open(this.suspensionConfirmationModal, { centered: true })
    } else {
      this.modalService.open(this.renewConfirmationModal, { centered: true })
    }
  }

  getStatusBadgeClass(status: string): any {
    switch (status) {
      case 'Completed':
        return 'badge-success';
      case 'New':
        return 'badge-primary';
      case 'Deactivated':
        return 'badge-warning';
      case 'Cancelled':
        return 'badge-danger';
      case 'Pending':
        return 'badge-danger';
      default:
        return '';
    }
  }

  getTimelineData() {
    console.log("po Data",this.poId)
    this.renewalServiceService.getTimelineData({ CustomerId: this.poId?.customer_id}).then((res: any) => {
      if (res.Status) {
        this.timelineData = res?.data?.timeline
        this.filterTimeline('All');
      }
    }).catch((res: any) => {
      this.commonService.showToast("error", res?.message, "")
    })
  }

  filterTimeline(type: string): void {
    this.selectedActivityType = type;
    if (type === 'All') {
      this.filteredTimelineData = this.timelineData; // Show all if 'All' is selected
    } else {
      this.filteredTimelineData = this.timelineData.filter(event => event.activity_type === type);
    }
  }

  async sendToCustomerDashboard() {
    await this.customerProfileService.setCustomerId(this.renewal?.CustomerId ? this.renewal?.CustomerId : 2004018)
    this.customerProfileService.getMinimalProfileData()
    this.router.navigate(['/customer-dashboard/customer-profile'], { queryParams: { showBack: true } });
  }
}
