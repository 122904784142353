import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class RefundManagementService {

  constructor(private http: HttpService) { }



  getRefundList(data) {
    try {
      let url = "api/Customer/AdvancePlan";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}