<!-- Sidebar Wrapper -->

<div class="add-ticket-sidebar" >
    <div class="col-12 col-md-7 section p-0" style="width: 0%; height: 100%;">
        <div class="tab-content mt-3">
            <div class="container mt-4">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-md-12 justify-content-center">
                            <div class="col-md-12 p-2 d-flex justify-content-between">
                                <div class="col-6">
                                    <h4 class="text-dark mb-1">Add Top Up</h4>
                                    <p class="text-muted">All transactions are secure and encrypted.</p>
                                </div>
                                <div class="col-6 text-end">
                                    <button class="btn btn-danger btn-sm" (click)="closeSidebar()">Close</button>
                                </div>
                            </div>

                            <div class="p-0 pb-2 rounded d-flex flex-column justify-content-center">
                                <form class="mt-3 custom-form-width d-flex flex-md-column flex-sm-column">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Address</label>
                                            <input class="form-control" placeholder="Enter your address">
                                        </div>
                                        <div class="form-group">
                                            <label>Apartment, Suite, etc.</label>
                                            <input class="form-control" placeholder="Optional">
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-md-6">
                                                <label>City</label>
                                                <input class="form-control" placeholder="City">
                                            </div>
                                            <div class="col-md-6">
                                                <label>State</label>
                                                <input class="form-control" placeholder="State">
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Zip Code</label>
                                            <input class="form-control" placeholder="Zip Code">
                                        </div>

                                        <div class="form-group">
                                            <label>Credit Card</label>
                                            <div class="d-flex justify-content-between align-items-center px-2">
                                                <img src="../../../../../assets/images/credit-cards/card.png" alt="master-card" class="credit-card-icon">
                                                <img src="../../../../../assets/images/credit-cards/american-express.png" alt="amex" class="credit-card-icon">
                                                <img src="../../../../../assets/images/credit-cards/visa.png" alt="visa" class="credit-card-icon">
                                            </div>
                                        </div>

                                        <div class="form-group mb-3">
                                            <label>Name on Card</label>
                                            <input type="text" class="form-control" placeholder="Cardholder Name">
                                        </div>
                                        <div class="form-group position-relative">
                                            <label>Card Number</label>
                                            <input type="text" maxlength="16" class="form-control pe-5" placeholder="Card Number">
                                        </div>

                                        <div class="form-group row mb-3">
                                            <div class="col-md-6">
                                                <label>Expire Month</label>
                                                <select class="form-control form-select">
                                                    <option>Select Month</option>
                                                    <option>January</option>
                                                    <option>February</option>
                                                    <option>March</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label>Expire Year</label>
                                                <select class="form-control form-select">
                                                    <option>Select Year</option>
                                                    <option>2024</option>
                                                    <option>2025</option>
                                                    <option>2026</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group mb-3">
                                            <label>CVV</label>
                                            <input type="password" maxlength="4" class="form-control" placeholder="CVV">
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <button type="submit" class="btn btn-primary w-100 mt-3">Submit</button>
                                    </div>
                                </form>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
