import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { BoardModule } from 'ngx-board';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { TransactionComponent } from './transaction.component';
import { TransactionRoutingModule } from './transaction-routing.module';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { TransactionDetailComponent } from './transaction-detail/transaction-detail.component';
import { TimelineComponent } from './timeline/timeline.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LatestActivityComponent } from './latest-activity/latest-activity.component';
import { CustomerDashboardModule } from '../customer-dashboard/customer-dashboard.module';
import { DynamicInvoiceTemplateService } from '../shared/components/dynamic-invoice-template/dynamic-invoice-template.service';
import { RefundDashboardComponent } from './refund-management/refund-dashboard/refund-dashboard.component';
import { RefundMultiStepFormComponent } from './refund-management/refund-multi-step-form/refund-multi-step-form.component';

@NgModule({
    declarations: [
        TransactionComponent,
        TransactionListComponent,
        TransactionDetailComponent,
        TimelineComponent,
        LatestActivityComponent,
        RefundDashboardComponent,
        RefundMultiStepFormComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        BoardModule,
        AngularEditorModule,
        HttpClientModule,
        TransactionRoutingModule,
        DragDropModule,
        BsDatepickerModule,
        CustomerDashboardModule
    ],
    exports:[TimelineComponent],
    providers:[DynamicInvoiceTemplateService
        
    ]
})
export class TransactionModule { }
