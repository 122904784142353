import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuardService } from './auth/services/auth-guard.service';
import { CustomerProfileGuardService } from './shared/guard/customer-profile.guard';
import { AllNotificationComponent } from './shared/components/all-notification/all-notification.component';
import { CampaignEmailComponent } from './adhoc-invoice/campaign-email/campaign-email.component';
import { ManualNavigationGuard } from './shared/guard/manual-navigation.guard';
import { AllVisitorsComponent } from './customer-dashboard/customer-pages/visitors/all-visitors/all-visitors.component';
import { NotificationEmailDashboardComponent } from './user-settings/user_settings_dashboard/notification-email-dashboard/notification-email-dashboard.component';

const routes: Routes = [
  { path: 'fullfillment', redirectTo: 'fullfillment/provision-view', pathMatch: 'full', data: { breadcrumb: 'Fullfillment' } },
  {
    path: '',
    component: ContentComponent,
    data: { breadcrumb: 'Home' }, // Global breadcrumb for root path
    children: [
      { path: "", redirectTo: 'dashboard', pathMatch: 'full', data: { breadcrumb: 'Dashboard' } },

      // Lazy loaded component or module
      {path: "dashboard", canActivate: [AuthGuardService, ManualNavigationGuard], loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),data: { breadcrumb: 'Dashboard' }},
      {path: "search-result", canActivate: [AuthGuardService, ManualNavigationGuard], loadChildren: () => import("./search-result-page/search-result-page-routing.module").then((m) => m.SearchResultPageRoutingModule), data: { breadcrumb: 'Search Results' }},
      {path: 'review', canActivate: [AuthGuardService,ManualNavigationGuard], loadChildren: () => import("./review/review.module").then((m) => m.ReviewModule), data: { breadcrumb: 'Review' }},
      {path: 'fullfillment', canActivate: [AuthGuardService,ManualNavigationGuard], loadChildren: () => import("./fullfillment/fullfillment.module").then((m) => m.FullfillmentModule), data: { breadcrumb: 'Fullfillment' }},
      {path: 'awaiting-shipment', canActivate: [AuthGuardService,ManualNavigationGuard], loadChildren: () => import("./awaiting-shipment/awaiting-shipment.module").then((m) => m.AwaitingShipmentModule),data: { breadcrumb: 'Order' }},
      {path: 'porting', canActivate: [AuthGuardService,ManualNavigationGuard], loadChildren: () => import("./portIn-out/port.module").then((m) => m.PortModule), data: { breadcrumb: 'Porting' }},
      {path: "customer-dashboard", canActivate: [AuthGuardService, CustomerProfileGuardService,ManualNavigationGuard], loadChildren: () => import("./customer-dashboard/customer-dashboard.module").then((m) => m.CustomerDashboardModule),data: { breadcrumb: 'Customer Dashboard',isCustomerProfilePage:true }},
      {path: "ticket-system", canActivate: [AuthGuardService,ManualNavigationGuard], loadChildren: () => import("./ticket-system/ticket-system.module").then((m) => m.TicketSystemModule), data: { breadcrumb: 'Ticket System' }},
      {path: "reports", canActivate: [AuthGuardService,ManualNavigationGuard], loadChildren: () => import("./reports/reports.module").then((m) => m.ReportsModule), data: { breadcrumb: 'Reports' }},
      {path: "subscriptions", canActivate: [AuthGuardService,ManualNavigationGuard], loadChildren: () => import("./subscriptions/subscriptions.module").then((m) => m.SubscriptionsModule), data: { breadcrumb: 'Subscriptions' }},
      { path: "system-monitor",loadChildren: () => import("./system-monitor/system-monitor.module").then((m) => m.SystemMonitorModule),  data: { breadcrumb: 'System Monitor' }},
      {path: "scheduled-downgrades",canActivate: [AuthGuardService,ManualNavigationGuard],loadChildren: () => import("./scheduled-downgrades/scheduled-downgrades.module").then((m) => m.ScheduledDowngradeModule),data: { breadcrumb: 'Scheduled Downgrades' }},
      {path: "inventory",canActivate: [AuthGuardService,ManualNavigationGuard],loadChildren: () => import("./inventory/inventory.module").then((m) => m.InventoryModule),data: { breadcrumb: 'Inventory' }},
      {path: "excutive-dashboard",canActivate: [AuthGuardService,ManualNavigationGuard],data: { breadcrumb: 'Executive Dashboard' },loadChildren: () => import("./excutive-dashboard/my-dashboard.module").then((m) => m.MyDashboardModule),},
      {path: "add-member-group",canActivate: [AuthGuardService,ManualNavigationGuard],loadChildren: () => import("./add-member-group/add-member-group.module").then((m) => m.AddMemberGroupModule),data: { breadcrumb: 'Add Member Group' }},
      {path: "profile",canActivate: [AuthGuardService,ManualNavigationGuard],loadChildren: () => import("./profile/profile.module").then((m) => m.ProfileModule), data: { breadcrumb: 'Profile' },},
      { path: "bulk-report",canActivate: [AuthGuardService,ManualNavigationGuard],loadChildren: () => import("./bulk-reports/bulk-reports.module").then((m) => m.BulkReportsModule),data: { breadcrumb: 'Bulk Reports' },},
      { path: "bulk-process", canActivate: [AuthGuardService,ManualNavigationGuard], loadChildren: () => import("./bulk-users/bulk-users.module").then((m) => m.BulkUsersModule), data: { breadcrumb: 'Bulk Process' },},
      {path: "recent-customer-searches",canActivate: [AuthGuardService,ManualNavigationGuard],loadChildren: () => import("./recent-customer-searches/recent-customer-searches.module").then((m) => m.RecentCustomerSearchesModule),data: { breadcrumb: 'Recent Customer Searches' },},
      { path: "new-reports", canActivate: [AuthGuardService,ManualNavigationGuard], loadChildren: () => import("./reports-new/reports.module").then((m) => m.ReportsNewModule), data: { breadcrumb: 'Analytics Reports' },},
      { path: "transaction", canActivate: [AuthGuardService,ManualNavigationGuard], loadChildren: () => import("./transactions/transaction.module").then((m) => m.TransactionModule), data: { breadcrumb: 'Transaction' }, },
      {path: "renewal-reports",canActivate: [AuthGuardService,ManualNavigationGuard],loadChildren: () => import("./renewal-reports/renewal-reports.module").then((m) => m.RenewalReportsModule),data: { breadcrumb: 'Renewal Reports' },},
      {path: "customer-email",canActivate: [AuthGuardService,ManualNavigationGuard],loadChildren: () => import("./customer-email/customer-email.module").then((m) => m.CustomerEmailModule),data: { breadcrumb: 'Customer Email' },},
      {path: "adhoc-invoice",canActivate: [AuthGuardService,ManualNavigationGuard],loadChildren: () => import("./adhoc-invoice/adhoc-invoice.module").then((m) => m.AdhocInvoiceModule),data: { breadcrumb: 'Ad-hoc Invoice' },},
      {path: 'user-settings',loadChildren: () => import('./user-settings/user-settings.module').then(m => m.UserSettingsModule),canActivate: [AuthGuardService]},

      // Eagerly loaded component or module
      {path:'campaign-email',component:CampaignEmailComponent,data: { breadcrumb: 'Campaign Email' },},
      {path: 'all-notification',component:AllNotificationComponent ,data: { breadcrumb: 'All Notification' },},
      { path: 'auth/login',component: LoginComponent,data: { breadcrumb: 'Login' },},
      { 
        path: 'unauthorized', 
        loadChildren: () => import("./shared/unauthorized-landing-page/unauthorized-landing-page-routing.module").then((m) => m.UnauthorizedLandingPageRoutingModule), 
        data: { breadcrumb: 'Unauthorized' } 
      },
      {
        path: 'all-visitors',
        component:AllVisitorsComponent ,
        data: { breadcrumb: 'All Visitors' },
      },
      { 
        path: 'notification-email-tem', 
        component: NotificationEmailDashboardComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Notification Email template'} 
      },
      {
        path: '**',
        redirectTo: '/unauthorized', 
        data: { breadcrumb: 'Dashboard' }
      }
    ]

  },

];



@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'disabled',
    scrollPositionRestoration: 'disabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
