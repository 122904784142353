import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { LoaderService } from './loader.service'; // Import LoaderService
import { CommonService } from './common.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private authService: AuthService, private localStorage: LocalStorageService,private commonService:CommonService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('/login')) {
      return next.handle(req); // Bypass for login URL
    }

    const token = this.getTokenBasedOnUrl(req.url);
    if (token) {
      req = this.addToken(req, token); // Add token to the request
    }

    
  return next.handle(req).pipe(
    catchError(error => {

      if (error instanceof HttpErrorResponse ) {
        switch (error.status) {
          case 400:
            this.commonService.showToast('error', 'Bad Request', 'Invalid request parameters.');
            break;
          case 401:
            
            if (req.url.includes('/api/CAS/tokenRefresh')) {
              this.commonService.showToast('error', 'Session Expired', 'Your session has expired. Please log in again.');
              this.authService.logout();
              return throwError(error);
            }

            return this.handle401Error(req, next);
        }
      }

      return throwError(error);
    })
  );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      }
    });
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
  
      return this.authService.generateTokenByRefereshToken().pipe(
        switchMap((res: any) => {
  
          this.isRefreshing = false;
          this.localStorage.store(this.getCompanySpecificKey('Token'), res.data.Token);
          this.localStorage.store(this.getCompanySpecificKey('Refreshtoken'), res.data.data[0].refreshToken);
  
          this.refreshTokenSubject.next(res.data.Token);
          return next.handle(this.addToken(req, res.data.Token));
        }),
        catchError((error) => {
          this.isRefreshing = false;
  
          this.commonService.showToast('error', 'Session Expired', 'Your session has expired. Please log in again.');
          this.authService.logout();
          return throwError(error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => next.handle(this.addToken(req, token!)))
      );
    }
  }
  

  private getTokenBasedOnUrl(url: string): string | null {
    const currentCompany = localStorage.getItem("currentCompany");
    if (currentCompany) {
      return this.localStorage.retrieve(`${currentCompany}-Token`); // Safely retrieve token based on company
    }
    return null;
  }

  getCompanySpecificKey(key: string): string {
    const currentCompany = localStorage.getItem('currentCompany') || 'unity';
    return `${currentCompany}-${key}`;
  }
}
