<form [formGroup]="ticketForm" (ngSubmit)="onSubmit()" class="mt-3 custom-form-width ">
    <div class="d-flex justify-content-between align-items-center  rounded px-4 py-2 form-background mb-3 right-sidebar">
        <div class="right-sidebar ">
            <div class="sidebar-header bg-light text-dark ">
                <div *ngIf="!isEditingTitle && ticketForm.get('Title')?.value; else titleInput" class="mb-2"
                (click)="startEditingTitle()">
                <h4>
                    {{ ticketForm.get('Title')?.value }}
                </h4>
              </div>
        <ng-template #titleInput>
            <div class="w-100 mb-2">
                <h5 *ngIf="this.mainTask?.Title">
                    Creating sub ticket for {{ this.mainTask?.Title}}
                </h5>
                <input #inputRef class="comment-input mb-0" rows="2" placeholder="Add a Ticket Title... *"
                    formControlName="Title" (focus)="startEditingTitle()" (blur)="stopEditingTitle()"/>
            </div>
        </ng-template>
                <button class="close-btn" (click)="closeSidebar()">
                    <app-feather-icons [icon]="'x'" class="close-icon"></app-feather-icons>
                </button>
            </div>
            <div class=" row mt-3 custom-form-width">
                <div class="bg-light  pb-2 rounded px-4 py-2 ">
                    <div class="d-flex justify-content-between align-items-center rounded p-2 mb-3 form-background">
                        <h5 class="m-0 text-dark" style="letter-spacing: 2px;">Ticket Details</h5>
                      </div>
                    
                     
                    <!-- Assignee Section -->
                    <div class="form-group d-flex gap-2 mb-1">
                        <label class="col-md-3 p-0">Assign Ticket To </label>
                        <div class="col-md-5 pe-0 d-flex align-items-center ">
                            <div class="form-check form-check-inline pe-3 ps-0 d-flex align-items-center">
                                <input class="form-check-input" type="radio" formControlName="AssignmentType" id="inlineRadio1"
                                    value="AGENT" (change)="onAssignmentTypeChange()">
                                <label class="form-check-label mb-0 mt-1 ms-1" for="inlineRadio1">User</label>
                            </div>
                            <div class="form-check form-check-inline pe-3 d-flex align-items-center">
                                <input class="form-check-input" type="radio" formControlName="AssignmentType" id="inlineRadio2"
                                    value="GROUP" (change)="onAssignmentTypeChange()">
                                <label class="form-check-label mb-0 mt-1 ms-1" for="inlineRadio2">Group</label>
                            </div>
                        </div>
                        
                    </div>
        
                    <div class="section assignee-section row d-flex align-items-center mb-1">
                        <label class="col-md-3">Assignee </label>
                        <div class="col-md-6 d-flex align-items-center">
                            <div role="button" aria-label="Toggle assignee popover" aria-haspopup="listbox" class="icon-wrapper"
                                aria-disabled="false" tabindex="0">
                                <div class="AvatarPlaceholder">
                                    <svg class="MiniIcon UserMiniIcon" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                                        <path
                                            d="M12,14c-3.859,0-7-3.14-7-7S8.141,0,12,0s7,3.14,7,7-3.141,7-7,7Zm0-12c-2.757,0-5,2.243-5,5s2.243,5,5,5,5-2.243,5-5-2.243-5-5-5Zm10,21v-2c0-2.757-2.243-5-5-5H7c-2.757,0-5,2.243-5,5v2c0,.552,.447,1,1,1s1-.448,1-1v-2c0-1.654,1.346-3,3-3h10c1.654,0,3,1.346,3,3v2c0,.552,.447,1,1,1s1-.448,1-1Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <ng-select class="w-100 mb-2"
                                *ngIf="ticketForm.get('AssignmentType')?.value === 'AGENT' ||ticketForm.get('AssignmentType')?.value === 'USER'"
                                formControlName="AgentID" [multiple]="false">
                                <ng-option [value]="item.id" *ngFor="let item of allAgents">
                                    <div class="avatar-dropdown">
                                        <div class="avatar-circle mx-2" [style.background-color]="getColor(item.name)">
                                            {{ getInitials(item.name) }}
                                        </div>
                                        <span class="user-name">{{ item.name }}</span>
                                    </div>
                                </ng-option>
                            </ng-select>
                            <ng-select class="w-100 mb-2" *ngIf="ticketForm.get('AssignmentType')?.value === 'GROUP'"
                                formControlName="GroupID" [multiple]="false">
                                <ng-option [value]="item.id" *ngFor="let item of allGroups">
                                    <div class="avatar-dropdown">
                                        <div class="avatar-circle" [style.background-color]="getColor(item.Name)">
                                            {{ getInitials(item.Name) }}
                                        </div>
                                        <span class="user-name">{{ item.Name }}</span>
                                    </div>
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
        
                    <div class="section assignee-section row d-flex align-items-center mb-1">
                        <label class="col-md-3">Due Date </label>
                        <div class="col-md-6 d-flex align-items-center">
                            <div role="button" aria-label="Toggle assignee popover" aria-haspopup="listbox" class="icon-wrapper"
                                aria-disabled="false" tabindex="0">
                                <div class="AvatarPlaceholder">
                                    <svg class="MiniIcon UserMiniIcon" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                                        <path
                                            d="M12,14c-3.859,0-7-3.14-7-7S8.141,0,12,0s7,3.14,7,7-3.141,7-7,7Zm0-12c-2.757,0-5,2.243-5,5s2.243,5,5,5,5-2.243,5-5-2.243-5-5-5Zm10,21v-2c0-2.757-2.243-5-5-5H7c-2.757,0-5,2.243-5,5v2c0,.552,.447,1,1,1s1-.448,1-1v-2c0-1.654,1.346-3,3-3h10c1.654,0,3,1.346,3,3v2c0,.552,.447,1,1,1s1-.448,1-1Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <input id="bsdatepicker" type="text" class="form-control mb-2" #dp="bsDatepicker" bsDatepicker
                                formControlName="DueDate" placeholder="mm-dd-yy">
                            <!-- <input type="date" placeholder="mm-dd-yy" class="form-control mb-2" formControlName="DueDate" /> -->
                        </div>
                    </div>
        
                        <div class="section assignee-section row d-flex align-items-center mb-1">
                                <label class="col-md-3">Status</label>
                                <ng-select class="col-md-6 d-flex align-items-center" formControlName="StatusID" [multiple]="false">
                                    <ng-option [value]="item.id" *ngFor="let item of allStatusList">
                                        <span class="user-name">{{ item.Name }}</span>
                                    </ng-option>
                                </ng-select>
                        </div>
                        <div class="section assignee-section row d-flex align-items-center mb-1">
                                <label class="col-md-3">Ticket Type</label>
                                <ng-select class="col-md-6 d-flex align-items-center" formControlName="TicketTypeID" [multiple]="false">
                                    <ng-option *ngFor="let ticket of allTicketTypesList" [value]="ticket.id">
                                        <span class="user-name">{{ ticket.Name }}</span>
                                    </ng-option>
                                </ng-select>
                        </div>
                        <div class="section assignee-section row d-flex align-items-center mb-1">
                                <label class="col-md-3">Priority</label>
                                <ng-select class="col-md-6 d-flex align-items-center" formControlName="PriorityId" [multiple]="false">
                                    <ng-option [value]="item.id" *ngFor="let item of priorityList">
                                        <span>{{ item.Name }}</span>
                                    </ng-option>
                                </ng-select>
                        </div>
                    
                        <div class="section description-section mt-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <label class="col-md-3 p-0"><b>Description</b></label>
                                <app-feather-icons *ngIf="!this.descriptionConfig.showToolbar" class="toolbar-icon text-dark" [icon]="'eye'"
                                    (click)="toggleToolbar('descriptionConfig')"></app-feather-icons>
                                <app-feather-icons *ngIf="this.descriptionConfig.showToolbar" class="toolbar-icon text-dark"
                                    [icon]="'eye-off'" (click)="toggleToolbar('descriptionConfig')"></app-feather-icons>
                            </div>
                        

                        
                            <!-- Editable Description Field -->
                            <angular-editor 
                                [placeholder]="'Enter text here...'" 
                                formControlName="Description" 
                                class="text-dark"
                                [config]="descriptionConfig">
                            </angular-editor>
                        
                            <!-- Attachments Section -->
                            <div *ngIf="attachments?.length > 0">
                                <div class="d-flex justify-content-center px-3 py-1" *ngFor="let img of this.attachments">
                                    <img class="w-100 attachments" [src]="img.FileName">
                                </div>
                            </div>
                        </div>
                        
                </div>
            </div>

            <div class=" row mt-3 custom-form-width">

                <div class="bg-light p-0 pb-2 rounded">
                    <div class="row mt-3 custom-form-width">
                        <div class="d-flex justify-content-between align-items-center rounded p-2 mb-3 form-background">
                            <h5 class="m-0 text-dark" style="letter-spacing: 2px;">Customer Details</h5>
                          </div>
                          <div class="col-md-6 position-relative">
                            <label for="CustomerId">Customer Id <span style="color: red;">*</span></label>
                            <input class="form-control" (blur)="getCustomerData()" type="text" formControlName="CustomerId" placeholder="Customer Id">
                            <span *ngIf="isCustomerFound" class="position-absolute" style="top:65%; right: 20px; transform: translateY(-50%);">
                              <i class="fa fa-check text-success"></i>
                            </span>
                            <span *ngIf="showcrossIcon" class="position-absolute" style="top: 44%; right: 20px; transform: translateY(-50%);">
                              <i class="fa fa-times text-danger" aria-hidden="true"></i>
                            </span>
                          </div>
                          <div class="col-md-6">
                            <label for="CustomerName">Customer Name <span style="color: red;">*</span></label>
                            <input class="form-control" type="text" formControlName="CustomerName" placeholder="Customer Name">
                          </div>
                          
                          <!-- Conditionally display extra fields for Refund or Cancel Order -->
                          <ng-container *ngIf="isRefundOrCancelOrder()">

                            <div class="col-md-6 mb-3">
                            <label for="ContactNumber">Contact Number<span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="ContactNumber" placeholder="Contact Number">
                            </div>
                            <div class="col-md-6 mb-3">
                              <div class="form-group">
                            <label for="AlternateNumber">Alternate Phone Number </label>
                                <input type="text" class="form-control" formControlName="AlternateNumber" placeholder="Alternate Phone Number ">
                              </div>
                            </div>
                            <div class="col-md-6 mb-3">
                            <label for="TAT">Estimated Processing Time <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" [value]="getTAT('Refund or Cancel Order')" readonly placeholder="TAT">
                            </div>
                          </ng-container>
                          <ng-container *ngIf="isNetworkIssue()">
                            <div class="col-md-6 mb-3">
                              <label for="DeviceName">Device Name <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" formControlName="DeviceName" placeholder="Device Name">
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="IMEINumber">IMEI Number <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" formControlName="IMEINumber" placeholder="IMEI Number">
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="TAT">Estimated Processing Time <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" [value]="getTAT('Network Issue')" readonly placeholder="TAT">
                            </div>
                          </ng-container>

                          <ng-container *ngIf="isResetCallerID() || isResetVoicemail() || isReplacementCharger()">
                            <div class="col-md-6 mb-3">
                            <label for="ContactNumber" style="white-space:nowrap">Contact Number<span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="ContactNumber" placeholder="Contact Number">
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="TAT">Estimated Processing Time <span style="color: red;">*</span></label>
                              <input *ngIf="isResetCallerID()" type="text" class="form-control" [value]="getTAT('Reset Caller ID')" readonly placeholder="TAT">
                            <input *ngIf="isResetVoicemail()" type="text" class="form-control" [value]="getTAT('Reset Voicemail')" readonly placeholder="TAT">
                            <input *ngIf="isReplacementCharger()" type="text" class="form-control" [value]="getTAT('Replacement Charger')" readonly placeholder="TAT">
                            </div>
                            

                          </ng-container>

                          <ng-container *ngIf="isNumberPort()">
                            <div class="col-md-6 mb-3">
                              <label for="PhoneNumber">Phone Number <span style="color: red;">*</span> </label>
                              <input type="text" class="form-control" formControlName="PhoneNumber" placeholder="Phone Number">
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="AccountNumber">Account Number <span style="color: red;">*</span> </label>
                              <input type="text" class="form-control" formControlName="AccountNumber" placeholder="Account Number">
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="PinNumber">Account Pin Number <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" formControlName="PinNumber" placeholder="Account Pin Number">
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="ServiceProvider">Service Provider Name <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" formControlName="ServiceProvider" placeholder="Service Provider Name">
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="zipCode">Zip Code <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code">
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="TAT">Estimated Processing Time <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" [value]="getTAT('Number Port')" readonly placeholder="TAT">
                            </div>
                          </ng-container>

                          <ng-container *ngIf="isESIM()">
                            <div class="col-md-6 mb-3">
                              <label for="mdn">MDN <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" formControlName="MDN" placeholder="MDN">
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="makeAndModel">Make and Model <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" formControlName="MakeAndModel" placeholder="Make and Model">
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="imeiNumber">IMEI Number <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" formControlName="IMEINumber" placeholder="IMEI Number">
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="tat">Estimated processing Time <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" [value]="getTAT('ESIM')" readonly placeholder="TAT">
                            </div>
                          </ng-container>

                          <ng-container *ngIf="isSimReplacementSameAddress()">
                            <div class="col-md-6 mb-3">
                              <label for="tat">Estimated processing Time <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" [value]="getTAT('Sim Replacement Same Address')" readonly  placeholder="TAT">
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="ContactNumber">
                                  Contact Number<span style="color: red;">*</span>
                                </label>
                                <input type="text" class="form-control" formControlName="ContactNumber" placeholder="Contact Number">
                            </div>
                          </ng-container>
                          <ng-container *ngIf="isSimReplacementNewAddress()">
                            <div class="col-md-6 mb-3">
                              <label for="tat">Estimated processing Time <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" [value]="getTAT('Sim Replacement New Address')" readonly  placeholder="TAT">
                            </div>
                            <div class="col-md-6 mb-3">
                            <label for="ContactNumber" style="white-space:nowrap">Contact Number<span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="ContactNumber" placeholder="Contact Number">
                            </div>
                          </ng-container>
                          <!-- Other fields -->
                          <ng-container *ngIf="isOthers()">
                            <!-- Display all fields dynamically -->
                            <ng-container *ngFor="let field of filteredTicketFields; let i = index">
                              <div class="col-md-6 mb-3">
                                <div class="form-group">
                                  <ng-template #inputField>
                                    <input type="text" class="form-control" formControlName="{{ field.control }} *" placeholder="{{ field.label }}">
                                  </ng-template>
                                </div>
                              </div>
                            </ng-container>
                            <div class="col-md-6 mb-3">
                              <label for="tat">Estimated processing Time <span style="color: red;">*</span></label>
                              <input type="text" class="form-control" [value]="getTAT('Others')" readonly placeholder="TAT">
                              </div>
                              <div class="col-md-6 mb-3">
                              <label for="ContactNumber" style="white-space:nowrap">Contact Number<span style="color: red;">*</span></label>
                                  <input type="text" class="form-control" formControlName="ContactNumber" placeholder="Contact Number">
                              </div>
                          </ng-container>

                          <div class="div text-dark custom-card"  *ngIf="existingAddress">
                            <h5>Existing Address</h5>
                            <p
                        class="text-muted mt-1 mb-0"
                        style=" white-space: break-spaces; word-break: break-word;"
                      >
                        {{ existingAddress }}
                      </p>
                          </div>
                    </div>
                    <form [formGroup]="ticketForm" class="mt-3 custom-form-width" (ngSubmit)="onSubmit()" *ngIf="isSimReplacementNewAddress()" >
                          <div class="d-flex justify-content-between align-items-center rounded p-2 mb-3 form-background">
                            <h5 class="m-0 text-dark" style="letter-spacing: 2px;">Address Details</h5>
                          </div>
                          <label for="address1">Address 1 <span style="color: red;">*</span></label>
                          <input 
                            id="address1" 
                            formControlName="address1" 
                            class="form-control mb-2" 
                            placeholder="Address Line 1 " 
                            style="border: 1px solid #ddd;"
                          >
                          <label for="address2">Address 2</label>
                          <input 
                            id="address2" 
                            formControlName="address2" 
                            class="form-control mb-2" 
                            placeholder="Apartment, suite, etc. (optional)" 
                            style="border: 1px solid #ddd;"
                          >
                        <div class="row mb-2">
                          <div class="col-md-6">
                            <label for="city">City <span style="color: red;">*</span></label>
                            <input 
                              id="city" 
                              formControlName="city" 
                              class="form-control" 
                              placeholder="City" 
                            style="border: 1px solid #ddd;"
                            >
                          </div>
                          <div class="col-md-6">
                            <label for="state">State <span style="color: red;">*</span></label>
                            <input 
                              id="state" 
                              formControlName="state" 
                              class="form-control" 
                              placeholder="State" 
                            style="border: 1px solid #ddd;"
                            >
                          </div>
                        </div>
                          <label for="zip">ZIP Code <span style="color: red;">*</span></label>
                          <input 
                            id="zip" 
                            formControlName="zipCode" 
                            class="form-control" 
                            placeholder="ZIP Code" 
                            style="border: 1px solid #ddd;"
                          >
            
                        <!-- Extra Fields -->
        
            
        
                      </form>

                </div>

    
    
                
                <div class="d-flex py-2" *ngIf="!this.isSubTicket && !isSubticketMain">
                    <div class="ButtonTrain-item">
                        <div role="button" class="add-subticket-btn" tabindex="0" (click)="onAddSubTicketClick()">
                            <svg class="MiniIcon ThemeableRectangularButtonPresentation-leftIcon PlusMiniIcon"
                                viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                                <path
                                    d="M10,10V4c0-1.1,0.9-2,2-2s2,0.9,2,2v6h6c1.1,0,2,0.9,2,2s-0.9,2-2,2h-6v6c0,1.1-0.9,2-2,2s-2-0.9-2-2v-6H4c-1.1,0-2-0.9-2-2s0.9-2,2-2H10z">
                                </path>
                            </svg>Add sub ticket
                        </div>
                    </div>
                </div>

                <div class="mt-3" *ngIf="subtaskList?.length>0">
                    <label class="p-0">Sub Tickets</label>
                    <div class="d-flex flex-column gap-1 ">
                        <div class=" ItemRow" *ngFor="let item of subtaskList" (click)="setChildrenToMain(item)">
                            <div>
                                <i class="fa fa-check-circle-o"></i>
                                <span class="p-3">{{item.Title}}</span>
                            </div>
                            <div class="hover-show d-flex align-items-center gap-2 ">
                                <div class="AvatarPlaceholder">
                                    <svg class="MiniIcon UserMiniIcon" viewBox="0 0 24 24" aria-hidden="true"
                                        focusable="false">
                                        <path
                                            d="M12,14c-3.859,0-7-3.14-7-7S8.141,0,12,0s7,3.14,7,7-3.141,7-7,7Zm0-12c-2.757,0-5,2.243-5,5s2.243,5,5,5,5-2.243,5-5-2.243-5-5-5Zm10,21v-2c0-2.757-2.243-5-5-5H7c-2.757,0-5,2.243-5,5v2c0,.552,.447,1,1,1s1-.448,1-1v-2c0-1.654,1.346-3,3-3h10c1.654,0,3,1.346,3,3v2c0,.552,.447,1,1,1s1-.448,1-1Z">
                                        </path>
                                    </svg>
                                </div>
                                <!-- <div class="avatar-dropdown">
                                    <div class="avatar-circle" [style.background-color]="getColor(item.name)">
                                        {{ getInitials(item.name) }}
                                    </div>
                                    <span class="user-name">{{ item.name }}</span>
                                </div> -->
                                <div class="AvatarPlaceholder">
                                    <svg class="MiniIcon CalendarMiniIcon" viewBox="0 0 24 24" aria-hidden="true"
                                        focusable="false">
                                        <path
                                            d="M19.1,2H18V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H8V1c0-0.6-0.4-1-1-1S6,0.4,6,1v1H4.9C2.2,2,0,4.2,0,6.9v12.1  C0,21.8,2.2,24,4.9,24h14.1c2.7,0,4.9-2.2,4.9-4.9V6.9C24,4.2,21.8,2,19.1,2z M4.9,4H6v1c0,0.6,0.4,1,1,1s1-0.4,1-1V4h8v1  c0,0.6,0.4,1,1,1s1-0.4,1-1V4h1.1C20.7,4,22,5.3,22,6.9V8H2V6.9C2,5.3,3.3,4,4.9,4z M19.1,22H4.9C3.3,22,2,20.7,2,19.1V10h20v9.1  C22,20.7,20.7,22,19.1,22z">
                                        </path>
                                    </svg>
                                </div>
                                <i class="fa fa-angle-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
    
    
    
            </div>
            <div class="section comments-section mt-2 bg-light  text-dark">
                <div class="comments-list sidebar-footer bg-light text-dark">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" (navChange)="onNavChange($event)"
                        class="nav-tabs mb-2">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>Comments</a>
                            <ng-template ngbNavContent>
                                <div *ngFor="let comment of ticketForm.get('comments')?.value || []"
                                    class="comment-item mb-3">
                                    <div class="d-flex align-items-center gap-3">
                                        <div class="avatar-dropdown">
                                            <div class="avatar-circle m-0"
                                                [style.background-color]="getColor(comment?.created_by_user?.name)">
                                                {{ getInitials(comment?.created_by_user?.name) }}
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column  gap-1 ">
                                            <div class="d-flex align-items-center gap-2">
                                                <span class="fw-semibold">{{ comment?.created_by_user?.name }}</span>
                                                <span class="comment-date text-muted">{{ formatDate(comment?.CreatedDate)
                                                    }}</span>
                                            </div>
                                            <span [innerHTML]="comment?.CommentText"></span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="ticketForm.get('comments')?.value.length==0"
                                    class="d-flex p-3 justify-content-center align-items-center">
                                    <span>No comments found</span>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>All activity</a>
                            <ng-template ngbNavContent>
                                <div *ngFor="let item of activityList || []" class="comment-item mb-2">
                                    <div class="d-flex align-items-center gap-3">
                                        <div class="avatar-dropdown">
                                            <div class="avatar-circle m-0" [style.background-color]="getColor(item?.User)">
                                                {{ getInitials(item?.User) }}
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column  gap-1 ">
                                            <div class="d-flex align-items-center gap-2">
                                                <span class="fw-semibold">{{ item?.User }}</span>
                                                <span class="comment-date text-muted">{{item?.CreatedDate }}</span>
                                            </div>
                                            <span [innerHTML]="item?.Activity" *ngIf="item.Type=='activity'"></span>
                                            <span [innerHTML]="item?.Comment" *ngIf="item.Type=='comment'"></span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activityList.length==0"
                                    class="d-flex p-3 justify-content-center align-items-center">
                                    <span>No activity found</span>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" cla ss="mt-2"></div>
                </div>
                <div class="sticky-editor-wrapper bg-light text-dark">
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="col-md-3 p-0">
                            {{ isTicketClosed ? 'Closing Comment' : 'Comments' }}
                            <span *ngIf="isTicketClosed" class="text-danger">*</span>
                        </label>
                        <app-feather-icons *ngIf="!this.commentsConfig.showToolbar" class="toolbar-icon" [icon]="'eye'"
                            (click)="toggleToolbar('commentsConfig')"></app-feather-icons>
                        <app-feather-icons *ngIf="this.commentsConfig.showToolbar" class="toolbar-icon" [icon]="'eye-off'"
                            (click)="toggleToolbar('commentsConfig')"></app-feather-icons>
                    </div>
                    <angular-editor [placeholder]="'Enter text here...'" formControlName="CommentText"
                        [config]="commentsConfig"></angular-editor>
                    <button class="btn btn-primary btn-sm mt-2 cmtbtn" type="button" (click)="onCommentAdd()"
                        [disabled]="!ticketId" [ngClass]="{'cursor-disabled': !ticketId}">Comment</button>
                </div>
                <div class="d-flex gap-2 justify-content-end p-2">
                    <button class="btn-primary btn btn-sm mt-2" type="submit" >{{isEditing?"Save Changes":"Save"}}</button>
                </div>
            </div>
        </div>
    </div>

</form>