<div class="container mt-4">
  <!-- <h3 class="text-center">Refund Creation Wizard</h3> -->

  <div class="card">
    <div class="card-body">
      <!-- Step Indicator -->
      <ul class="nav nav-pills nav-justified mb-3">
        <li class="nav-item">
          <a class="nav-link" [class.active]="step === 1">Customer</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="step === 2">Refund Details</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="step === 3">Calculation</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="step === 4">Approval</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="step === 5">Submit</a>
        </li>
      </ul>

      <!-- Step 1: Customer Selection -->
      <div *ngIf="step === 1">
        <h5>Select Customer</h5>
      
        <!-- Search Input -->
        <input
          type="text"
          class="form-control"
          placeholder="Search by Account Number, Customer Name, Subscription ID, or MDN"
          (input)="searchCustomer($event)"
        />
      
        <!-- Summary Panel -->
        <div *ngIf="selectedCustomer" class="custom-card col-md-6 mt-3 p-3 d-flex justify-content-between">
          <div class="info-1">
          <h5>Customer Summary</h5>
          <p><strong>Name:</strong> {{ selectedCustomer.name }}</p>
          <p><strong>Account #:</strong> {{ selectedCustomer.accountNumber }}</p>
          <p><strong>Subscription ID:</strong> {{ selectedCustomer.subscriptionId }}</p>
          <p><strong>MDN:</strong> {{ selectedCustomer.mdn }}</p>
          </div>
          <div class="info">
    
          <p><strong>Current Plan:</strong> {{ selectedCustomer.plan }}</p>
          <p><strong>Balance:</strong> ${{ selectedCustomer.balance }}</p>
          
          <h6>Payment History</h6>
          <ul>
            <li *ngFor="let payment of selectedCustomer.paymentHistory">
              {{ payment }}
            </li>
          </ul>
          </div>
        </div>
      </div>

      <!-- Step 2: Refund Details -->
      <div *ngIf="step === 2">
        <h5>Refund Details</h5>
        <label>Refund Type</label>
        <select class="form-control" formControlName="refundType">
          <option value="">Select</option>
          <option value="prorated">Prorated</option>
          <option value="flat">Flat Amount</option>
          <option value="full">Full Refund</option>
          <option value="Usage-Based">Usage-Based</option>
        </select>

        <label>Reason</label>
        <select class="form-control" formControlName="reason">
          <option value="">Select</option>
          <option value="overcharge">Overcharge</option>
          <option value="misconfiguration">Plan Misconfiguration</option>
          <option value="service_failure">Service Failure</option>
        </select>

        <label>Notes (Optional)</label>
        <textarea class="form-control" formControlName="notes"></textarea>
      </div>

      <!-- Step 3: Calculation -->
      <div *ngIf="step === 3">
        <h5>Calculation Breakdown</h5>
        <label>Auto-Calculated Amount</label>
        <input
          type="text"
          class="form-control"
          formControlName="calculatedAmount"
          readonly
        />

        <label>Manual Adjustment</label>
        <input type="text" class="form-control" formControlName="manualAdjustment" />
      </div>

      <!-- Step 4: Approvals -->
      <div *ngIf="step === 4">
        <h5>Approval</h5>
        <label>Approver</label>
        <select class="form-control" formControlName="approver">
          <option value="">Select Approver</option>
          <option value="manager">Manager</option>
          <option value="finance">Finance Officer</option>
        </select>

        <label>Upload Attachment</label>
        <input type="file" class="form-control" formControlName="attachment" />
      </div>

      <!-- Step 5: Confirmation & Submission -->
      <div *ngIf="step === 5">
        <h5>Review & Submit</h5>
        <p><strong>Customer:</strong> {{ refundForm.value.customerSelection }}</p>
        <p><strong>Refund Type:</strong> {{ refundForm.value.refundType }}</p>
        <p><strong>Reason:</strong> {{ refundForm.value.reason }}</p>
        <p><strong>Notes:</strong> {{ refundForm.value.notes }}</p>
        <p><strong>Amount:</strong> {{ refundForm.value.calculatedAmount }}</p>
        <p><strong>Approver:</strong> {{ refundForm.value.approver }}</p>

        <button class="btn btn-success w-100" (click)="submitForm()">Submit Refund</button>
      </div>
    </div>

    <!-- Navigation Buttons -->
    <div class="card-footer d-flex justify-content-between">
      <div>
      <button class="custom-btn" (click)="prevStep()" [disabled]="step === 1">
        ← Back
      </button>
      </div>
      <div>
      <button class="custom-btn" (click)="nextStep()" *ngIf="step < 5">
        Next →
      </button>
      </div>
    </div>
  </div>
</div>
