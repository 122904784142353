import { data } from './../../excutive-dashboard/dashboard/data';
import { Component, HostListener, Input, OnChanges, OnInit, ViewChild,SimpleChanges } from '@angular/core';
import { RenewalServiceService } from '../service/renewal-service.service';
import { StateManagementService } from 'src/app/shared/services/state-management.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { SubscriptionService } from 'src/app/subscriptions/service/subscription.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-plan-renewal',
  templateUrl: './plan-renewal.component.html',
  styleUrls: ['./plan-renewal.component.scss'],
  animations:[fadeInOut]
})
export class PlanRenewalComponent implements OnInit, OnChanges {
  @Input() isCallFromPurchaseHistory:boolean;
  @Input() currentCustomerId:any;
 @ViewChild("renewConfirmationModal", { static: true })
  renewConfirmationModal: any;
  @ViewChild("suspensionConfirmationModal", { static: true })
  suspensionConfirmationModal: any;
  @ViewChild('poConfirmationModal', { static: true }) poConfirmationModal: any;
  highlightedRowIndex: number | null = null;
  bsRangeValue: any[];
  dateRange:any[];
  header: any = [
    { key: "CustomerId", display: "Customer Id" },
    { key: "SubscriptionId", display: "Subscription Id" },
    { key: "Customer", display: "Customer" },
    { key: "CustomerStatus", display: "Account Status" },
    { key: "SubscriptionStatus", display: "Subscription Status" },
    { key: "NextBillingDate", display: "Next Billing Date" },
    { key: "NextRenewalDate", display: "Next Renewal Date" },
    { key: "dueDate", display: "Due date" },
    { key: "po_status", display: "Status" },
    { key: "po_action", display: "PO action" },
    { key: "remarks", display: "Remarks" },
    { key: "Actions", display: "Actions" },
  ];
  filters = [
    { key: "customerStatus", display: "Customer Status", name: "Name", code: "code" },
    { key: "subscriptionStatus", display: "Subscription status", name: "code", code: "id" },
    { key: "poAction", display: "Po Action", name: "Name", code: "id" },
    // { key: "poSubAction", display: "Po Sub Action", name: "Name", code: "id" },
  ];
  paymentStatus: any = {
    Created: "#FFC107", // Yellow - Represents the initial state
    Failed: "#F44336", // Red - Represents an error or declined status
    Captured: "#2196F3", // Blue - Represents progress or authorization
    Settled: "#4CAF50", // Green - Represents success and completion
    Cancelled: "#9E9E9E", // Grey - Represents cancellation or no action needed
    NoDue: "#9C27B0",
    Draft: "#eb77ff",
  };

  renewalStatus: any = {
    Subscription: "#4CAF50", // Green - Indicates a new or ongoing subscription.
    Renewal: "#2196F3", // Blue - Represents continuity or refreshment of the service.
    PlanDowngrade: "#FFC107", // Yellow - Indicates a downgrade or cautionary change.
    Suspension: "#FF5722", // Orange - Signifies a temporary stop or alert.
    DeActivation: "#9E9E9E", // Grey - Represents deactivation or a neutral state.
  };

  poStatus: any = {
    New: "#03A9F4", // Light Blue - Represents a fresh state.
    Pending: "#FFC107", // Yellow - Indicates waiting or cautionary state.
    Progress: "#2196F3", // Blue - Represents active or ongoing processes.
    Hold: "#FF9800", // Orange - Signifies a paused or retry-needed state.
    Cancelled: "#9E9E9E", // Grey - Indicates no further action needed.
    Completed: "#4CAF50", // Green - Represents success and completion.
  };

  statusDescriptions: any = {
    poStatus: {
      New: "The PO is being prepared or just created.",
      Pending:
        "The payment of that PO has been Captured, waiting for the due date to proceed in Progress.",
      Progress: "The action is being performed.",
      Hold: "The payment of that PO is failed or declined, retry payment attempts required.",
      Cancelled: "The PO has been cancelled.",
      Completed:
        "The PO's order has been shipped or delivered. No further action required.",
    },
    paymentStatus: {
      Created: "The payment has been initiated.",
      Failed: "The payment has been failed or declined.",
      Captured:
        "The payment has been successfully authorized by the bank/card and captured by the gateway but still pending settlement.",
      Settled: "The payment successfully received.",
      Cancelled: "The PO has been cancelled, no payment required.",
      NoDue:"Advance Payment Received",
      Draft:"Payment in draft"
    },
    renewalStatus: {
      Subscription: "Indicates a new or ongoing subscription.",
      Renewal: "Represents continuity or refreshment of the service.",
      PlanDowngrade: "Indicates a downgrade or cautionary change.",
      Suspension: "Signifies a temporary stop or alert.",
      DeActivation: "Represents deactivation or a neutral state.",
    },
  };

  daysOptions = [
    { label: "Today", value: 1 },
    { label: "Tomorrow", value: 2 },
    { label: "Yesterday", value: -1 },
    { label: "3 Days", value: 3 },
    { label: "7 Days", value: 7 },
    { label: "15 Days", value: 15 },
    { label: "30 Days", value: 30 },
    { label: "Custom", value: 0 }, // -1 indicates custom
    { label: "No Filter Selection", value: 5 },
  ];

  showdaterange: any;

  rowDatas: any = [];
  logData: any;
  paymentData: any;
  invoiceData: any;
  customerDetails: any;
  action: any;
  dueDate: any;
  selectedCustomer: any;
  status: any = "New";
  showDatePicker: boolean;
  customerDropdown = new Set<number>();
  actionDropdown = new Set<number>();
  subscriptionDropdown = new Set<number>();
  days: any = -1;
  searchTerm: any = "";
  itemsPerPage: number = 50;
  totalItems: number;
  currentPage: number = 1;
  totalOrders: number = 0;
  startDate: any;
  endDate: any;
  isExportData: any = 0;
  isSkeletonLoader: boolean = true;
  summaryData: any = [];
  nextRenewalDate: any;
  showNextRenewalDate: boolean = true
  poDate: any;
  selectedValue: any;
  renewalTime: any = "onDate";
  subscriptionDetail: any;
  selectedCard:any
  activeCardId:any
  activeTabIndex: any;
  selectedFilters: any = [];
  paymentStatusData: any = [
   
    {
    "id": 2,
    "code": "Active",
    "Name": "Active",
    "Description": null,
    "Stage": 1,
    "IsActive": 1,
    'flag':'subscription',
},
{
  "id": 5,
  "code": "Pending",
  "Name": "Pending",
  "Description": null,
  "Stage": 3,
  "IsActive": 1
},
{
  "id": 1,
  "code": "Cancelled",
  "Name": "Cancelled",
  "Description": "",
  "Stage": 1,
  "IsActive": 1
},
];
  // New,Active,Suspend,Deactivated,Pending
  OrderStatusData: any = [
    {
      "id": 1,
      "code": "New",
      "Name": "New",
      "Description": "",
      "Stage": 1,
      "IsActive": 1,
      'flag':'customer',
  },
  {
      "id": 2,
      "code": "Active",
      "Name": "Active",
      "Description": null,
      "Stage": 1,
      "IsActive": 1
  },
  {
      "id": 3,
      "code": "Suspended",
      "Name": "Suspended",
      "Description": null,
      "Stage": 2,
      "IsActive": 1
  },
  {
      "id": 4,
      "code": "Deactivated",
      "Name": "Deactivated",
      "Description": null,
      "Stage": 3,
      "IsActive": 1
  },
  {
    "id": 5,
    "code": "Pending",
    "Name": "Pending",
    "Description": null,
    "Stage": 3,
    "IsActive": 1
},
  ];
  shipmentStatusData: any = [
    {
      "id": 2,
      "code": "Subscription",
      "Name": "Subscription",
      "Description": null,
      "Stage": 1,
      "IsActive": 1,
      "flag":'poAction'
  },
  {
      "id": 3,
      "code": "Renewal",
      "Name": "Renewal",
      "Description": null,
      "Stage": 2,
      "IsActive": 1
  },
  {
      "id": 4,
      "code": "PlanDowngrade",
      "Name": "PlanDowngrade",
      "Description": null,
      "Stage": 3,
      "IsActive": 1
  },
  {
    "id": 5,
    "code": "PlanChange",
    "Name": "PlanChange",
    "Description": null,
    "Stage": 3,
    "IsActive": 1
  },
  {
    "id": 1,
    "code": "Suspension",
    "Name": "Suspension",
    "Description": "",
    "Stage": 1,
    "IsActive": 1
  },
  {
    "id": 6,
    "code": "DeActivation",
    "Name": "DeActivation",
    "Description": "",
    "Stage": 1,
    "IsActive": 1
  },
  ];
  showFilterRow: boolean;
  customerStatus:any[]
  subscriptionStatus:any[]
  poAction:any[]
  private readonly uniqueKey = 'plan-renewal';

activeNestedTabIndex: any;
  constructor(
    private renewalServiceService: RenewalServiceService,
    private stateManagementService: StateManagementService,
    private commonService: CommonService,
    private customerProfileService: CustomerProfileService,
    private router: Router,
    private modalService: NgbModal,
    private http: HttpService,
    private subscriptionService: SubscriptionService
  ) { }
  @Input() key: number; // Accept the key as input

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['key']) {
    //   console.log('Key updated, reinitializing PlanRenewalComponent');
    //   this.reinitializeComponent();
    // }
  }
  async ngOnInit() {
    if(this.isCallFromPurchaseHistory){
      await this.callingCustomerSpecificRenewals(true,this.currentCustomerId);
      return;
    }
    console.log('customerId: ', this.currentCustomerId);
    this.renewalServiceService.activeTabIndex$.subscribe((index) => {
      this.activeTabIndex = index;
    });
  
    this.renewalServiceService.activeNestedTabIndex$.subscribe((index) => {
      this.activeNestedTabIndex = index;
    });
  
    if (
      !this.stateManagementService.restoreState(
        'renewal-reports',
        this.uniqueKey, // Pass the unique key here
        (state) => {
          this.rowDatas = state.items || [];
          this.highlightedRowIndex = state.selectedIndex ?? null; // Restore selected index
          this.isSkeletonLoader = false; // Stop loader
          this.totalOrders = state.filters.totalRecords;
          this.currentPage = state.filters.currentPage;
          this.itemsPerPage = state.filters.itemsPerPage;
          this.days = state.filters.selectedOption.value;
          this.searchTerm = state.filters.searchterm;
          console.log('Restoring state...', state);
          if (state.filters.showdaterange) {
            this.showdaterange = true;
            this.bsRangeValue = state.filters.dateRange;
          }
        }
      )
    ) {
      console.log('No state to restore, initializing defaults.');
      // If no saved state, fetch fresh data
      this.getPosReport();
    this.getRenewalSummaryData();
      return;
    }
    this.getRenewalSummaryData();
  }
  async handleFilterSelectionChange(e) {
    this.selectedFilters = e;
    for (const val of this.selectedFilters) {
      if (!val.optionList) {
        if (val.key === "customerStatus") {
          val.optionList = this.OrderStatusData;
        
        } else if (val.key === "subscriptionStatus") {
          val.optionList = this.paymentStatusData;
        } else if (val.key === "poAction") {
          val.optionList = this.shipmentStatusData;
        }
      }
    }


  }
  sendFilter(e: any[], items) {
    const codeValues = e.map(item => item.code);
    if(items.key == "customerStatus"){
    this.customerStatus = codeValues
    console.log("values",this.customerStatus)
    }else if(items.key == "subscriptionStatus"){
      this.subscriptionStatus = codeValues
    }else if(items.key =='poAction'){
      this.poAction = codeValues
    }
    this.getPosReport()
  }
  onFilterClick() {
    this.showFilterRow = !this.showFilterRow;
  }
  clearAll() {

    this.selectedFilters = [];
    // this.showFilterRow = false;
    // setTimeout(() => {
    //   this.showFilterRow = true;
    // }, 10);
  }
  callingCustomerSpecificRenewals(isCallFromPurchaseHistory:boolean, currentCustomerId){
    if(isCallFromPurchaseHistory && currentCustomerId){
      this.showdaterange=false
      console.log('customerId calling from purchase history');
      this.searchTerm=currentCustomerId;
      this.getPosReport( this.searchTerm);
    }
  }
  refreshingRowIndex: number | null = null;

  reinitializeComponent(){
    this.getPosReport();
    this.getRenewalSummaryData();

  }
  async refreshRow(item: any, index: number, suppressToast: boolean = false) {
    this.highlightedRowIndex= index
    this.refreshingRowIndex = index; // Indicate the row is refreshing

    try {
      const data: any = {
        searchTerm: item.CustomerId, // Fetch data for the specific CustomerId
      };
  
      // Fetch updated data for the row
      const res: any = await this.renewalServiceService.getPosReport(data);
      this.rowDatas[index] = res.data?.customers?.data[0];
  
      // Close the dropdown for the current row after refreshing
      this.closeDropdown(index, 'customerDropdown');
      this.closeDropdown(index, 'actionDropdown');
      if (!suppressToast) {
        this.commonService.showToast('success', 'Renewal Refresh', `CustomerID ${item.CustomerId}'s renewal refreshed successfully.`);

      }
    } catch (error) {
      console.error("Error refreshing row:", error);
      // Show an error message unless suppressToast is true
      if (!suppressToast) {
        this.commonService.showToast('error', 'Renewal Refresh', `An error occurred during refresh for CustomerID ${item.CustomerId}.`);
      }
    } finally {
      this.refreshingRowIndex = null; // Remove the refreshing state
    }
  }
  
  
  async getPosReport(customerId?: any, index?) {
    try {
      if (!this.isExportData && !customerId) {
        this.isSkeletonLoader = true
      }
      const data: any = {
        per_page: this.itemsPerPage,
        page: this.currentPage,
      };
      const custData: any = {
        searchTerm: customerId,
      };

      if(this.OrderStatusData && this.customerStatus?.length > 0){
        
        data["customerStatus[]"] = this.customerStatus
        console.log("values 1",data)
      }
      if(this.paymentStatusData.length  && this.subscriptionStatus?.length > 0){
        data["subscriptionStatus[]"] = this.subscriptionStatus
      }
      if(this.shipmentStatusData && this.poAction?.length > 0){
        data["poAction[]"] = [this.poAction]
      }
      // Add properties only if they exist
      if (this.days && this.days != 5) data.days = this.days;
      if (this.searchTerm) data.searchTerm = this.searchTerm;
      if (this.startDate) data.fromDate = this.startDate;
      if (this.endDate) data.toDate = this.endDate;
      if (this.isExportData) data.IsExport = this.isExportData;
      if (this.selectedCard) data.selectedCard = this.selectedCard.key || this.selectedCard.Title ;
      if (customerId) {
        const res: any =
          await this.renewalServiceService.getPosReport(custData).then((res: any) => {
            console.log(res.data?.customers?.data[0],index)
            this.rowDatas[index] = res.data?.customers?.data[0]
          })
      if(this.currentCustomerId){
       try {
        const custData: any = {
          searchTerm: customerId,
          per_page:this.itemsPerPage,
          page:1,
        };
              // Fetch data for the specific customer
      const res: any = await this.renewalServiceService.getPosReport(custData);
      this.rowDatas = res?.data?.customers?.data || []; // Assign specific customer data
      this.totalOrders = res?.data?.customers?.total || 0; // Set total orders
      this.isSkeletonLoader = false; // Stop skeleton loader

        if(res?.Status){
          this.isSkeletonLoader=false
        }else{
          this.isSkeletonLoader=false
        }
       } catch (error) {
        this.isSkeletonLoader=false
       }
        
      }
      } else {
        const res: any = await this.renewalServiceService.getPosReport(data);
        if (this.isExportData == 1) {
          const anchor = document.createElement("a");
          anchor.href = res.data;
          anchor.target = "_blank";
          anchor.download = "";
          anchor.click();
          anchor.remove();
          this.commonService.showToast(
            "success",
            "Data download successfully ",
            ""
          );
          this.isExportData = 0;
        } else {
          this.rowDatas = res.data?.customers?.data;
          console.log("rowsdata: ", this.rowDatas);
          this.totalOrders = res?.data?.customers?.total || 0;
          this.isSkeletonLoader = false;
          this.highlightedRowIndex = null;
        }
      }
    } catch (error) {
      console.error("Error fetching POS report:", error);
      this.isSkeletonLoader = false;
    }
  }

  async toggleDropdown(index: number, item, dropdown) {
    if (dropdown == 'customerDropdown') {
      await this.getCustomerPersonalInfo(index, item)
    } else if (dropdown == 'subscriptionDropdown') {
      await this.getSubscriptionDetail(item?.subscripId)
    }
    if (this[dropdown].has(index)) {
      this[dropdown].delete(index); // Hide if already visible
    } else {
      this[dropdown].clear();
      this[dropdown].add(index); // Show if not visible
    }
  }
  closeDropdown(index: number, dropdown: string): void {
    if (this[dropdown].has(index)) {
      this[dropdown].delete(index); // Hide the dropdown
    }
  }
  
  async getCustomerPersonalInfo(index: number, item) {
    const data = {
      CustomerId: item.CustomerId,
    };

    const res: any = await this.http
      .get("api/Customer/getCustomerPersonalInfo", data, false)
      .toPromise();
    if (res.Status) {
      this.customerDetails = res.data;
    }
  }

  async getSubscriptionDetail(id) {
    try {
      await this.subscriptionService.getDetail(id).subscribe(
        (res: any) => {
          this.subscriptionDetail = res?.data;
          console.log(this.subscriptionDetail)
        },
        (error: any) => {
        }
      );
    } catch (error) {
      console.error("Exception caught:", error);

    }
  }
async sendToCustomerDashboard(CustomerID, index) {
  await this.customerProfileService.setCustomerId(CustomerID);
  this.customerProfileService.getMinimalProfileData();
  const days = Number(this.days);
  const selectedOption = this.daysOptions.find((option) => option.value === days);
  const isDaterange = this.showdaterange;

  const filters: any = {
    searchterm: this.searchTerm,
    selectedOption: selectedOption,
    activeTabIndex: this.activeTabIndex,
  };
  if (isDaterange) {
    filters.showdaterange = isDaterange;
    filters.dateRange = this.bsRangeValue;
  }
  filters.totalRecords = this.totalOrders;
  filters.currentPage = this.currentPage;
  filters.itemsPerPage = this.itemsPerPage;
  this.renewalServiceService.setActiveTabIndex(0); // Current activeTabIndex
  this.renewalServiceService.setActiveNestedTabIndex(0);
  this.stateManagementService.saveTabIndices(0, 0);
  this.stateManagementService.saveState(
    'renewal-reports',
    this.uniqueKey, // Pass the unique key here
    filters, // Save filters (add appropriate filters if necessary)
    this.rowDatas, // Save row data
    index // Save selected index
  );
  this.router.navigate(['/customer-dashboard/customer-profile'], {
    queryParams: { showBack: true },
  });
}


  @HostListener("document:click", ["$event"])
  handleClickOutside(event: MouseEvent): void {
    const clickedInsideDropdown = (event.target as HTMLElement).closest(
      ".position-relative"
    );
    if (!clickedInsideDropdown) {
      this.customerDropdown.clear();
      this.actionDropdown.clear();
    }
  }



  searchChange(e) {
    this.searchTerm = e.target.value;
    console.log('searchTerm: ', this.searchTerm);
    this.getPosReport();
  }

  goToDetails(item: any, index: any, header) {
    if (header.key == "Customer" || header.key == "Actions" || header.key == "SubscriptionId") {
    } else {
      const days = Number(this.days);
      const selectedOption = this.daysOptions.find(option => option.value === days);
      const isDaterange = this.showdaterange;

      const filters: any = {
        searchterm: this.searchTerm,
        selectedOption: selectedOption
      };
      if (isDaterange) {
        filters.showdaterange=isDaterange;
        filters.dateRange = this.bsRangeValue;
    }
    filters.totalRecords= this.totalOrders;
    filters.currentPage = this.currentPage;
    filters.itemsPerPage= this.itemsPerPage;
      this.stateManagementService.saveState(
        "renewal-reports",
        this.uniqueKey,
        filters, // Save filters (add appropriate filters if necessary)
        this.rowDatas, // Save row data
        index // Save selected index
      );
      console.log('stateManagementService called');
      this.router.navigate([`/renewal-reports/details`], {
        state: { renewalItem: item },
      });
    }
  }

  generatePo(item, index) {
    let data: any = {
      customer_id: item?.CustomerId,
      subscription_id: item?.SubscriptionId,
      status: "New",
      action: "Renewal",
    };
    if (this.poDate) {
      data.date = this.poDate;
    }
    const highlightedRowId = item.CustomerId;

    // Highlight the current row
    this.highlightedRowIndex = index;
    this.stateManagementService.saveState(
      "renewal-reports",
      this.rowDatas, // Save the current rows
      this.highlightedRowIndex // Save the highlighted row index
    );

    this.renewalServiceService
      .generatePo(data)
      .then((res: any) => {
        if (res.Status) {
          this.showActionMessage("success");
          this.modalService.dismissAll();
          this.resetValues();
          // this.getPosReport().then(() => {
            // Reapply the highlight after data refresh
            const newIndex = this.rowDatas.findIndex(
              (row) => row.CustomerId === highlightedRowId
            );
            if (newIndex !== -1) {
              this.highlightedRowIndex = newIndex;

              // Save updated state with the new index
              this.stateManagementService.saveState(
                "renewal-reports",
                this.rowDatas, // Save the current rows
                this.highlightedRowIndex // Save the highlighted row index
              );
            }
            
          // });
        }
      })
      .catch((res: any) => {
        this.showActionMessage("error");
      });
  }

  forceSuspendAccount() {
    let data = {
      customer_id: this.selectedCustomer?.CustomerId,
      subscription_id: this.selectedCustomer?.SubscriptionId,
      action: "Suspension",
      status: this.status,
      po_id: this.selectedCustomer?.po_data?.id,
    };

    if (this.dueDate) {
      data["due_date"] = this.dueDate;
    }
    // Store the unique identifier for the row to be highlighted
    const highlightedRowId = this.selectedCustomer?.CustomerId;

    // Highlight the current row
    this.highlightedRowIndex = this.rowDatas.findIndex(
      (row) => row.CustomerId === highlightedRowId
    );
    this.stateManagementService.saveState(
      "renewal-reports",
      this.rowDatas, // Save the current rows
      this.highlightedRowIndex // Save the highlighted row index
    );

    this.renewalServiceService
      .generatePo(data)
      .then((res: any) => {
        if (res.Status) {
          this.showActionMessage("success");
          this.modalService.dismissAll();
          this.resetValues();
          // this.getPosReport().then(() => {
            // Reapply the highlight after data refresh
            const newIndex = this.rowDatas.findIndex(
              (row) => row.CustomerId === highlightedRowId
            );
            if (newIndex !== -1) {
              this.highlightedRowIndex = newIndex;

              // Save updated state with the new index
              this.stateManagementService.saveState(
                "renewal-reports",
                this.rowDatas, // Save the current rows
                this.highlightedRowIndex // Save the highlighted row index
              );
            }
          // });
        }
      })
      .catch((res: any) => {
        this.showActionMessage("error");
      });
  }

  forceRenewAccount() {
    let data = {
      customer_id: this.selectedCustomer.CustomerId,
      subscription_id: this.selectedCustomer.SubscriptionId,
      action: "Renewal",
      status: this.status,
      po_id: this.selectedCustomer?.po_data?.id,
    };

    if (this.dueDate) {
      data["due_date"] = this.dueDate;
    }
    // Store the unique identifier for the row to be highlighted
    const highlightedRowId = this.selectedCustomer?.CustomerId;

    // Highlight the current row
    this.highlightedRowIndex = this.rowDatas.findIndex(
      (row) => row.CustomerId === highlightedRowId
    );
    this.stateManagementService.saveState(
      "renewal-reports",
      this.rowDatas, // Save the current rows
      this.highlightedRowIndex // Save the highlighted row index
    );
    this.renewalServiceService
      .generatePo(data)
      .then((res: any) => {
        if (res.Status) {
          this.showActionMessage("success");
          this.modalService.dismissAll();
          this.resetValues();
          // this.getPosReport().then(() => {
            // Reapply the highlight after data refresh
            const newIndex = this.rowDatas.findIndex(
              (row) => row.CustomerId === highlightedRowId
            );
            if (newIndex !== -1) {
              this.highlightedRowIndex = newIndex;

              // Save updated state with the new index
              this.stateManagementService.saveState(
                "renewal-reports",
                this.rowDatas, // Save the current rows
                this.highlightedRowIndex // Save the highlighted row index
              );
            }
          // });
        }
      })
      .catch((res: any) => {
        this.showActionMessage("error");
      });
  }

  openConfirmationModal(action, item, index) {
    this.action = action;
    this.selectedCustomer = item;
    this.nextRenewalDate = item?.NextRenewalDate;
    console.log("nextRenewalDate", this.nextRenewalDate);
    let modalRef;
    if (action == "Suspend") {
      modalRef = this.modalService.open(this.suspensionConfirmationModal, { centered: true })
    } else if (action == "Renewal") {
      modalRef = this.modalService.open(this.renewConfirmationModal, { centered: true })
    } else {
      modalRef = this.modalService.open(this.poConfirmationModal, { centered: true })
    }
    this.highlightedRowIndex = index; // Highlight the processed row
    this.stateManagementService.saveState(
      "renewal-reports",
      this.rowDatas, // Save the current rows
      this.highlightedRowIndex // Save the highlighted row index
    );
    modalRef.result
      .then(
        (res) => {
          console.log("Modal closed with:", res);
          this.resetValues(); // Handle successful close
        },
        (reason) => {
          console.log("Modal dismissed with:", reason); // Handle dismissal
          if (reason === 0 || reason === 1) {
            this.resetValues();
          }
        }
      )
      .catch((error) => console.error("Error with modal:", error));
  }

  // Reset function
  resetValues() {
    this.dueDate = null;
    this.showDatePicker = false;
    this.renewalTime = "onDate";
    this.poDate = null
  }

  getStatusColor(action, status) {
    return this[action][status] || "#dbc4c4"; // Default to black if status not defined
  }

  getStatusBadgeClass(status: string): any {
    switch (status) {
      case "Active":
        return "badge-success";
      case "New":
        return "badge-primary";
      case "Deactivated":
        return "badge-danger";
      case "Suspended":
        return "badge-warning";
      case "Pending":
        return "badge-secondary";
      case "Cancelled":
        return "badge-danger";
      default:
        return "";
    }
  }

  onActionChange(action: string, event: any): void {
    this.selectedValue = event.target.value;

    // Show or hide the date picker based on the selected value
    this.showDatePicker = this.selectedValue === "specificDate";

    // Determine the status based on the selected value and action
    if (this.selectedValue === "now") {
      this.status = "Progress";
    } else if (this.selectedValue === "onDate") {
      this.status = 'New';
    } else if (this.selectedValue === "specificDate") {
      this.status = "Pending";
    }

    // Show or hide the next renewal date section
    this.showNextRenewalDate = this.selectedValue === "onDate";
  }

  performAction(action: string): void {
    if (action === "suspend") {
      if (this.selectedValue === "specificDate" && !this.dueDate) {
        this.commonService.showToast("error", "Due Date is required.", "Please select a due date for suspension.");
        return;
      }
      this.forceSuspendAccount();
    } else if (action === "renew") {
      if (this.selectedValue === "specificDate" && !this.dueDate) {
        this.commonService.showToast("error", "Due Date is required.", "Please select a due date for renewal.");
        return;
      }
      this.forceRenewAccount();
    } else if (action === "PO") {
      if (this.selectedValue === "specificDate" && !this.poDate) {
        this.commonService.showToast("error", "PO Date is required.", "Please select a date for generating the PO.");
        return;
      }
      this.generatePo(this.selectedCustomer, this.highlightedRowIndex);
    }
      // Refresh the row after the modal is closed
    this.modalService.dismissAll();
    const highlightedRowId = this.selectedCustomer?.CustomerId;
    const index = this.rowDatas.findIndex((row) => row.CustomerId === highlightedRowId);
    if (index !== -1) {
      this.refreshRow(this.selectedCustomer, index,true);
    }
  }

  ondaysFilterChange(e) {
    this.clearFilter();
    let days = e.target.value;
    if (days == 0) {
      this.days = null;
      this.showdaterange = true;
    } else if (days == 5) {
      this.showdaterange = false;
      return;
    } else {
      this.days = days
      this.showdaterange = false;
      this.getPosReport();
      this.getRenewalSummaryData();
    }
  }
  onDateRangeSelected(dateRange: Date[]): void {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to midnight
  
    const startDate = new Date(dateRange[0]); // Normalize start date
    startDate.setHours(0, 0, 0, 0);
  
    const endDate = new Date(dateRange[1]); // Normalize end date
    endDate.setHours(0, 0, 0, 0);
  
    console.log('startDate:', startDate, 'endDate:', endDate, 'today:', today);
  
    if (startDate < today && endDate <= today) {
      // Past range: start date strictly before today, end date can be today
      console.log('Valid past date range selected');
      this.startDate = this.formattedDate(startDate);
      this.endDate = this.formattedDate(endDate);
      this.dateRange = dateRange;
      this.getPosReport();
      this.getRenewalSummaryData();
      return;
    }
  
    if (startDate > today && endDate > today) {
      // Future range: start date strictly after today, end date strictly after today
      console.log('Valid future date range selected');
      this.startDate = this.formattedDate(startDate);
      this.endDate = this.formattedDate(endDate);
      this.dateRange = dateRange;
      this.getPosReport();
      this.getRenewalSummaryData();
      return;
    }
  
    // Handle invalid cases
    if (startDate.getTime() === today.getTime()) {
      this.commonService.showToast(
        'info',
        'Invalid Date Range',
        'Start date cannot be today. Please select a start date strictly before or after today.'
      );
    } else {
      this.commonService.showToast(
        'info',
        'Invalid Date Range',
        'Date range cannot span both past and future. Select a valid range either entirely in the past or entirely in the future.'
      );
    }
  
    // Clear invalid selection
    console.log('Invalid date range selected');
    this.dateRange = [];
    this.startDate = null;
    this.endDate = null;
  }
  
  
  
  
  formattedDate(inputDate: Date): string {
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const year = inputDate.getFullYear();
    return `${year}-${month}-${day}`;
  }

  getStatusDescription(category: string, status: string): string {
    if (
      !status ||
      !this.statusDescriptions[category] ||
      !this.statusDescriptions[category][status]
    ) {
      return "No description available";
    }
    return this.statusDescriptions[category][status];
  }

  onPaginationChanged(params: any) {
    this.getPosReport();
  }

  exportData() {
    this.isExportData = 1;
    this.getPosReport();
  }

  getRenewalSummaryData() {
    let data: any = {
      days: this.days,
    };
    if (this.startDate) data.fromDate = this.startDate;
    if (this.endDate) data.toDate = this.endDate;
    console.log('summery called');
    this.renewalServiceService
      .getRenewalSummaryData(data)
      .then((res: any) => {
        if (res.Status) {
          this.summaryData = res?.data;
          this.summaryData = this.summaryData.map((val) => ({
            ...val, // spread operator to keep other properties intact
            title: val.Title, // convert Title to lowercase
          }));
        }
      })
      .catch((res: any) => {
        this.commonService.showToast("error", res?.message, "");
      });
  }

  clearFilter() {
    this.searchTerm = null;
    this.startDate = null;
    this.endDate = null;
    this.isExportData = 0;
  }

  stop(event: Event) {
    event.stopPropagation();
  }

  sendToSubscriptionDetail(customer: any): void {
    console.log(customer)
    // Logic to navigate to the customer dashboard
    this.router.navigate(["subscriptions/subscription-details"], {
      queryParams: {
        id: customer.subscripId,
        CustomerId: customer.CustomerId,
      },
    });
  }

  refreshData() {
    this.getPosReport()
    this.getRenewalSummaryData()
  }

  showActionMessage(type): void {
    let message = '';

    switch (this.action) {
      case "Suspend":
        if (this.selectedValue === "now") {
          message = "The service will be suspended immediately.";
        } else if (this.selectedValue === "onDate") {
          message = "The service will be suspended on the next renewal date.";
        } else if (this.selectedValue === "specificDate") {
          message = "The service will be suspended on the specified date.";
        }
        break;

      case "Renewal":
        if (this.selectedValue === "now") {
          message = "The subscription will be renewed immediately.";
        } else if (this.selectedValue === "onDate") {
          message = "The subscription will be renewed on the next renewal date.";
        } else if (this.selectedValue === "specificDate") {
          message = "The subscription will be renewed on the specified date.";
        }
        break;

      case "PO":
        if (this.selectedValue === "now") {
          message = "Purchase Order (PO) has been generated successfully.";
        } else if (this.selectedValue === "specificDate") {
          message = "Purchase Order (PO) will be generated for the specified date.";
        }
        break;

      default:
        message = "Please select a valid action.";
    }

    this.commonService.showToast(type, type, message);
  }

  onCardClick(card){
    this.activeCardId = card.title
    this.selectedCard = card
    this.getPosReport()
  }
}
