import { Component, HostListener } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { Router } from '@angular/router';
import { MyProfileService } from 'src/app/profile/services/my-profile.service';
import { CommonService } from '../../services/common.service';
import { fadeInOut } from '../../services/app.animation';

@Component({
  selector: 'app-all-notification',
  templateUrl: './all-notification.component.html',
  styleUrls: ['./all-notification.component.scss'],
  animations: [fadeInOut]
})
export class AllNotificationComponent {
  notifications:any = []
  Allnotifications:any = []
  currentPage: number = 1;
  totalPages: number = 0;
  isLoading: boolean = false;
  userData:any
  filterList:any;
  categoryFilterList:any
  total:any;
  selectedCategoryId: number | null = null;
  selectedFilter: any = { title: "All" };
  searchTerm: string = '';
  expandedCategoryId: number | null = null;
  showFilterRow:boolean = false
 isSkeletonLoader: boolean = true;
constructor(private notificationService: NotificationService,private router: Router, private myProfileService: MyProfileService, private commonService: CommonService){}
 async ngOnInit() {

  this.fetchNotifications()
// this.userData = localStorage.getItem('tibss.userDetails');
this.userData = this.myProfileService.getUser();
console.log("user details ",this.userData

)
this.getFilterList()
this.getCategoryFilterList()
  }

  async getFilterList(){
    try {
      const res: any = await this.notificationService.getFilterList();
      this.filterList = res.data.sort((a, b) => (a.title > b.title ? 1 : -1));

    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      this.isLoading = false;
    }
  }
  async getCategoryFilterList(){
    try {
      const res: any = await this.notificationService.getCategoryFilterList();
      this.categoryFilterList = res.data.sort((a, b) => (a.Name > b.Name ? 1 : -1));
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      this.isLoading = false;
    }
  }
  applyFilter2(item: any) {
    this.isSkeletonLoader = true;
    this.selectedFilter = item; // Mark selected filter as active
    this.Allnotifications = []; // Reset notifications
    this.selectedCategoryId = 0
    if (item.title === "Read") {
      this.fetchNotifications('', 1);
    } else if (item.title === "Unread") {
      this.fetchNotifications('', 0);
    }else{
         this.selectedFilter.title = 'All'
         this.fetchNotifications('', null);
    }
  }
  applyfilter(id:any){
    this.isSkeletonLoader = true;
    this.selectedFilter.title = ''
    this.selectedCategoryId = id;
    this.Allnotifications = [];
    this.fetchNotifications(id)
  }


toggleCategory(categoryId: number) {
  this.expandedCategoryId = this.expandedCategoryId === categoryId ? null : categoryId;
}

  searchNotifications() {
    this.Allnotifications = []; // Reset notifications
    this.currentPage = 1; // Reset pagination
    this.fetchNotifications(); // Fetch with search term
  }
  async fetchNotifications(id?:any,id2?:any): Promise<void> {
    if (this.isLoading || (this.totalPages && this.currentPage > this.totalPages)) {
      return;
    }
    this.isLoading = true;
    try {
      const res: any = await this.notificationService.fetchNotification2(this.currentPage,id,id2,this.searchTerm);
      if (res.Status) {
        this.notifications = res;
        this.Allnotifications = [...this.Allnotifications, ...res?.data?.data];
        this.currentPage = res.data.current_page;
        this.totalPages = res.data.last_page;
        this.total = res?.data?.total
        setTimeout(() => {
          this.isSkeletonLoader = false;
        }, 1500);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      this.isLoading = false;
    }
  }
  @HostListener('scroll', ['$event'])
  onScroll(event: any): void {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollHeight - scrollTop <= clientHeight + 50) {
      // Load the next page when scrolled near the bottom
      this.currentPage++;
      this.fetchNotifications();
    }
  }
  handleClick(notification: any): void {
 
    // const regex = /#(\d+)/; // Regular expression to extract the ID
    // const match = notification?.Name?.match(regex);
    const customerId = notification?.CustomerID 
    const ticketId = notification?.EntityID // Extract the ticket ID
    const type = notification?.type?.CategoryID
    const URL = notification?.type?.category?.RedirectUrl
    console.log("notification type",URL,type)
    if (type == 2) {
      if(ticketId){
      this.router.navigate(['/ticket-system'], {
        queryParams: { ticketId: ticketId, showMsg: true },
      }).then(() => {
        console.log(`Navigated to ticket with ID: ${ticketId}`);
      });
    }else{
      this.commonService.showToast("error", 'Error', "No Ticket found")
    }
    } else if(type == 11) {
      if(ticketId && customerId){
        this.router.navigate(['/subscriptions/subscription-details'], {
          queryParams: {CustomerId: customerId ,id:ticketId}
        }).then(() => {
          console.log(`Navigated to ticket with ID: ${ticketId}`);
        });
      }else{
        console.log("ID is not Found")
        this.commonService.showToast("error", 'Error', "Subscription and Customer Id Not found")
      }
    } else if(type == 14) {
      if(ticketId && customerId){
    
        this.router.navigate([`/awaiting-shipment/order-details/${ticketId}`], {
          
          queryParams: {customerid: customerId}
        }).then(() => {
          console.log(`Navigated to ticket with ID: ${ticketId}`);
        });
        console.log("order click")
      }else{
        this.commonService.showToast("error", 'Error', "Order And Customer Id Not found")
      }
    }else if(type == 4) {
      if(ticketId){
        this.router.navigate(['/transaction/detail'], {
          queryParams: {paymentID:ticketId,customerid: customerId}
        }).then(() => {
          console.log(`Navigated to ticket with ID: ${ticketId}`);
        });
      }else{
        this.commonService.showToast("error", 'Error', "Payment ID  And Customer Id Not found")
      }
    }
    this.notificationService.getmarAsRead(notification.id)
  }
    markAsRead(id: any): void {
      this.notificationService.markAsRead(id);
    }

    onFilterClick() {
      this.showFilterRow = !this.showFilterRow;
    }
    getCategoryIcon(name: string): string {
      switch (name) {
        case 'All': return 'fa-bell'; // 🔔 Notification Icon
        case 'Read': return 'fa-envelope-open-text'; // 📩 Opened Mail Icon
        case 'Unread': return 'fa-envelope'; // 📧 Unread Mail Icon
        case 'Account Management': return 'fa-user-gear';
        case 'Billing & Payments': return 'fa-credit-card';
        case 'Customer Support': return 'fa-headset';
        case 'Miscellaneous': return 'fa-folder';
        case 'Order Management': return 'fa-box';
        case 'Promotional & Marketing': return 'fa-bullhorn';
        case 'Security & Compliance': return 'fa-shield-alt';
        case 'Service & Plan Management': return 'fa-cogs';
        case 'Subscription Management': return 'fa-receipt';
        case 'System Admin': return 'fa-server';
        case 'System Admin (Enrollment)': return 'fa-user-check';
        case 'Task': return 'fa-tasks';
        case 'Ticket': return 'fa-ticket-alt'; // 🎫 Ticket Icon
        case 'Usage Alert': return 'fa-exclamation-triangle'; // ⚠️ Alert Icon
        default: return 'fa-layer-group'; // Default icon
      }
    }
}
