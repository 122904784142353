<div class="container-fluid mt-3">
    <div class="row align-items-center">
        <div class="col-md-6">
            <div class="mb-3">
                <h5 class="card-title" (click)="back()">  <i class="fa-solid fa-arrow-left mr-2" style="font-size: 15px; cursor: pointer;"></i>Template Type List <span class="text-muted fw-normal ms-2">(Total: {{totalusers
                        }})</span></h5>
            </div>
        </div>
        <div class="col-md-6">
            <div class="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <div class="input-group col-md-4 px-0">
                    <input type="text" placeholder="Search here ..." class="form-control" value="" (keyup)="filterdData($event)">
                </div>
                <div class=" text-end mx-0 px-0">
                    <a (click)="openModal()" data-bs-toggle="modal" data-bs-target=".add-new"
                        class="btn btn-primary text-nowrap"><i class="bx bx-plus me-1"></i> New Template Type</a>
                </div>

            </div>
        </div>
    </div>
    <div class="row" >
        <div class="col-lg-12">
            <div class="table-responsive" >
                <table class="table project-list-table table-nowrap align-middle table-borderless" *ngIf="!isSkeletonLoader" @fadeInOut >
                    <thead>
                        <tr class="text-center">
                      
                            <th scope="col">Tamplate Type ID</th>
                            <th scope="col">Template Type Name</th>
                            <th scope="col">Description</th>
                    
                            <th scope="col">Created Date</th>
                            <th scope="col">Updated Date</th>
                            <th scope="col" style="width: 150px;">Actions</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr *ngFor="let user of userList" >
                           
                            <td>{{ user?.id }}</td>
                            <td>{{ user?.type_name }}</td>
                            <td>{{user?.description}}</td>
                            <td> {{ user?.created_at | date  }}  </td>
                            <td> {{ user?.updated_at | date}}</td>
                            <td>
                                <ul class="list-inline mb-0" style="cursor: pointer;">
                                    <li class="list-inline-item" (click)="editRouter(user)">
                                        <i class="fa-solid fa-pen text-primary"></i>
                                    </li>
                                    <li class="list-inline-item mx-1" (click)="deleteTag(user.id)">
                                        <i class="fa fa-trash text-danger"></i>
                                    </li>
                               
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="pull-right p-20 d-flex">
                    <ngb-pagination [directionLinks]="true" [boundaryLinks]="true" [collectionSize]="totalItems"
                        [(page)]="currentPage" (pageChange)="pageChange($event)" [maxSize]="itemsPerPage"
                        [pageSize]="itemsPerPage">
                        <ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                </div>
            </div>
           
        </div>
    </div>

</div>
<div *ngIf="isSkeletonLoader" @fadeInOut  style="max-width: 100%;">
    <app-skeleton-loader [tableColumn]="7" [tableRow]="10"  shape="table"></app-skeleton-loader>
  </div>

  <ng-template #tagModal let-modal>
    <form [formGroup]="tagForm">
      <div class="modal-header">
        <h5 class="modal-title">Create Template Type</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="tagName" class="form-label"><strong>Template Type Name:</strong></label>
          <input
            type="text"
            id="typeName"
            class="form-control"
            formControlName="typeName"
            placeholder="Enter Template type name"
          />
          <div *ngIf="tagForm.get('typeName').invalid && tagForm.get('typeName').touched" class="text-danger">
            type Name Name is required.
          </div>
        </div>
        <div class="mb-3">
            <label for="tagName" class="form-label"><strong>Description:</strong></label>
            <textarea
              type="text"
              id="typeName"
              class="form-control"
              formControlName="description"
              placeholder="Enter description"
           ></textarea>
         
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-primary" (click)="saveTagData()" [disabled]="tagForm.invalid">Save Changes</button>
      </div>
    </form>
  </ng-template>