import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-purchase-equipments',
  templateUrl: './purchase-equipments.component.html',
  styleUrls: ['./purchase-equipments.component.scss']
})
export class PurchaseEquipmentsComponent {
 @Input() currentCustomerId: any;
}
