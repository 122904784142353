import { Injectable, Injector } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { LocalStorage } from 'ngx-webstorage';
import { HttpService } from './httpService.service';
import { firstValueFrom, Observable } from 'rxjs';
import { NavService } from './nav.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  @LocalStorage()
  private userDetails;
  private navService: NavService;
  constructor(private dbService: NgxIndexedDBService, private http: HttpService,private injector: Injector) { }

   /**
   * Add or update a resource category in IndexedDB.
   * ✅ Optimized: Uses a **single DB operation** instead of `getByKey()` + `update()/add()`.
   */
   async addResourceCategory(resourceCategory: any): Promise<any> {
    try {
      // Try to update first (if it exists), otherwise add it
      return await firstValueFrom(
        this.dbService.update('resource_categories', resourceCategory)
      ).catch(async (error) => {
        console.warn(`Category not found, adding new: ${resourceCategory.Name}`);
        return await firstValueFrom(this.dbService.add('resource_categories', resourceCategory));
      });
    } catch (error) {
      console.error(`Error saving resource category: ${resourceCategory.Name}`, error);
      throw error;
    }
  }
  /**
   * Fetch a resource category by its ID.
   */
  getResourceCategoryById(id: string): Promise<any> {
    return firstValueFrom(this.dbService.getByKey('resource_categories', id));
  }

  /**
   * Fetch all resource categories from IndexedDB.
   */
  getAllResourceCategories(): Promise<any[]> {
    return firstValueFrom(this.dbService.getAll('resource_categories'));
  }

  /**
   * Update a resource category in IndexedDB.
   * ✅ Uses `update()` and falls back to `add()` if not found.
   */
  async updateResourceCategory(resourceCategory: any): Promise<any> {
    try {
      return await firstValueFrom(this.dbService.update('resource_categories', resourceCategory));
    } catch (error) {
      console.error(`Error updating resource category: ${resourceCategory.Name}`, error);
      throw error;
    }
  }

  /**
   * Delete a resource category by ID.
   */
  deleteResourceCategory(id: number): Promise<any> {
    return firstValueFrom(this.dbService.delete('resource_categories', id));
  }

  /**
   * Fetch all resource categories from API.
   * ✅ Uses `firstValueFrom()` instead of `toPromise()`.
   */
  async fetchAllResourceCategories(): Promise<any> {
    try {
      const url = 'api/system/resource-permission-detail';
      const body = {
        userID: this.getUser()?.userID,
        companyId: 'UNITYCRM0021',
      };
      return await firstValueFrom(this.http.get(url, body));
    } catch (error) {
      console.error('Error fetching resource categories from API:', error);
      throw error;
    }
  }

  /**
   * Get user details from local storage.
   */
  getUser() {
    return this.userDetails;
  }

 /**
 * Ensures that a store exists in IndexedDB.
 * ✅ Optimized: Uses a **single indexedDB.open() call** per execution.
 */
private ensureStoreExists(storeName: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const request = indexedDB.open('CombinedDB', 3); // ✅ Use the correct version

    request.onupgradeneeded = (event) => {
      const db = request.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: 'Name', autoIncrement: false });
        console.log(`Store '${storeName}' created successfully.`);
      }
    };

    request.onsuccess = () => {
      request.result.close();
      resolve();
    };

    request.onerror = () => {
      console.error(`Error opening database:`, request.error);
      reject(request.error);
    };
  });
}

/**
 * Updates resource permissions from API and stores them in IndexedDB.
 * ✅ Optimized: Uses **batch processing** and **lazy NavService initialization**.
 */
async updatePermission(): Promise<void> {
  try {
    const res: any = await this.fetchAllResourceCategories();
    const data = res?.data || [];

    if (data.length) {
      // **Batch write optimization**
      const addOrUpdatePromises = data.map(val => this.addResourceCategory(val));
      await Promise.all(addOrUpdatePromises);
      console.log('Resource categories updated successfully.');
    }

    // **Lazy-load NavService only when needed**
    this.navService ??= this.injector.get(NavService);
    this.navService.sideMenuInitilize();
  } catch (error) {
    console.error('Error updating permissions:', error);
  }
}

}
