<div class="notification-container pb-0">
  <div class="header d-flex flex-column gap-2 p-3">
    <div class="d-flex justify-content-between w-100 text-dark align-items-center ">
      <h3>Notifications </h3>
      <span class="align-items-center d-flex gap-2" routerLink="/all-notification">
        <!-- <app-feather-icons [icon]="'check-circle'"></app-feather-icons> -->
        <span class="btn btn-outline-primary">View all</span>
      </span>

    </div>

  </div>

  <div class="notification-list d-flex flex-column " >
    <div class="notification-item p-2" [ngClass]="{'gray': !notification?.UserIsRead}"
      *ngFor="let notification of notifications$ | async" (click)="handleClick(notification)">
      <!-- <img class="avatar"
        src="https://bootdey.com/img/Content/avatar/avatar7.png"
        alt="User Avatar" /> -->
      <div class="avatar d-flex align-items-center justify-content-center text-dark">
        <i class="fa-regular fa-user"></i>
      </div>
      <div class="d-flex flex-column gap-2 w-100 pr-3">
        <div class="details d-flex align-items-center justify-content-between gap-2">
          <p class="text-auto">
            {{ notification?.Name }}
            {{ notification?.Message }}
          </p>
          <!-- <span class="status-dot" *ngIf="!notification?.IsRead"></span> -->
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <!-- <span class="timestamp">{{ notification?.UpdatedDate | date }}</span> -->
          <span class="time-ago text-muted">{{ notification?.UpdatedDate | date}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="tabs d-flex justify-contnt-start w-100  p-3">
    <button class="btn btn-primary w-100" (click)="markAllAsRead()">Mark all as read </button>
  </div>
</div>