import { ChangeDetectorRef, Component, HostListener, ViewChild } from "@angular/core";
import { RenewalServiceService } from "../service/renewal-service.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpService } from "src/app/shared/services/httpService.service";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common.service";
import { fadeInOut } from "src/app/shared/services/app.animation";
import { StateManagementService } from "src/app/shared/services/state-management.service";
import { SubscriptionService } from "src/app/subscriptions/service/subscription.service";

@Component({
  selector: "app-renewal-list",
  templateUrl: "./renewal-list.component.html",
  styleUrls: ["./renewal-list.component.scss"],
  animations: [fadeInOut],
})
export class RenewalListComponent {
  @ViewChild("renewConfirmationModal", { static: true })
  renewConfirmationModal: any;
  @ViewChild("suspensionConfirmationModal", { static: true })
  suspensionConfirmationModal: any;
  @ViewChild('poConfirmationModal', { static: true }) poConfirmationModal: any;
  highlightedRowIndex: number | null = null;
  bsRangeValue: any[];
  dateRange:any[];
  header: any = [
    { key: "CustomerId", display: "Customer Id" },
    { key: "SubscriptionId", display: "Subscription Id" },
    { key: "Customer", display: "Customer" },
    { key: "CustomerStatus", display: "Account Status" },
    { key: "SubscriptionStatus", display: "Subscription Status" },
    { key: "NextBillingDate", display: "Next Billing Date" },
    { key: "NextRenewalDate", display: "Next Renewal Date" },
    { key: "dueDate", display: "Due date" },
    { key: "po_status", display: "Status" },
    { key: "po_action", display: "PO action" },
    { key: "remarks", display: "Remarks" },
    { key: "Actions", display: "Actions" },
  ];

  paymentStatus: any = {
    Created: "#FFC107", // Yellow - Represents the initial state
    Failed: "#F44336", // Red - Represents an error or declined status
    Captured: "#2196F3", // Blue - Represents progress or authorization
    Settled: "#4CAF50", // Green - Represents success and completion
    Cancelled: "#9E9E9E", // Grey - Represents cancellation or no action needed
    NoDue: "#9C27B0",
    Draft: "#eb77ff",
  };

  renewalStatus: any = {
    Subscription: "#4CAF50", // Green - Indicates a new or ongoing subscription.
    Renewal: "#2196F3", // Blue - Represents continuity or refreshment of the service.
    PlanDowngrade: "#FFC107", // Yellow - Indicates a downgrade or cautionary change.
    Suspension: "#FF5722", // Orange - Signifies a temporary stop or alert.
    DeActivation: "#9E9E9E", // Grey - Represents deactivation or a neutral state.
  };

  poStatus: any = {
    New: "#03A9F4", // Light Blue - Represents a fresh state.
    Pending: "#FFC107", // Yellow - Indicates waiting or cautionary state.
    Progress: "#2196F3", // Blue - Represents active or ongoing processes.
    Hold: "#FF9800", // Orange - Signifies a paused or retry-needed state.
    Cancelled: "#9E9E9E", // Grey - Indicates no further action needed.
    Completed: "#4CAF50", // Green - Represents success and completion.
  };

  statusDescriptions: any = {
    poStatus: {
      New: "The PO is being prepared or just created.",
      Pending:
        "The payment of that PO has been Captured, waiting for the due date to proceed in Progress.",
      Progress: "The action is being performed.",
      Hold: "The payment of that PO is failed or declined, retry payment attempts required.",
      Cancelled: "The PO has been cancelled.",
      Completed:
        "The PO's order has been shipped or delivered. No further action required.",
    },
    paymentStatus: {
      Created: "The payment has been initiated.",
      Failed: "The payment has been failed or declined.",
      Captured:
        "The payment has been successfully authorized by the bank/card and captured by the gateway but still pending settlement.",
      Settled: "The payment successfully received.",
      Cancelled: "The PO has been cancelled, no payment required.",
      NoDue:"Advance Payment Received",
      Draft:"Payment in draft"
    },
    renewalStatus: {
      Subscription: "Indicates a new or ongoing subscription.",
      Renewal: "Represents continuity or refreshment of the service.",
      PlanDowngrade: "Indicates a downgrade or cautionary change.",
      Suspension: "Signifies a temporary stop or alert.",
      DeActivation: "Represents deactivation or a neutral state.",
    },
  };

  daysOptions = [
    { label: "Today", value: 1 },
    { label: "Tomorrow", value: 2 },
    { label: "Yesterday", value: -1 },
    { label: "3 Days", value: 3 },
    { label: "7 Days", value: 7 },
    { label: "15 Days", value: 15 },
    { label: "30 Days", value: 30 },
    { label: "Custom", value: 0 }, // -1 indicates custom
    { label: "No Filter Selection", value: 5 },
  ];

  showdaterange: any;

  rowDatas: any = [];
  logData: any;
  paymentData: any;
  invoiceData: any;
  customerDetails: any;
  action: any;
  dueDate: any;
  selectedCustomer: any;
  status: any = "New";
  showDatePicker: boolean;
  customerDropdown = new Set<number>();
  actionDropdown = new Set<number>();
  subscriptionDropdown = new Set<number>();
  days: any = -1;
  searchTerm: any = "";
  itemsPerPage: number = 50;
  totalItems: number;
  currentPage: number = 1;
  totalOrders: number = 0;
  startDate: any;
  endDate: any;
  isExportData: any = 0;
  isSkeletonLoader: boolean = true;
  summaryData: any = [];
  nextRenewalDate: any;
  showNextRenewalDate: boolean = true
  poDate: any;
  selectedValue: any;
  renewalTime: any = "onDate";
  subscriptionDetail: any;
  selectedCard:any
  activeCardId:any
  activeTabIndex: any=0;
activeNestedTabIndex: any=0;
nestedTabKey = 0; // A key to force re-rendering
  shouldRenderMainComponent: boolean;
  shouldRenderNestedComponent: boolean;
 private readonly uniqueKey = 'renewal-report'; 

constructor(
  private stateManagementService: StateManagementService,
  private cdr: ChangeDetectorRef
) {}

ngOnInit(): void {
  // Restore tab indices from state if available
  const savedState = this.stateManagementService.getTabIndices();
  this.activeTabIndex = savedState.activeTabIndex || 0;
  this.activeNestedTabIndex = savedState.activeNestedTabIndex || 0;
}

onTabChange(index: number): void {
  this.activeTabIndex = index;
  this.reRenderMainComponent(); // Force re-render for main tabs
  console.log('Active Main Tab Index:', index);
}

// Triggered on nested tab change
onNestedTabChange(index: number): void {
  this.activeNestedTabIndex = index;
  this.reRenderNestedComponent(); // Force re-render for nested tabs
  console.log('Active Nested Tab Index:', index);
}

// Helper to force re-rendering of main tabs
private reRenderMainComponent(): void {
  this.shouldRenderMainComponent = false; // Destroy the current component
  this.cdr.detectChanges(); // Apply the change
  this.shouldRenderMainComponent = true; // Reinitialize the component
  this.cdr.detectChanges(); // Apply the change
}

// Helper to force re-rendering of nested tabs
private reRenderNestedComponent(): void {
  this.shouldRenderNestedComponent = false; // Destroy the current component
  this.cdr.detectChanges(); // Apply the change
  this.shouldRenderNestedComponent = true; // Reinitialize the component
  this.cdr.detectChanges(); // Apply the change
}
}
