import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-po-info-card',
  templateUrl: './po-info-card.component.html',
  styleUrls: ['./po-info-card.component.scss']
})
export class PoInfoCardComponent {
  @Input() po_details


  constructor(private router: Router) {}
  ngOnInit(): void {
   console.log("po_details",this.po_details)
    
  }

  navigateToPoDetails(poId: any | null | undefined): void {
    if (poId) {
      this.router.navigate(['/renewal-reports/po-details'], {
        state: { poId: poId }
      });
    }
  }
  getStatusBadgeClass(status: string): any {
    switch (status) {
      case 'Completed':
        return 'badge-success';
      case 'New':
        return 'badge-primary';
      case 'Deactivated':
        return 'badge-warning';
      case 'Suspended':
        return 'badge-danger';
      case 'Pending':
        return 'badge-danger';
      default:
        return '';
    }
  }
}
