<div class="container m-0 p-0" style="max-width: 100%;">
  <div class="card form-card shadow mt-0" style="max-height: 80vh;">
    <div class="br-5 d-flex justify-content-between text-white text-center bg-primary">
      <h5 class="card-title mb-0 p-3" style="font-size: 1.5rem; letter-spacing: 2px;">CUSTOMER TAGS</h5>
    </div>

    <div class="card-body">
      <div class="row">
        <!-- Device Tags Section -->
        <div class="col-md-4">
          <div class="mb-3">
            <div class="d-flex align-items-center gap-2">
              <app-feather-icons [icon]="'cpu'" class="text-primary"></app-feather-icons>
              <h5 class="mb-0 text-primary">Device Tags</h5>
            </div>

            <!-- Assigned Tags -->
            <div class="mt-3">
              <h6 class="text-muted">Assigned Tags:</h6>
              <div *ngIf="deviceTags?.length === 0" class=" badge tag-style-assigned text-muted fst-italic">
                No Assigned Tags
              </div>
              <div class="d-flex flex-wrap gap-2">
                <span class="badge tag-style-assigned" *ngFor="let tag of deviceTags">
                  <app-feather-icons [icon]="'tag'" class="me-1 text-secondary"></app-feather-icons>
                  {{ tag?.Name }}
                  <i class="fa fa-times ms-1 text-dark small" (click)="removeDeviceTag(tag)"></i>
                </span>
              </div>
            </div>

            <!-- Available Tags for Addition -->
            <div class="mt-3">
              <h6 class="text-muted">Available for Addition:</h6>
              <div class="d-flex flex-wrap gap-2">
                <span *ngFor="let tag of availableDeviceTags"
                      (click)="selectDeviceTag(tag)"
                      class="badge d-flex align-items-center tag-style-available">
                    <app-feather-icons [icon]="'plus-circle'" class="me-1 text-success"></app-feather-icons>
                    {{ tag?.Name }}
                </span>
              </div>
              <div *ngIf="availableDeviceTags?.length === 0" class=" badge tag-style-assigned text-muted fst-italic">
                No Tag available for addition
              </div>
            </div>

            <!-- Device Tag Input -->
            <div class="device-tag-input-container" *ngIf="showDeviceTagInput">
              <div class="position-relative">
                <input type="text" [(ngModel)]="newDeviceTag" placeholder="Enter new tag"
                  class="form-control border rounded-pill px-3 pe-5"
                  (keyup.enter)="createNewDeviceTag()"
                  (click)="showTagInput()" 
                  style="height: 45px;">
            
                <button class="btn text-white rounded-pill position-absolute end-0 top-50 translate-middle-y px-4"
                  style="background-color: #ff3b3b; border: none; height: 37px; right:5px !important; cursor:pointer"
                  (click)="createNewDeviceTag()">
                  Create
                </button>
              </div>
            </div>
            

          </div>
        </div>

        <!-- Vertical Divider -->
        <div class="col-md-1 d-none d-md-block text-center">
          <div class="vertical-divider"></div>
        </div>

        <!-- Order Tags Section -->
        <div class="col-md-7">
          <div class="mb-3">
            
                      <!-- Order Tags Heading (Now First) -->
                <!-- Order Tags Section with Bootstrap Grid -->
                <div class="row align-items-center justify-content-between text-center text-md-start g-3">

                  <!-- Order Tags Label + Icon (Left Column) -->
                  <div class="col-md-3 col-12 d-flex align-items-center gap-2 justify-content-center justify-content-md-start">
                    <app-feather-icons [icon]="'shopping-cart'" class="text-success"></app-feather-icons>
                    <h5 class="mb-0 text-success">Order Tags</h5>
                  </div>
                
                  <!-- Select Order Dropdown (Right Column) -->
                  <div class="col-md-8 col-12 d-flex align-items-center gap-2 justify-content-center justify-content-md-start" *ngIf="customerOrders?.length > 0"
                       [ngClass]="{'selected-order': selectedOrder}">
                
                    <!-- Label -->
                    <label class="fw-bold text-muted mb-0" style="white-space:nowrap">Select Order:</label>
                
                    <!-- ng-select Full Width -->
                    <ng-select [items]="customerOrders" 
                               bindLabel="displayLabel" 
                               [(ngModel)]="selectedOrder"
                               (change)="onOrderSelect()"
                               class="custom-dropdown w-100"
                               [searchable]="true"
                               [placeholder]="'Select an Order'">
                
                      <!-- Custom Template for Displaying Orders -->
                      <ng-template ng-option-tmp let-order="item">
                        <div class="order-item">
                          <div>🆔 <strong>#{{ order.OrderId }}</strong> | 📅 {{ order.OrderDate | date:'dd MMM yyyy' }}</div>
                          <div>🏷️ {{ order.OrderType }} | 💰 {{ order.PaymentStatus }} </div>
                          <div>📌 {{ order.OrderTags || 'No Tags' }}</div>
                        </div>
                      </ng-template>
                
                    </ng-select>
                
                  </div>
                
                </div>
                



            <!-- Assigned Tags -->
            <div class="mt-3">
              <h6 class="text-muted">Assigned Tags:</h6>
              <div *ngIf="orderTags?.length === 0" class=" badge tag-style-assigned text-muted fst-italic">
                No Assigned Tags
              </div>
              <div class="d-flex flex-wrap gap-2">
                <span class="badge tag-style-assigned" *ngFor="let tag of orderTags">
                  <app-feather-icons [icon]="'tag'" class="me-1 text-secondary"></app-feather-icons>
                  {{ tag?.Name }}
                  <i class="fa fa-times ms-1 text-dark small" (click)="removeOrderTag(tag)"></i>
                </span>
              </div>
            </div>

            <!-- Available Tags for Addition -->
            <div class="mt-3">
              <h6 class="text-muted">Available for Addition:</h6>
              <div class="d-flex flex-wrap gap-2">
                <span *ngFor="let tag of displayedTags"
                      (click)="selectOrderTag(tag)"
                      class="badge d-flex align-items-center tag-style-available">
                    <app-feather-icons [icon]="'plus-circle'" class="me-1 text-secondary"></app-feather-icons>
                    {{ tag?.Name }}
                </span>
                <span *ngIf="availableTags?.length > 5"
                  class="tag-style-more-and-less d-flex align-items-center gap-2"
                  (click)="toggleTagView()">              
            
              <!-- Show +X when collapsed -->
              {{ !showAllTags ? '+' + (availableTags?.length - 5) : '' }}
            
              <!-- Show an upward arrow when expanded -->
              <app-feather-icons *ngIf="showAllTags" [icon]="'chevron-up'" class="text-secondary"></app-feather-icons>
            
            </span>
            
            
              </div>
            
              <div *ngIf="availableTags?.length === 0" class="badge tag-style-assigned text-muted fst-italic">
                No Tag available for addition
              </div>
            
              <!-- Toggle Button for See More / See Less -->

              
              
            </div>
            

            <!-- Order Tag Input -->
            <div *ngIf="showOrderTagInput" class="mt-2 position-relative">
              <div class="position-relative">
                <input type="text" [(ngModel)]="newOrderTag" placeholder="Enter new tag"
                  class="form-control border rounded-pill px-3 pe-5"
                  (keyup.enter)="createNewOrderTag()"
                  style="height: 45px;">
                <button class="btn text-white rounded-pill position-absolute end-0 top-50 translate-middle-y px-4"
                  style="background-color: #ff3b3b; border: none; height: 37px; right:5px !important; cursor:pointer"
                  (click)="createNewOrderTag()">
                  Create
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
