import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ManualNavigationGuard implements CanActivate {

  constructor(private router: Router, private location: Location) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const navigationState = this.location.getState();
    const lastNavigation = sessionStorage.getItem('lastNavigation');
    if (!navigationState || Object.keys(navigationState).length === 0 || lastNavigation !== 'app') {
      console.log("🚨 Redirecting to /unauthorized due to manual entry or refresh");
      this.router.navigate(['/unauthorized']);
      return false;
    }
    return true; 
  }
}
