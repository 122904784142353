import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { CommonService } from 'src/app/shared/services/common.service';

import { fadeInOut } from 'src/app/shared/services/app.animation';
import { SubscriptionService } from 'src/app/subscriptions/service/subscription.service';
@Component({
  selector: 'app-payment-his-card',
  templateUrl: './payment-his-card.component.html',
  styleUrls: ['./payment-his-card.component.scss']
})
export class PaymentHisCardComponent {
  isHistorySkeletonLoader: boolean=true;
  isInvoiceSkeletonLoader: boolean=true;
  invoiceList: any;
  itemsPerPage: number = 4;
  @Input() id
  totalItems: number;
  currentPage: number = 1;
  subscriptionDetail:any
  invoiceTotalItems: number;
  invoiceCurrentPage: number = 1;
  showmsg:boolean = false
  public invoiceColumnDefs: ColDef[] = [
    {
      headerName: "Id", field: "id", 
    },
    { headerName: "Created By", field: "CreatedBy", },
    { headerName: "Code", field: "Code", },
    {
      headerName: "Description",
      field: 'Description',
      // width: 150
    },
    {
      headerName: "Due Date",
      field: 'DueDate',
      cellRenderer: this.dateCellRenderer.bind(this),
      // width: 150
    },
    {
      headerName: "Total Amount",
      field: 'TotalAmount',
      // width: 150
    },
    { headerName: "CustomerID", field: "CustomerID", width: 100 },
    {
      headerName: "Payment Date",
      field: 'PaymentDate',
      cellRenderer: this.dateCellRenderer.bind(this),
      // width: 160
    },
    {
      headerName: "Status ID",
      field: 'StatusID',
      width: 150
    },

  ];
  constructor(private subscriptionService: SubscriptionService, private commonService: CommonService, private datePipe: DatePipe
  ) {

  }
  ngOnInit() {


      this.getInvoiceHistory()
 
  }
  dateCellRenderer(params) {
    return this.datePipe.transform(params.value, 'short');
  }
  async getInvoiceHistory() {
    
    let obj: any = {
      "per_page": this.itemsPerPage,
      "page": this.currentPage,
      "CustomerId": this.id
    };
  
    this.subscriptionService.getPaymentHistory(obj).subscribe(
      (res: any) => {
        if (res.Status) {
          console.log("Invoice Details:-", res)
          this.invoiceList = res?.data?.data
          if (res?.data?.data?.length === 0) {
            this.showmsg = true
            this.commonService.showToast('success', "Success", 'No Record Found');
          }
          this.isInvoiceSkeletonLoader=false;
          this.isHistorySkeletonLoader=false

        }
        
      },
      (error: any) => {
        console.error("Error fetching payment history:", error);
        this.commonService.showToast('error', "Error", 'Failed to fetch payment history');
        this.isInvoiceSkeletonLoader=false
        this.isHistorySkeletonLoader=false
        
      }
    );
  }
  pageChange(event: any) {
    this.currentPage = event;
  
      this.getInvoiceHistory()
    
  }
 
  invoicePageChange(event: any) {
    this.currentPage = event;
      this.getInvoiceHistory()
    
  }
}
