import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class RenewalServiceService {

  constructor(private http: HttpService) { }
  private activeTabIndexSubject = new BehaviorSubject<number>(0); // Default to the first tab
  private activeNestedTabIndexSubject = new BehaviorSubject<number | null>(null); // Default to no nested tab

  // Observables
  activeTabIndex$ = this.activeTabIndexSubject.asObservable();
  activeNestedTabIndex$ = this.activeNestedTabIndexSubject.asObservable();

  // Update activeTabIndex
  setActiveTabIndex(index: number): void {
    console.log('active tab:', index);
    this.activeTabIndexSubject.next(index);
  }

  // Update activeNestedTabIndex
  setActiveNestedTabIndex(index: number | null): void {
    console.log('setActiveNestedTabIndex tab:', index);

    this.activeNestedTabIndexSubject.next(index);
  }

  getPosReport(data) {
    try {
      let url = "api/report/renewalCustomers";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getMisAlignedSubscriptions(data) {
    try {
      let url = "api/report/misAlignedSubscriptions";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getPosLogs(data) {
    try {
      let url = "api/report/getPosLogs";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getPosPayment(data) {
    try {
      let url = "api/report/getPosPayment";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getPosInvoice(data) {
    try {
      let url = "api/report/getPosInvoice";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  generatePo(data) {
    try {
      let url = "api/restore/customer/subscription/po";
      return this.http.post(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  
  forceSuspendAccount(data) {
    try {
      let url = "api/restore/customer/subscription/suspend";
      return this.http.post(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  forceRenewAccount(data) {
    try {
      let url = "api/restore/customer/subscription/renew";
      return this.http.post(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getTimelineData(data) {
    try {
      let url = "api/subscription/renewal/timeline";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getRenewalSummaryData(data) {
    try {
      let url = "api/subscription/renewal/summary";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getadvancePlanData(data) {
    try {
      let url = "api/Customer/AdvancePlan";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  AddadvancePlanData(data) {
    try {
      let url = "api/Customer/AddAdvancePlan";
      return this.http.post(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  updateadvancePlanData(data,id) {
    try {
      let url = "api/Customer/UpdateAdvancePlan/"+id;
      return this.http.post(url, data, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}
