import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Output() onDateRangeChange = new EventEmitter<Date[]>();
  @Input() placeholder: string = 'Select Date Range';
  @Input() startDate?: string;  // ✅ Accept startDate as an optional input
  @Input() endDate?: string;    // ✅ Accept endDate as an optional input

  bsRangeValue: Date[];
  maxDate: Date = new Date();
  bsConfig = { 
    rangeInputFormat: 'MMM DD, YYYY', 
    adaptivePosition: true, 
    showWeekNumbers: false 
  };

  constructor() {}
  
  ngOnInit() {
    if (this.startDate && this.endDate) {
      // ✅ If startDate & endDate are provided, use them
      this.bsRangeValue = [new Date(this.startDate), new Date(this.endDate)];
    } else {
      // ✅ Default to last 30 days if no values provided
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);
      this.bsRangeValue = [startDate, this.maxDate];
    }
  }
}
