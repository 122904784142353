import { Component, HostListener, OnInit } from '@angular/core';
import { RenewalServiceService } from '../service/renewal-service.service';
import { StateManagementService } from 'src/app/shared/services/state-management.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SubscriptionService } from 'src/app/subscriptions/service/subscription.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-mis-aligned-subscriptions',
  templateUrl: './mis-aligned-subscriptions.component.html',
  styleUrls: ['./mis-aligned-subscriptions.component.scss'],
  animations:[fadeInOut]
})
export class MisAlignedSubscriptionsComponent implements OnInit {

daysOptions: any;
  riskLevel: string;
  previousRiskLevel:string;
  private readonly uniqueKey = 'mis-aligned-subscriptions'; 
ondaysFilterChange($event: Event) {
throw new Error('Method not implemented.');
}

  itemsPerPage: any=50;
  currentPage: any=1;
  isExportData: any;
  isSkeletonLoader: boolean = true;
  days: boolean;
  searchTerm: any='Active';
  startDate: any;
  endDate: any;
  selectedCard: any;
  misAlingnedData: any;
  highlightedRowIndex: any;
  misAlingnedTotal: any;
   isRiskLevelDropdownOpen: boolean = false;
  misAlingnedHeader:any=[
    { key: "CustomerId", display: "Customer Id" },
    { key: "SubscriptionId", display: "Subscription Id" },
    { key: "CustomerStatus", display: "Customer Status" },
    { key: "SubscriptionStatus", display: "Subscription Status" },
    { key: "FirstName", display: "Customer Name" },
    { key: "BillingPeriod", display: "Billing Period" },
    { key: "IntervalLength", display: "Interval Length" },
    { key: "NextBillingDate", display: " Next Billing Date" },
    { key: "NextRenewalDate", display: "Next Renewal Date" },
    { key: "RiskLevel", display: "RiskLevel" },
    { key: "Remarks", display: "Remarks" },
  ]
  constructor(
        private renewalServiceService: RenewalServiceService,
        private stateManagementService: StateManagementService,
        public commonService: CommonService,
        private customerProfileService: CustomerProfileService,
        private router: Router,
        private modalService: NgbModal,
        private http: HttpService,
        private subscriptionService: SubscriptionService
  ){

  }
  ngOnInit(){
    if (
      !this.stateManagementService.restoreState(
        'renewal-reports',
        this.uniqueKey, // Pass the unique key here
        (state) => {
          this.misAlingnedData = state.items || [];
          this.highlightedRowIndex = state.selectedIndex ?? null; // Restore selected index
          this.isSkeletonLoader = false; // Stop loader
          this.misAlingnedTotal = state.filters.totalRecords;
          this.currentPage = state.filters.currentPage;
          this.itemsPerPage = state.filters.itemsPerPage;
          this.searchTerm = state.filters.searchterm;
          this.riskLevel = state.filters.riskLevel;
        }
      )
    ) {
      // If no saved state, fetch fresh data
      this.getMisAlingnedSubscription();
    }

  }
  async onNameClick(CustomerID,index) {
   try {
    await this.customerProfileService.setCustomerId(CustomerID);
    this.customerProfileService.getMinimalProfileData();
    this.renewalServiceService.setActiveTabIndex(0); // Current activeTabIndex
    this.renewalServiceService.setActiveNestedTabIndex(1);
    const filters = {
      searchTerm: this.searchTerm,
      riskLevel: this.riskLevel,
      totalRecords: this.misAlingnedTotal,
      currentPage: this.currentPage,
      itemsPerPage: this.itemsPerPage,
    };
    this.stateManagementService.saveTabIndices(0, 1);
    this.stateManagementService.saveState(
      'renewal-reports',
      this.uniqueKey, // Pass the unique key here
      filters,
      this.misAlingnedData,
      index
    );
    this.router.navigate(['/customer-dashboard/customer-profile'], {
      queryParams: { showBack: true },
    });
   } catch (error) {
    
   }
  }

  onSubscriptionIdClick(SubscriptionId,CustomerId,index){
    try {
      const filters = {
        searchTerm: this.searchTerm,
        riskLevel: this.riskLevel,
        totalRecords: this.misAlingnedTotal,
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      };
      this.renewalServiceService.setActiveTabIndex(0); // Current activeTabIndex
      this.renewalServiceService.setActiveNestedTabIndex(1);
      console.log('ids: ', SubscriptionId, CustomerId);
      this.stateManagementService.saveTabIndices(0, 1);
      this.stateManagementService.saveState(
        'renewal-reports',
        this.uniqueKey, // Pass the unique key here
        filters,
        this.misAlingnedData,
        index
      );
      this.router.navigate(['/subscriptions/subscription-details'], {
        queryParams: {
          id: SubscriptionId,
          CustomerId: CustomerId,
        },
      });
    } catch (error) {
      
    }
  }

  searchChange($event: Event): void {
    const inputElement = $event.target as HTMLInputElement;
    this.searchTerm = inputElement.value.trim(); // Capture and trim the search term
    this.currentPage = 1; // Reset to the first page for new search results
    this.getMisAlingnedSubscription(); // Fetch data with the updated search term
  }
  exportData() {
    this.isExportData = 1;
    this.getMisAlingnedSubscription();
  }
    refreshData() {
      this.riskLevel=''
    this.getMisAlingnedSubscription();
    }

    
    fetchFilteredData(): void {
      this.getMisAlingnedSubscription(); // Use the same API method to fetch filtered data
    }


    toggleRiskLevelDropdown(): void {
      this.isRiskLevelDropdownOpen = !this.isRiskLevelDropdownOpen;
    }
  
    applyRiskLevelFilter(riskLevel: string): void {
      this.riskLevel = riskLevel; // Set the risk level filter
      this.isRiskLevelDropdownOpen = false; // Close the dropdown
      this.currentPage = 1; // Reset to the first page
      this.getMisAlingnedSubscription(); // Fetch filtered data
    }
    
  
    @HostListener('document:click', ['$event'])
    closeDropdownOnClickOutside(event: Event): void {
      if (!event.target) return;
      const dropdownElement = document.querySelector('.dropdown');
      if (dropdownElement && !dropdownElement.contains(event.target as Node)) {
        this.isRiskLevelDropdownOpen = false;
      }
    }
    async getMisAlingnedSubscription(): Promise<void> {
      try {
        this.isSkeletonLoader = true; // Show loader while fetching data
        // Prepare the payload
        const data: any = {
          per_page: this.itemsPerPage,
          page: this.currentPage,
        };
    
        // Conditionally add the search term if it exists
        if (this.searchTerm) {
          data.searchTerm = this.searchTerm;
        }
    
        // Conditionally add the risk level if it exists
        if (this.riskLevel) {
          data.riskLevel = this.riskLevel;
        }
    
        // Add additional filters dynamically
        if (this.days) data.days = this.days;
        if (this.startDate) data.fromDate = this.startDate;
        if (this.endDate) data.toDate = this.endDate;
        if (this.isExportData) data.IsExport = this.isExportData;
    
        // Call the service
        const res: any = await this.renewalServiceService.getMisAlignedSubscriptions(data);
    
        if (res?.Status) {
          if (this.isExportData === 1) {
            // Handle export logic
            const anchor = document.createElement('a');
            anchor.href = res.data; // Assuming API returns a downloadable URL
            anchor.target = '_blank';
            anchor.download = 'MisAlignedSubscriptions.csv'; // Set the desired file name
            anchor.click();
            anchor.remove();
            this.commonService.showToast('success', 'Export Successful', 'Data downloaded successfully.');
            this.isExportData = 0; // Reset export flag
          } else {
            // Update data for regular API calls
            if(res.data?.data.length>0){
              this.misAlingnedData = res.data?.data || [];
              this.misAlingnedTotal = res.data?.total || 0;
              this.previousRiskLevel = this.riskLevel;
            }else{
              this.commonService.showToast('info','Mis-Aligned Subscriptions','No Record Found');
              this.riskLevel = this.previousRiskLevel || '';
              
              return;
            }

          }
        } else {
          this.commonService.showToast('error', 'Error', res?.message || 'An error occurred while fetching data.');
        }
      } catch (error) {
        this.commonService.showToast('error', 'Error', error.message || 'An error occurred');
        console.error('Error fetching data:', error);
      } finally {
        this.isSkeletonLoader = false; // Hide loader
      }
    }
    
  
  
  getStatusBadgeClass(status: string): any {
    switch (status) {
      case "Active":
        return "badge badge-success";
      case "New":
        return "badge badge-primary";
      case "Deactivated":
        return "badge badge-danger";
      case "Suspended":
        return "badge badge-warning";
      case "Pending":
        return "badge badge-secondary";
      case "Cancelled":
        return "badge badge-danger";
      default:
        return "";
    }
  }

  getRiskLevelWidth(status: string): string {
    switch (status) {
      case 'Critical':
        return '100%';
      case 'High':
        return '75%';
      case 'Medium':
        return '50%';
      case 'Low':
        return '25%';
      default:
        return '0%';
    }
  }
  getRiskLevelTooltip(riskLevel: string): string {
    switch (riskLevel) {
      case 'Critical':
        return 'Critical: Immediate attention required!';
      case 'High':
        return 'High: Immediate attention required!';
      case 'Medium':
        return 'Medium: Moderate risk.';
      case 'Low':
        return 'Low: Minimal risk.';
      default:
        return 'Unknown: No risk level specified.';
    }
  }
  
  onMisAlignedPageChange(page: number) {
    this.currentPage = page;
    this.getMisAlingnedSubscription(); // Fetch the new page data
  }
  
  
  
}
